import React from 'react';
import classes from './DashboardNav.module.css';
import DashbordItem from './DashbordItem';
import pubImage from '../images/pubberryimage.png';
import refImage from '../images/RefBerryImage.png'
import liboImage from '../images/liboberyimage.png'
export default function DashboardNav(props) {


  return (
    <div className={classes.NavItem}>

      <DashbordItem title="LiboBerry" src={liboImage} href="/panel/projects">
        LiboBerry editor is the AI-powered editor for making enriched scientific documents. By LiboBerry editor you can upload your reading documents and use them for automated citation and plagiarism proofreading.
      </DashbordItem>
      <DashbordItem title="PubBerry" src={pubImage} href="/PubBerry">
        PubBerry is a PubMed XML generator that abides by all PubMed XML standards for indexing your papers in the PubMed database. With this service, we automated XML generation with AI-powered document parsers.
      </DashbordItem>
      <DashbordItem title="RefBerry" src={refImage} href="/RefBerry">
        Have ever had a problem checking your scientific documents’ reference styles?  RefBerry checks your reference styles just with one click and lets you know if your references comply with the selected style or not!
      </DashbordItem>

      <br />

    </div>
  );
}
