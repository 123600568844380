import React, {useEffect, useState} from 'react';
import classes from './CustomDatePicker.module.css';
import CustomTextField from "../CustomTextField/CustomTextField";
import Dialog from '@material-ui/core/Dialog';
import {IconButton} from "@material-ui/core";
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import PropTypes from 'prop-types';

let mos = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novemeber', 'Decemeber']
let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const CustomDatePicker = props => {
    const [currPage, setCurrPage] = useState(0) // month
    const [year, setYear] = useState(2021);
    const [day, setDay] = useState(new Date().getDate());
    const [open, setOpen] = useState(false);
    const {value, onSelect, label, name, id, dark} = props;
    const [refresh, setRefresh] = useState(0);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
        setRefresh(Math.random());
    };

    const handleNextMonthClick = () => {
        if (currPage < 11)
            setCurrPage(currPage => currPage + 1);
        else {
            handleNextYearClick();
            setCurrPage(0);
        }
    }

    const handlePrevMonthClick = () => {
        if (currPage > 0)
            setCurrPage(currPage => currPage - 1);
        else {
            handlePrevYearClick();
            setCurrPage(11);
        }
    }

    const handleNextYearClick = () => {
        setYear(year => year + 1);
    }
    const handlePrevYearClick = () => {
        setYear(year => year - 1);
    }

    const firstDay = new Date(year, currPage, 1).getDay();
    const lastDay = new Date(year, currPage + 1, 0).getDate();

    useEffect(() => {
        let date = value;
        if (!value || !!!value.trim()) {
            date = (new Date()).toLocaleDateString()
        }
        const [m, d, y] = date.split('/');
        setYear(+y);
        setCurrPage(m - 1);
        setDay(+d);
    }, [value, refresh])

    return (
        <div className={classes.wrapper}>
            <CustomTextField
                dark={dark}
                value={value}
                name={name}
                id={id}
                label={label}
                variant="outlined"
                onClick={handleOpen}
            />
            <Dialog onClose={handleClose} style={{borderRadius: 20}} open={open}>
                <div id="datepicker" className={classes.datepicker}>
                    <div className={classes.header}>
                        <div className={classes.yearPicker}>
                            <IconButton style={{color: 'white'}} onClick={handlePrevYearClick}>
                                <IndeterminateCheckBoxIcon/>
                            </IconButton>
                            <div className={classes.headerYear}>{year}</div>
                            <IconButton style={{color: 'white'}} onClick={handleNextYearClick}>
                                <AddBoxIcon/>
                            </IconButton>
                        </div>
                        <div className={classes.monthPicker}>
                            <IconButton style={{color: 'white'}} onClick={handlePrevMonthClick}>
                                <NavigateBeforeIcon/>
                            </IconButton>
                            <h4 className={classes.headerMonth}>{mos[currPage]}</h4>
                            <IconButton style={{color: 'white'}} onClick={handleNextMonthClick}>
                                <NavigateNextIcon/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.daysNames}>
                        {days.map(day => <div key={day} className={classes.dayName}>{day}</div>)}
                    </div>
                    <div className={classes.table}>
                        {Array(firstDay).fill().map((index) => <div key={index}
                            className={[classes.day, classes.empty].join(' ')}/>)}
                        {
                            Array(lastDay).fill().map((a, index) => (
                                <div
                                    className={classes.day}
                                    onClick={() => {
                                        onSelect(`${currPage + 1}/${index + 1}/${year}`);
                                        handleClose();
                                    }}
                                >
                                    {index + 1}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default CustomDatePicker;

CustomDatePicker.propTypes = {
    onSelect: PropTypes.func,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    dark: PropTypes.bool
}

CustomDatePicker.defaultProps = {
    onSelect: () => {
    },
    label: '',
    name: '',
    id: '',
    dark: true
}