import axios from 'axios';
import getToken from './getToken';
import urls from './urls';

// axios configuration: sets CSRF Token to the request header and also sets the baseURL.
export default function configAxios() {
  axios.defaults.baseURL = urls.baseURL;

  axios.interceptors.request.use(function (config) {
    config.headers['X-CSRFToken'] = getToken();
    config.withCredentials = true;
    return config;
  });
}
