import React from "react";
import { toast } from "react-toastify";
// get number of mounth and return  month name
// type value is short or long
export const getMonthName = (monthNumber, type) => {
  if (monthNumber) {
    try {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-US", { month: type }) + ".";
    } catch (ex) {
      console.log(ex);
    }
  }
  return "";
};
export const onlyUnique = (value, index, array) => {
  return (
    array
      .map((word) => word.toLowerCase().trim())
      .indexOf(value.toLowerCase().trim()) === index
  );
};
/// get array of date like return 23 jun. 2024
export const getDateFormat = (array) => {
  let date = "";
  try {
    if (array && array.length != 0) {
      if (array[2]) {
        date += array[2] + " ";
      }
      if (array[1]) {
        let month = getMonthName(array[1], "short");
        date += month + (month == "" ? "" : " ");
      }
      if (array[0]) {
        date += array[0];
      }
    }
  } catch (ex) {
    console.log(ex);
  }
  return date;
};
export const findReferenceIds = (Htmlcontent) => {
  let div = document.createElement("div");
  div.innerHTML = Htmlcontent;
  let ReferenceIdList = [];
  let refs = div.getElementsByTagName("ref");
  for (let ref of refs) {
    if (
      ref.className != null &&
      ref.className != undefined &&
      ref.className.startsWith("class_ref")
    ) {
      const refId = ref.className.replace("class_ref", "");
      if (
        ReferenceIdList == null ||
        ReferenceIdList.length == 0 ||
        ReferenceIdList.findIndex((x) => x == refId) == -1
      ) {
        ReferenceIdList.push(refId);
      }
    }
  }
  return ReferenceIdList;
};
export const DownloadExportFile = (
  type,
  filename,
  contentDisposition,
  headerContentType,
  data
) => {
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1].replace(/['"]/g, "");
    }
  }

  if (type === "HTML") {
    const myWindow = window.open("", "_blank");
    myWindow.document.open();
    myWindow.document.write(data);
    myWindow.document.close();
  } else {
    // For binary types (like 'pdf', 'zip'), create an object URL for download
    // Use response type 'blob' for correct handling of binary data
    const blob = new Blob([data], {
      type: headerContentType,
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link); // Append link to the body before triggering the download
    link.click();
    document.body.removeChild(link); // Clean up
    window.URL.revokeObjectURL(url); // Free up memory by releasing the object URL
  }
};
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const validateMultiEmail = (emails) => {
  const emailArray = emails.split(",").map((email) => email.trim());
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  for (const email of emailArray) {
    if (!emailRegex.test(email.toLowerCase())) {
      return false;
    }
  }

  return true;
};
