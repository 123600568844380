import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./SearchResult.modeule.css";
import "../../../Loader/skeleton.css";
import "../../../LiboBerryCss/liboBerry.css";
import { saveXref } from "../../../../Requests/Requests";
import { getDateFormat } from "../../../../Functions/liboFunction";
import { toast } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import SortIcon from "@material-ui/icons/Sort";
import { IconButton } from "@material-ui/core";
import { SortTypeList } from "./SortTypeList";

function SearchResult(props) {
  const [result, setResult] = useState(null);
  let sort = null;
  let publisher = "All Publisher";
  let sortType = "Relevancy";
  let addlist = [];
  useEffect(() => {
    sort = null;
    publisher = "All Publisher";
    sortType = "Relevancy";
    addlist = [];
    setResult(
      CreateSearchItems(props.status, props.references, props.errorMsg)
    );
  }, [props.status, props.references, props.errorMsg]);

  const getSortItem = (references) => {
    if (sortType == "Relevancy" && (sort == null || sort == "desc")) {
      return references;
    } else if (sortType == "Relevancy" && sort == "asc") {
      return references.reverse();
    }
    if (sortType == "Year" && (sort == null || sort == "desc")) {
      return references
        .sort((a, b) => {
          let aYear = a.issued["date-parts"][0] || null;
          let bYear = b.issued["date-parts"][0] || null;
          if (aYear == null) return -1;
          if (bYear == null) return 1;
          if (aYear > bYear) return 1;
          if (aYear == bYear) return 0;
          return -1;
        })
        .reverse();
    } else if (sortType == "Year" && sort == "asc") {
      return references.sort((a, b) => {
        let aYear = a.issued["date-parts"][0] || null;
        let bYear = b.issued["date-parts"][0] || null;
        if (aYear == null) return -1;
        if (bYear == null) return 1;
        if (aYear > bYear) return 1;
        if (aYear == bYear) return 0;
        return -1;
      });
    }
    if (sortType == "Publisher" && (sort == null || sort == "desc")) {
      return references.filter(
        (s) => s.publisher == publisher || publisher == "All Publisher"
      );
    } else if (sortType == "Publisher" && sort == "asc") {
      return references
        .filter((s) => s.publisher == publisher || publisher == "All Publisher")
        .reverse();
    }
  };
  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };
  const getAllPublisher = (references, defaultItem) => {
    let list = [];
    list.push(defaultItem);
    if (references && references.length > 0) {
      for (let item of references) {
        if (
          list.indexOf(
            (s) =>
              s.toLowerCase() == item.publisher.toString().trim().toLowerCase()
          ) < 0
        ) {
          list.push(item.publisher.toString().trim());
        }
      }
    }
    return list.filter(onlyUnique);
  };
  const getSortPanel = (publisherList) => {
    return (
      <>
        <div className="SearchResult_sort_box">
          <select
            title={sortType}
            className={"SearchResult_Select_sort"}
            onChange={(e) => {
              sortType = e.target.value;
              if (sortType != "Publisher") {
                publisher = "All Publisher";
              }
              setResult(
                CreateSearchItems(
                  props.status,
                  props.references,
                  props.errorMsg
                )
              );
            }}
          >
            {SortTypeList.map((item, index) => {
              return (
                <>
                  {sortType == item.value && (
                    <option key={index} selected value={item.value}>
                      {item.title}
                    </option>
                  )}
                  {sortType != item.value && (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  )}
                </>
              );
            })}
          </select>
          <select
            title={publisher}
            onChange={(e) => {
              publisher = e.target.value;
              setResult(
                CreateSearchItems(
                  props.status,
                  props.references,
                  props.errorMsg
                )
              );
            }}
            style={{ display: sortType != "Publisher" ? "none" : "" }}
            className={"SearchResult_Select_sort_publisher"}
          >
            {publisherList.map((item, index) => {
              return (
                <>
                  {publisher == item && (
                    <option key={index} selected value={item}>
                      {item}
                    </option>
                  )}
                  {publisher != item && (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )}
                </>
              );
            })}
          </select>
          <IconButton
            title={sort == null ? "desc" : sort}
            className={
              sort == null
                ? ""
                : sort == "asc"
                ? "SearchResult_sortAsc"
                : "SearchResult_sortDesc"
            }
            onClick={() => {
              sort = sort == null || sort == "desc" ? "asc" : "desc";
              setResult(
                CreateSearchItems(
                  props.status,
                  props.references,
                  props.errorMsg
                )
              );
            }}
          >
            <SortIcon />
          </IconButton>
        </div>
        <hr />
      </>
    );
  };
  const findBtnClick = (e) => {
    let btn = e.target;
    while (btn != null && btn.tagName != "A") {
      btn = btn.parentNode;
    }
    return btn;
  };
  const AddToKnowledgeManegment = (e, refId) => {
    let btn = findBtnClick(e);

    if (btn.disabled == true) {
      return;
    }

    let projectId = props.projectId;
    let Parametrs = {
      search_results_ids: [refId],
    };

    btn.disabled = true;

    saveXref(projectId, Parametrs)
      .then((response) => {
        btn.disabled = false;
        if (response.status == 200) {
          if (response.data.message) {
            toast.success(response.data.message);
            btn.outerHTML = "<span>&nbsp;</span>";
          } else {
            addlist.push(refId.toString());
            toast.success("Saved to knowledge base successfully.");
            btn.outerHTML = "<span>&nbsp;</span>";
            props.onAddToKnowledgeManegment(response.data.citations);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        let btn = findBtnClick(e);
        btn.disabled = false;
        if (error.status == 400) {
          console.log(
            "Seems like there is a problem To Add it in the citation",
            error
          );
        } else {
          toast.warn("The document might already added to your KB!");
          console.log("The document might already added to your KB!", error);
        }
      });
  };

  const CreateSearchItems = (status, references, errorMsg) => {
    if (status == "loading") {
      return (
        <>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
        </>
      );
    }
    if (status == "error400") {
      return (
        <>
          <div className={"SearchResult_error"}>
            Please make sure about your search filters and &nbsp;
            <a
              className={"underline" + " " + "pointer"}
              onClick={() => props.onSearch()}
            >
              Try Again
            </a>
            .
          </div>
        </>
      );
    } else if (status == "errorCustom") {
      return (
        <>
          <div className={"SearchResult_error"}>{errorMsg}</div>
        </>
      );
    } else if (status == "errorServer") {
      return (
        <>
          <div className={"SearchResult_error"}>
            Ops! Seems like there is a problem with the server. Please&nbsp;
            <a
              className={"underline" + " " + "pointer"}
              onClick={() => props.onSearch()}
            >
              Try Again
            </a>
            .
          </div>
        </>
      );
    } else if (status == "success") {
      let list = [];
      if (
        references != null &&
        references != undefined &&
        references.length != 0
      ) {
        for (let item of references) {
          list.push(item);
        }
      }
      let publisherList = getAllPublisher(list, "All Publisher");
      let sortItem = getSortPanel(publisherList);
      let sortList = getSortItem(list);

      if (sortList == null || sortList == undefined || sortList.length == 0) {
        return (
          <>
            {sortItem}
            <div className={"SearchResult_info"}>
              No records were found. You can click on the Filter button to
              search .
            </div>
          </>
        );
      } else {
        let referencesList = [];
        referencesList.push(sortItem);
        for (let ref of sortList) {
          let authors =
            ref.author && ref.author.length > 0
              ? ref.author
                  .map((b) => {
                    return b.family + ". " + b.given;
                  })
                  .join(", ")
              : "";

          referencesList.push(
            <div key={ref.id} className={"SearchResult_result"}>
              <div>
                <div className={"SearchResult_resultTitle"}>{ref.title}. </div>

                {authors != "" && (
                  <div title={authors} className={"SearchResult_resultAuthor"}>
                    {" "}
                    {authors}
                  </div>
                )}
                <div>
                  <span className={"SearchResult_publisher"}>
                    {ref.publisher}.
                  </span>
                  <span className={"SearchResult_Date"}>
                    &nbsp;{getDateFormat(ref.issued["date-parts"][0]) || ""}
                  </span>
                </div>
              </div>
              <div className={"SearchResult_btnArea"}>
                {addlist.indexOf((s) => s == ref.id.toString()) < 0 && (
                  <a
                    className={"SearchResult_btnAddToKnowledgeManegment"}
                    onClick={(e) => {
                      AddToKnowledgeManegment(e, ref.id);
                    }}
                  >
                    <AddIcon className={"SearchResult_IconSize"} />{" "}
                    <span>Add To Knowledge Management</span>
                  </a>
                )}
                {addlist.indexOf((s) => s == ref.id.toString()) >= 0 && (
                  <span>&nbsp;</span>
                )}
                <a
                  className={"SearchResult_btnDoi"}
                  href={"https://doi.org/" + ref.DOI}
                  target={"_blank"}
                >
                  <OpenInNewIcon className={"SearchResult_IconSize"} />{" "}
                  <span>View in new Tab</span>
                </a>
              </div>
            </div>
          );
        }
        return referencesList;
      }
    }
    return <></>;
  };

  return <>{result}</>;
}
export default SearchResult;

SearchResult.propTypes = {
  status: PropTypes.string,
  references: PropTypes.arrayOf(PropTypes.object),
  errorMsg: PropTypes.string,
  projectId: PropTypes.number,
  onSearch: PropTypes.func,
  onAddToKnowledgeManegment: PropTypes.func,
};

SearchResult.defaultProps = {
  status: null,
  errorMsg: null,
  references: [],
  projectId: 0,
  onSearch: () => {},
  onAddToKnowledgeManegment: (result) => {},
};
