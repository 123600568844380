import React from "react";
import classes from "./ProjectCard.module.css";
import AssignmentIcon from "@material-ui/icons/Assignment";

function ProjectCard(props) {
  let projects = [];
  if (props.project == null) {
    projects = [];
  }
  if (props.projects.length > 3) {
    projects = props.projects
      .sort((a, b) => {
        let dateA = new Date(a.last_modified);
        let dateB = new Date(b.last_modified);
        return dateB - dateA;
      })
      .slice(0, 3); //We can only Show 3 project
  } else if (props.projects.length > 0) {
    projects = props.projects.sort((a, b) => {
      let dateA = new Date(a.last_modified);
      let dateB = new Date(b.last_modified);
      return dateB - dateA;
    });
  }

  let header; //we dont know projects at this moment -header of projectcard depends  on its lenghts
  if (props.projects.length == 0) {
    header = "Start New Project ";
  } else {
    header = " Open Latest Projects";
  }

  var content = null;
  if (projects.length == 0) {
    content = (
      <div className={classes.Icon}>
        <h3 className={classes.Text}>No Project</h3>
        <a href="/panel/projects" className={classes.Link}>
          <button className={classes.Btn}>Create New One</button>
        </a>
      </div>
    );
  }

  if (projects.length > 0) {
    content = projects.map((project) => {
      return (
        <a
          href={`/panel/projects/${project.id}`}
          key={project.id}
          className={classes.Link}
        >
          <div className={classes.Icon}>
            <AssignmentIcon
              className={classes.muiIcon}
              style={{
                fontSize: 17,
                marginTop: "11px",
              }}
            />
            <h3 title={project.title} className={classes.Text}>
              {project.title}
            </h3>

            <button className={classes.Btn}> Open Now</button>
          </div>
        </a>
      );
    });
  }

  return (
    <div className={classes.ProjectCard}>
      <h4 className={classes.Header}>{header}</h4>
      {content}
    </div>
  );
}
export default ProjectCard;

ProjectCard.defaultProps = {
  projects: [],
};
