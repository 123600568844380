export const removeSuggestionsFromHTML = (html) => {
  let newHTML = html
    .replace(
      /(<sug[^>]+>)|(<\/sug>)|(\s?data-plg="[^"]+"\s?)|(\s?data-plg='[^']+'\s?)|(\s?data-sug="[^"]+"\s?)|(\s?data-sug='[^']+'\s?)|(\s?class="sug"\s?)|(\s?class='sug'\s?)/g,
      ""
    )
    .replace(/<sug[^>]+>/g, "")
    .replace(/(<(?:sug)[^<]*)(style="[^"]*")([^>]*>)/gi, "")
    .replace(/<\/sug>/g, "")
    .replace(/(<editImagesBtn[^>]*>)[^f]*(<\/editImagesBtn>)/gi, "")
    .replace(/(<aiautocomplete.*.<\/aiautocomplete>)/iu, "")
    .replace(/data-sug="[^"]+"/g, "")
    .replace(/data-sug='[^"]+'/g, "")
    .replace(/data-plg="[^"]+"/g, "")
    .replace(/data-plg='[^"]+'/g, "")
    .replace(/class="sug"/g, "")
    .replace(/class = 'sug'/g, "")
    .replace(/<sug>/g, "");

  return newHTML;
};
export const hasClass = (element, cls) => {
  return (" " + element.className + " ").indexOf(" " + cls + " ") > -1;
};

export const saveCursor = function () {
  const selection = window.getSelection();
  return selection.rangeCount === 0 ? null : selection.getRangeAt(0);
};

export const restoreCursor = function (range) {
  try {
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  } catch (ex) {}
};
export const CreateGuid = () => {
  function _p8(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
};
