import {Button} from "@material-ui/core";

const CustomButton = props => {
    const {style, ...otherProps} = props;
    return (
        <Button
            style={{
                backgroundColor : 'white',
                width: '100%',
                height: 55,
                margin: 5,
                borderRadius: 15,
                color: 'var(--light-red)',
                ...(style || {})
            }}
            {...otherProps}>
            {props.children}
        </Button>
    )
}

export default CustomButton;