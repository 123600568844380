import React, { isValidElement, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import { SendFeedback } from "../../Requests/Requests.js";
import { feeddbackResults } from "./feeddbackResults.js";
import classes from "./FeedBack.module.css";
import FeedbackDialog from "./component/FeedbackDialog.js";
import Iconformenu from "./component/icon.js";
import menuClasses from "./Menu.module.css";
import MessageIcon from "@material-ui/icons/Message";
import VideocamIcon from "@material-ui/icons/Videocam";
import { ReactComponent as DiscordIcon } from "./Images/discord.svg";
import ShortcutIcon from "../Icons/ShortcutIcon.js";
import ShortcutDialog from "././component/ShortcutDialog.js";
import propTypes from "prop-types";
function FeedBack(props) {
  const [showPopup, setShowPopup] = useState(props.showPopup);
  const [showfeedback, setShowfeedback] = useState(false);
  const [selectTopic, setSelectTopic] = useState("");
  const [content, setContent] = useState("");
  const [customTopic, setCustomTopic] = useState("");
  const menuRef = useRef(null);
  const [showShortcutDialog, setShowShortcutDialog] = useState(false);

  useEffect(() => {
    setShowPopup(props.showPopup);
  }, [props.showPopup]);

  const handleOpenShortcutDialog = () => {
    props.setShowPopup(false);
    setShowPopup(false);
    setShowShortcutDialog(true);
  };

  const handleCloseShortcutDialog = () => {
    props.setShowPopup(false);
    setShowPopup(false);
    setShowShortcutDialog(false);
  };

  const handleOpenAddTagDialog = (event) => {
    props.setShowPopup(false);
    setShowPopup(false);
    setShowfeedback(true);
  };

  const handleCloseDialog = () => {
    setShowfeedback(false);
  };

  const validationFeedBack =
    selectTopic != "" &&
    selectTopic != null &&
    content != "" &&
    content != null &&
    (selectTopic != "Others" ||
      (selectTopic == "Others" && customTopic != "" && customTopic != null));

  const sendFeedback = () => {
    if (validationFeedBack) {
      let topic = selectTopic == "Others" ? customTopic?.trim() : selectTopic;
      let message = content?.trim();
      let data = {
        project_id: props.projectId,
        topic: topic,
        message: message,
      };
      // setShowfeedback(false);
      SendFeedback(data)
        .then((res) => {
          if (res.status >= 200 && res.status <= 299) {
            toast.success(res.data.message);
            handleCloseDialog();
          } else {
            toast.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err, err.response);
          toast.error(err.response.data.error);
        });
    }
  };

  useEffect(() => {
    setSelectTopic("");
    setContent("");
    setCustomTopic("");
    props.setShowPopup(false);
    setShowPopup(false);
  }, [showfeedback]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest(`.${classes.QuestionMarkIcon}`)
      ) {
        props.setShowPopup(false);
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);
  return (
    <>
      <div
        style={{
          position: props.position,
          bottom: props.bottom,
          right: props.bottom,
        }}
        className={classes.popUpIconButtonContainer}
      >
        <Iconformenu
          showPopup={showPopup}
          onSetShowPopup={() => {
            props.setShowPopup(!showPopup);
            setShowPopup((prevShowPopup) => !prevShowPopup);
          }}
          showbtnPopup={true}
          toolTip="Help"
          title="Menu"
          position={props.position}
          bWidth={props.bWidth}
          bHeight={props.bHeight}
          custombtn={
            <div
              style={{ backgroundColor: props.bgcolor, color: props.color }}
              className={classes.QuestionMarkIcon}
            >
              <span className={classes.AnimationQuestionMark}>?</span>
            </div>
          }
          customValue={
            <div ref={menuRef} className={menuClasses.Menu}>
              <div
                className={menuClasses.MenuItem}
                onClick={handleOpenAddTagDialog}
              >
                <div className={menuClasses.MenuItemicons}>
                  <MessageIcon
                    style={{ color: "#7b6bc9" }}
                    className={menuClasses.MenuItemIcon}
                  />
                </div>
                Question/Report bug
              </div>
              <div
                onClick={() => {
                  let tagA = document.createElement("a");
                  tagA.href =
                    "https://www.youtube.com/channel/UCvf7RlumuuCz7gQklZAI4uA";
                  tagA.target = "_blank";
                  tagA.click();
                }}
                className={menuClasses.MenuItem}
              >
                <div className={menuClasses.MenuItemicons}>
                  <VideocamIcon
                    style={{ color: "#7b6bc9" }}
                    className={menuClasses.MenuItemIcon}
                  />
                </div>
                YouTube Channel
              </div>
              <div
                onClick={() => {
                  let tagA = document.createElement("a");
                  tagA.href = "https://discord.gg/2FBD4p2ThM";
                  tagA.target = "_blank";
                  tagA.click();
                }}
                className={menuClasses.MenuItem}
              >
                <div className={menuClasses.MenuItemicons}>
                  <DiscordIcon className={menuClasses.MenuItemIcon} />
                </div>
                Join Discard
              </div>
              <div
                className={menuClasses.MenuItem}
                onClick={handleOpenShortcutDialog}
              >
                <div className={menuClasses.MenuItemicons}>
                  <ShortcutIcon
                    color="#7b6bc9"
                    style={{ marginRight: "8px" }}
                  />
                </div>
                Shortcuts
              </div>
            </div>
          }
        />
      </div>

      <FeedbackDialog
        open={showfeedback}
        onClose={handleCloseDialog}
        selectTopic={selectTopic}
        setSelectTopic={setSelectTopic}
        content={content}
        setContent={setContent}
        customTopic={customTopic}
        setCustomTopic={setCustomTopic}
        validationFeedBack={validationFeedBack}
        sendFeedback={sendFeedback}
      />
      <ShortcutDialog
        open={showShortcutDialog}
        onClose={handleCloseShortcutDialog}
      />
    </>
  );
}

FeedBack.propTypes = {
  projectId: PropTypes.number,
  projectId: PropTypes.number.isRequired,
  position: PropTypes.string,
  bgcolor: propTypes.string,
  color: PropTypes.string,
};

FeedBack.defaultProps = {
  projectId: null,
  position: null,
  bgcolor: null,
  color: null,
  bottom: null,
  right: null,
  setShowPopup: () => { },
  showPopup: false,
};

export default FeedBack;
