import React, { useState, useEffect } from "react";
import PropTypes, { bool } from "prop-types";
import { getXref } from "../../../../Requests/Requests";
import DiscoveryFilter from "./DiscoveryFilter";
import SearchResult from "./SearchResult";
import { onlyUnique } from "../../../../Functions/liboFunction";
function Discovery(props) {
  const [searchParametrs, setSearchParametrs] = useState({
    title: props.title,
    author: props.author,
    year: props.year,
    doi: props.doi,
    content: props.content,
    keyWords:
      props.keyWords == null
        ? []
        : props.keyWords
            .split(",")
            .map((word) => word.trim())
            .filter((s) => s.trim() != "")
            .filter(onlyUnique),
  });
  const [status, SetStatus] = useState("loading");
  const [discoveryResponse, SetDiscoveryResponse] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    discoverySearch(
      searchParametrs.content,
      searchParametrs.title,
      searchParametrs.author,
      searchParametrs.year,
      searchParametrs.doi,
      searchParametrs.keyWords
    );
  }, []);

  const discoverySearch = (content, title, author, year, doi, keyWords) => {
    let parametrs = {
      title: title,
      author: author,
      year: year,
      doi: doi,
      content: content,
      keywords: keyWords,
    };
    console.log(parametrs);
    SetStatus("loading");
    SetDiscoveryResponse(null);
    setErrorMsg(null);
    console.log(parametrs);
    getXref(props.projectId, parametrs)
      .then((response) => {
        if (response.status == 400) {
          SetStatus("error400");
        } else {
          if (response.data.error) {
            SetStatus("errorCustom");
            setErrorMsg(response.data.error);
            SetDiscoveryResponse(null);
          } else {
            SetStatus("success");
            SetDiscoveryResponse(response.data.references);
            setErrorMsg(null);
          }
        }
      })
      .catch((error) => {
        console.log("Failed to Descovers:", error);
        SetDiscoveryResponse(null);
        setErrorMsg(null);

        if (error.response.status == 400) {
          SetStatus("error400");
        } else {
          SetStatus("errorServer");
        }
      });
  };

  return (
    <>
      <DiscoveryFilter
        content={searchParametrs.content}
        title={searchParametrs.title}
        author={searchParametrs.author}
        year={searchParametrs.year}
        doi={searchParametrs.doi}
        keyWords={searchParametrs.keyWords}
        isOpen={props.showFilter}
        onIsOpen={(value) => {
          props.onShowFilter(value);
        }}
        onSearch={(result) => {
          setSearchParametrs({
            searchParametrs: result.content,
            searchParametrs: result.title,
            searchParametrs: result.author,
            searchParametrs: result.year,
            searchParametrs: result.doi,
            searchParametrs: result.keyWords,
          });
          discoverySearch(
            result.content,
            result.title,
            result.author,
            result.year,
            result.doi,
            result.keyWords
          );
        }}
      />

      <SearchResult
        status={status}
        references={discoveryResponse}
        errorMsg={errorMsg}
        projectId={props.projectId}
        onSearch={() => {
          discoverySearch(
            searchParametrs.content,
            searchParametrs.title,
            searchParametrs.author,
            searchParametrs.year,
            searchParametrs.doi,
            searchParametrs.keyWords
          );
        }}
        onAddToKnowledgeManegment={(result) => {
          props.onAddToKnowledgeManegment(result);
        }}
      />
    </>
  );
}

export default Discovery;
Discovery.propTypes = {
  projectId: PropTypes.number,
  content: PropTypes.string,
  title: PropTypes.string,
  year: PropTypes.number,
  autour: PropTypes.string,
  doi: PropTypes.string,
  keyWords: PropTypes.string,
  showFilter: PropTypes.bool,
  onAddToKnowledgeManegment: PropTypes.func,
  onShowFilter: PropTypes.func,
};

Discovery.defaultProps = {
  projectId: 0,
  content: "",
  title: "",
  year: new Date().getFullYear(),
  autour: "",
  doi: "",
  keyWords: "",
  onAddToKnowledgeManegment: (result) => {},
  showFilter: bool,
  onShowFilter: (value) => {},
};
