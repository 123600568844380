import React, { useState, useEffect } from "react";

import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  LinearProgress,
  CircularProgress,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
} from "@material-ui/core";
import { validateEmail } from "../../../Functions/liboFunction.js";
import ExtentionIcon from "../../../components/ExtentionIcon/ExtentionIcon.js";
import { Fade } from "react-reveal";
import "./NewProjectDialog.css";
import "../../../components/LiboBerryCss/liboBerry.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import { createProject } from "../../../Requests/Requests";
import { projectTypes } from "../ProjectTypes";
import { CitationStyles } from "../Citation_styles.js";
import settingClasses from "../../../Containers/Settings.module.css";
function NewProjectDialog({
  handleCloseNewProjectModal,
  newProjectNameRef,
  setCurrentStep,
  currentStep,
  refreshProjects,
  onClose,
  open,
  props,
}) {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = React.useState("");
  const [citationStyle, setCitationStyle] = useState("");
  const [projectType, setProjectType] = useState("");
  const [authorsList, setAuthorsList] = useState([]);
  const [authorName, setAuthorName] = React.useState("");
  const [authorLastName, setAuthorLastName] = React.useState("");
  const [authorEmail, setAuthorEmail] = React.useState("");
  const [isCorresponding, setIsCorresponding] = useState(false);
  const [isCreateOutline, setIsCreateOutline] = useState(true);
  const [isCreateProject, setIsCreateProject] = useState(false);
  const totalSteps = 3;
  const progress = ((currentStep - 1) / (totalSteps - 1)) * 100;

  const Weak = "Too short";
  const Average = "Hmm! not bad";
  const Strong = "Wow! well done";

  useEffect(() => {
    clearForm();
  }, [open]);
  const clearForm = () => {
    setIsCreateProject(false);
    setProjectName("");
    setProjectDescription("");
    setCitationStyle("");
    setProjectType("");
    setAuthorName("");
    setAuthorLastName("");
    setAuthorEmail("");
    setAuthorsList([]);
    setIsCorresponding(false);
    setIsCreateOutline(true);
    setCurrentStep(1);
  };
  const handleDeleteAuthor = (indexToRemove) => {
    let autors = authorsList;
    if (autors == null) autors = [];
    if (autors.length > 0 && indexToRemove > -1) {
      autors.splice(indexToRemove, 1);
    }
    setAuthorsList(autors.filter((_, index) => index !== -1));
  };

  const countWords = (text) => {
    return text.split(/\s+/).filter(Boolean).length;
  };

  const calculateProgress = (wordCount) => {
    if (wordCount >= 15) {
      // Word count is 10 or more, progress is 100%
      return 100;
    }
    return (wordCount / 15) * 100;
  };

  const descriptionStrength = (text) => {
    const wordCount = countWords(text);
    if (wordCount > 10) return Strong;
    if (wordCount > 5) return Average;
    return Weak;
  };

  const isStepOneValid = () => {
    return (
      projectName.trim() !== "" &&
      citationStyle.trim() !== "" &&
      projectType.trim() !== ""
    );
  };
  let dragOn = null;
  const handleAuthorDragStart = (e) => {
    e.target.style.opacity = "0.4";
    e.effectAllowed = "move";
    e.dataTransfer.setData("text", e.target.id);
    dragOn = "authors";
  };
  const handleDragEnd = (e) => {
    e.target.style.opacity = "1";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    var parentNode = e.target;
    var tr = e.target;
    while (parentNode != null) {
      console.log(parentNode.tagName);
      if (parentNode.tagName == "TBODY") {
        break;
      }
      if (parentNode.tagName == "TR") {
        tr = parentNode;
      }
      parentNode = parentNode.parentNode;
    }
    if (parentNode == null || tr == null) {
      return false;
    }

    let indexDragEl = e.dataTransfer.getData("text").replace(dragOn, "");
    let indexDropEl = tr.id.replace(dragOn, "");

    if (parentNode.id == "TbodyAuthor" && dragOn == "authors") {
      let autors = authorsList;
      if (autors == null) autors = [];
      if (autors.length > 0) {
        array_move(autors, indexDragEl, indexDropEl);
        setAuthorsList(autors.filter((_, index) => index !== -1));
      }
    }
    return false;
  };
  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return;
  };

  const handleAddAuthor = () => {
    let result = true;
    if (authorName == null || authorName?.trim() == "") {
      result = false;
      toast.error("Enter Autor First Name");
    }
    if (authorLastName == null || authorLastName?.trim() == "") {
      result = false;
      toast.error("Enter Autor Last Name");
    }
    if (authorEmail == null || authorEmail?.trim() == "") {
      result = false;
      toast.error("Enter Autor Email");
    } else if (!validateEmail(authorEmail?.trim())) {
      result = false;
      toast.error("The entered email format is not correct");
    }
    if (result) {
      // Add the current author to the authors list
      const newAuthor = {
        surname: authorName.trim(),
        giveName: authorLastName.trim(),
        email: authorEmail.trim(),
        isCorresponding: isCorresponding,
      };
      setAuthorsList([...authorsList, newAuthor]);
      // Clear the input fields and reset isCorresponding
      setAuthorName("");
      setAuthorLastName("");
      setAuthorEmail("");
      setIsCorresponding(false);
    }
  };

  const isDisabledNext = () => {
    return (
      (currentStep === 1 && !isStepOneValid()) ||
      (currentStep === 2 && descriptionStrength(projectDescription) === Weak)
    );
  };
  const handleCreateProject = () => {
    const data = {
      title: projectName,
      pined: false,
      archived: false,
      type: projectType,
      citation_style: citationStyle,
      description: projectDescription,
      authors: authorsList,
      create_outline: isCreateOutline,
      labels: [],
    };

    setIsCreateProject(true);
    createProject(data)
      .then((res) => {
        toast.success("Project created!");
        if (res.status >= 200 && res.status <= 299) {
          window.location.href = "./projects/" + res.data.id;
        }
        // refreshProjects();
        handleCloseNewProjectModal();
      })
      .catch((err) => {
        setIsCreateProject(false);
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          toast.error("Error creating project!");
        }
        console.log(err, err.response);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="new-project-dialog-title"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className={"dialog-title-panel "}
      >
        {currentStep > 1 && (
          <Button
            onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
            className="backButton"
          >
            <ArrowBackIcon />
          </Button>
        )}
        {/* Adjusts the Typography to align properly when the back button is present */}
        {currentStep == 1 && <>Project Info</>}
        {currentStep == 2 && <>Tell us more about the project?</>}
        {currentStep == 3 && <>Author Details</>}
        <div style={{ width: 48 }} />{" "}
        {/* Placeholder to balance the header layout */}
      </DialogTitle>

      <LinearProgress
        variant="determinate"
        value={progress}
        style={{ marginTop: "2px" }}
      />

      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className={"liboScroll"}
      >
        {currentStep === 1 && (
          <div className={"dialog-action-content"}>
            <Typography variant="h6" className="typography-center-mb11">
              What is your project title?
            </Typography>
            <TextField
              inputProps={{ ref: newProjectNameRef }}
              id="filled-basic"
              label="Project name"
              variant="outlined"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="textfield-custom"
            />

            <Typography variant="h6" className="typography-center-mb10">
              Where is your project going to be published?
            </Typography>
            <RadioGroup
              style={{ marginLeft: "-11px" }}
              aria-label="project-type"
              name="projectType"
              value={projectType}
              onChange={(e) => {
                setProjectType(e.target.value);
              }}
              className="radiogroup-flex-row-wrap"
            >
              {projectTypes.map((type) => (
                <FormControlLabel
                  key={type.key}
                  className="formcontrollabel-custom"
                  value={type.key}
                  control={<Radio />}
                  label={
                    <span className="label-span-custom">{type.label}</span>
                  }
                />
              ))}
            </RadioGroup>

            <Typography variant="h6" className="typography-center-mb10">
              What bibliography style should be considered?
            </Typography>
            <RadioGroup
              style={{ marginLeft: "-11px" }}
              aria-label="citation-style"
              name="citationStyle"
              value={citationStyle}
              onChange={(e) => setCitationStyle(e.target.value)}
              row
            >
              {CitationStyles.map((type) => (
                <FormControlLabel
                  key={type.key}
                  className="formcontrollabel-custom"
                  value={type.key}
                  control={<Radio />}
                  label={
                    <span className="label-span-custom">{type.label}</span>
                  }
                />
              ))}
            </RadioGroup>
          </div>
        )}

        {currentStep === 2 && (
          <>
            <br />
            <TextField
              id="project-description"
              variant="outlined"
              label="Project Description ..."
              multiline
              rows={6}
              InputLabelProps={{ shrink: true }}
              placeholder="Please provide additional details about your project using technical keywords. This will help us better understand your needs."
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              className="textfield-project-description"
            />
            <Box className="box-flex-center">
              <Typography variant="body2" color="textSecondary">
                Description Strength:
                <span
                  className={
                    descriptionStrength(projectDescription) === Weak
                      ? "WeakColorLine"
                      : descriptionStrength(projectDescription) === Average
                      ? "AverageColorLine"
                      : "StrongColorLine"
                  }
                >
                  {countWords(projectDescription) == 0
                    ? "-"
                    : descriptionStrength(projectDescription)}
                </span>
              </Typography>
            </Box>

            <LinearProgress
              variant="determinate"
              value={calculateProgress(countWords(projectDescription))}
              className={
                "linear-progress-custom" +
                " " +
                (descriptionStrength(projectDescription) === Weak
                  ? "WeakColorLine"
                  : descriptionStrength(projectDescription) === Average
                  ? "AverageColorLine"
                  : "StrongColorLine")
              }
            />
            <div style={{ paddingTop: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCreateOutline}
                    onChange={(e) => setIsCreateOutline(e.target.checked)}
                  />
                }
                label="Outline Builder: automatically create your document headings "
                className="checkbox-margin"
              />
            </div>
          </>
        )}

        {currentStep === 3 && (
          <div className="container">
            <div className="form-section">
              <br />
              <TextField
                id="author-name"
                label="Author's First Name"
                variant="outlined"
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
                className="textfield-fullwidth-mb20"
              />
              <TextField
                id="author-family"
                label="Author's Last Name"
                variant="outlined"
                value={authorLastName}
                onChange={(e) => setAuthorLastName(e.target.value)}
                className="textfield-fullwidth-mb20"
              />
              <TextField
                id="author-email"
                label="Author's Email"
                variant="outlined"
                value={authorEmail}
                onChange={(e) => setAuthorEmail(e.target.value)}
                className="textfield-fullwidth-mb20"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCorresponding}
                    onChange={(e) => setIsCorresponding(e.target.checked)}
                  />
                }
                label="Is Corresponding "
                className="checkbox-margin"
              />
              <Button
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
                onClick={handleAddAuthor}
                className="button-margin-20-0"
              >
                Add Author
              </Button>
            </div>
            <div className={"list-section liboScroll"}>
              <Table aria-label="caption table">
                <TableBody
                  id={"TbodyAuthor"}
                  className={settingClasses.whiteTable}
                >
                  {(!authorsList || authorsList.length === 0) && (
                    <TableRow key="emptyautour">
                      <TableCell
                        colSpan={6}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          paddingRight: "0px",
                          paddingLeft: "10px",
                          paddingTop: "20px",
                        }}
                        align="center"
                      >
                        The authors list is empty...
                      </TableCell>
                    </TableRow>
                  )}
                  {(authorsList || []).map((author, index) => (
                    <TableRow
                      key={author.email}
                      id={"authors" + index}
                      className={"drag-box"}
                      draggable={true}
                      onDragStart={(e) => {
                        handleAuthorDragStart(e);
                      }}
                      onDragEnd={(e) => {
                        handleDragEnd(e);
                      }}
                      onDragOver={(e) => {
                        handleDragOver(e);
                      }}
                      onDrop={(e) => {
                        handleDrop(e);
                      }}
                    >
                      <TableCell
                        style={{
                          padding: "10px",
                          paddingRight: "0px",
                        }}
                        scope="row"
                      >
                        <div
                          title={author.surname + " " + author.giveName}
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "190px",
                          }}
                        >
                          {author.surname} {author.giveName}
                        </div>
                        <div
                          title={author.email}
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "190px",
                          }}
                        >
                          {author.email}
                        </div>

                        {author.isCorresponding ? (
                          <div>
                            <ExtentionIcon
                              FileName={"envelope"}
                              width="20px"
                              style={{ paddingTop: "0px" }}
                            />
                            &nbsp;Corresponding Author
                          </div>
                        ) : (
                          ""
                        )}
                      </TableCell>

                      <TableCell
                        style={{
                          paddingRight: "0px",
                          paddingLeft: "0px",
                        }}
                        align="right"
                      >
                        <Fade top>
                          <IconButton
                            className={settingClasses.LiboberryColor}
                            onClick={() => {
                              handleDeleteAuthor(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Fade>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className="dialog-action-panel">
        <Button
          onClick={handleCloseNewProjectModal}
          color="primary"
          className={"liboBtnCancel"}
        >
          Cancel
        </Button>
        <div>
          {currentStep < totalSteps ? (
            <Button
              onClick={() => {
                if (!isDisabledNext()) {
                  setCurrentStep((prevStep) => prevStep + 1);
                }
              }}
              color="primary"
              disabled={isDisabledNext()}
              className={isDisabledNext() ? "liboBtnDisable" : "liboBtnApprove"}
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (!isCreateProject) {
                  handleCreateProject();
                }
              }}
              color="primary"
              autoFocus
              className={isCreateProject ? "liboBtnDisable" : "liboBtnApprove"}
            >
              Create
              {isCreateProject && (
                <>
                  &nbsp;
                  <CircularProgress style={{ width: "15px", height: "15px" }} />
                </>
              )}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default NewProjectDialog;
