import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../LiboBerryCss/liboBerry.css";
import classesFloatSuggestion from "./floatSuggestion.module.css";
import TabItem from "./TabMenu/TabItem";
import ConfiemDialog from "./ConfirmDialog";
import { IconButton } from "@material-ui/core";
import Paraphrase from "./Paraphrase";
import RefreshIcon from "@material-ui/icons/Refresh";
import { createPlagiarismSuggestionResult } from "../../../../Functions/FloatSuggestion.js";
function Plagiarism(props) {
  const [sugCheckContent, setSugCheckContent] = useState(null);
  const [floatSuggestionTab, setFloatSuggestionTab] = useState(1);
  const [showParaphrase, setShowParaphrase] = useState(false);
  const [sugContent, setSugContent] = useState(null);
  const [paraphraseValue, setParaphraseValue] = useState(null);
  const [paraphraseStatus, setParaphraseStatus] = useState(null);

  const getPlagiarismSuggestionColor = (value) => {
    let result = "";
    if (value >= 0.5) result = "#ff0000d1";
    else if (value < 0.5 && value >= 0.3) result = "#fd8e1e";
    else if (value < 0.3 && value >= 0.2) result = "#f5ff07c2";
    else if (value < 0.2 && value >= 0.1) result = "#00d20054";
    else result = "#00d200ba";
    return result;
  };

  const createPlagiarismSuggestionHtmlResult = (suggestionValueSimPositive) => {
    let htmlRefValidateResult = [];
    for (let i = 0; i < suggestionValueSimPositive.length; i++) {
      if (i >= 4) {
        break;
      }

      htmlRefValidateResult.push(
        <div
          id={"plg_suggestionid" + i}
          key={"plg_suggestionid" + i}
          style={{
            backgroundColor: getPlagiarismSuggestionColor(
              suggestionValueSimPositive[i].tfidf_sim
            ),
          }}
          className={classesFloatSuggestion.documentPlagiarism}
        >
          <div className={classesFloatSuggestion.documentPlagiarismContent}>
            Similarity :{" "}
            {Math.floor(suggestionValueSimPositive[i].tfidf_sim * 100)}%
          </div>
          {suggestionValueSimPositive[i].doc_name}
        </div>
      );
    }
    return htmlRefValidateResult;
  };
  useEffect(() => {
    let suggestionList = createPlagiarismSuggestionResult(props.dataPlg);
    setSugContent(createPlagiarismSuggestionHtmlResult(suggestionList));
  }, [props.Element, props.dataPlg]);
  return (
    <div
      id={"floatSuggestionPlagiarism"}
      className={classesFloatSuggestion.floatSuggestionBox}
    >
      <div className={classesFloatSuggestion.floatSuggestionPanel}>
        <h4 className={classesFloatSuggestion.floatSuggestionTitle}>
          Detected similarity
        </h4>
        <IconButton
          style={{
            padding: "0px",
            color: "#ffffff",
            width: "35px",
            height: "30px",
          }}
          onClick={() => {
            props.onCloseFloatSuggestion();
          }}
        >
          &times;
        </IconButton>
      </div>
      {!sugCheckContent && (
        <>
          {" "}
          <div>
            <div className={classesFloatSuggestion.floatSuggestionHeader}>
              <TabItem
                active={floatSuggestionTab == 1}
                title={"AI plagiarism"}
                onClick={() => {
                  setFloatSuggestionTab(1);
                }}
                type={"Left"}
              />
              <TabItem
                active={floatSuggestionTab == 2}
                title={"AI Paraphrase"}
                onClick={() => {
                  setFloatSuggestionTab(2);
                  setShowParaphrase(true);
                  if (
                    paraphraseStatus != "success" &&
                    paraphraseStatus != "loading"
                  ) {
                    setParaphraseStatus(null);
                  }
                }}
                type={"Right"}
              />
            </div>
            <div
              className={
                classesFloatSuggestion.floatSuggestionContent +
                " " +
                "liboScroll"
              }
            >
              <div className={floatSuggestionTab == 1 ? "" : "hide"}>
                {sugContent}
              </div>
              <div
                className={floatSuggestionTab == 2 ? "" : "hide"}
                style={{
                  fontSize: "0.85em",
                  minHeight: "100px",
                }}
              >
                {showParaphrase && (
                  <Paraphrase
                    status={paraphraseStatus}
                    content={props.content}
                    onStatus={setParaphraseStatus}
                    onResult={setParaphraseValue}
                    ProjectId={props.ProjectId}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={classesFloatSuggestion.floatSuggestionFooter}>
            {floatSuggestionTab == 2 &&
              paraphraseStatus != null &&
              paraphraseStatus == "success" && (
                <input
                  type="button"
                  id={"btnParaphraseReplace"}
                  value={"Replace"}
                  className={classesFloatSuggestion.floatSuggestion_AcceptBtn}
                  onClick={() => {
                    setSugCheckContent(true);
                    props.onReplaceClick(props.Element, paraphraseValue);
                    setTimeout(() => {
                      props.onDismissClick(props.Element);
                    }, 1000);
                  }}
                ></input>
              )}
            {floatSuggestionTab == 2 &&
              ((paraphraseStatus == "success" && paraphraseStatus != null) ||
                paraphraseStatus == "error") && (
                <>
                  <div>
                    <a
                      style={{ fontSize: "0.8em" }}
                      className={"liboBtnRefreshWithIcon"}
                      onClick={() => {
                        setParaphraseStatus(
                          paraphraseStatus == "error" ? null : "regenerate"
                        );
                      }}
                    >
                      <>
                        {paraphraseStatus == "error" && <>Try Again</>}
                        {paraphraseStatus == "success" && <>Regenerate</>}
                        &nbsp;
                        <RefreshIcon
                          style={{ width: "0.8em" }}
                          className={"rotate360"}
                        />
                      </>
                    </a>
                  </div>
                </>
              )}

            <input
              type="button"
              value={"Dismiss"}
              id={"btnPlagiarismDismiss"}
              className={classesFloatSuggestion.floatSuggestion_DismissBtn}
              onClick={() => {
                props.onDismissClick(props.Element);
              }}
            ></input>
          </div>
        </>
      )}
      {sugCheckContent && <ConfiemDialog />}
    </div>
  );
}
export default Plagiarism;
Plagiarism.propTypes = {
  onCloseFloatSuggestion: PropTypes.func,
  Element: PropTypes.object,
  ProjectId: PropTypes.number,
  onReplaceClick: PropTypes.func,
  onDismissClick: PropTypes.func,
  content: PropTypes.string,
};
Plagiarism.defaultProps = {
  onCloseFloatSuggestion: () => {},
  Element: null,
  dataPlg: [],
  ProjectId: 0,
  onReplaceClick: () => {},
  onDismissClick: (div) => {},
  content: "",
};
