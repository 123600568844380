import classes from './SingleChoice.module.css';
import PropTypes from 'prop-types';

const SingleChoice = props => {
    const {options, active, onOptionSelect, name, ...otherProps} = props;

    return (
        <div className={classes.options}>
            {
                options.map(option => (
                    <div
                        name={name}
                        key={option.value}
                        className={[classes.option, ...(active === option.value ? [classes.active] : [])].join(' ')}
                        onClick={onOptionSelect.bind(this, option.value)}
                    >
                        {option.displayValue}
                    </div>
                ))
            }
        </div>
    )
}

export default SingleChoice;

SingleChoice.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.stringify,
            displayValue: PropTypes.stringify,
        })
    ),
        onOptionSelect: PropTypes.func
}
SingleChoice.defaultProps = {
    options: [],
    onOptionSelect: () => {},
    active: PropTypes.any
}