export const createPlagiarismSuggestionResult = (suggestionValueList) => {
  let suggestionValueSimPositive = [];
  if (suggestionValueList != null) {
    for (let suggestionValue of suggestionValueList) {
      for (let i = 0; i < suggestionValue.length; i++) {
        if (
          suggestionValue[i].tfidf_sim != null &&
          suggestionValue[i].tfidf_sim != undefined &&
          suggestionValue[i].tfidf_sim > 0
        ) {
          suggestionValueSimPositive.push(suggestionValue[i]);
        }
      }
    }
  }
  suggestionValueSimPositive.sort(function (a, b) {
    return a.tfidf_sim - b.tfidf_sim;
  });
  suggestionValueSimPositive.reverse();
  return suggestionValueSimPositive;
};
