import { useEffect, useState } from "react";

export default function useAsyncSelectList(getList = async () => { }) {
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState(null);

    const setSelectedById = (id) => {
        const selected = list.find(item => item.id + '' === id);
        setSelected(selected)
    }

    useEffect(() => {
        const doGetList = async () => {
            const list = await getList();
            setList(list);
            if (list && list.length > 0) {
                setSelected(list[0]);
            }

        }
        doGetList();
    }, []);

    return [
        list,
        selected,
        setSelectedById
    ]
}