import React, { useEffect, useState } from 'react';
import './MLCard.css';
const MLCard = ({ model, handleUpdate, handleDelete, projectName, message }) => {
    const [vectorSize, setVectorSize] = useState(model.vector_size);
    const [minCount, setMinCount] = useState(model.min_count);
    const [epochs, setEpochs] = useState(model.epochs);

    useEffect(() => {
        setVectorSize(model.vector_size);
        setMinCount(model.min_count);
        setEpochs(model.epochs);
    }, [model]);

    // Update state when input fields change
    const onVectorSizeChange = (e) => setVectorSize(e.target.value);
    const onMinCountChange = (e) => setMinCount(e.target.value);
    const onEpochsChange = (e) => setEpochs(e.target.value);


    const prepareUpdate = () => {
        // Create an object with the updated model data
        const updatedModel = {
            ...model, // Spread in the existing model data
            vector_size: Number(vectorSize), // Parse the string input value to a number
            min_count: Number(minCount),
            epochs: Number(epochs),

        };


        handleUpdate(model.project, model.id, updatedModel);

    };
    // Function to determine the class based on the model's status
    const getStatusClass = (status) => {
        switch (status) {
            case 'trained':
                return 'status-trained';
            case 'training':
                return 'status-training';
            case 'failed':
                return 'status-failed';
            default:
                return ''; // default class or no class
        }
    };

    return (
        <div>
            <div className="ml-card">
                <h3>{message}</h3>
                <h3>{projectName}.[{model.model_type || 'Unknown Model Type'}]</h3>

                <p>Status: <span className={getStatusClass(model.state)}>{model.state}</span></p>

                <label htmlFor="vectorSize">Vector Size: {vectorSize}</label>
                <input
                    id="vectorSize"
                    type="range"
                    min="1" // Set the minimum value for the slider
                    max="1024" // Set the maximum value for the slider
                    value={vectorSize}
                    className='slider'
                    onChange={(e) => setVectorSize(e.target.value)}
                />

                <label htmlFor="epochs">Epochs: {epochs}</label>
                <input
                    id="epochs"
                    type="range"
                    min="1" // Set the minimum value for the slider
                    max="100" // Set the maximum value for the slider
                    value={epochs}
                    className='slider'
                    onChange={(e) => setEpochs(e.target.value)}
                />
                <p>Created At: {model.created_at}</p>
                <p>Updated At: {model.updated_at}</p>
                <button className="update-button" onClick={prepareUpdate}>Update</button>
                <button className="delete-button" onClick={() => handleDelete(model.id)}>Delete</button>
            </div>


        </div>
    );
};

export default MLCard;