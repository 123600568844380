import { IconButton, Popover } from "@material-ui/core";
import classes from "./Toolbar.module.css";
import React from "react";
import PropTypes from 'prop-types';

function ButtonWithPopover(props) {
    const { id, icon, renderPopoverContent, onPopoverOpen, onPopoverClose, title } = props;
    const Icon = icon;

    const [imageAnchorEl, setImageAnchorEl] = React.useState(null);

    const handleOpenPopover = (event) => {
        // prevSelection = save();
        onPopoverOpen();
        setImageAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        onPopoverClose()
        setImageAnchorEl(null);
    };

    const popoverOpen = Boolean(imageAnchorEl);
    const popoverId = popoverOpen ? id : undefined;
    return (
        <>
            <IconButton title={title} id={popoverId} className={classes.ToolbarButton} onClick={handleOpenPopover}>
                {icon && <Icon />}
            </IconButton>
            <Popover
                id={popoverId}
                open={popoverOpen}
                anchorEl={imageAnchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {renderPopoverContent(handleClosePopover)}
            </Popover>
        </>
    )
}

export default ButtonWithPopover;

ButtonWithPopover.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.elementType,
    renderPopoverContent: PropTypes.func,
    onPopoverOpen: PropTypes.func,
    onPopoverClose: PropTypes.func,
}

ButtonWithPopover.defaultProps = {
    id: '',
    icon: null,
    title: '',
    renderPopoverContent: () => null,
    onPopoverOpen: () => { },
    onPopoverClose: () => { },
}