import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classes from "./AiModelIcon.module.css";

export default function AiModelIcon(props) {
  const [overAllStatus, setOverAllStatus] = useState("loading");
  useEffect(() => {
    if (
      props.step1Status == "loading" ||
      props.step2Status == "loading" ||
      props.step3Status == "loading"
    ) {
      setOverAllStatus("loading");
    } else if (
      props.step1Status == "error" ||
      props.step2Status == "error" ||
      props.step3Status == "error"
    ) {
      setOverAllStatus("error");
    } else if (
      props.step1Status == "success" &&
      props.step2Status == "success" &&
      props.step3Status == "success"
    ) {
      setOverAllStatus("success");
    }
  }, [props]);
  return (
    <svg
      width={props.width}
      viewBox="0 0 617 432"
      fill="none"
      style={{ ...props.style }}
      xmlns="http://www.w3.org/2000/svg"
    >
      {overAllStatus == "error" && <title>{props.ErrorTitle}</title>}
      {overAllStatus == "success" && <title>{props.SuccessTitle}</title>}
      {overAllStatus == "loading" && <title>{"Processing..."}</title>}
      <path
        d="M258.71 220.185C259.641 218.086 258.054 215.346 255.167 214.065C252.279 212.784 249.183 213.448 248.252 215.547C247.321 217.646 248.907 220.386 251.795 221.667C254.683 222.948 257.778 222.285 258.71 220.185Z"
        fill="white"
      />
      <path
        d="M254.729 223.087C253.606 223.079 252.497 222.838 251.473 222.377C249.992 221.747 248.747 220.668 247.913 219.291C247.118 217.914 246.983 216.472 247.527 215.226C248.071 213.98 249.24 213.108 250.794 212.777C252.372 212.476 254.004 212.677 255.461 213.351C258.732 214.802 260.499 218.007 259.396 220.491C258.678 222.161 256.846 223.087 254.729 223.087ZM252.202 214.185C251.842 214.184 251.482 214.221 251.13 214.296C250.659 214.34 250.208 214.509 249.824 214.785C249.441 215.061 249.137 215.434 248.946 215.867C248.754 216.299 248.682 216.775 248.735 217.244C248.789 217.714 248.967 218.161 249.251 218.539C249.923 219.627 250.919 220.478 252.098 220.973C254.593 222.08 257.239 221.59 257.995 219.882C258.751 218.173 257.34 215.886 254.844 214.779C254.017 214.401 253.12 214.198 252.21 214.185H252.202Z"
        fill="#2A344F"
      />
      <path
        d="M242.297 208.272C242.092 208.272 241.896 208.191 241.751 208.046C241.607 207.901 241.525 207.705 241.525 207.501C241.525 207.296 241.607 207.1 241.751 206.955C241.896 206.81 242.092 206.729 242.297 206.729C242.559 206.729 268.525 206.625 283.094 200.851C283.188 200.813 283.289 200.794 283.391 200.795C283.493 200.796 283.593 200.817 283.687 200.858C283.78 200.898 283.865 200.956 283.936 201.029C284.006 201.102 284.062 201.188 284.1 201.283C284.137 201.377 284.155 201.479 284.153 201.58C284.152 201.682 284.13 201.782 284.089 201.876C284.048 201.969 283.989 202.053 283.916 202.123C283.842 202.194 283.756 202.249 283.661 202.286C268.822 208.168 243.377 208.272 242.301 208.272H242.297Z"
        fill="#2A344F"
      />
      <path
        d="M301.465 209.125C293.67 218.359 266.836 229.051 266.836 229.051C264.969 228.507 263.087 227.893 261.235 227.269C273.516 223.165 291.051 211.03 291.051 211.03C278.434 206.116 281.493 194.136 281.493 194.136C281.493 194.136 289.47 184.879 296.274 186.333C303.078 187.787 309.261 199.887 301.465 209.125Z"
        fill="white"
      />
      <path
        d="M266.836 229.827C266.763 229.827 266.69 229.817 266.62 229.796C264.799 229.268 262.902 228.662 260.981 228.003C260.828 227.951 260.695 227.853 260.601 227.722C260.507 227.591 260.456 227.433 260.456 227.272C260.456 227.11 260.507 226.953 260.601 226.822C260.695 226.691 260.828 226.593 260.981 226.541C271.214 223.12 285.331 213.94 289.439 211.189C277.867 205.789 280.702 194.072 280.737 193.948C280.766 193.833 280.822 193.726 280.899 193.636C281.239 193.25 289.254 184.051 296.424 185.582C300.069 186.353 303.433 189.794 304.991 194.338C306.819 199.668 305.762 205.242 302.044 209.627C294.214 218.884 268.213 229.334 267.11 229.773C267.023 229.808 266.93 229.826 266.836 229.827ZM263.6 227.247C264.688 227.602 265.764 227.933 266.813 228.242C269.513 227.143 293.705 217.149 300.895 208.632C302.518 206.774 303.621 204.518 304.089 202.095C304.557 199.672 304.374 197.169 303.56 194.839C302.194 190.862 299.213 187.75 296.135 187.094C290.284 185.844 283.233 193.385 282.222 194.507C281.898 196.004 280.213 205.978 291.352 210.322C291.483 210.372 291.597 210.458 291.682 210.569C291.768 210.68 291.822 210.812 291.838 210.951C291.855 211.09 291.833 211.231 291.775 211.359C291.717 211.486 291.625 211.596 291.51 211.675C291.325 211.783 275.638 222.591 263.6 227.247Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M467.94 258.442C467.735 258.442 467.539 258.361 467.394 258.216C467.25 258.071 467.168 257.875 467.168 257.67V253.721C467.168 253.516 467.25 253.32 467.394 253.175C467.539 253.031 467.735 252.949 467.94 252.949C468.144 252.949 468.341 253.031 468.485 253.175C468.63 253.32 468.711 253.516 468.711 253.721V257.67C468.711 257.875 468.63 258.071 468.485 258.216C468.341 258.361 468.144 258.442 467.94 258.442Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M473.895 260.449H469.957C469.752 260.449 469.556 260.368 469.412 260.223C469.267 260.079 469.186 259.882 469.186 259.678C469.186 259.473 469.267 259.277 469.412 259.132C469.556 258.988 469.752 258.906 469.957 258.906H473.907C474.111 258.908 474.307 258.991 474.451 259.136C474.594 259.282 474.674 259.479 474.672 259.683C474.671 259.888 474.588 260.084 474.442 260.227C474.297 260.371 474.1 260.451 473.895 260.449Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M467.94 266.407C467.735 266.407 467.539 266.325 467.394 266.181C467.25 266.036 467.168 265.84 467.168 265.635V261.685C467.168 261.481 467.25 261.285 467.394 261.14C467.539 260.995 467.735 260.914 467.94 260.914C468.144 260.914 468.341 260.995 468.485 261.14C468.63 261.285 468.711 261.481 468.711 261.685V265.635C468.711 265.84 468.63 266.036 468.485 266.181C468.341 266.325 468.144 266.407 467.94 266.407Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M465.93 260.449H461.98C461.776 260.449 461.58 260.368 461.435 260.223C461.29 260.079 461.209 259.882 461.209 259.678C461.209 259.473 461.29 259.277 461.435 259.132C461.58 258.988 461.776 258.906 461.98 258.906H465.93C466.135 258.906 466.331 258.988 466.476 259.132C466.62 259.277 466.702 259.473 466.702 259.678C466.702 259.882 466.62 260.079 466.476 260.223C466.331 260.368 466.135 260.449 465.93 260.449Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M202.202 82.0093C201.998 82.0093 201.801 81.928 201.657 81.7833C201.512 81.6386 201.431 81.4424 201.431 81.2378V75.8378C201.431 75.6332 201.512 75.437 201.657 75.2924C201.801 75.1477 201.998 75.0664 202.202 75.0664C202.407 75.0664 202.603 75.1477 202.748 75.2924C202.892 75.437 202.974 75.6332 202.974 75.8378V81.2378C202.974 81.4424 202.892 81.6386 202.748 81.7833C202.603 81.928 202.407 82.0093 202.202 82.0093Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M210.372 84.7616H204.972C204.767 84.7616 204.571 84.6803 204.426 84.5356C204.281 84.391 204.2 84.1948 204.2 83.9902C204.2 83.7856 204.281 83.5894 204.426 83.4447C204.571 83.3 204.767 83.2188 204.972 83.2188H210.372C210.576 83.2188 210.772 83.3 210.917 83.4447C211.062 83.5894 211.143 83.7856 211.143 83.9902C211.143 84.1948 211.062 84.391 210.917 84.5356C210.772 84.6803 210.576 84.7616 210.372 84.7616Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M202.202 92.9311C201.998 92.9311 201.801 92.8499 201.657 92.7052C201.512 92.5605 201.431 92.3643 201.431 92.1597V86.7597C201.431 86.5551 201.512 86.3589 201.657 86.2142C201.801 86.0696 201.998 85.9883 202.202 85.9883C202.407 85.9883 202.603 86.0696 202.748 86.2142C202.892 86.3589 202.974 86.5551 202.974 86.7597V92.1597C202.974 92.3643 202.892 92.5605 202.748 92.7052C202.603 92.8499 202.407 92.9311 202.202 92.9311Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M199.448 84.7616H194.048C193.843 84.7616 193.647 84.6803 193.502 84.5356C193.358 84.391 193.276 84.1948 193.276 83.9902C193.276 83.7856 193.358 83.5894 193.502 83.4447C193.647 83.3 193.843 83.2188 194.048 83.2188H199.448C199.652 83.2188 199.849 83.3 199.993 83.4447C200.138 83.5894 200.219 83.7856 200.219 83.9902C200.219 84.1948 200.138 84.391 199.993 84.5356C199.849 84.6803 199.652 84.7616 199.448 84.7616Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M420.972 57.6813C420.767 57.6813 420.571 57.6 420.426 57.4553C420.281 57.3107 420.2 57.1144 420.2 56.9098V52.4433C420.2 52.2387 420.281 52.0425 420.426 51.8978C420.571 51.7531 420.767 51.6719 420.972 51.6719C421.176 51.6719 421.372 51.7531 421.517 51.8978C421.662 52.0425 421.743 52.2387 421.743 52.4433V56.9098C421.743 57.1144 421.662 57.3107 421.517 57.4553C421.372 57.6 421.176 57.6813 420.972 57.6813Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M427.702 59.9491H423.235C423.031 59.9491 422.834 59.8678 422.69 59.7232C422.545 59.5785 422.464 59.3823 422.464 59.1777C422.464 58.9731 422.545 58.7769 422.69 58.6322C422.834 58.4875 423.031 58.4062 423.235 58.4062H427.702C427.906 58.4062 428.103 58.4875 428.247 58.6322C428.392 58.7769 428.473 58.9731 428.473 59.1777C428.473 59.3823 428.392 59.5785 428.247 59.7232C428.103 59.8678 427.906 59.9491 427.702 59.9491Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M420.972 66.6852C420.767 66.6852 420.571 66.604 420.426 66.4593C420.281 66.3146 420.2 66.1184 420.2 65.9138V61.4511C420.2 61.2465 420.281 61.0503 420.426 60.9056C420.571 60.761 420.767 60.6797 420.972 60.6797C421.176 60.6797 421.372 60.761 421.517 60.9056C421.662 61.0503 421.743 61.2465 421.743 61.4511V65.9177C421.742 66.1216 421.66 66.3168 421.516 66.4607C421.371 66.6045 421.176 66.6853 420.972 66.6852Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M418.699 59.9491H414.233C414.028 59.9491 413.832 59.8678 413.687 59.7232C413.543 59.5785 413.461 59.3823 413.461 59.1777C413.461 58.9731 413.543 58.7769 413.687 58.6322C413.832 58.4875 414.028 58.4062 414.233 58.4062H418.699C418.904 58.4062 419.1 58.4875 419.245 58.6322C419.39 58.7769 419.471 58.9731 419.471 59.1777C419.471 59.3823 419.39 59.5785 419.245 59.7232C419.1 59.8678 418.904 59.9491 418.699 59.9491Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_1 : classes.display_none
        }
        d="M413.951 152.884C417.828 152.884 420.971 149.741 420.971 145.864C420.971 141.987 417.828 138.844 413.951 138.844C410.074 138.844 406.931 141.987 406.931 145.864C406.931 149.741 410.074 152.884 413.951 152.884Z"
        fill="#53669B"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M140.088 340.175C142.897 340.175 145.175 337.897 145.175 335.088C145.175 332.278 142.897 330 140.088 330C137.278 330 135 332.278 135 335.088C135 337.897 137.278 340.175 140.088 340.175Z"
        fill="#53669B"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_1 : classes.display_none
        }
        d="M215.451 372.185C215.282 372.185 215.117 372.128 214.982 372.025C214.848 371.922 214.751 371.777 214.707 371.613C214.664 371.449 214.675 371.276 214.74 371.119C214.804 370.962 214.919 370.831 215.065 370.746L236.507 358.288L220.693 349.169C220.576 349.101 220.479 349.004 220.412 348.887C220.344 348.77 220.309 348.637 220.309 348.502C220.309 348.367 220.344 348.234 220.412 348.117C220.479 348 220.576 347.903 220.693 347.835L244.091 334.238C244.267 334.146 244.472 334.126 244.663 334.181C244.854 334.236 245.016 334.364 245.116 334.536C245.216 334.708 245.245 334.912 245.197 335.105C245.15 335.299 245.03 335.466 244.862 335.573L222.618 348.502L238.432 357.62C238.549 357.688 238.646 357.785 238.714 357.902C238.781 358.02 238.816 358.152 238.816 358.288C238.816 358.423 238.781 358.555 238.714 358.673C238.646 358.79 238.549 358.887 238.432 358.955L215.841 372.069C215.724 372.142 215.589 372.182 215.451 372.185Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M166.211 361.512C166.043 361.51 165.879 361.454 165.746 361.35C165.613 361.247 165.518 361.104 165.474 360.941C165.43 360.778 165.441 360.606 165.504 360.45C165.568 360.294 165.681 360.163 165.825 360.077L193.253 343.943L165.598 327.978C165.481 327.91 165.384 327.813 165.316 327.696C165.249 327.579 165.213 327.446 165.213 327.311C165.213 327.176 165.249 327.043 165.316 326.926C165.384 326.809 165.481 326.711 165.598 326.644L196.871 308.465C197.048 308.373 197.253 308.352 197.444 308.408C197.635 308.463 197.797 308.59 197.897 308.762C197.996 308.935 198.026 309.139 197.978 309.332C197.931 309.525 197.811 309.693 197.643 309.799L167.522 327.322L195.163 343.279C195.28 343.347 195.377 343.445 195.444 343.562C195.512 343.679 195.547 343.812 195.547 343.947C195.547 344.082 195.512 344.215 195.444 344.332C195.377 344.449 195.28 344.546 195.163 344.614L166.593 361.42C166.476 361.483 166.344 361.515 166.211 361.512Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M164.19 368.238C159.981 368.238 156.684 366.387 156.684 364.023C156.684 361.658 159.981 359.807 164.19 359.807C168.398 359.807 171.696 361.658 171.696 364.023C171.696 366.387 168.398 368.238 164.19 368.238ZM164.19 361.353C160.676 361.353 158.226 362.761 158.226 364.026C158.226 365.292 160.676 366.699 164.19 366.699C167.703 366.699 170.153 365.292 170.153 364.026C170.153 362.761 167.703 361.353 164.19 361.353Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_1 : classes.display_none
        }
        d="M175.819 291.43H154.262C154.057 291.43 153.861 291.348 153.716 291.204C153.571 291.059 153.49 290.863 153.49 290.658C153.49 290.454 153.571 290.257 153.716 290.113C153.861 289.968 154.057 289.887 154.262 289.887H175.815C176.02 289.886 176.216 289.967 176.361 290.111C176.506 290.256 176.588 290.452 176.589 290.656C176.589 290.861 176.508 291.057 176.364 291.202C176.22 291.347 176.024 291.429 175.819 291.43Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_1 : classes.display_none
        }
        d="M147.527 294.873C143.319 294.873 140.021 293.022 140.021 290.657C140.021 288.293 143.315 286.438 147.527 286.438C151.739 286.438 155.033 288.289 155.033 290.653C155.033 293.018 151.743 294.873 147.527 294.873ZM147.527 287.988C144.013 287.988 141.564 289.396 141.564 290.661C141.564 291.926 144.013 293.334 147.527 293.334C151.041 293.334 153.49 291.926 153.49 290.661C153.49 289.396 151.041 287.98 147.527 287.98V287.988Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M406.75 372.184C406.615 372.184 406.482 372.148 406.365 372.08L383.773 358.966C383.657 358.898 383.559 358.801 383.492 358.684C383.425 358.566 383.389 358.434 383.389 358.299C383.389 358.163 383.425 358.031 383.492 357.914C383.559 357.796 383.657 357.699 383.773 357.631L399.588 348.513L377.344 335.584C377.251 335.536 377.17 335.469 377.104 335.389C377.038 335.308 376.989 335.215 376.96 335.115C376.931 335.015 376.923 334.911 376.935 334.807C376.948 334.704 376.981 334.604 377.033 334.514C377.085 334.424 377.155 334.346 377.238 334.283C377.322 334.221 377.417 334.176 377.518 334.151C377.619 334.126 377.724 334.122 377.826 334.139C377.929 334.156 378.027 334.193 378.115 334.249L401.512 347.846C401.629 347.914 401.726 348.011 401.794 348.128C401.861 348.245 401.897 348.378 401.897 348.513C401.897 348.648 401.861 348.781 401.794 348.898C401.726 349.015 401.629 349.112 401.512 349.18L385.698 358.299L407.14 370.757C407.281 370.845 407.391 370.976 407.452 371.131C407.513 371.285 407.522 371.456 407.478 371.616C407.434 371.777 407.34 371.919 407.209 372.021C407.077 372.123 406.917 372.181 406.75 372.184Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M464.746 366.397C464.611 366.396 464.478 366.361 464.361 366.293L427.035 344.604C426.918 344.536 426.821 344.439 426.754 344.322C426.686 344.205 426.651 344.072 426.651 343.937C426.651 343.801 426.686 343.669 426.754 343.552C426.821 343.434 426.918 343.337 427.035 343.269L454.679 327.324L424.551 309.816C424.459 309.768 424.377 309.702 424.311 309.621C424.246 309.541 424.197 309.448 424.168 309.348C424.139 309.248 424.13 309.143 424.143 309.04C424.155 308.936 424.189 308.837 424.241 308.747C424.293 308.657 424.363 308.578 424.446 308.516C424.529 308.453 424.624 308.408 424.725 308.383C424.826 308.359 424.931 308.355 425.034 308.372C425.136 308.388 425.235 308.426 425.322 308.482L456.6 326.66C456.717 326.728 456.814 326.826 456.881 326.943C456.949 327.06 456.984 327.193 456.984 327.328C456.984 327.463 456.949 327.596 456.881 327.713C456.814 327.83 456.717 327.927 456.6 327.995L428.96 343.952L465.132 364.973C465.272 365.061 465.379 365.192 465.439 365.346C465.499 365.499 465.508 365.668 465.465 365.828C465.422 365.987 465.329 366.128 465.199 366.23C465.07 366.333 464.911 366.391 464.746 366.397Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M466.767 373.123C462.559 373.123 459.261 371.272 459.261 368.907C459.261 366.543 462.559 364.691 466.767 364.691C470.975 364.691 474.273 366.543 474.273 368.907C474.273 371.272 470.975 373.123 466.767 373.123ZM466.767 366.238C463.253 366.238 460.804 367.646 460.804 368.911C460.804 370.176 463.253 371.584 466.767 371.584C470.281 371.584 472.73 370.176 472.73 368.911C472.73 367.646 470.281 366.238 466.767 366.238Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M377.718 212.446C377.548 212.446 377.384 212.39 377.249 212.286C377.115 212.183 377.018 212.038 376.974 211.874C376.93 211.711 376.941 211.537 377.006 211.38C377.071 211.223 377.185 211.092 377.332 211.007L399.576 198.078L383.762 188.96C383.645 188.892 383.548 188.795 383.481 188.678C383.413 188.561 383.378 188.428 383.378 188.293C383.378 188.157 383.413 188.025 383.481 187.908C383.548 187.79 383.645 187.693 383.762 187.625L406.353 174.511C406.441 174.455 406.539 174.418 406.642 174.401C406.745 174.384 406.85 174.388 406.951 174.413C407.052 174.438 407.147 174.482 407.23 174.545C407.313 174.607 407.383 174.686 407.435 174.776C407.487 174.866 407.52 174.966 407.533 175.069C407.546 175.172 407.537 175.277 407.508 175.377C407.479 175.477 407.43 175.57 407.364 175.65C407.298 175.731 407.217 175.797 407.125 175.846L385.683 188.304L401.497 197.422C401.614 197.49 401.711 197.588 401.779 197.705C401.846 197.822 401.882 197.955 401.882 198.09C401.882 198.225 401.846 198.358 401.779 198.475C401.711 198.592 401.614 198.689 401.497 198.757L378.1 212.353C377.983 212.417 377.851 212.449 377.718 212.446Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_1 : classes.display_none
        }
        d="M417.816 250.878C417.647 250.878 417.482 250.821 417.347 250.718C417.213 250.615 417.116 250.47 417.072 250.306C417.028 250.142 417.04 249.969 417.104 249.812C417.169 249.655 417.284 249.524 417.43 249.439L442.938 234.612L415.297 218.655C415.181 218.588 415.083 218.49 415.016 218.373C414.949 218.256 414.913 218.123 414.913 217.988C414.913 217.853 414.949 217.72 415.016 217.603C415.083 217.486 415.181 217.389 415.297 217.321L452.6 195.628C452.688 195.572 452.786 195.535 452.889 195.518C452.991 195.501 453.096 195.505 453.197 195.53C453.298 195.555 453.393 195.6 453.477 195.662C453.56 195.724 453.63 195.803 453.682 195.893C453.734 195.983 453.767 196.083 453.78 196.186C453.792 196.289 453.784 196.394 453.755 196.494C453.726 196.594 453.677 196.687 453.611 196.768C453.545 196.848 453.463 196.915 453.371 196.963L417.199 217.984L444.839 233.941C444.956 234.009 445.053 234.106 445.121 234.223C445.188 234.341 445.224 234.473 445.224 234.608C445.224 234.744 445.188 234.876 445.121 234.994C445.053 235.111 444.956 235.208 444.839 235.276L418.179 250.77C418.069 250.835 417.944 250.873 417.816 250.878Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_1 : classes.display_none
        }
        d="M455.026 197.227C450.818 197.227 447.52 195.375 447.52 193.011C447.52 190.646 450.818 188.795 455.026 188.795C459.234 188.795 462.532 190.646 462.532 193.011C462.532 195.375 459.234 197.227 455.026 197.227ZM455.026 190.342C451.512 190.342 449.063 191.749 449.063 193.015C449.063 194.28 451.512 195.688 455.026 195.688C458.54 195.688 460.989 194.28 460.989 193.015C460.989 191.749 458.54 190.342 455.026 190.342Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M205.666 245.763C205.53 245.763 205.397 245.727 205.28 245.659L179.283 230.55C179.166 230.482 179.069 230.385 179.001 230.268C178.934 230.151 178.898 230.018 178.898 229.883C178.898 229.748 178.934 229.615 179.001 229.498C179.069 229.381 179.166 229.283 179.283 229.216L202.067 216.063L190.314 209.166C190.218 209.12 190.133 209.055 190.063 208.974C189.994 208.894 189.942 208.8 189.911 208.698C189.879 208.596 189.869 208.489 189.881 208.384C189.893 208.278 189.927 208.176 189.98 208.084C190.034 207.992 190.106 207.912 190.191 207.849C190.277 207.786 190.375 207.741 190.479 207.718C190.583 207.695 190.69 207.693 190.794 207.713C190.899 207.734 190.998 207.775 191.086 207.835L203.965 215.399C204.082 215.467 204.179 215.564 204.246 215.682C204.313 215.799 204.349 215.931 204.349 216.067C204.349 216.202 204.313 216.335 204.246 216.452C204.179 216.569 204.082 216.666 203.965 216.734L181.188 229.887L206.032 244.324C206.177 244.408 206.291 244.538 206.356 244.692C206.422 244.847 206.434 245.019 206.393 245.182C206.352 245.345 206.258 245.49 206.127 245.594C205.995 245.699 205.834 245.758 205.666 245.763Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M189.045 209.407C185.416 209.407 182.677 207.865 182.677 205.774C182.677 203.683 185.416 202.141 189.045 202.141C192.675 202.141 195.414 203.683 195.414 205.774C195.414 207.865 192.675 209.407 189.045 209.407ZM189.045 203.683C186.284 203.683 184.22 204.787 184.22 205.774C184.22 206.761 186.284 207.865 189.045 207.865C191.807 207.865 193.871 206.761 193.871 205.774C193.871 204.787 191.807 203.683 189.045 203.683Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M467.94 291.43H446.386C446.182 291.43 445.985 291.348 445.841 291.204C445.696 291.059 445.615 290.863 445.615 290.658C445.615 290.454 445.696 290.257 445.841 290.113C445.985 289.968 446.182 289.887 446.386 289.887H467.94C468.144 289.887 468.341 289.968 468.485 290.113C468.63 290.257 468.711 290.454 468.711 290.658C468.711 290.863 468.63 291.059 468.485 291.204C468.341 291.348 468.144 291.43 467.94 291.43Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_3 : classes.display_none
        }
        d="M474.674 294.873C470.466 294.873 467.168 293.022 467.168 290.657C467.168 288.293 470.466 286.441 474.674 286.441C478.883 286.441 482.18 288.293 482.18 290.657C482.18 293.022 478.883 294.873 474.674 294.873ZM474.674 287.988C471.161 287.988 468.711 289.396 468.711 290.661C468.711 291.926 471.161 293.334 474.674 293.334C478.188 293.334 480.638 291.926 480.638 290.661C480.638 289.396 478.188 287.98 474.674 287.98V287.988Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M310.692 403.509C310.487 403.509 310.291 403.427 310.146 403.283C310.002 403.138 309.92 402.942 309.92 402.737V375.494C309.92 375.289 310.002 375.093 310.146 374.949C310.291 374.804 310.487 374.723 310.692 374.723C310.896 374.723 311.093 374.804 311.237 374.949C311.382 375.093 311.463 375.289 311.463 375.494V402.737C311.463 402.942 311.382 403.138 311.237 403.283C311.093 403.427 310.896 403.509 310.692 403.509Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_2 : classes.display_none
        }
        d="M310.692 413.951C306.484 413.951 303.186 412.1 303.186 409.735C303.186 407.371 306.484 405.52 310.692 405.52C314.9 405.52 318.198 407.371 318.198 409.735C318.198 412.1 314.9 413.951 310.692 413.951ZM310.692 407.066C307.178 407.066 304.729 408.474 304.729 409.739C304.729 411.004 307.178 412.412 310.692 412.412C314.206 412.412 316.655 411.004 316.655 409.739C316.655 408.474 314.206 407.066 310.692 407.066Z"
        fill="#2A344F"
      />
      <path
        className={
          overAllStatus == "error"
            ? classes.error_Window
            : overAllStatus == "success"
            ? classes.success_Window
            : ""
        }
        id="floor"
        d="M437.357 273.285L311.101 346.659L184.023 273.289L310.279 199.918L437.357 273.285Z"
        fill="#CCD3E4"
      />
      <path
        d="M311.101 347.426C310.965 347.426 310.832 347.39 310.715 347.322L183.638 273.955C183.521 273.887 183.424 273.79 183.356 273.673C183.289 273.556 183.253 273.423 183.253 273.288C183.253 273.153 183.289 273.02 183.356 272.903C183.424 272.786 183.521 272.688 183.638 272.621L309.886 199.265C310.003 199.198 310.136 199.162 310.271 199.162C310.407 199.162 310.54 199.198 310.657 199.265L437.734 272.632C437.851 272.7 437.948 272.797 438.016 272.914C438.083 273.032 438.119 273.164 438.119 273.299C438.119 273.435 438.083 273.567 438.016 273.684C437.948 273.802 437.851 273.899 437.734 273.967L311.479 347.337C311.362 347.399 311.232 347.429 311.101 347.426ZM185.562 273.288L311.097 345.767L435.818 273.288L310.271 200.808L185.562 273.288Z"
        fill="#CCD3E4"
      />
      <path
        d="M281.829 298.857L261.181 286.934C261.045 286.854 260.89 286.813 260.732 286.812C260.574 286.813 260.419 286.854 260.283 286.934L234.054 302.178L235.848 303.211L260.73 288.751L279.588 299.636L267.245 306.798L254.698 314.092L256.491 315.13L268.159 308.349L281.833 300.411C281.967 300.331 282.078 300.219 282.156 300.083C282.233 299.948 282.275 299.795 282.276 299.64C282.278 299.481 282.237 299.325 282.158 299.188C282.08 299.05 281.966 298.936 281.829 298.857Z"
        fill="white"
      />
      <path
        d="M332.593 309.463L311.791 321.536L333.117 333.879L331.324 334.92L309.998 322.608L289.775 334.342L287.981 333.308L309.55 320.764L332.138 307.65C332.275 307.571 332.431 307.529 332.589 307.529C332.747 307.529 332.903 307.571 333.04 307.65L355.705 320.737L353.919 321.775L332.593 309.463Z"
        fill="white"
      />
      <path
        d="M424.917 280.513L423.132 281.554L385.895 260.058L353.927 278.642L338.429 287.641L375.662 309.136L373.868 310.178L336.188 288.42C336.052 288.342 335.94 288.229 335.861 288.094C335.783 287.958 335.741 287.805 335.74 287.648C335.742 287.492 335.783 287.34 335.861 287.204C335.939 287.069 336.05 286.957 336.184 286.877L351.69 277.863L287.954 241.066L208.347 287.351L206.553 286.314L287.514 239.249H287.538L287.63 239.21L287.703 239.183L287.792 239.16H287.873H288.043H288.135H288.216C288.244 239.167 288.271 239.176 288.297 239.187L288.382 239.226H288.409L353.472 276.821L385.436 258.241C385.572 258.162 385.727 258.12 385.885 258.12C386.043 258.12 386.198 258.162 386.334 258.241L424.917 280.513Z"
        fill="white"
      />
      <path
        className={
          overAllStatus == "loading" ? classes.state_1 : classes.display_none
        }
        d="M364.823 231.404L352.592 238.513L349.919 236.97L327.524 224.041L339.755 216.932L337.962 215.899L325.731 223.007L298.048 207.023L296.255 208.065L325.28 224.82L352.137 240.326C352.206 240.366 352.28 240.396 352.357 240.415C352.431 240.436 352.507 240.446 352.584 240.446H352.661C352.714 240.443 352.765 240.435 352.816 240.422C352.842 240.416 352.868 240.406 352.893 240.395C352.941 240.379 352.987 240.358 353.032 240.334L366.609 232.442L364.823 231.404Z"
        fill="white"
      />
      <path
        d="M262.628 241.046L251.03 234.365L249.236 235.407L260.387 241.84L230.938 258.955L219.787 252.494L218.001 253.531L230.49 260.744C230.626 260.823 230.781 260.864 230.938 260.864C231.097 260.864 231.254 260.823 231.393 260.744L262.636 242.589C262.77 242.509 262.881 242.396 262.959 242.261C263.036 242.126 263.078 241.973 263.079 241.817C263.079 241.66 263.037 241.506 262.958 241.371C262.879 241.235 262.765 241.123 262.628 241.046Z"
        fill="white"
      />
      <path
        d="M311.101 346.658L310.977 364.023L183.9 290.656L184.023 273.287L311.101 346.658Z"
        fill="white"
      />
      <path
        d="M310.977 364.795C310.842 364.795 310.709 364.759 310.592 364.691L183.514 291.324C183.396 291.256 183.298 291.158 183.23 291.039C183.162 290.92 183.127 290.786 183.128 290.649L183.252 273.292C183.253 273.157 183.289 273.025 183.357 272.908C183.425 272.791 183.522 272.695 183.64 272.628C183.757 272.56 183.889 272.525 184.024 272.525C184.159 272.526 184.292 272.561 184.409 272.629L311.486 345.996C311.605 346.064 311.703 346.162 311.771 346.281C311.838 346.399 311.873 346.534 311.872 346.671L311.749 364.028C311.748 364.232 311.666 364.427 311.521 364.571C311.377 364.715 311.181 364.795 310.977 364.795ZM184.675 290.213L310.214 362.693L310.325 347.099L184.787 274.619L184.675 290.213Z"
        fill="#2A344F"
      />
      <path
        d="M310.854 346.658L310.977 364.023L437.48 290.656L437.357 273.287L310.854 346.658Z"
        fill="white"
      />
      <path
        d="M310.977 364.795C310.773 364.795 310.576 364.714 310.432 364.569C310.287 364.425 310.206 364.228 310.206 364.024L310.082 346.667C310.082 346.531 310.117 346.397 310.185 346.279C310.253 346.161 310.35 346.063 310.468 345.996L436.983 272.629C437.099 272.561 437.232 272.526 437.367 272.525C437.502 272.525 437.635 272.56 437.752 272.628C437.869 272.695 437.967 272.791 438.035 272.908C438.103 273.025 438.139 273.157 438.14 273.292L438.263 290.649C438.264 290.785 438.228 290.919 438.161 291.037C438.093 291.155 437.995 291.253 437.877 291.32L311.363 364.687C311.246 364.757 311.113 364.794 310.977 364.795ZM311.629 347.099L311.741 362.689L436.713 290.213L436.601 274.623L311.629 347.099Z"
        fill="#2A344F"
      />
      <path
        d="M371.118 253.127L310.908 288.115L250.304 253.127L310.514 218.139L371.118 253.127Z"
        fill="white"
      />
      <path
        d="M310.908 288.888C310.772 288.887 310.639 288.851 310.522 288.783L249.922 253.799C249.806 253.731 249.709 253.634 249.641 253.517C249.574 253.4 249.538 253.267 249.538 253.132C249.538 252.997 249.574 252.864 249.641 252.747C249.709 252.63 249.806 252.532 249.922 252.465L310.132 217.476C310.25 217.409 310.383 217.373 310.518 217.373C310.654 217.373 310.787 217.409 310.904 217.476L371.503 252.465C371.62 252.532 371.717 252.63 371.785 252.747C371.852 252.864 371.888 252.997 371.888 253.132C371.888 253.267 371.852 253.4 371.785 253.517C371.717 253.634 371.62 253.731 371.503 253.799L311.293 288.791C311.176 288.856 311.043 288.89 310.908 288.888ZM251.847 253.128L310.908 287.21L369.579 253.113L310.518 219.015L251.847 253.128Z"
        fill="#2A344F"
      />
      <g id="step1">
        <title>{props.step1Title}</title>
        <path
          className={
            props.step1Status == "error"
              ? classes.error_Wall
              : props.step1Status == "success"
              ? classes.success_Wall
              : classes.pulse_window
          }
          d="M310.916 240.681L310.85 297.643L240.858 257.236L240.928 200.27L310.916 240.681Z"
          fill="#53669B"
        />
        <path
          d="M248.9 222.141L272.645 235.845V258.29L248.873 244.566L248.9 222.141Z"
          fill="white"
        />
        <path
          d="M272.645 259.053C272.51 259.052 272.377 259.016 272.259 258.948L248.488 245.225C248.37 245.157 248.272 245.059 248.205 244.941C248.137 244.823 248.102 244.689 248.102 244.554L248.133 222.128C248.133 221.993 248.169 221.86 248.237 221.743C248.304 221.625 248.402 221.528 248.519 221.461C248.636 221.393 248.769 221.357 248.905 221.357C249.04 221.357 249.173 221.393 249.29 221.461L273.035 235.165C273.152 235.233 273.249 235.33 273.317 235.447C273.384 235.564 273.42 235.697 273.42 235.833V258.281C273.42 258.383 273.4 258.483 273.361 258.577C273.322 258.671 273.265 258.756 273.193 258.828C273.121 258.9 273.035 258.956 272.941 258.995C272.847 259.033 272.747 259.053 272.645 259.053ZM249.645 244.114L271.874 256.947V236.295L249.672 223.478L249.645 244.114Z"
          fill="#2A344F"
        />
        <path
          d="M302.904 253.317L302.881 275.751L279.133 262.038V239.594L302.904 253.317Z"
          fill="white"
        />
        <path
          d="M302.881 276.521C302.746 276.521 302.613 276.485 302.496 276.417L278.747 262.705C278.63 262.637 278.533 262.54 278.465 262.423C278.397 262.306 278.362 262.173 278.361 262.038V239.593C278.362 239.458 278.397 239.325 278.465 239.207C278.533 239.09 278.63 238.993 278.747 238.925C278.865 238.858 278.998 238.822 279.133 238.822C279.268 238.822 279.401 238.858 279.519 238.926L303.29 252.649C303.407 252.717 303.505 252.814 303.572 252.931C303.64 253.048 303.676 253.181 303.676 253.317L303.653 275.75C303.653 275.954 303.571 276.151 303.427 276.295C303.282 276.44 303.086 276.521 302.881 276.521ZM279.904 261.594L302.114 274.415L302.137 253.764L279.904 240.924V261.586V261.594Z"
          fill="#2A344F"
        />
        <path
          d="M310.781 240.681L310.85 297.643L380.521 257.236L380.452 200.27L310.781 240.681Z"
          fill="white"
        />
        <path
          d="M310.85 298.416C310.645 298.416 310.449 298.334 310.305 298.19C310.16 298.045 310.079 297.849 310.079 297.644L310.013 240.678C310.013 240.543 310.049 240.41 310.117 240.293C310.184 240.176 310.282 240.078 310.399 240.011L380.086 199.603C380.203 199.536 380.336 199.5 380.471 199.5C380.607 199.5 380.74 199.536 380.857 199.603C380.974 199.671 381.072 199.768 381.139 199.885C381.207 200.002 381.243 200.135 381.243 200.271L381.309 257.237C381.308 257.372 381.273 257.505 381.205 257.622C381.137 257.739 381.04 257.836 380.923 257.904L311.236 298.312C311.119 298.379 310.986 298.415 310.85 298.416ZM311.556 241.122L311.621 296.306L379.75 256.793L379.7 201.609L311.556 241.122Z"
          fill="#2A344F"
        />
        <path
          className={
            props.step1Status == "error"
              ? classes.error_Window
              : props.step1Status == "success"
              ? classes.success_Window
              : classes.pulse_window
          }
          d="M372.796 222.141L349.055 235.845V258.29L372.827 244.566L372.796 222.141Z"
          fill="#CCD3E4"
        />
        <path
          className={
            props.step1Status == "error"
              ? classes.error_Window
              : props.step1Status == "success"
              ? classes.success_Window
              : classes.pulse_window
          }
          d="M318.792 253.317L318.815 275.751L342.567 262.038V239.594L318.792 253.317Z"
          fill="#CCD3E4"
        />
      </g>
      <path
        d="M380.452 200.271L310.916 240.682L240.928 200.271L310.464 159.863L380.452 200.271Z"
        fill="white"
      />
      <path
        d="M310.916 241.453C310.78 241.452 310.647 241.416 310.53 241.348L240.542 200.941C240.425 200.873 240.328 200.776 240.261 200.659C240.193 200.542 240.158 200.409 240.158 200.274C240.158 200.139 240.193 200.006 240.261 199.889C240.328 199.772 240.425 199.674 240.542 199.606L310.079 159.195C310.196 159.127 310.329 159.092 310.464 159.092C310.6 159.092 310.733 159.127 310.85 159.195L380.857 199.603C380.974 199.67 381.071 199.768 381.139 199.885C381.206 200.002 381.242 200.135 381.242 200.27C381.242 200.405 381.206 200.538 381.139 200.655C381.071 200.772 380.974 200.869 380.857 200.937L311.305 241.345C311.187 241.415 311.053 241.452 310.916 241.453ZM242.467 200.27L310.916 239.79L378.929 200.27L310.464 160.753L242.467 200.27Z"
        fill="#2A344F"
      />
      <path
        d="M346.791 200.269L310.808 221.178L274.589 200.269L310.572 179.359L346.791 200.269Z"
        fill="white"
      />
      <path
        d="M310.808 221.951C310.672 221.951 310.539 221.915 310.422 221.847L274.203 200.937C274.086 200.87 273.989 200.772 273.922 200.655C273.854 200.538 273.819 200.405 273.819 200.27C273.819 200.135 273.854 200.002 273.922 199.885C273.989 199.768 274.086 199.671 274.203 199.603L310.187 178.693C310.304 178.625 310.437 178.59 310.572 178.59C310.708 178.59 310.841 178.625 310.958 178.693L347.177 199.603C347.294 199.671 347.391 199.768 347.458 199.885C347.525 200.002 347.561 200.135 347.561 200.27C347.561 200.405 347.525 200.538 347.458 200.655C347.391 200.772 347.294 200.87 347.177 200.937L311.193 221.847C311.076 221.915 310.943 221.951 310.808 221.951ZM276.128 200.27L310.804 220.292L345.248 200.274L310.572 180.251L276.128 200.27Z"
        fill="#2A344F"
      />
      <path
        d="M310.908 217.572L310.85 235.064L250.247 200.076L250.304 182.584L310.908 217.572Z"
        fill="white"
      />
      <path
        d="M310.85 235.837C310.715 235.837 310.581 235.801 310.464 235.733L249.861 200.748C249.743 200.68 249.645 200.583 249.578 200.465C249.51 200.347 249.475 200.213 249.475 200.077L249.533 182.585C249.533 182.45 249.569 182.317 249.637 182.2C249.704 182.082 249.802 181.985 249.919 181.918C250.036 181.85 250.169 181.814 250.305 181.814C250.44 181.814 250.573 181.85 250.69 181.918L311.29 216.906C311.407 216.974 311.505 217.072 311.573 217.19C311.641 217.308 311.676 217.441 311.675 217.577L311.618 235.069C311.617 235.273 311.535 235.467 311.392 235.611C311.248 235.755 311.053 235.836 310.85 235.837ZM251.022 199.653L310.082 233.75L310.136 218.032L251.076 183.935L251.022 199.653Z"
        fill="#2A344F"
      />
      <path
        d="M310.792 217.572L310.85 235.064L371.45 200.076L371.392 182.584L310.792 217.572Z"
        fill="white"
      />
      <path
        d="M310.85 235.837C310.645 235.837 310.449 235.755 310.305 235.611C310.16 235.466 310.079 235.27 310.079 235.065L310.021 217.573C310.02 217.437 310.056 217.304 310.123 217.186C310.191 217.068 310.289 216.97 310.406 216.902L371.006 181.91C371.123 181.842 371.256 181.807 371.392 181.807C371.527 181.807 371.66 181.842 371.777 181.91C371.894 181.977 371.992 182.075 372.06 182.192C372.127 182.309 372.163 182.442 372.163 182.577L372.221 200.069C372.222 200.205 372.186 200.339 372.118 200.457C372.051 200.575 371.953 200.673 371.835 200.741L311.236 235.736C311.118 235.803 310.985 235.838 310.85 235.837ZM311.564 218.017L311.618 233.735L370.678 199.653L370.624 183.935L311.564 218.017Z"
        fill="#2A344F"
      />
      <path
        d="M371.118 182.582L310.908 217.574L250.304 182.586L310.514 147.594L371.118 182.582Z"
        fill="white"
      />
      <path
        d="M310.908 218.344C310.772 218.344 310.639 218.308 310.522 218.24L249.923 183.252C249.806 183.184 249.709 183.087 249.641 182.97C249.574 182.853 249.538 182.72 249.538 182.585C249.538 182.45 249.574 182.317 249.641 182.2C249.709 182.083 249.806 181.985 249.923 181.918L310.133 146.926C310.25 146.858 310.383 146.822 310.518 146.822C310.654 146.822 310.787 146.858 310.904 146.926L371.504 181.91C371.62 181.978 371.718 182.075 371.785 182.192C371.852 182.309 371.888 182.442 371.888 182.577C371.888 182.712 371.852 182.845 371.785 182.962C371.718 183.079 371.62 183.177 371.504 183.244L311.294 218.236C311.177 218.306 311.044 218.343 310.908 218.344ZM251.847 182.581L310.908 216.678L369.579 182.581L310.518 148.484L251.847 182.581Z"
        fill="#2A344F"
      />
      <g id="step2">
        <title>{props.step2Title}</title>
        <path
          className={
            props.step2Status == "error"
              ? classes.error_Wall
              : props.step2Status == "success"
              ? classes.success_Wall
              : classes.pulse
          }
          d="M310.916 170.134L310.85 227.1L240.858 186.693L240.928 129.727L310.916 170.134Z"
          fill="#53669B"
        />
        <path
          d="M248.9 151.594L272.645 165.298V187.743L248.873 174.023L248.9 151.594Z"
          fill="white"
        />
        <path
          d="M272.645 188.513C272.51 188.513 272.377 188.477 272.259 188.409L248.488 174.686C248.371 174.618 248.273 174.521 248.206 174.404C248.138 174.286 248.102 174.154 248.102 174.018L248.133 151.593C248.133 151.458 248.169 151.325 248.237 151.207C248.304 151.09 248.402 150.993 248.519 150.925C248.636 150.858 248.769 150.822 248.905 150.822C249.04 150.822 249.173 150.858 249.29 150.926L273.035 164.63C273.152 164.698 273.249 164.795 273.317 164.912C273.384 165.029 273.42 165.162 273.42 165.297V187.742C273.42 187.844 273.4 187.944 273.361 188.038C273.322 188.132 273.265 188.217 273.193 188.289C273.121 188.361 273.035 188.417 272.941 188.456C272.847 188.494 272.747 188.514 272.645 188.513ZM249.645 173.575L271.874 186.407V165.745L249.672 152.927L249.645 173.575Z"
          fill="#2A344F"
        />
        <path
          d="M302.904 182.771L302.881 205.204L279.133 191.495V169.047L302.904 182.771Z"
          fill="white"
        />
        <path
          d="M302.881 205.974C302.746 205.974 302.613 205.938 302.496 205.87L278.747 192.158C278.63 192.09 278.533 191.993 278.465 191.876C278.397 191.759 278.362 191.626 278.361 191.491V169.05C278.362 168.915 278.397 168.782 278.465 168.664C278.533 168.547 278.63 168.45 278.747 168.382C278.865 168.315 278.998 168.279 279.133 168.279C279.268 168.279 279.401 168.315 279.519 168.383L303.29 182.103C303.407 182.17 303.505 182.267 303.572 182.385C303.64 182.502 303.676 182.635 303.676 182.77L303.653 205.203C303.653 205.408 303.571 205.604 303.427 205.748C303.282 205.893 303.086 205.974 302.881 205.974ZM279.904 191.047L302.114 203.868L302.137 183.217L279.908 170.385V191.047H279.904Z"
          fill="#2A344F"
        />
        <path
          d="M310.781 170.134L310.85 227.1L380.521 186.693L380.452 129.727L310.781 170.134Z"
          fill="white"
        />
        <path
          d="M310.85 227.873C310.645 227.873 310.449 227.791 310.305 227.647C310.16 227.502 310.079 227.306 310.079 227.101L310.013 170.135C310.013 170 310.049 169.867 310.117 169.75C310.184 169.633 310.282 169.536 310.399 169.468L380.086 129.068C380.203 129.001 380.336 128.965 380.471 128.965C380.607 128.965 380.74 129 380.857 129.068C380.974 129.136 381.072 129.233 381.139 129.35C381.207 129.467 381.243 129.6 381.243 129.735L381.309 186.702C381.308 186.837 381.273 186.97 381.205 187.087C381.137 187.204 381.04 187.301 380.923 187.369L311.236 227.769C311.119 227.837 310.986 227.872 310.85 227.873ZM311.556 170.579L311.621 225.763L379.75 186.25L379.7 131.066L311.556 170.579Z"
          fill="#2A344F"
        />
        <path
          className={
            props.step2Status == "error"
              ? classes.error_Window
              : props.step2Status == "success"
              ? classes.success_Window
              : classes.pulse_window
          }
          d="M372.796 151.594L349.055 165.298V187.743L372.827 174.023L372.796 151.594Z"
          fill="#CCD3E4"
        />
        <path
          className={
            props.step2Status == "error"
              ? classes.error_Window
              : props.step2Status == "success"
              ? classes.success_Window
              : classes.pulse_window
          }
          d="M318.792 182.771L318.815 205.204L342.567 191.495V169.047L318.792 182.771Z"
          fill="#CCD3E4"
        />
      </g>
      <path
        d="M380.452 129.724L310.916 170.135L240.928 129.728L310.464 89.3164L380.452 129.724Z"
        fill="white"
      />
      <path
        d="M310.916 170.906C310.78 170.905 310.647 170.87 310.53 170.802L240.542 130.394C240.425 130.326 240.328 130.229 240.261 130.112C240.193 129.995 240.158 129.862 240.158 129.727C240.158 129.592 240.193 129.459 240.261 129.342C240.328 129.225 240.425 129.127 240.542 129.06L310.079 88.6483C310.196 88.5806 310.329 88.5449 310.464 88.5449C310.6 88.5449 310.733 88.5806 310.85 88.6483L380.857 129.067C380.974 129.135 381.071 129.232 381.139 129.35C381.206 129.467 381.242 129.599 381.242 129.735C381.242 129.87 381.206 130.003 381.139 130.12C381.071 130.237 380.974 130.334 380.857 130.402L311.305 170.802C311.187 170.87 311.052 170.906 310.916 170.906ZM242.467 129.723L310.916 169.243L378.929 129.727L310.464 90.2066L242.467 129.723Z"
        fill="#2A344F"
      />
      <path
        d="M346.791 129.722L310.808 150.635L274.589 129.726L310.572 108.812L346.791 129.722Z"
        fill="white"
      />
      <path
        d="M310.808 151.408C310.672 151.408 310.539 151.372 310.422 151.304L274.203 130.394C274.086 130.326 273.989 130.229 273.922 130.112C273.854 129.995 273.819 129.862 273.819 129.727C273.819 129.592 273.854 129.459 273.922 129.342C273.989 129.225 274.086 129.128 274.203 129.06L310.187 108.146C310.304 108.079 310.437 108.043 310.572 108.043C310.708 108.043 310.841 108.079 310.958 108.146L347.177 129.056C347.294 129.124 347.391 129.221 347.458 129.338C347.525 129.455 347.561 129.588 347.561 129.723C347.561 129.858 347.525 129.991 347.458 130.108C347.391 130.225 347.294 130.323 347.177 130.39L311.193 151.304C311.076 151.372 310.943 151.408 310.808 151.408ZM276.128 129.723L310.804 149.746L345.248 129.727L310.572 109.705L276.128 129.723Z"
        fill="#2A344F"
      />
      <path
        d="M310.908 147.025L310.85 164.521L250.247 129.533L250.304 112.037L310.908 147.025Z"
        fill="white"
      />
      <path
        d="M310.85 165.292C310.715 165.292 310.581 165.256 310.464 165.188L249.861 130.2C249.743 130.132 249.645 130.034 249.578 129.916C249.51 129.798 249.475 129.664 249.475 129.528L249.533 112.036C249.533 111.901 249.569 111.768 249.637 111.651C249.704 111.534 249.802 111.436 249.919 111.369C250.036 111.301 250.169 111.266 250.305 111.266C250.44 111.266 250.573 111.301 250.69 111.369L311.29 146.357C311.407 146.425 311.505 146.523 311.573 146.641C311.641 146.759 311.676 146.892 311.675 147.028L311.618 164.52C311.618 164.724 311.537 164.92 311.393 165.065C311.249 165.209 311.054 165.291 310.85 165.292ZM251.022 129.089L310.082 163.186L310.136 147.472L251.076 113.375L251.022 129.089Z"
        fill="#2A344F"
      />
      <path
        d="M310.792 147.025L310.85 164.521L371.45 129.533L371.392 112.037L310.792 147.025Z"
        fill="white"
      />
      <path
        d="M310.85 165.292C310.645 165.292 310.449 165.211 310.305 165.066C310.16 164.921 310.079 164.725 310.079 164.52L310.021 147.028C310.02 146.892 310.056 146.759 310.123 146.641C310.191 146.523 310.289 146.425 310.406 146.357L371.006 111.369C371.123 111.301 371.256 111.266 371.392 111.266C371.527 111.266 371.66 111.301 371.777 111.369C371.894 111.436 371.992 111.534 372.06 111.651C372.127 111.768 372.163 111.901 372.163 112.036L372.221 129.528C372.222 129.664 372.186 129.798 372.118 129.916C372.051 130.034 371.953 130.132 371.835 130.2L311.236 165.188C311.119 165.256 310.986 165.292 310.85 165.292ZM311.564 147.472L311.618 163.186L370.678 129.089L370.624 113.375L311.564 147.472Z"
        fill="#2A344F"
      />
      <path
        d="M371.118 112.037L310.908 147.025L250.304 112.037L310.514 77.0488L371.118 112.037Z"
        fill="white"
      />
      <path
        d="M310.908 147.796C310.772 147.795 310.639 147.76 310.522 147.692L249.923 112.707C249.806 112.639 249.709 112.542 249.641 112.425C249.574 112.308 249.538 112.175 249.538 112.04C249.538 111.905 249.574 111.772 249.641 111.655C249.709 111.538 249.806 111.441 249.923 111.373L310.133 76.3807C310.25 76.313 310.383 76.2773 310.518 76.2773C310.654 76.2773 310.787 76.313 310.904 76.3807L371.504 111.369C371.62 111.437 371.718 111.534 371.785 111.651C371.852 111.768 371.888 111.901 371.888 112.036C371.888 112.171 371.852 112.304 371.785 112.421C371.718 112.538 371.62 112.636 371.504 112.703L311.294 147.695C311.176 147.762 311.043 147.797 310.908 147.796ZM251.847 112.036L310.908 146.133L369.579 112.036L310.518 77.939L251.847 112.036Z"
        fill="#2A344F"
      />
      <g id="step3">
        <title>{props.step3Title}</title>

        <path
          className={
            props.step3Status == "error"
              ? classes.error_Wall
              : props.step3Status == "success"
              ? classes.success_Wall
              : classes.pulse
          }
          d="M310.916 99.5852L310.85 156.551L240.858 116.144L240.928 59.1777L310.916 99.5852Z"
          fill="#53669B"
        />
        <path
          d="M248.9 81.0488L272.645 94.7533V117.198L248.873 103.474L248.9 81.0488Z"
          fill="white"
        />
        <path
          d="M272.645 117.969C272.51 117.968 272.377 117.932 272.259 117.864L248.488 104.141C248.371 104.073 248.273 103.976 248.206 103.859C248.138 103.742 248.102 103.609 248.102 103.473L248.133 81.048C248.133 80.9126 248.169 80.7797 248.237 80.6625C248.304 80.5454 248.402 80.4481 248.519 80.3805C248.636 80.3129 248.769 80.2773 248.905 80.2773C249.04 80.2774 249.173 80.313 249.29 80.3807L273.035 94.0851C273.152 94.1528 273.249 94.25 273.317 94.3671C273.384 94.4843 273.42 94.6171 273.42 94.7524V117.197C273.42 117.299 273.4 117.399 273.361 117.493C273.322 117.587 273.265 117.672 273.193 117.744C273.121 117.816 273.035 117.872 272.941 117.911C272.847 117.949 272.747 117.969 272.645 117.969ZM249.645 103.03L271.874 115.863V95.196L249.672 82.3941L249.645 103.03Z"
          fill="#2A344F"
        />
        <path
          d="M302.904 112.226L302.881 134.659L279.133 120.947V98.502L302.904 112.226Z"
          fill="white"
        />
        <path
          d="M302.881 135.429C302.746 135.429 302.613 135.393 302.496 135.325L278.747 121.613C278.63 121.545 278.533 121.448 278.465 121.331C278.397 121.214 278.362 121.081 278.361 120.946V98.5011C278.362 98.3658 278.397 98.2328 278.465 98.1157C278.533 97.9985 278.63 97.9012 278.747 97.8336C278.865 97.766 278.998 97.7304 279.133 97.7305C279.268 97.7305 279.401 97.7661 279.519 97.8338L303.29 111.558C303.407 111.625 303.505 111.722 303.572 111.84C303.64 111.957 303.676 112.09 303.676 112.225L303.653 134.658C303.653 134.863 303.571 135.059 303.427 135.203C303.282 135.348 303.086 135.429 302.881 135.429ZM279.904 120.502L302.11 133.308L302.133 112.657L279.904 99.8241V120.487V120.502Z"
          fill="#2A344F"
        />
        <path
          d="M310.781 99.5852L310.85 156.551L380.521 116.144L380.452 59.1777L310.781 99.5852Z"
          fill="white"
        />
        <path
          d="M310.85 157.324C310.645 157.324 310.449 157.243 310.305 157.098C310.16 156.953 310.079 156.757 310.079 156.552L310.013 99.5863C310.013 99.451 310.049 99.3181 310.117 99.201C310.184 99.0839 310.282 98.9866 310.399 98.919L380.086 58.5116C380.203 58.4439 380.336 58.4082 380.471 58.4082C380.607 58.4082 380.74 58.4437 380.857 58.5114C380.974 58.579 381.072 58.6762 381.139 58.7934C381.207 58.9106 381.243 59.0435 381.243 59.1788L381.309 116.145C381.308 116.28 381.273 116.413 381.205 116.53C381.137 116.647 381.04 116.745 380.923 116.812L311.236 157.224C311.118 157.29 310.985 157.325 310.85 157.324ZM311.556 100.03L311.621 155.214L379.75 115.701L379.7 60.5173L311.556 100.03Z"
          fill="#2A344F"
        />
        <path
          className={
            props.step3Status == "error"
              ? classes.error_Window
              : props.step3Status == "success"
              ? classes.success_Window
              : classes.pulse_window
          }
          d="M372.796 81.0488L349.055 94.7533V117.198L372.827 103.474L372.796 81.0488Z"
          fill="#CCD3E4"
        />
        <path
          className={
            props.step3Status == "error"
              ? classes.error_Window
              : props.step3Status == "success"
              ? classes.success_Window
              : classes.pulse_window
          }
          d="M318.792 112.226L318.815 134.659L342.567 120.947V98.502L318.792 112.226Z"
          fill="#CCD3E4"
        />

        <path
          d="M380.452 59.1769L310.916 99.5844L240.928 59.1769L310.464 18.7656L380.452 59.1769Z"
          fill="white"
        />
        <path
          d="M310.916 100.357C310.78 100.357 310.647 100.321 310.53 100.253L240.542 59.8492C240.425 59.7814 240.328 59.6841 240.261 59.567C240.193 59.4498 240.158 59.3171 240.158 59.1819C240.158 59.0468 240.193 58.914 240.261 58.7969C240.328 58.6798 240.425 58.5824 240.542 58.5146L310.079 18.1034C310.196 18.0356 310.329 18 310.464 18C310.6 18 310.733 18.0356 310.85 18.1034L380.857 58.5069C380.974 58.5747 381.071 58.6721 381.139 58.7892C381.206 58.9063 381.242 59.0391 381.242 59.1742C381.242 59.3093 381.206 59.4421 381.139 59.5592C381.071 59.6763 380.974 59.7737 380.857 59.8415L311.305 100.253C311.187 100.321 311.052 100.357 310.916 100.357ZM242.467 59.1781L310.916 98.6983L378.929 59.1781L310.464 19.6616L242.467 59.1781Z"
          fill="#2A344F"
        />
        <path
          id="roof"
          className={
            props.step3Status == "error"
              ? classes.error_Window
              : props.step3Status == "success"
              ? classes.success_Window
              : classes.pulse_window
          }
          d="M346.791 59.1771L310.808 80.0867L274.589 59.1771L310.572 38.2676L346.791 59.1771Z"
          fill="#CCD3E4"
        />
      </g>
    </svg>
  );
}

AiModelIcon.propTypes = {
  width: PropTypes.string,
  ErrorTitle: PropTypes.string,
  SuccessTitle: PropTypes.string,
  step1Status: PropTypes.string,
  step2Status: PropTypes.string,
  step3Status: PropTypes.string,
  step1Title: PropTypes.string,
  step2Title: PropTypes.string,
  step3Title: PropTypes.string,
  style: PropTypes.array,
};
AiModelIcon.defaultProps = {
  style: [],
  width: "150px",
  ErrorTitle: "Failed",
  SuccessTitle: "Complete successfully",
  step1Status: "loading",
  step2Status: "loading",
  step3Status: "loading",
  step1Title: "Processing...",
  step2Title: "Processing...",
  step3Title: "Processing...",
};
