import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import "./Duplicate.css";
import "../../../components/LiboBerryCss/liboBerry.css";
const DuplicateProjectDialog = ({
  open,
  onClose,
  projectName,
  setProjectName,
  onDuplicate,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={"dialog-title-panel"}>
        Duplicate Project
      </DialogTitle>
      <DialogContent>
        <br />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Project Name"
          type="text"
          className={"duplicate-input"}
          variant="outlined"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
        <br />
      </DialogContent>

      <DialogActions className={"dialog-action-panel"}>
        <Button onClick={onClose} color="primary" className={"liboBtnCancel"}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!(projectName == null || projectName.trim() == ""))
              onDuplicate();
          }}
          className={
            projectName == null || projectName.trim() == ""
              ? "liboBtnDisable"
              : "liboBtnApprove"
          }
          color="primary"
        >
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateProjectDialog;
