import React from "react";
import classesConfirmDialog from "./ConfirmDialog.module.css";

function ConfirmDialog(props) {
  return (
    <>
      <svg className={classesConfirmDialog.animatedCheck} viewBox="0 0 24 24">
        <circle
          cx="12"
          cy="12"
          r="12"
          stroke="black"
          fill="#53cc86"
          stroke-width="0"
        />
        <path d="M5.1 12.7L9 17.6 20.3 6.3" fill="none" />
      </svg>
      <div
        style={{
          paddingTop: "8px",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        Well Done!
      </div>
    </>
  );
}
export default ConfirmDialog;
