import React, { useState } from "react";

import { TextField, Paper } from "@material-ui/core";
import classes from "./index.module.css";
import { withRouter } from "react-router";
import { resetPassword } from "../../Requests/Requests";
import { toast } from "react-toastify";

const ResetPasswordPage = (props) => {
  const [formData, setFormData] = useState({
    token: props.match.params.id,
    password: "",
    password_confirm: "",
  });

  const handleFormChange = (e) => {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleResetPassword = () => {
    let hasError = false;

    if (!formData.password) {
      toast.error("Please Input Password");
      hasError = true;
    } else {
      if (formData.password.length < 8) {
        toast.error("Password must be at least 8 characters long");
        hasError = true;
      }
    }
    if (formData.password != formData.password_confirm) {
      toast.error("Passwords do not match.");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    resetPassword(formData, formData.token)
      .then((res) => {
        console.log(res);
        if (res.status >= 200 && res.status <= 299) {
          toast.success(res.data.message);
          props.history.replace("/");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        }
        toast.error("Something went wrong!");
      });
  };

  return (
    <div className={classes.mostOuterDiv}>
      <div className={classes.divAroundForm}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleResetPassword();
          }}
        >
          <Paper className={classes.paper} variant="outlined" elevation={24}>
            <div className={classes.logo}>
              <img
                className={classes.img}
                src={process.env.PUBLIC_URL + "/assets/logo-new.png"}
                alt="brand icon"
              />
            </div>
            <div className={classes.textfields}>
              <div className={classes.textfield}>
                <TextField
                  onChange={handleFormChange}
                  value={formData.password}
                  name="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  type={"password"}
                  style={{
                    width: "67%",
                  }}
                />
              </div>
              <div className={classes.textfield}>
                <TextField
                  onChange={handleFormChange}
                  value={formData.password_confirm}
                  name="password_confirm"
                  id="password_confirm"
                  label="Confirm Password"
                  variant="outlined"
                  type={"password"}
                  style={{
                    width: "67%",
                  }}
                />
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <button className={classes.loginBtn} type="submit">
                Reset Password
              </button>
            </div>
          </Paper>
        </form>
      </div>
    </div>
  );
};

export default withRouter(ResetPasswordPage);
