export const DocumentTypes = {
  journalArticle: "journal-article",
  proceedingsArticle: "proceedings-article",
  bookChapter: "book-chapter",
  webPage: "web-page",
};

export const SaveValidation = (data) => {
  let errorList = [];
  if (data == null || data.length == 0) {
    errorList.push("data is empty!!!");
    return errorList;
  }

  if (
    data.type == DocumentTypes.journalArticle ||
    data.type == DocumentTypes.bookChapter ||
    data.type == DocumentTypes.webPage ||
    data.type == DocumentTypes.proceedingsArticle
  ) {
    if (data.title == null || data.title.trim() == "") {
      errorList.push("please input title");
    }
  }
  if (
    data.type == DocumentTypes.journalArticle ||
    data.type == DocumentTypes.bookChapter ||
    data.type == DocumentTypes.proceedingsArticle
  ) {
    if (
      data.year == null ||
      isNaN(Number(data.year)) ||
      Number(data.year) == undefined ||
      Number(data.year) < 1900 ||
      data.year.trim() == ""
    ) {
      errorList.push("please input valid year (more than 1900)");
    }
    if (
      data.authors_json &&
      data.authors_json.length > 0 &&
      data.authors_json.filter(
        (s) =>
          s.given == null ||
          s.family == null ||
          s.given.trim() == "" ||
          s.family.trim() == ""
      ).length > 0
    ) {
      errorList.push("Please Enter Author's First Name or Last Name ");
    }
  }

  if (data.type == DocumentTypes.webPage) {
    if (data.accessed == null || data.accessed?.trim() == "") {
      errorList.push("please input Accessed Date");
    }
    if (data.publisher == null || data.publisher?.trim() == "") {
      errorList.push("please input Publisher");
    }
  }

  return errorList;
};
