import "../confirm-alert/confirm-alert.css";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MUIAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { PropTypes } from "prop-types";
import { logout } from "../../Requests/Requests";
import { KeyboardBackspace } from "@material-ui/icons";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { getProjectDocuments } from "../../Requests/Requests";

const DocumentTypes = {
  journalArticle: "journal-article",
  proceedingsArticle: "proceedings-article",
  bookChapter: "book-chapter",
  webPage: "web-page",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    bottom: "auto",
    top: 20,
  },
}));

export default function AppBar(props) {
  const classes = useStyles();
  const { onHamburgurClick, toolbar } = props;
  const [liboberryNavTitle, setliboberryNavTitle] = useState("");

  useEffect(() => {
    // alert(props.pathname.toLowerCase());
    props.title.forEach((element) => {
      if (element.checkWithStartWith == true) {
        if (props.pathname.toLowerCase().includes(element.path.toLowerCase())) {
          setliboberryNavTitle("/ " + element.title);
          return;
        }
      } else {
        if (element.path.toLowerCase() == props.pathname.toLowerCase()) {
          setliboberryNavTitle("/ " + element.title);
          return;
        }
      }
    });
  }, [props.pathname]);

  const handleLogout = () => {
    logout()
      .then((res) => {
        props.history.replace("/");
        toast.success("You logged out successfully!");
      })
      .catch((err) => {
        toast.error("Sorry an error occurred while trying to logout!");
      });
  };

  const back = () => {
    if (props.pathname.toLowerCase().includes("managereferences")) {
      let id = props.pathname.split("/")[3];

      getProjectDocuments(id)
        .then((res) => {
          let needtoconfirm = false;
          let article_name = "";
          let index = 0;
          for (let i = 0; i < res.data.length; i++) {
            let error = "";
            if (
              res.data[i].documentbibtexdata == null &&
              (res.data[i].state == "parsed" ||
                res.data[i].state == "parse_failed")
            ) {
              needtoconfirm = true;
              error += "please input All fields";
            } else if (res.data[i].documentbibtexdata != null) {
              if (
                res.data[i].documentbibtexdata.type ==
                  DocumentTypes.proceedingsArticle ||
                res.data[i].documentbibtexdata.type ==
                  DocumentTypes.journalArticle ||
                res.data[i].documentbibtexdata.type == DocumentTypes.webPage ||
                res.data[i].documentbibtexdata.type == DocumentTypes.bookChapter
              ) {
                if (
                  res.data[i].documentbibtexdata.title == null ||
                  res.data[i].documentbibtexdata.title.trim() == ""
                ) {
                  needtoconfirm = true;
                  error += "Title,";
                }
              }
              if (
                res.data[i].documentbibtexdata.type ==
                  DocumentTypes.proceedingsArticle ||
                res.data[i].documentbibtexdata.type ==
                  DocumentTypes.journalArticle ||
                res.data[i].documentbibtexdata.type == DocumentTypes.bookChapter
              ) {
                if (res.data[i].documentbibtexdata.year == null) {
                  needtoconfirm = true;
                  error += "Year,";
                }
              }
              if (
                res.data[i].documentbibtexdata.type == DocumentTypes.bookChapter
              ) {
                if (res.data[i].documentbibtexdata.page == null) {
                  needtoconfirm = true;
                  error += "Page,";
                }
              }
              if (
                res.data[i].documentbibtexdata.type == DocumentTypes.webPage
              ) {
                if (
                  res.data[i].documentbibtexdata.accessed == null ||
                  res.data[i].documentbibtexdata.accessed?.trim() == ""
                ) {
                  needtoconfirm = true;
                  error += "Accessed Date,";
                }
                if (
                  res.data[i].documentbibtexdata.publisher == null ||
                  res.data[i].documentbibtexdata.publisher?.trim() == ""
                ) {
                  needtoconfirm = true;
                  error += "Publisher,";
                }
              }

              error = error == "" ? error : " Invalid Fields : " + error;
            } else {
              if (
                res.data[i].state == "waiting" ||
                res.data[i].state == "parsing"
              ) {
                needtoconfirm = true;
                error += "Processing...";
              }
            }
            if (error != "") {
              index++;
              article_name +=
                index + " - " + res.data[i].name + " [" + error + "]\n";
            }
          }
          if (needtoconfirm) {
            confirmAlert({
              title:
                "The information of the entered articles is not complete. Are you sure you want to exit?",
              message: article_name,
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    let url = props.pathname
                      .toLowerCase()
                      .replace("managereferences", "");
                    props.history.replace(url);
                    // props.history.goBack();
                  },
                },
                {
                  label: "No",
                  onClick: () => {},
                },
              ],
            });
          } else {
            let url = props.pathname
              .toLowerCase()
              .replace("managereferences", "");
            props.history.replace(url);
          }
        })
        .catch((err) => {
          let url = props.pathname
            .toLowerCase()
            .replace("managereferences", "");
          props.history.replace(url);
          // toast.error('An error occurred while getting the document!');
        });
    } else if (props.pathname.toLowerCase().includes("/panel/projects")) {
      // let url = props.pathname.toLowerCase().replace('', '');
      props.history.replace("/panel/projects");
    } else {
      props.history.goBack();
    }
  };

  return (
    <MUIAppBar position="fixed" className={classes.root}>
      <Toolbar>
        {toolbar.back && (
          <IconButton
            onClick={back}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <KeyboardBackspace />
          </IconButton>
        )}
        {toolbar.hamburger && (
          <IconButton
            onClick={onHamburgurClick}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography variant="h6" className={classes.title}>
          LiboBerry {liboberryNavTitle}
        </Typography>
        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </MUIAppBar>
  );
}

AppBar.propTypes = {
  onHamburgurClick: PropTypes.func,
  title: PropTypes.array,
  pathname: PropTypes.string,
};

AppBar.defaultProps = {
  onHamburgurClick: () => {},
  title: [],
  pathname: "/",
  toolbar: {},
};
