import React from 'react';
import classes from './DashbordItem.module.css'
export default function DashbordItem(props) {


  return (
    <div className={classes.Item}>
      <a className={classes.Link} href={props.href}>
        <img className={classes.Img} src={props.src}></img>
        <div>
          <h2 className={classes.Header}  >
            {props.title}
          </h2>
          {props.children}
        </div>
      </a>
    </div>
  );
}
