import PropTypes from "prop-types";
import { useState } from "react";
const defaultISSN = "";
function EditableISSNList({ issns, onAdd, onDelete, onEdit, ...props }) {
  const [newISSN, setNewISSN] = useState(defaultISSN);
  const resetNewISSN = () => setNewISSN(defaultISSN);

  const handleAdd = () => {
    // onAdd({
    //     issn: newISSN
    // });
    onAdd({
      issn: "",
    });
    resetNewISSN();
  };
  return (
    <table>
      <tbody>
        <tr>
          <td>
            {issns?.length > 0 &&
              issns?.map((issn, index) => (
                <span key={index}>
                  {issn} {issns.length != index + 1 ? " ," : ""}
                </span>
              ))}
          </td>
        </tr>
      </tbody>

      {/* <tbody>
        {issns?.map(({ issn }, index) => (
          <tr key={index}>
            <td>
              <input
                className={classes.textField}
                type="text"
                value={issn}
                onChange={(e) =>
                  onEdit(index, {
                    ...issns[index],
                    issn: e.target.value,
                  })
                }
                placeholder="ISSN"
              />
            </td>
            <td>
              <IconButton
                className={classes.cancelButton}
                onClick={onDelete.bind(this, index)}
              >
                <Cancel />
              </IconButton>
            </td>
          </tr>
        ))}
        <tr className={classes.newAuthorTR}>
          {/* <td>
                        <input
                            className={classes.textField}
                            type="text" value={newISSN}
                            onChange={e => setNewISSN(e.target.value)}
                            placeholder="New ISSN"
                        />
                    </td> */}
      {/* <td style={{ border: "none" }}>
            <button className={classes.addAuthorButton} onClick={handleAdd}>
              <Add />
              &nbsp;Add ISSN
            </button>
          </td>
        </tr>
      </tbody> */}
    </table>
  );
}

export default EditableISSNList;

EditableISSNList.propTypes = {
  onAdd: () => {},
  onDelete: () => {},
  onEdit: () => {},
};

EditableISSNList.defaultProps = {
  issns: [],
  onAdd: () => {},
  onDelete: () => {},
  onEdit: () => {},
};
