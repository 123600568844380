import React, { useEffect, useState } from "react";
import PropTypes, { element } from "prop-types";
import "../../../LiboBerryCss/liboBerry.css";
import classesFloatSuggestion from "./floatSuggestion.module.css";
import TabItem from "./TabMenu/TabItem";
import ConfiemDialog from "./ConfirmDialog";
import Discovery from "../Discovery/Discovery";
import FilterListIcon from "@material-ui/icons/FilterList";
import classesSpin from "../../../Loader/Loader.module.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { IconButton, TextField, Button } from "@material-ui/core";
import { hasClass } from "../../../../Functions/EditorFunction";
import { DocumentUploadFileAllowMimeType } from "../../../../Functions/FilterUploadFile";
import {
  uploadProjectDocument,
  CreateProjectDocumentWithDocumentbibtexdata,
  CreateProjectDocumentWithBibTex,
} from "../../../../Requests/Requests.js";
import { toast } from "react-toastify";
import EditableArticalMetaData from "../../../../components/EditableArticalMetaData/EditableArticalMetaData";
import {
  DocumentTypes,
  SaveValidation,
} from "../../../../components/EditableArticalMetaData/EditableFunction.js";

function Citation(props) {
  const [sugCheckContent, setSugCheckContent] = useState(null);
  const [floatSuggestionTab, setFloatSuggestionTab] = useState(1);
  const [floatSuggestionCustomTab, setFloatSuggestionCustomTab] = useState(1);
  const [isCustmReferenceDataChanged, setIsCustmReferenceDataChanged] =
    useState(false);
  const [showDiscovery, setShowDiscovery] = useState(false);
  const [sugContent, setSugContent] = useState(null);
  const [documentCitationContent, setDocumentCitationContent] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [citationIds, SetCitationIds] = useState([]);
  const [selectCitation, setSelectCitation] = useState([]);
  const [showAcceptBtn, SetShowAcceptBtn] = useState({
    tab1ActiveAcceptBtn: false,
    tab2ActiveAcceptBtn: false,
    tab3ActiveAcceptBtn: false,
  });
  const [bibTexValue, setBibTexValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loadFile, setLoadFile] = useState(false);
  const [article, setArticle] = useState(null);
  const bibtexPlaceholder = `@article{vaswani2017attention,
  title={Attention is all you need},
  author={Vaswani, Ashish and Shazeer},
  journal={Advances in neural information processing systems},
  volume={30},
  year={2017}
}`;

  const clearArticle = () => {
    let id = Math.random(1000000);

    setArticle({
      active: true,
      documentbibtexdata: {
        accessed: null,
        authors_json: [],
        doi: null,
        id: id,
        issns_json: [],
        issue: null,
        page: "",
        published_at: null,
        publisher: null,
        title: "",
        type: "journal-article",
        url: null,
        volume: "",
        year: "",
      },
      file: null,
      id: id,
      name: "",
      source: "",
      state: "parsed",
      url: null,
    });
  };

  const handleSaveCustomReference = (data) => {
    let errorlist = SaveValidation(data);
    if (errorlist.length > 0) {
      for (let error of errorlist) {
        toast.error(error);
      }
    } else {
      if (
        data.type == DocumentTypes.webPage &&
        data.authors_json &&
        data.authors_json.length > 0
      ) {
        data.authors_json = data.authors_json.filter(
          (s) =>
            !(
              s.given == null ||
              s.family == null ||
              s.given.trim() == "" ||
              s.family.trim() == ""
            )
        );
        setArticle({ ...article, documentbibtexdata: data });
      }
      setLoadFile(true);
      CreateProjectDocumentWithDocumentbibtexdata(props.ProjectId, data)
        .then((res) => {
          clearArticle();
          setLoadFile(false);
          setIsCustmReferenceDataChanged(false);

          if (res.status >= 200 && res.status <= 299) {
            props.bindDocumentCitations();
            if (res.data && res.data.message) {
              toast.success(res.data.message);
            } else {
              toast.success("document updated successfully.");
            }
          }
          // console.log(res.data);
        })
        .catch((err) => {
          setLoadFile(false);
          setIsCustmReferenceDataChanged(true);
          if (err.response?.data?.error) {
            toast.error(err.response.data.error);
          } else {
            console.log(err, err.response);
          }
        });
    }
  };
  const handleSaveCustomReferenceWithBibText = (data) => {
    setLoadFile(true);
    CreateProjectDocumentWithBibTex(props.ProjectId, data)
      .then((res) => {
        setLoadFile(false);
        setBibTexValue("");
        if (res.status >= 200 && res.status <= 299) {
          props.bindDocumentCitations();
          if (res.data && res.data.message) {
            toast.success(res.data.message);
          } else {
            toast.success("document updated successfully.");
          }
        }
        // console.log(res.data);
      })
      .catch((err) => {
        setLoadFile(false);
        if (err.response?.data?.error) {
          toast.error(err.response.data.error);
        } else {
          console.log(err, err.response);
        }
      });
  };
  const getSuggestionColor = (value) => {
    let result = "";
    if (value >= 0.9) result = "#00d200ba";
    else if (value < 0.9 && value >= 0.8) result = "#00d20087";
    else if (value < 0.8 && value >= 0.7) result = "#00d20054";
    else if (value < 0.7 && value >= 0.5) result = "#f5ff07c2";
    else result = "#ff0000d1";
    return result;
  };
  const handleSelectCitation = (doc_id, citation_string, bibtex, event) => {
    let target = event.target;
    if (target.classList.length == 0) {
      target = target.parentNode;
    }

    let list = citationIds;

    let selectlist = selectCitation;
    if (!list || list == null || list == NaN || list == undefined) {
      list = [];
    }
    if (
      !hasClass(target, classesFloatSuggestion.documentCitationContentActive)
    ) {
      target.classList.add(
        classesFloatSuggestion.documentCitationContentActive
      );
      if (list.length == 0 || list.findIndex((x) => x.doc_id == doc_id) < 0) {
        let item = {
          doc_id: doc_id,
          type: "",
          citation_string: citation_string,
          bibtex: bibtex,
        };
        list.push(item);
        selectlist.push(item);

        setSelectCitation(selectlist);
        SetCitationIds(list);
        createcitationSaggestion(props.dataSug, list);
      }
    } else {
      target.classList.remove(
        classesFloatSuggestion.documentCitationContentActive
      );
      let index = list.findIndex((x) => x.doc_id == doc_id);
      if (index >= 0) {
        list.splice(index, 1);
        let indexSelected = selectlist.findIndex((x) => x.doc_id == doc_id);
        if (indexSelected >= 0) {
          selectlist.splice(index, 1);
        }

        setSelectCitation(selectlist);
        SetCitationIds(list);
        createcitationSaggestion(props.dataSug, list);
      }
    }
  };

  const createSuggestionResult = (suggestionValue, citationIdsList = []) => {
    let htmlRefValidateResult = [];
    let suggestionValueSimPositive = [];
    for (let i = 0; i < suggestionValue.length; i++) {
      if (
        suggestionValue[i].sim != null &&
        suggestionValue[i].sim != undefined &&
        suggestionValue[i].sim > 0
      ) {
        suggestionValueSimPositive.push(suggestionValue[i]);
      }
    }
    if (suggestionValueSimPositive.length > 0) {
      suggestionValueSimPositive.sort(function (a, b) {
        return a.sim - b.sim;
      });
      suggestionValueSimPositive.reverse();
    } else {
      htmlRefValidateResult.push(
        <div
          id={"suggestionid" + 0}
          key={"suggestionid" + 0}
          style={{
            backgroundColor: "lightblue",
            marginBottom: "5px",
            textAlign: "left",
            padding: "5px",
            fontSize: "0.8em",
            borderRadius: "5px",
          }}
        >
          {"The AI hasn't provided any suggestions!"}
        </div>
      );
    }

    for (let i = 0; i < suggestionValueSimPositive.length; i++) {
      if (i >= 4) {
        break;
      }
      let citation_string = suggestionValueSimPositive[i].citation_string;
      let bibtex = suggestionValueSimPositive[i].bibtex;

      tab1ActiveAcceptBtn = true;
      tab2ActiveAcceptBtn = true;
      htmlRefValidateResult.push(
        <div
          id={"suggestionid_" + i}
          key={"suggestionid_" + i}
          className={
            classesFloatSuggestion.documentCitationContent +
            (citationIdsList.findIndex(
              (x) => x.doc_id == suggestionValueSimPositive[i].doc_id
            ) >= 0
              ? " " + classesFloatSuggestion.documentCitationContentActive
              : "")
          }
          onClick={(e) => {
            handleSelectCitation(
              suggestionValueSimPositive[i].doc_id,
              citation_string,
              bibtex,
              e
            );
          }}
          style={{
            cursor: "pointer",
            backgroundColor: getSuggestionColor(
              suggestionValueSimPositive[i].sim
            ),
            fontSize: "0.8em",
            marginBottom: "5px",
            textAlign: "left",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              fontSize: "0.8em",
              paddingBottom: "8px",
              fontStyle: "italic",
            }}
          >
            Similarity : {Math.floor(suggestionValueSimPositive[i].sim * 100)}%
          </div>
          {suggestionValueSimPositive[i].document_name}
        </div>
      );
    }
    return htmlRefValidateResult;
  };
  const createtDocumentCitationContent = (
    suggestionValue,
    citationIdsList = []
  ) => {
    let htmlRefValidateResult = [];

    if (suggestionValue == null || suggestionValue.length == 0) {
      htmlRefValidateResult.push(
        <div
          id={"suggestionid" + 0}
          key={"suggestionid" + 0}
          style={{
            backgroundColor: "lightblue",
            marginBottom: "5px",
            textAlign: "left",
            padding: "5px",
            fontSize: "0.8em",
            borderRadius: "5px",
          }}
        >
          {
            "There is no document to cite. Please check the Knowledge Management."
          }
        </div>
      );
    } else {
      htmlRefValidateResult.push(
        <div className={classesFloatSuggestion.sreach_box}>
          <input
            type="text"
            placeholder="Search in your library ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={classesFloatSuggestion.searchInput}
          />
        </div>
      );

      const filteredSuggestions = suggestionValue.filter((item) =>
        item.document_name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (filteredSuggestions.length == 0) {
        htmlRefValidateResult.push(
          <div
            id={"noFilteredSuggestion"}
            key={"noFilteredSuggestion"}
            style={{
              backgroundColor: "lightblue",
              marginBottom: "5px",
              textAlign: "left",
              padding: "5px",
              fontSize: "0.8em",
              borderRadius: "5px",
            }}
          >
            {"There is no filtered suggestion..."}
          </div>
        );
      } else {
        for (let i = 0; i < filteredSuggestions.length; i++) {
          let citation_string = filteredSuggestions[i].citation_string;
          let bibtex = filteredSuggestions[i].bibtex;
          tab1ActiveAcceptBtn = true;
          htmlRefValidateResult.push(
            <div
              id={"documentCitationSuggestionId_" + i}
              key={"documentCitationSuggestionId_" + i}
              className={
                classesFloatSuggestion.documentCitationContent +
                (citationIdsList.findIndex(
                  (x) => x.doc_id == filteredSuggestions[i].doc_id
                ) >= 0
                  ? " " + classesFloatSuggestion.documentCitationContentActive
                  : "")
              }
              onClick={(e) => {
                handleSelectCitation(
                  filteredSuggestions[i].doc_id,
                  citation_string,
                  bibtex,
                  e
                );
              }}
            >
              {filteredSuggestions[i].document_name}
            </div>
          );
        }
      }
    }

    return htmlRefValidateResult;
  };

  const AddToCitationIds = (newlist) => {
    let ListcitetionIds = citationIds;
    while (ListcitetionIds.length != 0) {
      ListcitetionIds.pop();
    }
    let list = selectCitation;
    for (let cite of list) {
      if (ListcitetionIds.findIndex((s) => s.doc_id == cite.doc_id) < 0) {
        ListcitetionIds.push(cite);
      }
    }
    for (let refcite of newlist) {
      if (ListcitetionIds.findIndex((s) => s.doc_id == refcite.doc_id) < 0) {
        ListcitetionIds.push(refcite);
      }
    }
    SetCitationIds(ListcitetionIds);
    return ListcitetionIds;
  };
  let tab1ActiveAcceptBtn = false;
  let tab2ActiveAcceptBtn = false;
  let tab3ActiveAcceptBtn = false;
  const createcitationSaggestion = (getsuggestionvalue, citationList) => {
    tab1ActiveAcceptBtn = false;
    tab2ActiveAcceptBtn = false;
    tab3ActiveAcceptBtn = true;

    let list = citationList;
    if (getsuggestionvalue == null) {
      props.onDismissClick(props.Element);
      return;
    }

    let suggestionResult = createSuggestionResult(getsuggestionvalue, list);
    let documentCitationContentResult = createtDocumentCitationContent(
      props.documentRefrence,
      list
    );
    SetShowAcceptBtn({
      tab1ActiveAcceptBtn: tab1ActiveAcceptBtn,
      tab2ActiveAcceptBtn: tab2ActiveAcceptBtn,
      tab3ActiveAcceptBtn: tab3ActiveAcceptBtn,
    });

    setDocumentCitationContent(documentCitationContentResult);
    setSugCheckContent(false);
    setSugContent(suggestionResult);
  };
  const AddToSelectCitationIds = (citations) => {
    let list = citationIds;
    let selectList = selectCitation;
    for (let citation of citations) {
      if (list.indexOf((s) => s.doc_id == citation.doc_id) < 0) {
        list.push({
          doc_id: citation.doc_id,
          type: "",
          citation_string: citation.citation_string,
          bibtex: citation.bibtex,
        });
      }
      if (selectList.indexOf((s) => s.doc_id == citation.doc_id) < 0) {
        selectList.push({
          doc_id: citation.doc_id,
          type: "",
          citation_string: citation.citation_string,
          bibtex: citation.bibtex,
        });
      }
    }

    setSelectCitation(selectList);
    SetCitationIds(list);
    return list;
  };
  const AddToDocumentRefrence = (citations) => {
    let list = props.documentRefrence;

    for (let citation of citations) {
      if (list.indexOf((s) => s.doc_id == citation.doc_id) < 0) {
        list.push(citation);
      }
    }
    props.onDocumentRefrence(list);
    return list;
  };
  const AddNewCitation = (newCitations) => {
    let refsList = AddToDocumentRefrence(newCitations);
    let list = AddToSelectCitationIds(newCitations);
    let documentCitationContentResult = createtDocumentCitationContent(
      refsList,
      list
    );
    SetShowAcceptBtn({
      tab1ActiveAcceptBtn: true,
      tab2ActiveAcceptBtn: showAcceptBtn.tab2ActiveAcceptBtn,
      tab3ActiveAcceptBtn: true,
    });
    setDocumentCitationContent(documentCitationContentResult);
  };
  const uploadArticleFile = (e) => {
    setLoadFile(true);

    if (DocumentUploadFileAllowMimeType(e.target.files[0].type)) {
      if (e.target.files[0].name.length >= 99) {
        setLoadFile(false);
        toast.error(
          "Upload failed! The file name exceed than 100 characters. Please rename the file."
        );
        return;
      }

      uploadProjectDocument(props.ProjectId, e.target.files[0])
        .then((res) => {
          setLoadFile(false);
          if (res.status >= 200 && res.status <= 299) {
            props.bindDocumentCitations();
            if (res.data.message) {
              toast.success(res.data.message);
            } else {
              toast.success("file :" + e.target.files[0].name + " Uploaded!");
            }
          }
        })
        .catch((err) => {
          setLoadFile(false);
          if (err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            toast.error("File Upload Failed!");
          }
        });
    } else {
      toast.error("File Format is invalid");
      setLoadFile(false);
    }
  };
  const clearCitations = () => {
    var list = citationIds;
    var selectList = selectCitation;
    while (list.length > 0) {
      list.pop();
    }
    while (selectList.length > 0) {
      selectList.pop();
    }

    setSelectCitation(selectList);
    SetCitationIds(list);
  };

  useEffect(() => {
    setOpenFilter(false);
  }, [props.closeFilter]);
  useEffect(() => {
    let documentCitationContentResult = createtDocumentCitationContent(
      props.documentRefrence,
      citationIds
    );

    setDocumentCitationContent(documentCitationContentResult);
  }, [props.documentRefrence]);
  useEffect(() => {
    clearArticle();
    setBibTexValue("");
    setOpenFilter(false);
    setFloatSuggestionTab(1);
    clearCitations();
    setSugCheckContent(false);
    setShowDiscovery(false);
  }, [props.Element]);

  useEffect(() => {
    let list = AddToCitationIds(props.citationIds);
    createcitationSaggestion(props.dataSug, list);
  }, [props.dataSug, props.citationIds, searchQuery]);

  return (
    <div
      id={"floatSuggestionCitition"}
      className={classesFloatSuggestion.floatSuggestionBox}
    >
      <div className={classesFloatSuggestion.floatSuggestionPanel}>
        <h4 className={classesFloatSuggestion.floatSuggestionTitle}>
          Citation Management
        </h4>

        <IconButton
          style={{
            padding: "0px",
            color: "white",
            width: "35px",
            height: "30px",
          }}
          onClick={() => {
            props.onCloseFloatSuggestion();
          }}
        >
          &times;
        </IconButton>
      </div>
      {!sugCheckContent && (
        <>
          {" "}
          <div>
            <div className={classesFloatSuggestion.floatSuggestionHeader}>
              <TabItem
                active={floatSuggestionTab == 1}
                title={"Your library"}
                onClick={() => {
                  setOpenFilter(false);
                  setFloatSuggestionTab(1);
                }}
                type={"Left"}
              />
              <TabItem
                active={floatSuggestionTab == 2}
                title={"AI suggestions"}
                onClick={() => {
                  setOpenFilter(false);
                  setFloatSuggestionTab(2);
                }}
                type={"Mid"}
              />
              <TabItem
                active={floatSuggestionTab == 3}
                title={"Discover"}
                onClick={() => {
                  setFloatSuggestionTab(3);
                  setShowDiscovery(true);
                }}
                type={"MidWithOutLeftBorder"}
              />
              <TabItem
                active={floatSuggestionTab == 4}
                title={"Add reference"}
                onClick={() => {
                  setOpenFilter(false);
                  setFloatSuggestionTab(4);
                }}
                type={"Right"}
              />
            </div>
            {floatSuggestionTab == 4 && (
              <div
                style={{ paddingTop: "10px" }}
                className={classesFloatSuggestion.floatSuggestionHeader}
              >
                <TabItem
                  active={floatSuggestionCustomTab == 1}
                  title={"Upload file"}
                  onClick={() => {
                    setOpenFilter(false);
                    setFloatSuggestionCustomTab(1);
                  }}
                  type={"Left"}
                />
                <TabItem
                  active={floatSuggestionCustomTab == 2}
                  title={"Custom"}
                  onClick={() => {
                    setOpenFilter(false);
                    setFloatSuggestionCustomTab(2);
                  }}
                  type={"Mid"}
                />
                <TabItem
                  active={floatSuggestionCustomTab == 3}
                  title={"BibTeX"}
                  onClick={() => {
                    setFloatSuggestionCustomTab(3);
                  }}
                  type={"Right"}
                />
              </div>
            )}
            <div
              className={
                classesFloatSuggestion.floatSuggestionContent +
                " " +
                "liboScroll"
              }
            >
              <div className={floatSuggestionTab == 1 ? "" : "hide"}>
                {/* <div className={classesFloatSuggestion.sreach_box}>
                  <input
                    type="text"
                    placeholder="Search suggestions..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={classesFloatSuggestion.searchInput}
                  />
                </div> */}
                {documentCitationContent}
              </div>
              <div className={floatSuggestionTab == 2 ? "" : "hide"}>
                {sugContent}
              </div>
              <div
                className={floatSuggestionTab == 3 ? "" : "hide"}
                style={{
                  minHeight: "100px",
                }}
              >
                {showDiscovery && (
                  <Discovery
                    showFilter={openFilter}
                    onShowFilter={(value) => {
                      setOpenFilter(value);
                    }}
                    keyWords={props.keyWords}
                    content={props.content}
                    projectId={props.ProjectId}
                    onAddToKnowledgeManegment={(result) => {
                      if (result != null) {
                        AddNewCitation(result);
                      }
                    }}
                  />
                )}
              </div>
              <div
                className={floatSuggestionTab == 4 ? "" : "hide"}
                style={{
                  minHeight: "100px",
                  paddingTop: "10px",
                }}
              >
                {floatSuggestionCustomTab == 1 && !loadFile && (
                  <>
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        textTransform: "none",
                        margin: "5px",
                        width: "100%",
                        height: "84px",
                        backgroundColor: "transparent",
                        color: "gray",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <CloudUploadIcon />
                        Upload your reference file (.pdf, .bib, etc.)
                      </div>
                      <input
                        type="file"
                        hidden
                        accept=".pdf, .csv, text/plain, .ppt, .pptx, .doc, .docx, .bib"
                        onChange={(e) => {
                          uploadArticleFile(e);
                        }}
                      />
                    </Button>
                  </>
                )}
                {floatSuggestionCustomTab == 2 && !loadFile && (
                  <>
                    <EditableArticalMetaData
                      key={article.id}
                      customArticle={true}
                      onUpdateCustomArticle={(bibtexdata) => {
                        setArticle({
                          ...article,
                          documentbibtexdata: bibtexdata,
                        });
                      }}
                      dataChangedValue={isCustmReferenceDataChanged}
                      isDataChanged={setIsCustmReferenceDataChanged}
                      headerMinHeight={""}
                      handleProcessContent={() => {}}
                      handleDeleteProjectArticle={() => {}}
                      handleDeleteTagMng={(tagId, doc_id) => {}}
                      selectArticless={(doc_id) => {}}
                      removeSelectArticles={(doc_id) => {}}
                      projectId={props.projectId}
                      article={article}
                      status={article.state}
                      authors={article.documentbibtexdata?.authors_json}
                      issns={article.documentbibtexdata?.issns_json}
                      handleOnClickAccordion={() => {}}
                      refreshDocumentsAndFile={() => {}}
                      AccordionStatus={false}
                    />
                  </>
                )}
                {floatSuggestionCustomTab == 3 && !loadFile && (
                  <>
                    <TextField
                      id="txtBIbTex"
                      label="Insert your BibTeX data"
                      variant="outlined"
                      style={{
                        width: "100%",
                      }}
                      disabled={false}
                      value={bibTexValue}
                      multiline
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={bibtexPlaceholder}
                      minRows={12}
                      onChange={(e) => {
                        setBibTexValue(e.target.value);
                      }}
                    />
                  </>
                )}
                {loadFile && (
                  <div className={classesFloatSuggestion.loaderPanel}>
                    <img
                      className={classesSpin.spinItemAlways}
                      src="/loader.png"
                      style={{ width: "65px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classesFloatSuggestion.floatSuggestionFooter}>
            {props.Element != null &&
              ((floatSuggestionTab == 1 && showAcceptBtn.tab1ActiveAcceptBtn) ||
                (floatSuggestionTab == 2 &&
                  showAcceptBtn.tab2ActiveAcceptBtn) ||
                (floatSuggestionTab == 3 &&
                  showAcceptBtn.tab3ActiveAcceptBtn)) && (
                <input
                  type="button"
                  id={"btnCitationAccept"}
                  value={
                    "Accept" +
                    (citationIds?.length > 0
                      ? "(" + citationIds?.length + ")"
                      : "")
                  }
                  className={
                    citationIds?.length > 0
                      ? classesFloatSuggestion.floatSuggestion_AcceptBtn
                      : "liboBtnDisable"
                  }
                  onClick={() => {
                    if (citationIds?.length > 0) {
                      if (
                        props.onCitationAcceptClick(props.Element, citationIds)
                      ) {
                        setSugCheckContent(true);
                        setTimeout(() => {
                          props.onDismissClick(props.Element);
                        }, 1000);
                      }
                    }
                  }}
                ></input>
              )}
            {floatSuggestionTab == 3 && (
              <a
                id={"btnCitationFilter"}
                value={"Filter"}
                className={classesFloatSuggestion.floatSuggestion_FilterBtn}
                onClick={(e) => {
                  setOpenFilter(!openFilter);
                }}
              >
                <FilterListIcon style={{ width: "16px" }} /> &nbsp;Filter
              </a>
            )}
            {floatSuggestionTab == 4 &&
              !loadFile &&
              floatSuggestionCustomTab != 1 && (
                <a
                  id={"btnAddReference"}
                  value={"Add"}
                  style={{ fontSize: "0.85rem" }}
                  className={
                    (bibTexValue.trim() != "" &&
                      floatSuggestionCustomTab == 3) ||
                    (isCustmReferenceDataChanged &&
                      floatSuggestionCustomTab == 2)
                      ? classesFloatSuggestion.floatSuggestion_AcceptBtn
                      : "liboBtnDisable"
                  }
                  onClick={(e) => {
                    if (
                      bibTexValue.trim() != "" &&
                      floatSuggestionCustomTab == 3
                    ) {
                      handleSaveCustomReferenceWithBibText(bibTexValue.trim());
                    }
                    if (
                      isCustmReferenceDataChanged &&
                      floatSuggestionCustomTab == 2
                    ) {
                      handleSaveCustomReference(article.documentbibtexdata);
                    }
                  }}
                >
                  Add to your library
                </a>
              )}
            <input
              type="button"
              value={"Dismiss"}
              id={"btnCitationDismiss"}
              className={classesFloatSuggestion.floatSuggestion_DismissBtn}
              onClick={() => {
                setOpenFilter(false);
                props.onDismissClick(props.Element);
              }}
            ></input>
          </div>
        </>
      )}
      {sugCheckContent && <ConfiemDialog />}
    </div>
  );
}
export default Citation;
Citation.propTypes = {
  onCloseFloatSuggestion: PropTypes.func,
  Element: PropTypes.object,
  onDocumentRefrence: PropTypes.func,
  ProjectId: PropTypes.number,
  onCitationAcceptClick: PropTypes.func,
  onDismissClick: PropTypes.func,
  content: PropTypes.string,
  keyWords: PropTypes.string,
  bindDocumentCitations: PropTypes.func,
};

Citation.defaultProps = {
  onCloseFloatSuggestion: () => {},
  Element: null,
  dataSug: [],
  documentRefrence: [],
  onDocumentRefrence: () => {},
  ProjectId: 0,
  citationIds: [],
  onCitationAcceptClick: () => {},
  onDismissClick: (div) => {},
  content: "",
  closeFilter: null,
  keyWords: "",
  bindDocumentCitations: () => {},
};
