import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { getProjects } from "../../../Requests/Requests";
import classes from "./Projects.module.css";
import { withRouter } from "react-router-dom";
function Projects(props) {
  const [projects, setProject] = useState([]);

  useEffect(() => {
    getProjects()
      .then((res) => {
        setProject(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const sortProjectsdate = (a, b) => {
    let sortFielddate = "last_modified";

    if (a.id == props.projectid) return -1;
    if (b.id == props.projectid) return 1;
    // If sorting by date

    const dateA = new Date(a[sortFielddate]);
    const dateB = new Date(b[sortFielddate]);
    return dateB - dateA;
  };
  const changeProject = (id) => {
    window.location.href = "/panel/projects/" + id + "/";
  };
  return (
    <>
      {projects &&
        projects.length &&
        projects.sort(sortProjectsdate).map((pr, index) => {
          return (
            <div
              onClick={() => {
                if (pr.id != props.projectid) {
                  props.onSave();
                  setTimeout(() => {
                    changeProject(pr.id);
                  }, 500);
                }
              }}
              className={
                classes.box +
                " " +
                (index % 2 == 1 ? classes.alternativeColor : "") +
                " " +
                (props.projectid != pr.id
                  ? classes.clickable
                  : classes.notClickAble)
              }
              key={pr.id}
            >
              {pr.title}
            </div>
          );
        })}
    </>
  );
}
export default withRouter(Projects);
Projects.propTypes = {
  projectid: propTypes.number,
  onSave: propTypes.func,
};
Projects.defaultProps = {
  projectid: 0,
  onSave: (func) => {},
};
