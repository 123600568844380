import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import classes from "./ProfileCard.module.css";
import CheckIcon from "@material-ui/icons/Check";
import defaultAvatar from "../../asset/default_user_avatar.png";
import BusinessIcon from "@material-ui/icons/Business";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { CircularProgress, TextField } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import {
  saveUserInfo,
  uploadProfileImage,
} from "../../../../Requests/Requests";
import { ImageDocumentUploadFileAllowMimeType } from "../../../../Functions/FilterUploadFile.js";
function ProfileCard(props) {
  const [organizationEditable, setOrganizationEditable] = useState(false);
  const [organizationNewValue, setOrganizationNewValue] = useState("");
  const refUploadFile = useRef(null);
  const [isFistName_LastName_Editable, setIsFistName_LastName_Editable] =
    useState(false);
  const [firstNameNewValue, setFirstNameNewValue] = useState("");
  const [lastNameNewValue, setLastNameNewValue] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [is_active, setIs_active] = useState(false);
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [profile_image, setProfile_image] = useState("");
  const [loadFile, setLoadFile] = useState(false);
  const [is_profile_image_in_hand, setIs_profile_image_in_hand] =
    useState(true);

  useEffect(() => {
    if (props.data && props.data.first_name) {
      setFirst_name(props.data.first_name);
      setLast_name(props.data.last_name);
      setIs_active(props.data.is_active);
      setEmail(props.data.email);
      setOrganization(props.data.organization);
      setProfile_image(
        props.data.profile_image == null || props.data.profile_image == ""
          ? defaultAvatar
          : props.data.profile_image
      );
      setIs_profile_image_in_hand(!props.data.profile_image);
    }
  }, [props.data]);

  const callUploadProfileImage = (data) => {
    uploadProfileImage(data)
      .then((res) => {
        setLoadFile(false);
        if (res.status >= 200 && res.status <= 200) {
          if (res.data.message) {
            toast.success(res.data.message);
          } else {
            toast.success("Upload Successfully!");
          }
          if (res.data.data) {
            setProfile_image(
              res.data.data.profile_image == null ||
                res.data.data.profile_image == ""
                ? defaultAvatar
                : res.data.data.profile_image
            );
            setIs_profile_image_in_hand(!res.data.data.profile_image);
          }
        }
      })
      .catch((err) => {
        setLoadFile(false);
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
          console.log(err, err.response);
          toast.error("Something went wrong!");
        }
      });
  };
  const callSaveUserInfo = (data) => {
    saveUserInfo(data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 200) {
          if (res.data.message) {
            toast.success(res.data.message);
          } else {
            toast.success("Saved Successfully!");
          }
          if (res.data.data) {
            first_name(res.data.data.first_name);
            setLast_name(res.data.data.last_name);
            setOrganization(res.data.data.organization);

            setProfile_image(
              res.data.data.profile_image == null ||
                res.data.data.profile_image == ""
                ? defaultAvatar
                : res.data.data.profile_image
            );
            setIs_profile_image_in_hand(!res.data.data.profile_image);
          }
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
          console.log(err, err.response);
          toast.error("Something went wrong!");
        }
      });
  };
  const handleFileSelect = (e) => {
    setLoadFile(true);

    if (e.target.files.length > 0) {
      let file = e.target.files[0];

      if (!ImageDocumentUploadFileAllowMimeType(file.type)) {
        setLoadFile(false);
        return;
      }
      if (file.name.length >= 99) {
        toast.error(
          "Upload failed! The file name exceed than 100 characters. Please rename the file."
        );
        setLoadFile(false);
        return;
      }
      const f = new FormData();
      f.append("first_name", first_name);
      f.append("last_name", last_name);
      f.append("organization", organization);
      f.append("profile_image", file);
      callUploadProfileImage(f);
    }
  };
  let year, month, day, isExpired;

  if (props.expirationDate) {
    let currentDate = new Date();
    let date = new Date(props.expirationDate);
    let isExpired = date < currentDate;
    year = date.getFullYear();
    month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns a 0-based month, so we add 1
    day = date.getDate().toString().padStart(2, "0");
  } else {
    isExpired = null;
    year = month = day = "";
  }
  const active = (
    <div className={classes.active}>
      <h3>Active user</h3>
    </div>
  );
  const nonactive = (
    <>
      <div className={classes.nonactive}>
        <h3 className={classes.Text}>Nonactive user</h3>
      </div>
      <h5>
        Active<span> Now!</span>
      </h5>
    </>
  );
  const changingPart = is_active ? active : nonactive;

  return (
    <div className={classes.CardProfile}>
      <div className={classes.ImageBox}>
        {!loadFile ? (
          <div
            onClick={() => {
              refUploadFile.current.click();
            }}
            className={classes.uploadImageBox}
          >
            <EditIcon />
            &nbsp;Profile Picture
            <input
              ref={refUploadFile}
              type="file"
              accept="image/*"
              hidden
              onChange={handleFileSelect}
            />
          </div>
        ) : (
          <div className={classes.imageLoadingBox}>
            <CircularProgress
              style={{
                height: "60px",
                width: "60px",
              }}
            />
          </div>
        )}
        <img
          src={profile_image}
          alt={`${first_name} profile picture`}
          title={`${first_name} ${last_name} profile picture`}
          className={classes.ImageProfile}
          style={{ objectFit: is_profile_image_in_hand ? "cover" : "contain" }}
        />
      </div>

      <div className={classes.mainSection}>
        <div className={classes.Icon}>
          <PersonIcon
            className={classes.leftIcon}
            style={{
              backgroundColor: "white ",
              color: "rgb(29, 33, 29)",
              borderRadius: "50%",
              fontSize: 20,
            }}
          />
          <div
            className={classes.Text + " " + classes.inputDisabled}
            title={`${first_name} ${last_name} `}
          >
            {!isFistName_LastName_Editable ? (
              <>{`${first_name} ${last_name} `}</>
            ) : (
              <div style={{ display: "flex" }}>
                <TextField
                  style={{ paddingRight: "0.2rem" }}
                  variant="outlined"
                  placeholder="First Name"
                  title="First Name"
                  value={firstNameNewValue}
                  onChange={(e) => setFirstNameNewValue(e.target.value)}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "18.5px 6px!important",
                    },
                  }}
                />
                <TextField
                  style={{ paddingRight: "0.5rem" }}
                  variant="outlined"
                  placeholder="Last Name"
                  title="Last Name"
                  value={lastNameNewValue}
                  onChange={(e) => setLastNameNewValue(e.target.value)}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "18.5px 6px!important",
                    },
                  }}
                />
              </div>
            )}
          </div>
          {!isFistName_LastName_Editable ? (
            <EditIcon
              title="Edit"
              style={{
                color: "rgba(38, 222, 115, 0.851)",
                borderRadius: "50%",
                fontSize: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setIsFistName_LastName_Editable(true);
                setFirstNameNewValue(first_name);
                setLastNameNewValue(last_name);
              }}
              className={classes.rightIcon}
            />
          ) : (
            <>
              <CancelIcon
                title="Cancel"
                style={{
                  color: "tomato",
                  borderRadius: "50%",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsFistName_LastName_Editable(false);
                  setFirstNameNewValue(first_name);
                  setLastNameNewValue(last_name);
                }}
                className={classes.rightIcon}
              />
              <SaveIcon
                title="Save"
                style={{
                  color: "rgb(33 186 97)",
                  borderRadius: "50%",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let validation_result = true;
                  if (
                    firstNameNewValue == null ||
                    firstNameNewValue.trim() == ""
                  ) {
                    toast.error("Please Input First Name");
                    validation_result = false;
                  }
                  if (
                    lastNameNewValue == null ||
                    lastNameNewValue.trim() == ""
                  ) {
                    toast.error("Please Input Last Name");
                    validation_result = false;
                  }
                  if (validation_result) {
                    setIsFistName_LastName_Editable(false);
                    const f = new FormData();
                    f.append("first_name", firstNameNewValue);
                    f.append("last_name", lastNameNewValue);
                    f.append("organization", organization);

                    callSaveUserInfo(f);

                    setLast_name(lastNameNewValue);
                    setFirst_name(firstNameNewValue);
                  }
                }}
                className={classes.rightIcon}
              />
            </>
          )}
        </div>

        <div className={classes.Icon}>
          <EmailIcon
            className={classes.leftIcon}
            style={{
              backgroundColor: "white ",
              color: "rgb(29, 33, 29)",
              borderRadius: "50%",
              fontSize: 20,
            }}
          />
          <div
            className={classes.Text + " " + classes.inputDisabled}
            title={email}
          >
            {email}
          </div>

          <CheckIcon
            style={{
              backgroundColor: "rgba(38, 222, 115, 0.851) ",
              color: "white",
              borderRadius: "50%",
              fontSize: 15,
            }}
            className={classes.rightIcon}
          />
        </div>
        <div className={classes.Icon}>
          <BusinessIcon
            className={classes.leftIcon}
            style={{
              backgroundColor: "white ",
              color: "rgb(29, 33, 29)",
              borderRadius: "50%",
              fontSize: 20,
            }}
          />
          <div
            className={classes.Text + " " + classes.inputDisabled}
            title={`${organization}`}
          >
            {!organizationEditable ? (
              <>{`${organization}`}</>
            ) : (
              <TextField
                style={{ width: "100%", paddingRight: "0.5rem" }}
                variant="outlined"
                placeholder="Organization"
                value={organizationNewValue}
                onChange={(e) => setOrganizationNewValue(e.target.value)}
                size="small"
              />
            )}
          </div>
          {!organizationEditable ? (
            <EditIcon
              title="Edit"
              style={{
                color: "rgba(38, 222, 115, 0.851)",
                borderRadius: "50%",
                fontSize: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setOrganizationEditable(true);
                setOrganizationNewValue(organization);
              }}
              className={classes.rightIcon}
            />
          ) : (
            <>
              <CancelIcon
                title="Cancel"
                style={{
                  color: "tomato",
                  borderRadius: "50%",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOrganizationEditable(false);
                  setOrganizationNewValue(organization);
                }}
                className={classes.rightIcon}
              />
              <SaveIcon
                title="Save"
                style={{
                  color: "rgb(33 186 97)",
                  borderRadius: "50%",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOrganizationEditable(false);
                  const f = new FormData();
                  f.append("first_name", first_name);
                  f.append("last_name", last_name);
                  f.append("organization", organizationNewValue);

                  callSaveUserInfo(f);

                  setOrganization(organizationNewValue);
                }}
                className={classes.rightIcon}
              />
            </>
          )}
        </div>
        <div className={classes.Icon}>
          <VpnKeyIcon
            className={classes.leftIcon}
            style={{
              backgroundColor: "white ",
              color: "rgb(29, 33, 29)",
              borderRadius: "50%",
              fontSize: 20,
            }}
          />
          <input
            className={classes.Text + " " + classes.inputDisabled}
            style={{
              fontSize: 12,
              fontFamily: "monospace",
            }}
            disabled
            value={props.ApiKey || ""}
            placeholder="API Key ..."
          ></input>

          {props.ApiKey && (
            <FileCopyIcon
              title="copy"
              style={{
                color: "rgba(38, 222, 115, 0.85)",
                cursor: "pointer",
                fontSize: 20,
              }}
              className={classes.rightIcon}
              onClick={() => {
                props.copyToClipBoard(props.ApiKey);
              }}
            />
          )}
        </div>
        <div
          className={classes.Icon}
          style={{
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            backgroundColor: "#eb3449",
          }}
          onClick={() => {
            props.GenerateApiKeys();
          }}
        >
          <h4 style={{ color: "white" }}>Generate API Key</h4>
        </div>
        {props.expirationDate && (
          <div
            className={classes.Icon}
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "white",
              backgroundColor: "#eb3449",
            }}
          >
            <h5 style={{ color: "black" }}>
              {isExpired ? "Expired at" : "Expires at"}:{" "}
              {`${month}-${day}-${year}`}
            </h5>
          </div>
        )}
        {changingPart}
      </div>
    </div>
  );
}
export default ProfileCard;
