import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import useAsyncSelectList from '../../hooks/useAsyncSelectList';
import { demoUser, liboBerryPlans, pubBerryPlans, refBerryPlans } from './data';
import BraintreeForm from './BraintreeForm';
import { api, getUserInfo } from '../../Requests/Requests';

const useStyles = makeStyles((theme) => ({
  page: {
    padding: 20,
    overflowAnchor: 'none',
  },
  sectionTitle: {
    fontSize: 20,
    color: '#444',
  },
  cardsWrapper: {
    display: 'flex',
    // justifyContent: 'center',
    flexFlow: 'row wrap',
  },
  card: {
    position: 'relative',
    backgroundColor: '#EFEFEF',
    margin: 10,
    width: 350,
    minWidth: 350,
    height: 200,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 30,
  },
  getSubBtn: {
    textTransform: 'none',
  },
  comboBox: {
    height: 25,
    outline: 'none',
    border: 'none',
    backgroundColor: '#00000000',
    borderRadius: 5,
    fontWeight: 'bold',
    marginLeft: -3,
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#00000011',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
  },
  getSubBtn2: {
    borderRadius: 30,
    color: 'white',
    border: 'none',
    zIndex: 1,
    fontSize: 16,
    padding: '5px 10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'inset 0 0 3px 5px #00000033',
    },
  },
}));

function SubscriptionPage(props) {
  const classes = useStyles();
  const [user, setUser] = useState(null);

  const getPlansWith = async (func) => {
    try {
      const res = await func();
      return res.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const generateLiboPlanTitle = (plan) => {
    return `MEMBERSHIP FOR ${plan?.duration_in_days} DAYS / ${plan?.storage_limit} MB`;
  };

  const generatePubPlanTitle = (plan) => {
    return `${plan?.credits_count} ITEMS`;
  };

  const generateRefPlanTitle = (plan) => {
    return `${plan?.credits_count} ITEMS`;
  };

  const getPubBerryPlans = async () => {
    const plans = await getPlansWith(api.getPubberryPackages);
    return plans.map((plan) => ({
      ...plan,
      title: plan?.title || generatePubPlanTitle(plan),
    }));
  };

  const getRefBerryPlans = async () => {
    const plans = await getPlansWith(api.getRefberryPackages);
    return plans.map((plan) => ({
      ...plan,
      title: plan?.title || generateRefPlanTitle(plan),
    }));
  };

  const getLiboBerryPlans = async () => {
    const plans = await getPlansWith(api.getLiboberryPlans);
    return plans.map((plan) => ({
      ...plan,
      title: plan.title || generateLiboPlanTitle(plan),
    }));
  };

  const [pubPlans, selectedPubPlan, setSelectedPubPlan] =
    useAsyncSelectList(getPubBerryPlans);

  const [refPlans, selectedRefPlan, setSelectedRefPlan] =
    useAsyncSelectList(getRefBerryPlans);

  const [liboPlans, selectedLiboPlan, setSelectedLiboPlan] =
    useAsyncSelectList(getLiboBerryPlans);

  const getUser = async () => {
    getUserInfo()
      .then((res) => {
        setUser(res.data);
      })
      .then(console.log);
  };

  useEffect(() => {
    getUser();
  }, []);

  const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);
  const [numberOfProducts, setNumberOfProducts] = useState(1);

  const PRICE = 50;

  return (
    <div className={classes.page}>
      <h2 className={classes.sectionTitle}>Current subscription and credits</h2>
      <br />
      <div className={classes.cardsWrapper}>
        <PlanCard color='#FF7843' lColor='#FFEAE2'>
          <h3>LiboBerry</h3>
          <section className={classes.row}>
            <b>Start Date:&nbsp;</b>
            {new Date(
              user?.liboberry_subscription?.start_date_time
            ).toLocaleString()}
          </section>
          <section className={classes.row}>
            <b>Storage Limit:&nbsp;</b>
            {user?.liboberry_subscription?.plan.storage_limit} MB
          </section>
          <section className={classes.row}>
            <b>Duration:&nbsp;</b>
            {user?.liboberry_subscription?.plan.duration_in_days} Days
          </section>
        </PlanCard>

        <PlanCard color='#00bcd4' lColor='#EAF7F7'>
          <h3>PubBerry</h3>
          <section className={classes.row}>
            <b>Remaining Credits:&nbsp;</b>
            {user?.remaining_pubberry_credits}
          </section>
        </PlanCard>

        <PlanCard color='#8A5DD2' lColor='#F3F0F8'>
          <h3>RefBerry</h3>
          <section className={classes.row}>
            <b>Remaining Credits:&nbsp;</b>
            {user?.remaining_refberry_credits}
          </section>
        </PlanCard>
      </div>

      <Divider style={{ margin: '10px 0' }} />
      <h2 className={classes.sectionTitle}>Get new subscription / credits</h2>
      {/* Choose a service to start subscription. */}
      <br />

      <div className={classes.cardsWrapper}>
        {/* ------------------------- LiboBerry ------------------------- */}
        {/* <div className={classes.card} style={{ backgroundColor: '#FFEAE2', color: '#FF7843' }}> */}
        {liboPlans && (
          <PlanCard color='#FF7843' lColor='#FFEAE2'>
            <h3>LiboBerry</h3>
            <section className={classes.row}>
              <select
                name='storage'
                className={classes.comboBox}
                value={selectedLiboPlan?.id}
                onChange={(e) => setSelectedLiboPlan(e.target.value)}
                style={{ color: '#FF7843' }}
              >
                {liboPlans.map(({ id, title }) => (
                  <option key={id} value={id}>
                    {title}
                  </option>
                ))}
              </select>
            </section>
            <section className={classes.row}>
              <b>${selectedLiboPlan?.price}</b>
            </section>
            {/* <GetSubButton title="Get Subscription" color="#FF7843" /> */}
            <PaymentButton
              onPayButtonClick={(payment_method_nonce) =>
                api.buySubscription.bind(null, {
                  payment_method_nonce,
                  plan_id: selectedLiboPlan?.id,
                })
              }
              btnText='Buy Credit'
              data={{ plan_id: selectedLiboPlan?.id }}
              renderPlan={() => (
                <>
                  <b>LiboBerry credit package:</b>{' '}
                  {generateLiboPlanTitle(selectedLiboPlan)}
                  <br />
                  <b>Price:</b> ${selectedLiboPlan?.price}
                </>
              )}
              renderPaymentButton={(openPaymentForm) => (
                <GetSubButton
                  title='Get Subscription'
                  color='#FF7843'
                  onClick={openPaymentForm}
                />
              )}
            />
          </PlanCard>
        )}

        {/* ------------------------- PubBerry ------------------------- */}
        <PlanCard color='#00bcd4' lColor='#EAF7F7'>
          <h3>PubBerry</h3>
          <section className={classes.row}>
            <select
              name='storage'
              className={classes.comboBox}
              value={selectedPubPlan?.id}
              onChange={(e) => {
                setSelectedPubPlan(e.target.value);
              }}
              style={{ color: '#00bcd4' }}
            >
              {pubPlans.map(({ id, title }) => (
                <option key={id} value={id}>
                  {title}
                </option>
              ))}
            </select>
          </section>
          <section className={classes.row}>
            <b>${selectedPubPlan?.price}</b>
          </section>
          {/* <GetSubButton title="Buy Credit" color="#00bcd4" /> */}
          <PaymentButton
            onPayButtonClick={(payment_method_nonce) =>
              api.buyPubberryCredit.bind(null, {
                payment_method_nonce,
                credit_package_id: selectedPubPlan?.id,
              })
            }
            btnText='Buy Credit'
            data={{ credit_package_id: selectedPubPlan?.id }}
            renderPlan={() => (
              <>
                <b>PubBerry credit package:</b>{' '}
                {generatePubPlanTitle(selectedPubPlan)}
                <br />
                <b>Price:</b> ${selectedPubPlan?.price}
              </>
            )}
            onPaymentCompleted={() => {
              console.log('Payment :: OK');
            }}
            renderPaymentButton={(openPaymentForm) => (
              <GetSubButton
                title='Buy Credit'
                color='#00bcd4'
                onClick={openPaymentForm}
              />
            )}
          />
        </PlanCard>

        {/* ------------------------- RefBerry ------------------------- */}
        <PlanCard color='#8A5DD2' lColor='#F3F0F8'>
          <h3>RefBerry</h3>
          <section className={classes.row}>
            <select
              name='storage'
              className={classes.comboBox}
              value={selectedRefPlan?.id}
              onChange={(e) => {
                setSelectedRefPlan(e.target.value);
              }}
              style={{ color: '#8A5DD2' }}
            >
              {refPlans.map(({ id, title }) => (
                <option key={id} value={id}>
                  {title}
                </option>
              ))}
            </select>
          </section>
          <section className={classes.row}>
            <b>${selectedRefPlan?.price}</b>
          </section>
          <PaymentButton
            onPayButtonClick={(payment_method_nonce) =>
              api.buyRefberryCredit.bind(null, {
                payment_method_nonce,
                credit_package_id: selectedRefPlan?.id,
              })
            }
            btnText='Buy Credit'
            data={{ credit_package_id: selectedRefPlan?.id }}
            renderPlan={() => (
              <>
                <b>RefBerry credit package:</b>{' '}
                {generateRefPlanTitle(selectedRefPlan)}
                <br />
                <b>Price:</b> ${selectedRefPlan?.price}
              </>
            )}
            renderPaymentButton={(openPaymentForm) => (
              <GetSubButton
                title='Buy Credit'
                color='#8A5DD2'
                onClick={openPaymentForm}
              />
            )}
          />
          {/* <GetSubButton title="Buy Credit" color="#8A5DD2" /> */}
        </PlanCard>
      </div>
      <br />
      <div
        style={{
          display: showBraintreeDropIn ? 'flex' : 'none',
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 2000,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#00000055',
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowBraintreeDropIn(false);
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 10,
            padding: 10,
            width: 500,
            minHeight: 410,
            maxWidth: 'calc(100% - 20px)',
            maxHeight: '100%',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <BraintreeForm
            show={showBraintreeDropIn}
            onPaymentCompleted={() => {
              setShowBraintreeDropIn(false);
              setNumberOfProducts(1);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(SubscriptionPage);

SubscriptionPage.propTypes = {};

function PaymentButton(props) {
  const {
    onPaymentCompleted,
    renderPaymentButton,
    renderPlan,
    onPayButtonClick,
  } = props;
  const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);

  return (
    <>
      {renderPaymentButton(() => setShowBraintreeDropIn(true))}

      {showBraintreeDropIn && (
        <div
          style={{
            display: showBraintreeDropIn ? 'flex' : 'none',
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 2000,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#00000055',
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowBraintreeDropIn(false);
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
              padding: 10,
              width: 500,
              minHeight: 410,
              maxWidth: 'calc(100% - 20px)',
              maxHeight: '100%',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {renderPlan()}
            <BraintreeForm
              onPayButtonClick={onPayButtonClick}
              show={showBraintreeDropIn}
              onPaymentCompleted={() => {
                onPaymentCompleted();
                setShowBraintreeDropIn(false);
                // setNumberOfProducts(1);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

PaymentButton.propTypes = {
  data: PropTypes.object,
  onPaymentCompleted: PropTypes.func,
  onPayButtonClick: PropTypes.func,
  renderPaymentButton: PropTypes.func,
  renderPlan: PropTypes.func,
};

const Image = (props) => {
  const { color } = props;
  return (
    <svg
      style={{ position: 'absolute', bottom: 0, left: 0 }}
      xmlns='http://www.w3.org/2000/svg'
      width='350'
      height='53.627'
      viewBox='0 0 350 53.627'
    >
      <g id='Group_5' data-name='Group 5' transform='translate(-44 -229.373)'>
        <path
          d='M20.921,199.912s70.77-42.9,136.231-40.693,68.558,26.1,125.174,25.654S350,163.2,350,163.2V180a20,20,0,0,1-18.11,19.912Z'
          transform='translate(44 83.088)'
          opacity='0.33'
          fill={color}
        />
        <path
          d='M20,200A20,20,0,0,1,0,180V167.225S48.135,142.117,114.692,147s107.259,30.57,151.535,39.771c30.15,6.265,56.835,10.13,70.792,11.958A19.958,19.958,0,0,1,330,200Z'
          transform='translate(44 83)'
          opacity='0.33'
          fill={color}
        />
      </g>
    </svg>
  );
};

function GetSubButton(props) {
  const { onClick, title, color } = props;
  const classes = useStyles();
  return (
    <>
      <div style={{ flex: 1 }} />
      <button
        className={classes.getSubBtn2}
        style={{ backgroundColor: color }}
        onClick={onClick}
      >
        {title}
      </button>
    </>
  );
}

GetSubButton.propTypes = {
  onClick: PropTypes.func,
};

GetSubButton.defaultProps = {
  onClick: () => { },
};

function PlanCard(props) {
  const { color, lColor, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.card} style={{ backgroundColor: lColor, color }}>
      <Image color={color} />
      {children}
    </div>
  );
}
