import { useState, useEffect } from "react";
import * as dropin from "braintree-web-drop-in";
import { Button, CircularProgress } from "@material-ui/core";
import { api } from "../../Requests/Requests";
import styles from './BraintreeCardStyles.module.css';
import { toast } from 'react-toastify'

export default function BraintreeDropIn(props) {
  const { show, onPaymentCompleted, onPayButtonClick } = props;

  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [transactionId, setTransactionId] = useState(null);
  const [trLoading, setTrLoading] = useState(false);

  useEffect(() => {
    if (show) {
      const initializeBraintree = async () =>
        dropin.create(
          {
            authorization: (await api.getPaypalClientToken())?.data
              ?.paypal_client_token, // insert your tokenization key or client token here
            container: "#braintree-drop-in-div",
          },
          function (error, instance) {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
            setLoading(false);
          }
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    } else setLoading(true);
  }, [show]);

  return (
    <div
      className={styles.BrainTreePopup}
      style={{
        display: show ? "flex" : "none",
        flexDirection: "column",
        minHeight: "387px",
        margin: 0,
      }}
    >
      {transactionId && (
        <div style={{ color: 'black', textAlign: 'center', fontSize: 23 }}><p>Your Transaction ID: <b><pre style={{ padding: 5, backgroundColor: '#CCC', borderRadius: 5 }}>{transactionId}</pre></b></p></div>
      )}

      {trLoading && (
        <div
          style={{
            width: '100%',
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "green",
            }}
          />
        </div>
      )}
      <div id={"braintree-drop-in-div"} style={{ flex: 1 }} />
      {loading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            width: 200,
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "green",
            }}
          />
        </div>
      )}
      {(braintreeInstance && !loading && !transactionId && !trLoading) && (
        <Button
          style={{ backgroundColor: "green", color: "white" }}
          className={"braintreePayButton"}
          variant="contained"
          color="success"
          disabled={(!braintreeInstance)}
          onClick={() => {
            if (braintreeInstance) {
              braintreeInstance.requestPaymentMethod((error, payload) => {
                if (error) {
                  console.error(error);
                } else {
                  const paymentMethodNonce = payload.nonce;
                  setTrLoading(true)
                  onPayButtonClick(paymentMethodNonce)().then(res => {
                    setTransactionId(res.data.transaction_id);
                    setTrLoading(false);
                    // onPaymentCompleted()
                  }).catch(err => {
                    setTransactionId(null)
                    setLoading(false)
                    setTrLoading(false)
                    toast.error('Something went wrong! Please retry...');
                  })
                  // onPaymentCompleted();
                }
              });
            }
          }}
        >
          Pay
        </Button>
      )}
    </div>
  );
}
