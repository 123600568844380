import React from "react";
import propTypes from "prop-types";
import classess from "./ProjectInfo.module.css";
import { toast } from "react-toastify";
import { updateProject } from "../../../Requests/Requests.js";
import { projectTypes } from "../../../Pages/DocumentsPage/ProjectTypes.js";
import { CitationStyles } from "../../../Pages/DocumentsPage/Citation_styles.js";
import { TextField, MenuItem } from "@material-ui/core";
function ProjectsInfo(props) {
  const updateProjectInfo = (value, title) => {
    updateProject(props.projectid, value)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299)
          if (res.data.message) {
            toast.success(res.data.message);
          } else {
            toast.success("updated " + title + " successfully");
          }
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else {
          console.log(err, err.response);
        }
      });
  };

  return (
    <>
      <div className={classess.box}>
        <TextField
          defaultValue={props.projectInfoValue?.title}
          name="projectName"
          id="projectName"
          label="Title"
          size="small"
          style={{ width: "100%" }}
          onBlur={(e) => {
            let result = e.target.value.trim();
            if (result != props.projectInfoValue?.title) {
              if (result != null && result != "") {
                updateProjectInfo({ title: result }, "Project Title");

                if (props.setProjectInfo != null) {
                  props.setProjectInfo({
                    ...props.projectInfoValue,
                    title: result,
                  });
                }
              } else {
                toast.error("Project Name must have value");
              }
            }
          }}
          req
          variant={"outlined"}
        />
      </div>
      <div className={classess.box}>
        <TextField
          defaultValue={props.projectInfoValue?.type}
          name="projectType"
          size="small"
          id="projectType"
          label="Type"
          style={{ width: "100%" }}
          select
          onChange={(e) => {
            updateProjectInfo({ type: e.target.value }, "Project Type");

            if (props.setProjectInfo != null) {
              props.setProjectInfo({
                ...props.projectInfoValue,
                type: e.target.value,
              });
            }
          }}
          req
          variant={"outlined"}
        >
          {projectTypes.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classess.box}>
        <TextField
          defaultValue={props.projectInfoValue?.citation_style}
          name="projectCitation_style"
          id="projectCitation_style"
          label="Citation Style"
          size="small"
          style={{ width: "100%" }}
          select
          onChange={(e) => {
            updateProjectInfo(
              { citation_style: e.target.value.toLocaleLowerCase() },
              "Citation Style"
            );

            if (props.setProjectInfo != null) {
              props.setProjectInfo({
                ...props.projectInfoValue,
                citation_style: e.target.value.toLocaleLowerCase(),
              });
            }
            if (props.onCitationTypeChange != null) {
              props.onCitationTypeChange(e.target.value.toLocaleLowerCase());
            }
          }}
          req
          variant={"outlined"}
        >
          {CitationStyles.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  );
}
export default ProjectsInfo;
ProjectsInfo.propTypes = {
  projectInfoValue: propTypes.object,
  projectid: propTypes.number,
  setProjectInfo: propTypes.func,
  onCitationTypeChange: propTypes.func,
};
ProjectsInfo.defaultProps = {
  projectInfoValue: { title: "", citation_style: "", type: "" },
  projectid: 0,
  setProjectInfo: () => {},
  onCitationTypeChange: () => {},
};
