import { Button, Checkbox, Chip } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import EditableAuthorList from "../../EditableAuthorList/EditableAuthorList";
import EditableISSNList from "../../EditableISSNList/EditableISSNList";
import classes from "./JournalArticle.module.css";
import classesAccordion from "../../accordion/accordion.module.css";
import classesSpin from "../../Loader/Loader.module.css";
import ExtentionIcon from "../../ExtentionIcon/ExtentionIcon.js";
import { DocumentUploadFileAllowMimeTypeWithOutBibTex } from "../../../Functions/FilterUploadFile";
import { toast } from "react-toastify";
import {
  updateProjectDocumentFile,
  deleteDocumentFileOfProject,
} from "../../../Requests/Requests.js";
import FloatDeleteButton from "../../FloatDeleteButton/FloatDeleteButton.js";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PublicIcon from "@material-ui/icons/Public";
import LabelItem from "../../Tags/LabelItem.js";

function JournalArticle(props) {
  const {
    formObj,
    renderTypeChooser,
    onDataChange,
    renderSaveButton,
    status,
    projectId,
    AccordionStatus,
    refreshDocumentsAndFile,
    handleOnClickAccordion,
    handleDeleteTagMng,
    handleDeleteProjectArticle,
    handleProcessContent,
    headerMinHeight,
    customArticle,
    onUpdateCustomArticle,
  } = props;
  const { name, file, documentbibtexdata, url, id, document_tags, source } =
    formObj;
  const [changing, setChanging] = useState(false);
  const [formData, setFormData] = useState({
    // title: documentbibtexdata?.title || '',
    // doi: documentbibtexdata?.doi || '',
    // year: documentbibtexdata?.year || '',
    // volume: documentbibtexdata?.volume || '',
    // page: documentbibtexdata?.page || '',
    // issue: documentbibtexdata?.issue || '',
    ...documentbibtexdata,
  });
  const [loadFile, setLoadFile] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [allowToHandleAccordion, setAllowToHandleAccordion] = useState(true);
  const handleDeleteTag = (tagId, doc_id) => {
    handleDeleteTagMng(tagId, doc_id);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      props.selectArticless(id);
    } else {
      props.removeSelectArticles(id);
    }
  };
  const uploadArticleFile = (e, id) => {
    setLoadFile(true);

    if (DocumentUploadFileAllowMimeTypeWithOutBibTex(e.target.files[0].type)) {
      if (e.target.files[0].name.length >= 99) {
        setLoadFile(false);
        toast.error(
          "Upload failed! The file name exceed than 100 characters. Please rename the file."
        );
        return;
      }
      handleProcessContent("loading");
      updateProjectDocumentFile(projectId, id, e.target.files[0])
        .then((res) => {
          handleProcessContent("success");
          setLoadFile(false);
          if (res.status >= 200 && res.status <= 299) {
            refreshDocumentsAndFile();
            if (res.data.message) {
              toast.success(res.data.message);
            } else {
              toast.success("file :" + e.target.files[0].name + " Uploaded!");
            }
          }
        })
        .catch((err) => {
          handleProcessContent("error");
          setLoadFile(false);
          if (err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            toast.error("File Upload Failed!");
          }
        });
    } else {
      toast.error("File Format is invalid");
      setLoadFile(false);
    }
  };
  const handleDeleteFile = (id) => {
    deleteDocumentFileOfProject(projectId, id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          refreshDocumentsAndFile();
          if (res.data.message) {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Delete Failed!");
          console.log(err, err.response);
        }
      });
  };
  const handleFormChange = useCallback((e) => {
    // console.log(e.target.name+':'+e.target.value)
    setFormData((formData) => ({
      ...JSON.parse(JSON.stringify(formData)),
      [e.target.name]: e.target.value,
    }));
    setChanging(true);
    // console.log(formData)
  }, []);

  const [authors, setAuthors] = useState(
    props.authors_json == null
      ? []
      : props.authors_json.length == 0
      ? []
      : typeof props.authors_json === "string"
      ? JSON.parse(props.authors_json)
      : props.authors_json
  );
  const [issns, setISSNs] = useState(
    props.issns_json == null
      ? []
      : props.issns_json.length == 0
      ? []
      : typeof props.issns_json === "string"
      ? JSON.parse(props.issns_json)
      : props.issns_json
  );

  // const [issns, setISSNs] = useState(documentbibtexdata==null?[]:((documentbibtexdata.issns_json==null||documentbibtexdata.issns_json.length==0)?[]:JSON.parse(documentbibtexdata?.issns_json) ));

  const handleAddISSN = useCallback((newISSN) => {
    setISSNs((issns) => [...issns, newISSN]);
    setChanging(true);
  }, []);

  const handleDeleteISSN = useCallback((index) => {
    setISSNs((issns) => {
      return issns.filter((issn, idx) => index !== idx);
    });
    setChanging(true);
  }, []);

  const handleEditISSN = useCallback((index, newISSN) => {
    setISSNs((issns) => {
      const prevISSNs = JSON.parse(JSON.stringify(issns));
      prevISSNs[index] = newISSN;
      return prevISSNs;
    });
    setChanging(true);
  }, []);

  const getSubmitData = () => {
    const {
      title,
      issue,
      doi,
      page,
      volume,
      year,
      publisher,
      published_at,
      accessed,
      url,
    } = formData;
    const { type } = formObj.documentbibtexdata;
    const data = {
      title, // ---------
      issue,
      doi: doi,
      page,
      volume,
      year,
      publisher,
      published_at,
      accessed,
      type, // ---------
      authors_json: authors, // -------
      issns_json: issns,
      year, // ---------
      url,
    };
    // console.log(data)
    return data;
  };
  useEffect(() => {
    if (!changing) return;
    onDataChange(getSubmitData());
  }, [formObj.documentbibtexdata.type, issns, authors, formData]);

  return (
    <div
      style={{ fontSize: customArticle ? "0.75rem" : null }}
      className={classes.formWrapper}
    >
      {!customArticle && (
        <>
          <FloatDeleteButton onClick={() => handleDeleteProjectArticle(id)} />
          <h3
            style={{ minHeight: headerMinHeight }}
            className={classes.documentHeader}
          >
            <div>
              <Checkbox
                style={{ color: isChecked ? "#f4f4ee" : "", paddingTop: "7px" }}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div
              className={
                classesAccordion.accordion +
                " " +
                (AccordionStatus?.maxHeight ? classesAccordion.active : "")
              }
              style={{
                float: "left",
                width: file != null ? "calc(100% - 80px)" : "",
              }}
              onClick={(e) => {
                if (allowToHandleAccordion) {
                  handleOnClickAccordion(e, AccordionStatus?.id);
                } else {
                  setAllowToHandleAccordion(true);
                }
              }}
            >
              &nbsp;
              <ExtentionIcon FileName={file} url={url} />
              &nbsp;{name}&nbsp;
              {status != "parse_failed" ? "" : "[" + status + "]"}
              <span className={classes.inline_Flex}>
                {source == "discovery" && (
                  <LabelItem
                    name={"Discovery"}
                    color="#574d8c"
                    curserIsPointer={false}
                    hasCount={false}
                    icon={
                      <PublicIcon style={{ width: "15px", height: "15px" }} />
                    }
                  />
                )}
                {source == "Bibtex" && (
                  <LabelItem
                    name={"Bibtex"}
                    color="#008000"
                    curserIsPointer={false}
                    hasCount={false}
                    icon={<span style={{ fontWeight: "bold" }}>Bib</span>}
                  />
                )}
                {source == "kb" && (
                  <LabelItem
                    name={"Knowledge Base"}
                    color="#eb3449"
                    curserIsPointer={false}
                    hasCount={false}
                    icon={
                      <CloudUploadIcon
                        style={{ width: "16px", height: "16px" }}
                      />
                    }
                  />
                )}
                {source == "url" && (
                  <LabelItem
                    name={"Url"}
                    color="#659795"
                    icon={<span style={{ fontWeight: "bold" }}>Http</span>}
                    curserIsPointer={false}
                    hasCount={false}
                  />
                )}
                {source == "custom" && (
                  <LabelItem
                    name={"Custom"}
                    color="#73d673"
                    curserIsPointer={false}
                    hasCount={false}
                    icon={<span style={{ fontWeight: "bold" }}>Cu</span>}
                  />
                )}

                {document_tags &&
                  document_tags.length > 0 &&
                  document_tags.map((tag) => {
                    return (
                      <LabelItem
                        key={tag.id}
                        name={tag.name}
                        color={tag.color}
                        curserIsPointer={false}
                        hasCount={false}
                        hasDeleteIcon={true}
                        onDeleteClick={() => {
                          setAllowToHandleAccordion(false);
                          handleDeleteTag(tag.id, id);
                        }}
                      />
                    );
                  })}
              </span>
            </div>
            {/* ({documentbibtexdata.type})&nbsp; */}
            {file != null && (
              <a
                href={file || url}
                target="_blank"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  position: "absolute",
                  right: "0px",
                }}
              >
                <CloudDownload />
              </a>
            )}

            <div style={{ flex: 1 }}></div>
            {renderSaveButton()}
          </h3>
        </>
      )}
      <div
        className={classesAccordion.panel}
        style={{
          maxHeight: customArticle ? "100%" : AccordionStatus?.maxHeight,
        }}
      >
        {props.hasDocumentBibTexData && (
          <>
            {renderTypeChooser()}
            <br />
            {(source == "discovery" ||
              source == "Bibtex" ||
              source == "custom") && (
              <div className={classes.row}>
                <b>Upload File:</b>&nbsp;
                {file == null ? (
                  <div
                    className={classes.textInputWrapper}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      overflow: "hidden",
                    }}
                  >
                    {!loadFile ? (
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          textTransform: "none",
                          margin: 5,
                          width: "170px ",
                          backgroundColor: "#eb3449",
                          color: "white",
                        }}
                      >
                        Upload File
                        <input
                          type="file"
                          hidden
                          accept=".pdf, .csv, text/plain, .ppt, .pptx, .doc, .docx"
                          onChange={(e) => {
                            uploadArticleFile(e, id);
                          }}
                        />
                      </Button>
                    ) : (
                      <>
                        <img
                          className={classesSpin.spinItemAlways}
                          src="/loader.png"
                          style={{ width: "40px" }}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <Chip
                    title={file.split("/")[file.split("/").length - 1]}
                    icon={null}
                    label={file.split("/")[file.split("/").length - 1]}
                    onDelete={() => handleDeleteFile(id)}
                    className={classes.UploadFileResultChip}
                  />
                )}
              </div>
            )}
            <div className={classes.row}>
              <b>Article Title:</b>&nbsp;
              <div className={classes.textInputWrapper}>
                <input
                  size={Math.max(formData.title?.length || 20, 20)}
                  className={classes.textField}
                  type="text"
                  value={formData.title || ""}
                  name="title"
                  onChange={handleFormChange}
                  placeholder="title"
                />
              </div>
            </div>
            <div className={classes.row}>
              <b>DOI:</b>&nbsp;
              <input
                size={Math.max(formData.doi?.length || 20, 20)}
                className={classes.textField}
                type="text"
                value={formData.doi || ""}
                name="doi"
                onChange={handleFormChange}
                placeholder="DOI"
              />
            </div>

            <div className={classes.row}>
              <b>Year:</b>&nbsp;
              <input
                size={Math.max(formData.year?.length || 4, 4)}
                className={classes.textField}
                type="text"
                value={formData.year || ""}
                name="year"
                onChange={handleFormChange}
                placeholder="Year"
              />
            </div>
            <div className={classes.row}>
              <b>Publisher:</b>&nbsp;
              <div className={classes.textInputWrapper}>
                <input
                  size={Math.max(formData.publisher?.length || 20, 20)}
                  className={classes.textField}
                  type="text"
                  value={formData.publisher || ""}
                  name="publisher"
                  onChange={handleFormChange}
                  placeholder="Publisher"
                />
              </div>
            </div>
            <div className={classes.row}>
              <b>Journal:</b>&nbsp;
              <div className={classes.textInputWrapper}>
                <input
                  size={Math.max(formData.published_at?.length || 20, 20)}
                  className={classes.textField}
                  type="text"
                  value={formData.published_at || ""}
                  name="published_at"
                  onChange={handleFormChange}
                  placeholder="Journal"
                />
              </div>
            </div>
            <div className={classes.row}>
              <b>Volume:</b>&nbsp;
              <input
                size={Math.max(formData.volume?.length || 10, 10)}
                className={classes.textField}
                type="text"
                value={formData.volume || ""}
                name="volume"
                onChange={handleFormChange}
                placeholder="Volume"
              />
            </div>

            <div className={classes.row}>
              <b>Page:</b>&nbsp;
              <input
                size={Math.max(formData.page?.length || 8, 8)}
                className={classes.textField}
                type="text"
                value={formData.page || ""}
                name="page"
                onChange={handleFormChange}
                placeholder="Page"
              />
            </div>

            <div className={classes.row}>
              <b>Issue:</b>&nbsp;
              <input
                size={Math.max(formData.issue?.length || 10, 10)}
                className={classes.textField}
                type="text"
                value={formData.issue || ""}
                name="issue"
                onChange={handleFormChange}
                placeholder="Issue"
              />
            </div>

            <div
              style={{
                backgroundColor: "#DDD",
                padding: 5,
                marginTop: 5,
                borderRadius: 5,
              }}
            >
              <EditableAuthorList
                authors={authors}
                setAuthors={setAuthors}
                setChanging={setChanging}
              />
            </div>
            {issns && issns.length > 0 && (
              <div
                style={{
                  backgroundColor: "#DDD",
                  padding: 5,
                  marginTop: 5,
                  borderRadius: 5,
                }}
              >
                <b>ISSN:</b>
                <EditableISSNList
                  issns={issns}
                  onAdd={handleAddISSN}
                  onEdit={handleEditISSN}
                  onDelete={handleDeleteISSN}
                />
              </div>
            )}
          </>
        )}
        {!props.hasDocumentBibTexData && (
          <div
            className={classesSpin.divSpinCenter}
            style={{ textAlign: "left" }}
          >
            <div
              className={classesSpin.skeletonBox}
              style={{ width: "70%", Height: "20px" }}
            >
              {" "}
              &nbsp;
            </div>
            <div
              className={classesSpin.skeletonBox}
              style={{ width: "40%", Height: "20px" }}
            >
              {" "}
              &nbsp;
            </div>
            <div
              className={classesSpin.skeletonBox}
              style={{ width: "20%", Height: "20px" }}
            >
              {" "}
              &nbsp;
            </div>
            <div
              className={classesSpin.skeletonBox}
              style={{ width: "30%", Height: "20px" }}
            >
              {" "}
              &nbsp;
            </div>
            <div
              className={classesSpin.skeletonBox}
              style={{ width: "20%", Height: "20px" }}
            >
              {" "}
              &nbsp;
            </div>
            <div
              className={classesSpin.skeletonBox}
              style={{ width: "20%", Height: "20px" }}
            >
              {" "}
              &nbsp;
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

JournalArticle.defaultProps = {
  onDataChange: () => {},
  renderTypeChooser: () => {},
  formObj: {
    documentbibtexdata: {},
  },
  authors_json: {},
  issns_json: {},
  hasDocumentBibTexData: false,
  handleOnClickAccordion: (e, doc_id) => {},
  refreshDocumentsAndFile: () => {},
  AccordionStatus: null,
  selectArticless: (doc_id) => {},
  removeSelectArticles: (doc_id) => {},
  handleDeleteProjectArticle: (id) => {},
  headerMinHeight: null,
  customArticle: false,
  onUpdateCustomArticle: (bibtexdata) => {},
};

export { JournalArticle };
