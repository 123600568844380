import React, { useEffect, useState } from "react";

import { TextField, Paper, Button } from "@material-ui/core";
import classes from "./index.module.css";
import { withRouter } from "react-router";
import {
  login,
  signUp,
  authCheck,
  requestPasswordReset,
} from "../../Requests/Requests";

import { toast } from "react-toastify";
import { GoogleLoginButton } from "react-social-login-buttons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import ReCAPTCHA from "react-google-recaptcha";

const AuthPage = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    repassword: "",
    first_name: "",
    last_name: "",
    organization: "",
  });
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const handleFormChange = (e) => {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  };
  const toggleLogin = () => {
    setIsLogin((isLogin) => !isLogin);
  };
  const handleAuth = () => {
    const authData = formData;
    const requestFunction = isLogin ? login : signUp;
    let hasError = false;
    if (!isCaptchaVerified) {
      toast.error("Captcha not verified");
      hasError = true;
    }
    if (isLogin) {
      if (
        !authData.email ||
        !authData.password ||
        !ValidateEmail(authData.email) ||
        authData.password.length < 8
      ) {
        toast.error("Invalid Login credentials!");
        hasError = true;
      }
    } else {
      if (!authData.email) {
        toast.error("Email field cannot be empty.");
        hasError = true;
      } else if (!ValidateEmail(authData.email)) {
        toast.error("You have entered an invalid email address");
        hasError = true;
      }
      if (!authData.password) {
        toast.error("Please Input Password");
        hasError = true;
      } else {
        if (authData.password.length < 8) {
          toast.error("Password must be at least 8 characters long");
          hasError = true;
        }
      }
      if (authData.password != authData.repassword) {
        toast.error("Passwords do not match.");
        hasError = true;
      }
      if (!authData.first_name) {
        toast.error("Please input First Name");
        hasError = true;
      }
      if (!authData.last_name) {
        toast.error("Please input Last Name");
        hasError = true;
      }
    }
    if (hasError) {
      return;
    }
    requestFunction(authData)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          props.history.replace("/panel");
          toast.success("You logged in successfully!");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const onReCAPTCHAChange = (value) => {
    if (value) {
      setIsCaptchaVerified(true);
    } else {
      setIsCaptchaVerified(false);
    }
  };

  const taggelForgotPassword = () => {
    setIsForgotPassword((isForgotPassword) => !isForgotPassword);
  };
  const handelRequestPasswordResetEmail = () => {
    let emailvalue = document.getElementById("forgot_Password_Email").value;
    const authData = { email: emailvalue };
    let hasError = false;
    if (!authData.email) {
      toast.error("Email field cannot be empty.");
      hasError = true;
    } else if (!ValidateEmail(authData.email)) {
      toast.error("You have entered an invalid email address");
      hasError = true;
    }
    if (!hasError) {
      requestPasswordReset(authData)
        .then((res) => {
          console.log(res);
          if (res.status >= 200 && res.status <= 299) {
            toast.success(res.data.message);
            taggelForgotPassword();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            console.log(err, err.response);
            toast.error("Something went wrong!");
          }
        });
    }
  };
  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    authCheck()
      .then(() => props.history.replace("/panel"))
      .catch(() => {});
  }, []);
  return (
    <div className={classes.mostOuterDiv}>
      <div className={classes.divAroundForm}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (formData.email?.length > 0 && formData.password?.length > 0) {
              handleAuth();
            }
          }}
        >
          <Paper className={classes.paper} variant="outlined" elevation={24}>
            <div className={classes.logo}>
              <img
                className={classes.img}
                src={process.env.PUBLIC_URL + "/assets/logo-new.png"}
                alt="brand icon"
              />
            </div>
            <div className={classes.textfields}>
              <div className={classes.textfield}>
                <TextField
                  // InputProps={{ styles }}
                  onChange={handleFormChange}
                  value={formData.email}
                  name="email"
                  id="email"
                  label="Email"
                  variant="outlined"
                  style={{
                    width: "67%",
                  }}
                />
              </div>
              <div className={classes.textfield}>
                <TextField
                  onChange={handleFormChange}
                  value={formData.password}
                  name="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  type={"password"}
                  style={{
                    width: "67%",
                  }}
                />
              </div>
              {!isLogin && (
                <>
                  <div className={classes.textfield}>
                    <TextField
                      onChange={handleFormChange}
                      value={formData.repassword}
                      name="repassword"
                      id="repassword"
                      label="Confirm Password"
                      variant="outlined"
                      type={"password"}
                      style={{
                        width: "67%",
                      }}
                    />
                  </div>
                  <div className={classes.textfield}>
                    <TextField
                      onChange={handleFormChange}
                      value={formData.FirstName}
                      name="first_name"
                      id="first_name"
                      label="First Name"
                      variant="outlined"
                      type={"text"}
                      style={{
                        width: "67%",
                      }}
                    />
                  </div>
                  <div className={classes.textfield}>
                    <TextField
                      onChange={handleFormChange}
                      value={formData.LastName}
                      name="last_name"
                      id="last_name"
                      label="Last Name"
                      variant="outlined"
                      type={"text"}
                      style={{
                        width: "67%",
                      }}
                    />
                  </div>
                  <div className={classes.textfield}>
                    <TextField
                      onChange={handleFormChange}
                      value={formData.organization}
                      name="organization"
                      id="organization"
                      label="Organization"
                      variant="outlined"
                      type={"text"}
                      style={{
                        width: "67%",
                      }}
                    />
                  </div>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingButton: "10px",
                }}
              >
                <ReCAPTCHA
                  sitekey="6LdBruQoAAAAAIZnQCg90LaLsOjqlGK7Qu2wRytk"
                  onChange={onReCAPTCHAChange}
                />
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <button className={classes.loginBtn} type="submit">
                {isLogin ? "Sign in" : "Sign up"}
              </button>
            </div>
            <div className={classes.GoogleLoginButtonWrapper}>
              <GoogleLoginButton
                style={{
                  backgroundColor: "black",
                  color: "white",
                  maxWidth: "450px",
                  borderRadius: "25px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                activeStyle={{ color: "black", backgroundColor: "#36454F" }}
                align="center"
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_BASE_URL +
                    `/auth/oauth/login/?provider=google&next_url=${
                      window.location.href +
                      (window.location.href.endsWith("/") ? "panel" : "/panel")
                    }`)
                }
              >
                <span
                  style={{
                    padding: "1.3rem",
                    paddingTop: "2rem",
                    paddingButton: "2rem",
                    fontSize: "1.1rem",
                  }}
                >
                  {isLogin ? "Log in " : "Sign up "}With Google
                </span>
              </GoogleLoginButton>
            </div>
            <div
              style={{ cursor: "pointer", color: "gray" }}
              onClick={taggelForgotPassword}
              className={classes.forgetPasswordWrapper}
            >
              Forgot Password? click here to reset it!
            </div>

            {isLogin ? (
              <div className={classes.signedup}>
                New user ?{" "}
                <div onClick={toggleLogin} className={classes.signUpHere}>
                  Sign up
                </div>
              </div>
            ) : (
              <div className={classes.signedup}>
                If you already have an account,{" "}
                <div onClick={toggleLogin} className={classes.signUpHere}>
                  Login here
                </div>
              </div>
            )}
          </Paper>
        </form>
      </div>

      <Dialog
        classes={{ paper: { background: "red" } }}
        open={isForgotPassword}
        onClose={taggelForgotPassword}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Forgot your password?"}
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            width: "420px",
          }}
        >
          <TextField
            id="forgot_Password_Email"
            label="Email"
            variant="outlined"
            type={"email"}
            name={"email"}
            onChange={handleFormChange}
          />

          <br />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Button
            onClick={handelRequestPasswordResetEmail}
            style={{
              width: "90%",
              color: "white",
              borderRadius: "25px",
              fontSize: "1.1rem",
              backgroundColor: "red",
            }}
            type="submit"
          >
            Reset Password
          </Button>
          <br />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(AuthPage);
