import classes from './FileInput.module.css';
import CustomButton from "../CustomButton/CustomButton";
import PropTypes from "prop-types";
import {useRef} from "react";

const FileInput = props => {
    const {
        id,
        accept,
        buttonText,
        onFileSelect,
        secondaryText,
        value,
        inputProps,
        name,
        containerProps,
        buttonProps,
        theme,
        containerStyle
    } = props;
    const inputRef = useRef(null);

    const handleClick = () => {
        inputRef.current.click();
    }

    const handleChange = (e) => {
        onFileSelect(e.target.files[0]);
    }

    return (
        <div className={classes.wrapper} {...containerProps} style={{
            border: `1px solid ${theme.borderColor}`,
            ...containerStyle
        }}>
            <CustomButton
                id={id}
                style={{
                    color: theme.button.color,
                    backgroundColor: theme.button.backgroundColor
                }}
                {...buttonProps}
                className={classes.chooseFileBtn}
                onClick={handleClick}
            >
                {buttonText}
            </CustomButton>
            <div className={classes.secondaryText} style={{color: theme.textColor}}>
                {secondaryText(value)}
            </div>
            <input
                name={name}
                id={id}
                {...inputProps}
                ref={inputRef}
                accept={accept}
                onChange={handleChange}
                type="file"
                style={{display: 'none'}}
            />
        </div>
    )
}

export default FileInput;

FileInput.propTypes = {
    containerProps: PropTypes.any,
    onFileSelect: PropTypes.func,
    secondaryText: PropTypes.func,
    value: PropTypes.any,
    buttonText: PropTypes.string,
    accept: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    inputProps: PropTypes.any,
    buttonProps: PropTypes.any,
    theme: PropTypes.shape({
        borderColor: PropTypes.string,
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        button: PropTypes.shape({
            color: PropTypes.string,
            backgroundColor: PropTypes.string
        }),
    }),
    containerStyle: PropTypes.any
}

FileInput.defaultProps = {
    onFileSelect: () => {
    },
    value: null,
    buttonText: 'Choose File',
    accept: '.pdf',
    secondaryText: (value) => value?.name || 'No file chosen...',
    inputProps: {},
    containerProps: {},
    buttonProps: {},
    theme: {
        borderColor: 'var(--light-red)',
        textColor: 'var(--black)',
        backgroundColor: 'transparent',
        button: {
            color: 'white',
            backgroundColor: 'var(--light-red)'
        },
    },
    containerStyle: {}
}