export function getOffset(el) {
    let left = 0;
    let top = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        left += el.offsetLeft - el.scrollLeft;
        top += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return {top, left};
}

export const scrollIntoView = (el, diff = -70) => {
    const top = getOffset(el).top + diff;
    window.scrollTo({
        top: top,
        behavior: "smooth"
    })
}