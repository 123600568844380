import React, { useEffect, useState } from 'react';
import classes from './Toolbar.module.css';
import {
  Button,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import RemoveIcon from '@material-ui/icons/Remove';
import { fonts } from './Fonts';
import { headings } from './Headings';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import GridOnIcon from '@material-ui/icons/GridOn';
import ButtonWithPopover from './ButtonWithPopover';
import { createHTMLTable } from './createHTMLTable';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import FileBase64 from 'react-file-base64';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const useStyles = makeStyles((theme) => ({
  divider: {
    background: 'red',
  },
}));

const save = function () {
  const selection = window.getSelection();
  return selection.rangeCount === 0 ? null : selection.getRangeAt(0);
};

const restore = function (range) {
  try {
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  } catch (ex) { }
};

let prevSelection = null;

function getSelectionProperty(selection, prop) {
  return window
    .getComputedStyle(selection.anchorNode.parentElement, null)
    .getPropertyValue(prop);
}

function getHeadingType(selection) {
  let selectionParent = selection.anchorNode;
  const headingType = 'DIV';
  let loopSize = 0;
  while (selectionParent.parentElement) {
    // console.log(selectionParent.parentElement.tagName)
    if (
      ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'DIV'].includes(
        selectionParent.parentElement.tagName
      )
    ) {
      return selectionParent.parentElement.tagName;
    }

    if (selectionParent.parentElement.tagName === 'article') return headingType;
    selectionParent = selectionParent.parentElement;
    loopSize++;
    if (loopSize > 1000) return headingType;
  }
}

const activeColor = 'blue';
const defaultColor = 'rgba(0, 0, 0, 0.54)';

function Toolbar(props) {
  const styles = useStyles();
  const { sel, selChanged } = props;
  const onCommand = (cmd, val) => {
    console.log(cmd);
    if (cmd === 'createLink') {
      // let url = prompt('Enter the link:', val);
      document.execCommand(cmd, false, val);
    } else if (cmd === 'fontName') {
      document.execCommand(cmd, false, val);
    } else if (cmd === 'fontSize') {
      document.execCommand(cmd, false, val);
    } else if (cmd === 'foreColor') {
      console.log('aaa');
      document.execCommand(cmd, false, val);
    } else if (cmd === 'backColor') {
      console.log('aaa');
      document.execCommand(cmd, false, val);
    } else if (cmd === 'formatBlock') {
      console.log('formatBlock');
      document.execCommand(cmd, false, val);
    } else if (cmd === 'insertImage') {
      document.execCommand(cmd, false, val);
    } else {
      document.execCommand(cmd, false, null);
    }
  };

  const [fontFamily, setFontFamily] = React.useState('Arial');
  const [fontSize, setFontSize] = React.useState(2);
  const [activeHeading, setActiveHeading] = React.useState('DIV');
  const [activeAlign, setActiveAlign] = useState('left');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikeThrough, setIsStrikeThrough] = useState(false);
  const [link, setLink] = React.useState('');
  const [code, setCode] = React.useState('');

  const [tableSize, setTableSize] = useState([-1, -1]);

  const handleTableSizeChange = (i, j) => {
    setTableSize([i, j]);
  };

  useEffect(() => {
    const selection = sel;
    if (selection) {
      // make sure it doesn't error if nothing is selected
      // console.log()
      if (selection.anchorNode) {
        let fontFamily = getSelectionProperty(selection, 'font-family');
        // let fontSize = getSelectionProperty(selection, 'font-size');
        let color = getSelectionProperty(selection, 'color');
        let fontWeight = getSelectionProperty(selection, 'font-weight');
        let fontStyle = getSelectionProperty(selection, 'font-style');
        let textDecoration = getSelectionProperty(selection, 'text-decoration');
        let textAlign = getSelectionProperty(selection, 'text-align');
        setFontFamily(fontFamily.replaceAll('"', ''));
        setActiveAlign(textAlign);
        setIsBold(fontWeight === '700');
        setIsItalic(fontStyle === 'italic');
        setIsUnderline(textDecoration.includes('underline'));
        setIsStrikeThrough(textDecoration.includes('line-through'));
        setActiveHeading(getHeadingType(selection));
        // setFontSize(Number(fontSize.replace('px', '')));
      }
    }
  }, [selChanged]);

  const handleChange = (event) => {
    setFontFamily(event.target.value);
    onCommand('fontName', event.target.value);
  };

  // const handleChangeFontSize = (event) => {
  //     setFontSize(event.target.value);
  //     onCommand('fontSize', event.target.value)
  // };

  const handleHeadingChange = (event) => {
    let selectionParent = sel?.anchorNode?.parentElement;
    if (
      selectionParent &&
      selectionParent.tagName === 'SPAN' &&
      selectionParent.className === 'sug'
    ) {
      selectionParent.replaceWith(selectionParent.innerText);
    }
    setActiveHeading(event.target.value);
    onCommand('formatBlock', event.target.value);
  };

  const handleTextAlignChanged = (textAlign) => {
    setActiveAlign(textAlign);
    switch (textAlign) {
      case 'left':
        return onCommand('justifyLeft');
      case 'center':
        return onCommand('justifyCenter');
      case 'right':
        return onCommand('justifyRight');
      case 'justify':
        return onCommand('justifyFull');
    }
  };
  return (
    <div className={classes.Toolbar} style={props.style || {}}>
      {/*<Autocomplete*/}
      {/*    id="combo-box-demo"*/}
      {/*    options={fonts}*/}
      {/*    getOptionLabel={(option) => option.title}*/}
      {/*    style={{ width: 300 }}*/}
      {/*    onChange={e => onCommand('fontName', e.target.innerText)}*/}
      {/*    renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}*/}
      {/*/>*/}
      <FormControl className={classes.formControl}>
        <Select
          style={{ width: 130, marginLeft: 10 }}
          value={fontFamily}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {fonts.map((font) => (
            <MenuItem value={font.title}>{font.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {/*<FormControl className={classes.formControl}>*/}
      {/*    <Select*/}
      {/*        style={{width: 130, marginLeft: 10}}*/}
      {/*        value={fontFamily}*/}
      {/*        onChange={handleChangeFontSize}*/}
      {/*        displayEmpty*/}
      {/*        inputProps={{'aria-label': 'Without label'}}*/}
      {/*    >*/}
      {/*        {fontSizes.map(fs => <MenuItem value={fs.value}>{fs.title}</MenuItem>)}*/}
      {/*    </Select>*/}
      {/*</FormControl>*/}
      <FormControl className={classes.formControl}>
        <Select
          style={{ width: 100, marginLeft: 10, marginRight: 10 }}
          value={activeHeading}
          onChange={handleHeadingChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {headings.map((font) => (
            <MenuItem value={font.value}>{font.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.divider} />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => {
          onCommand('bold');
          setIsBold((isBold) => !isBold);
        }}
        style={{ color: isBold ? activeColor : defaultColor }}
      >
        <FormatBoldIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => {
          setIsItalic((isItalic) => !isItalic);
          onCommand('italic');
        }}
        style={{ color: isItalic ? activeColor : defaultColor }}
      >
        <FormatItalicIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => {
          setIsUnderline((isUnderline) => !isUnderline);
          onCommand('underline');
        }}
        style={{ color: isUnderline ? activeColor : defaultColor }}
      >
        <FormatUnderlinedIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => {
          setIsStrikeThrough((isStrikeThrough) => !isStrikeThrough);
          onCommand('strikeThrough');
        }}
        style={{ color: isStrikeThrough ? activeColor : defaultColor }}
      >
        <FormatStrikethroughIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('superscript')}
        style={{ color: defaultColor }}
      >
        <p style={{ fontWeight: 'bold' }}>
          x
          <sup>
            <small>2</small>
          </sup>
        </p>
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('subscript')}
        style={{ color: defaultColor }}
      >
        <p style={{ fontWeight: 'bold' }}>
          x
          <sub>
            <small>2</small>
          </sub>
        </p>
      </IconButton>
      <div className={classes.divider} />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => handleTextAlignChanged('left')}
        style={{ color: activeAlign === 'left' ? activeColor : defaultColor }}
      >
        <FormatAlignLeftIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => handleTextAlignChanged('center')}
        style={{ color: activeAlign === 'center' ? activeColor : defaultColor }}
      >
        <FormatAlignCenterIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => handleTextAlignChanged('right')}
        style={{ color: activeAlign === 'right' ? activeColor : defaultColor }}
      >
        <FormatAlignRightIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => handleTextAlignChanged('justify')}
        style={{
          color: activeAlign === 'justify' ? activeColor : defaultColor,
        }}
      >
        <FormatAlignJustifyIcon />
      </IconButton>
      <div className={classes.divider} />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('insertUnorderedList')}
        style={{ color: defaultColor }}
      >
        <FormatListBulletedIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('insertOrderedList')}
        style={{ color: defaultColor }}
      >
        <FormatListNumberedIcon />
      </IconButton>
      <div className={classes.divider} />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('indent')}
      >
        <FormatIndentIncreaseIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('outdent')}
      >
        <FormatIndentDecreaseIcon />
      </IconButton>
      <div className={classes.divider} />

      <ButtonWithPopover
        id={'formatColor'}
        icon={FormatColorTextIcon}
        onPopoverOpen={() => {
          /* TODO save */
          prevSelection = save();
        }}
        onPopoverClose={() => { }}
        renderPopoverContent={(onClose) => {
          return (
            <div style={{ margin: 10 }}>
              <div className={classes.MSKEditorColorsWrapper}>
                {[
                  '#C00000',
                  '#FF0000',
                  '#ED7D31',
                  '#FFC000',
                  '#FFFF00',
                  '#92D050',
                  '#00B050',
                  '#00B0F0',
                  '#0070C0',
                  '#002060',
                  '#7030A0',
                  '#FFFFFF',
                  '#7F7F7F',
                  '#595959',
                  '#262626',
                  '#000000',
                ].map((color) => (
                  <div
                    style={{ backgroundColor: color }}
                    className={classes.MSKEditorColor}
                    onClick={() => {
                      restore(prevSelection);
                      onCommand('foreColor', color);
                      onClose();
                    }}
                  />
                ))}
              </div>
            </div>
          );
        }}
      />
      <ButtonWithPopover
        id={'backColor'}
        icon={FormatColorFillIcon}
        onPopoverOpen={() => {
          /* TODO save */
          prevSelection = save();
        }}
        onPopoverClose={() => { }}
        renderPopoverContent={(onClose) => {
          return (
            <div style={{ margin: 10 }}>
              <div className={classes.MSKEditorColorsWrapper}>
                {[
                  '#C00000',
                  '#FF0000',
                  '#ED7D31',
                  '#FFC000',
                  '#FFFF00',
                  '#92D050',
                  '#00B050',
                  '#00B0F0',
                  '#0070C0',
                  '#002060',
                  '#7030A0',
                  '#FFFFFF',
                  '#7F7F7F',
                  '#595959',
                  '#262626',
                  '#000000',
                ].map((color) => (
                  <div
                    style={{ backgroundColor: color }}
                    className={classes.MSKEditorColor}
                    onClick={() => {
                      restore(prevSelection);
                      onCommand('backColor', color);
                      onClose();
                    }}
                  />
                ))}
              </div>
            </div>
          );
        }}
      />
      <div className={classes.divider} />
      <ButtonWithPopover
        id={'insertImage'}
        icon={InsertPhotoIcon}
        onPopoverOpen={() => (prevSelection = save())}
        onPopoverClose={() => setLink('')}
        renderPopoverContent={(onClose) => {
          return (
            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ margin: 10 }}>
                <b>Paste an online link</b>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  style={{ margin: 10 }}
                  id="outlined-basic"
                  label="Image link"
                  variant="outlined"
                  placeholder={'https://'}
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
                <Button
                  style={{ margin: '0 10px' }}
                  onClick={() => {
                    restore(prevSelection);
                    onCommand('insertImage', link);
                    onClose();
                  }}
                >
                  Done
                </Button>
              </div>
              <div style={{ marginLeft: 10 }}>
                <b>Or select a local image</b>
              </div>
              <div style={{ margin: 10 }}>
              
                <FileBase64
                  multiple={false}
                  onDone={({ base64 }) => {
                    let selectionindex = prevSelection;
                    restore(prevSelection);
                    document.execCommand('insertHTML', false, '<br>');
                    // restore(selectionindex);
                    onCommand('insertImage', base64);
                    document.execCommand('insertHTML', false, '<div>&nbsp;</div>');
                    document.execCommand('insertHTML', false, 'Figure-{number}');
                    document.execCommand('insertHTML', false, '<div>&nbsp;</div>');
                    
                    onClose();
                  }}
                />
              </div>
            </div>
          );
        }}
      />

      <ButtonWithPopover
        id={'table'}
        icon={GridOnIcon}
        onPopoverOpen={() => {
          /* TODO save */
          prevSelection = save();
        }}
        onPopoverClose={() => {
          setTableSize([-1, -1]);
        }}
        renderPopoverContent={(onClose) => {
          return (
            <div style={{ margin: 10 }}>
              <div className={classes.MSKEditorTableTitle}>
                {tableSize[1] === -1 || tableSize[0] === -1 ? (
                  'Insert Table'
                ) : (
                  <>
                    {tableSize[1] + 1} &times; {tableSize[0] + 1} Table
                  </>
                )}
              </div>
              <div className={classes.MSKEditorTable}>
                {Array(10)
                  .fill(0)
                  .map((a, i) => {
                    return (
                      <div className={classes.MSKEditorTR}>
                        {Array(10)
                          .fill(0)
                          .map((b, j) => {
                            return (
                              <div
                                className={[
                                  classes.MSKEditorTD,
                                  ...(i <= tableSize[0] && j <= tableSize[1]
                                    ? [classes.active]
                                    : []),
                                ].join(' ')}
                                onMouseOver={() => handleTableSizeChange(i, j)}
                                onClick={() => {
                                  let table = createHTMLTable(
                                    tableSize[1] + 1,
                                    tableSize[0] + 1
                                  );
                                  let selectionindex = prevSelection;
                    
                                  restore(prevSelection);
                                  
                                  document.execCommand('insertHTML', false, '<br>');
                                  document.execCommand(
                                    'insertHTML',
                                    false,
                                    table
                                  );
                                  
                                  // restore(selectionindex);
                                  onClose();
                                }}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
              {/*<input type={'text'} placeholder={'rows'} className={classes.MSKEditorTable__SizeInput}/>*/}
              {/*&times;*/}
              {/*<input type={'text'} placeholder={'cols'} className={classes.MSKEditorTable__SizeInput}/>*/}
              {/*<div className={classes.MSKEditorTable}>*/}
              {/*    <div className={classes.MSKEditorTR}>*/}
              {/*        <div className={classes.MSKEditorTD}/>*/}
              {/*        <div className={classes.MSKEditorTD}/>*/}
              {/*    </div>*/}
              {/*    <div className={classes.MSKEditorTR}>*/}
              {/*        <div className={classes.MSKEditorTD}/>*/}
              {/*        <div className={classes.MSKEditorTD}/>*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
          );
        }}
      />
      <ButtonWithPopover
        id={'createLink'}
        icon={LinkIcon}
        onPopoverOpen={() => (prevSelection = save())}
        onPopoverClose={() => setLink('')}
        renderPopoverContent={(onClose) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                style={{ margin: 10 }}
                id="outlined-basic"
                label="Link"
                variant="outlined"
                placeholder={'https://'}
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  restore(prevSelection);
                  onCommand('createLink', link);
                  onClose();
                }}
              >
                Done
              </Button>
            </div>
          );
        }}
      />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('unlink')}
      >
        <LinkOffIcon />
      </IconButton>
      {/*<ButtonWithPopover*/}
      {/*    id={'insertCode'}*/}
      {/*    icon={CodeIcon}*/}
      {/*    onPopoverOpen={() => prevSelection = save()}*/}
      {/*    onPopoverClose={() => setCode('')}*/}
      {/*    renderPopoverContent={(onClose) => {*/}
      {/*        return (*/}
      {/*            <div style={{margin: 10}}>*/}
      {/*                <div className={classes.MSKEditorTableTitle} style={{marginBottom: 5}}>Enter the code:</div>*/}
      {/*                <TextField*/}
      {/*                    inputProps={{*/}
      {/*                        style: {*/}
      {/*                            width: 300,*/}
      {/*                            minHeight: 300*/}
      {/*                        }*/}
      {/*                    }}*/}
      {/*                    value={code}*/}
      {/*                    onChange={e => {*/}
      {/*                        setCode(e.target.value);*/}
      {/*                    }}*/}
      {/*                    variant={"outlined"}*/}
      {/*                    multiline={true}*/}
      {/*                />*/}
      {/*                <br />*/}
      {/*                <div style={{textAlign: 'right', paddingTop: 10}}>*/}
      {/*                    <Button*/}
      {/*                        style={{backgroundColor: 'green', color: 'white'}}*/}
      {/*                        onClick={() => {*/}
      {/*                            restore(prevSelection);*/}
      {/*                            document.execCommand('insertHTML', false, `<div class="code">${code}</div>`);*/}
      {/*                            onClose();*/}
      {/*                        }}*/}
      {/*                    >*/}
      {/*                        Add*/}
      {/*                    </Button>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        )*/}
      {/*    }}*/}
      {/*/>*/}

      {/*<IconButton className={classes.ToolbarButton} onClick={() => {*/}
      {/*    const html = '<code></code>'*/}
      {/*    // onCommand('insertHTML',)*/}
      {/*}}>*/}
      {/*    <LinkOffIcon/>*/}
      {/*</IconButton>*/}

      <div className={classes.divider} />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => {
          document.execCommand('insertHTML', false, '<hr />');
        }}
      >
        <RemoveIcon />
      </IconButton>
      <div className={classes.divider} />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('delete')}
      >
        <DeleteForeverIcon />
      </IconButton>
      <div className={classes.divider} />
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('undo')}
      >
        <UndoIcon />
      </IconButton>
      <IconButton
        className={classes.ToolbarButton}
        onClick={() => onCommand('redo')}
      >
        <RedoIcon />
      </IconButton>
      <CopyToClipboard text={props.htmlToCopy}
        onCopy={() => { }}>
        <Button style={{ textTransform: 'none' }} onClick={props.onCopyHTML}>
          Copy HTML
        </Button>
      </CopyToClipboard>
      {/*<IconButton className={classes.ToolbarButton} onClick={() => {*/}
      {/*    console.log('Test');*/}
      {/*    var range = window.getSelection().getRangeAt(0);*/}
      {/*    console.log(range)*/}
      {/*    var selectionContents = range.extractContents();*/}
      {/*    var span = document.createElement("span");*/}
      {/*    span.className = 'sug'*/}
      {/*    span.style.color = "black";*/}
      {/*    span.appendChild(selectionContents);*/}
      {/*    range.insertNode(span);*/}
      {/*    // var span = document.createElement("span");*/}
      {/*    // span.style.fontWeight = "bold";*/}
      {/*    // span.style.color = "red";*/}
      {/*    // if (window.getSelection) {*/}
      {/*    //     var sel = window.getSelection();*/}
      {/*    //     if (sel.rangeCount) {*/}
      {/*    //         var range = sel.getRangeAt(0).cloneRange();*/}
      {/*    //         range.surroundContents(span);*/}
      {/*    //         sel.removeAllRanges();*/}
      {/*    //         sel.addRange(range);*/}
      {/*    //     }*/}
      {/*    // }*/}
      {/*}}>*/}
      {/*    T*/}
      {/*</IconButton>*/}
    </div>
  );
}

export default Toolbar;
