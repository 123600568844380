import { axiosInstance, logout } from "./Requests";

const baseURL = process.env.REACT_APP_BASE_URL;

const setupInterceptors = () => {
    axiosInstance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        let status = error.response.status;
        let resBaseURL = error.response.config.baseURL;
        if (resBaseURL === baseURL && status === 403) {
            document.location.href = '/'
            // logout()
            //     .then(res => {
            //         document.location.href = '/'
            //     })
            //     .catch(err => {})

        }
        return Promise.reject(error);
    });
}

export { setupInterceptors };