import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import RefBerry from './RefBerry';

function RefBerryPage(props) {

    return (
        <RefBerry />
    );
}
export default withRouter(RefBerryPage);
RefBerryPage.propTypes = {};