let database;
let req;

function createOrGetIndexedDB(callback) {
  if (!window.indexedDB) {
    alert(
      "Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available."
    );
  } else {
    var request = window.indexedDB.open("dbref", 3);
    req = request;
    request.onupgradeneeded = (e) => {
      const db = e.target.result;
      db.createObjectStore("form_data", { keyPath: "key" });
      callback(db);
    };
    request.onerror = function (event) {
      console.error(event);
    };
    request.onsuccess = function (e) {
      const db = e.target.result;

      callback(db);
    };
  }
}

createOrGetIndexedDB((db) => {
  database = db;
});

const saveDataInBrowser = (data) => {
  if (!database) return;
  const tx = database.transaction("form_data", "readwrite");
  const formData = tx.objectStore("form_data");
  // tx.onerror = e => console.error('--', JSON.stringify(e)) // transaction error
  Object.entries(data).forEach(([key, value]) => {
    formData.put({ key, value });
  });
};

const restoreDataFromCookie = (callback) => {
  createOrGetIndexedDB((db) => {
    const form = {};
    const tx = db.transaction("form_data", "readonly");
    const formData = tx.objectStore("form_data");
    const request = formData.openCursor();
    request.onsuccess = (e) => {
      const cursor = e.target.result;
      if (!cursor) {
        return callback(form);
      }
      const { key, value } = cursor.value;
      form[key] = value;
      cursor.continue();
    };
  });
};

export { saveDataInBrowser, restoreDataFromCookie };
