const authBaseURL = `/auth`;

export const authURLs = {
  changePassword: `${authBaseURL}/change_password`,
  login: `${authBaseURL}/login`,
  logout: `${authBaseURL}/logout`,
  signUp: `${authBaseURL}/sign_up`,
  userInfo: `${authBaseURL}/user_info`,
  requestPasswordReset: `${authBaseURL}/request_password_reset/`,
  resetPassword: `${authBaseURL}/reset_password/`,
  get_api_keys: `${authBaseURL}/get_api_keys/`,
  gen_api_keys: `${authBaseURL}/gen_api_keys/`,
};

const editorBaseURL = `/editor`;
export const editorURLs = {
  specialProjectFiles: (projectId) =>
    `${editorBaseURL}/project/${projectId}/files/`,
  specialProjectFile: (projectId, fileId) =>
    `${editorBaseURL}/project/${projectId}/files/${fileId}`,
  projects: `${editorBaseURL}/projects/`,
  specialProject: (projectId) => `${editorBaseURL}/projects/${projectId}/`,
  exportProject: (projectId) => `${editorBaseURL}/project/${projectId}/export/`,
  duplicateProject: `${editorBaseURL}/project/duplicate/`,
  share: (projectId) => `${editorBaseURL}/project/${projectId}/share/`,
  shareList: (projectId) => `${editorBaseURL}/project/${projectId}/share/list/`,
  shareDelete: (projectId) =>
    `${editorBaseURL}/project/${projectId}/share/delete/`,
  shareConfirm: (projectId, token) =>
    `${editorBaseURL}/project/${projectId}/share/${token}/`,
  label: `${editorBaseURL}/project/labels/`,
  labeldelete: (id) => `${editorBaseURL}/project/labels/${id}/`,
  sendFeedback: `${editorBaseURL}/feedback/`,
};

const aiBaseURL = `/ai`;
export const aiURLS = {
  documents: (projectId) => `${aiBaseURL}/${projectId}/documents/`,
  specialDocument: (projectId, documentId) =>
    `${aiBaseURL}/${projectId}/documents/${documentId}`,
  deleteDocumentFile: (projectId, documentId) =>
    `${aiBaseURL}/${projectId}/documents/${documentId}/delete_file`,
  document_citations: (projectId) =>
    `${aiBaseURL}/document_citations/${projectId}/`,
  mlModels: (projectId) => `${aiBaseURL}/${projectId}/ml_models`,
  specificMlModel: (projectId, modelId) =>
    `${aiBaseURL}/${projectId}/ml_model/${modelId}`,
  paraphrase: (projectId) => `${aiBaseURL}/${projectId}/paraphrase/`,
  auto_complete: (projectId) => `${aiBaseURL}/${projectId}/auto_complete/`,
  documentTags: (projectId) => `${aiBaseURL}/${projectId}/documents/tags/`,
  delete_DocumentTags: (projectId, tagid) =>
    `${aiBaseURL}/${projectId}/documents/tags/${tagid}`,
  xref: (projectId) => `${aiBaseURL}/${projectId}/get_xref/`,
  GnteFormula: (projectId) => `${aiBaseURL}/${projectId}/generate_formula/`,
};

const citationBaseURL = `/citation`;

export const citationURLs = {
  xref: (projectId) => `${citationBaseURL}/${projectId}/get_xref/`,
  saveXref: (projectId) => `${citationBaseURL}/${projectId}/save_to_kb/`,
};

const subscriptionsBaseURL = `/subscription`;

export const subscriptionURLs = {
  liboPlans: `${subscriptionsBaseURL}/liboberry_plans`,
  pubPackages: `${subscriptionsBaseURL}/credit_packages/pubberry`,
  refPackages: `${subscriptionsBaseURL}/credit_packages/refberry`,
  paypalClientToken: `${subscriptionsBaseURL}/paypal_client_token`,
  subscription: `${subscriptionsBaseURL}/subscribe`,
  buyPub: `${subscriptionsBaseURL}/buy_pubberry`,
  buyRef: `${subscriptionsBaseURL}/buy_refberry`,
};
