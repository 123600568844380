import React from "react";
import PropTypes from "prop-types";
import csv from "./Images/csv.svg";
import excel from "./Images/excel.svg";
import pdf from "./Images/pdf.svg";
import pdf2 from "./Images/pdf2.svg";
import word from "./Images/word.svg";
import word2 from "./Images/word2.svg";
import text from "./Images/text.svg";
import ppt from "./Images/ppt.svg";
import url from "./Images/url.svg";
import html from "./Images/html.svg";
import latex from "./Images/Latex.svg";
import unknown from "./Images/unknown.svg";
import envelope from "./Images/envelope.svg";
import copy from "./Images/copy.svg";

export default function ExtentionIcon(props) {
  let src = null;
  if (
    props.type == null &&
    (props.FileName == null || props.FileName == "") &&
    props.url == null
  ) {
    src = unknown;
  } else if (props.type != null) {
    switch (props.type) {
      case "copy":
        src = copy;
        break;
      case "html":
        src = html;
        break;
      case "pdf":
        src = pdf2;
        break;
      case "docx":
        src = word2;
        break;
      case "latex":
        src = latex;
        break;
      default:
        break;
    }
  }
  if (src == null) {
    let fileextention =
      props.FileName == null
        ? null
        : props.FileName?.split(".").pop().toLowerCase();
    let urlExt =
      props.url == null ? null : props.url?.split(".").pop().toLowerCase();
    let extention = fileextention == null ? urlExt : fileextention;
    switch (extention) {
      case "envelope":
        src = envelope;
        break;
      case "html":
        src = html;
        break;
      case "tex":
        src = latex;
        break;
      case "csv":
        src = csv;
        break;
      case "xlsx":
        src = excel;
        break;
      case "xlsxs":
        src = excel;
        break;
      case "pdf":
        src = pdf;
        break;
      case "doc":
        src = word;
        break;
      case "docx":
        src = word;
        break;
      case "txt":
        src = text;
        break;
      case "ppt":
        src = ppt;
        break;
      case "pptx":
        src = ppt;
        break;
      default:
        if (fileextention == null) {
          src = url;
          break;
        }
        src = unknown;
    }
  }
  return src != null ? (
    <img
      alt={props.FileName}
      loading="lazy"
      src={src}
      style={{ width: props.width, ...props.style }}
    />
  ) : (
    <></>
  );
}
ExtentionIcon.propTypes = {
  FileName: PropTypes.string,
  width: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};
ExtentionIcon.defaultProps = {
  FileName: "",
  url: null,
  width: "20px",
  style: { paddingTop: "7px" },
  type: null,
};
