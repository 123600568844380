import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PropTypes from "prop-types";
export default function FloatDeleteButton(props) {
  return (
    <>
      <IconButton
        onClick={props.onClick}
        style={{
          backgroundColor: "white",
          margin: 0,
          padding: 0,
          color: "#EB3449",
          position: "absolute",
          top: -15,
          right: -21,
          ...props.style,
        }}
      >
        <HighlightOffIcon />
      </IconButton>
    </>
  );
}

FloatDeleteButton.propTypes = {
  onClick: PropTypes.func,
};
FloatDeleteButton.defaultProps = {
  onClick: () => {},
  style: [],
};
