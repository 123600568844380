import classes from "./ServiceCard.module.css";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";

function ServiceCard(props) {
  const {
    liboberry_subscription,
    remaining_pubberry_credits,
    remaining_refberry_credits,
  } = props.data || {};
  const { plan } = liboberry_subscription || {};
  let { storage_limit } = plan || 0;
  if (!storage_limit) {
    storage_limit = 0;
  }

  let LiboberryStatus;
  let PubBerryStatus;
  let RefBerryStatus;
  if (storage_limit > 0) {
    LiboberryStatus = (
      <CheckIcon
        className={classes.muiIcon1}
        style={{
          fontSize: 17,
          marginRight: "2px",
          marginLeft: "5px",
        }}
      />
    );
  } else {
    LiboberryStatus = (
      <CancelIcon
        // className={classes.muiIcon0}
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          color: "red",
          marginLeft: " 4%",
          fontSize: 20,
          marginRight: "2px",
          marginLeft: "5px",
        }}
      />
    );
  }
  if (remaining_pubberry_credits > 0) {
    PubBerryStatus = (
      <CheckIcon
        className={classes.muiIcon1}
        style={{
          fontSize: 17,
          marginRight: "2px",
          marginLeft: "5px",
        }}
      />
    );
  } else {
    PubBerryStatus = (
      <CancelIcon
        className={classes.muiIcon0}
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          color: "red",
          marginLeft: " 4%",
          fontSize: 20,
          marginRight: "2px",
          marginLeft: "5px",
        }}
      />
    );
  }
  if (remaining_refberry_credits > 0) {
    RefBerryStatus = (
      <CheckIcon
        className={classes.muiIcon1}
        style={{
          fontSize: 17,
          marginRight: "2px",
          marginLeft: "5px",
        }}
      />
    );
  } else {
    RefBerryStatus = (
      <CancelIcon
        className={classes.muiIcon0}
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          color: "red",
          marginLeft: " 4%",
          fontSize: 20,
          marginRight: "2px",
          marginLeft: "5px",
        }}
      />
    );
  }
  //----------------Button----------
  let LiboberryButton;
  let PubBerryButton;
  let RefBerryButton;

  if (storage_limit > 0) {
    LiboberryButton = (
      <button className={classes.Button1}>
        Storage Limit {storage_limit}
        <span>MB</span>
      </button>
    );
  } else {
    LiboberryButton = (
      <button className={classes.Button0}>
        Storage Limit {storage_limit} <span>MB</span>
      </button>
    );
  }

  if (remaining_pubberry_credits > 0) {
    PubBerryButton = (
      <button className={classes.Button1}>
        {" "}
        Remain Credit {remaining_pubberry_credits}
        <span>XML</span>
      </button>
    );
  } else {
    PubBerryButton = (
      <button className={classes.Button0}>
        {" "}
        Remain Credit {remaining_pubberry_credits}
        <span>XML</span>
      </button>
    );
  }
  if (remaining_refberry_credits > 0) {
    RefBerryButton = (
      <button className={classes.Button1}>
        Remain Credit {remaining_refberry_credits}
        <span>Ref Check</span>
      </button>
    );
  } else {
    RefBerryButton = (
      <button className={classes.Button0}>
        Remain Credit {remaining_refberry_credits}
        <span>Ref Check</span>
      </button>
    );
  }
  return (
    <div className={classes.ServiceCard}>
      <h3 className={classes.Header}>Services Status </h3>
      <div className={classes.TextArea}>
        <div className={classes.Icon}>
          {LiboberryStatus}
          <h4 className={classes.Text}>LiboBerry</h4>
          {LiboberryButton}
        </div>
        <div className={classes.Icon}>
          {PubBerryStatus}

          <h4 className={classes.Text}>PubBerry</h4>
          {PubBerryButton}
        </div>
        <div className={classes.Icon}>
          {RefBerryStatus}
          <h4 className={classes.Text}>RefBerry</h4>
          {RefBerryButton}
        </div>
      </div>
    </div>
  );
}
export default ServiceCard;
