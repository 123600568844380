export const createHTMLTable = (x, y) => {

    let table = `<div tablecaption="true" style="text-align:center"><tableblock><table textwidth="1" title="" style="max-width:100%">`;
    // let colsize = (100 / x).toFixed(2);
    table += Array(y).fill(0).map((a, i) => {
  
        return `<tr>${Array(x).fill(0).map((b, j) => `<td>&nbsp;</td>`).join('\n')}</tr>`
    }).join('\n');
    table += `</table></tableblock><tablenumber>Table-{number} </tablenumber><tablecaption> Table Caption</tablecaption></div><div></br></div>`;
    return table;

}