import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../../Loader/skeleton.css";
import "../../../LiboBerryCss/liboBerry.css";
import { getAutoComplete } from "../../../../Requests/Requests";

function AiTyping(props) {
  const [Result, setResult] = useState(props.result);
  const txtelement = useRef(null);
  var intervalId = useState(null);
  const typingTxt = (i, txt, speed, wordcount = 1) => {
    if (txt && i < txt?.split(" ")?.length) {
      intervalId = setTimeout(() => {
        try {
          let doc = txtelement.current;
          if (doc != null && doc.innerText != txt) {
            if (speed == 0) {
              doc.innerHTML = txt;
              clearTimeout(intervalId);
              intervalId = null;
            } else {
              let list = txt.split(" ");
              let newtxt = "";
              let countword = wordcount + i;
              countword = countword > list.length ? list.length : countword;
              for (; i < countword; i++) {
                if (i != 0) {
                  newtxt += list[i] == undefined ? "" : " " + list[i];
                } else {
                  newtxt += list[i] == undefined ? "" : list[i];
                }
              }
              doc.innerHTML += newtxt;
              if (i >= txt.split(" ").length) {
                typingTxt(0, txt, 0, wordcount);
              } else {
                typingTxt(i, txt, speed, wordcount);
              }
            }
          } else {
            clearTimeout(intervalId);
            intervalId = null;
          }
        } catch {}
      }, speed);
    }
  };
  const callAutoComplete = (regenerate = false) => {
    let prameters = {
      paragraph: props.content,
      task: props.type,
    };
    if (props.Tone != null && props.type == "change_tone") {
      prameters = {
        paragraph: props.content,
        task: props.type,
        tone: props.Tone,
      };
    }
    if (regenerate) {
      prameters.regenerate = regenerate;
      prameters.regentext = Result;
    }

    getAutoComplete(props.ProjectId, prameters)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.completed_paragraph != null) {
            props.onStatus("success");
            let txt = response.data.completed_paragraph;
            setResult(txt);
            props.onResult(txt);
            typingTxt(0, txt, 100, 5);
          } else {
            let txt = response.data.error?.trim();
            props.onStatus("error");
            txt =
              txt == null || txt == undefined || txt.trim() == ""
                ? "Ops! Failed to " + props.Caption + "!"
                : txt;
            setResult(txt);
            props.onResult(txt);
          }
        }
      })
      .catch((error) => {
        console.error("Failed to " + props.Caption + " :", error.response);
        let txt = "Ops! Failed to " + props.Caption + "!";
        props.onResult(txt);
        props.onStatus("error");
        setResult(txt);
      });
  };
  useEffect(() => {
    if (props.status == null) {
      props.onStatus("loading");
      callAutoComplete();
    } else if (props.status == "regenerate") {
      props.onStatus("loading");
      callAutoComplete(true);
    } else if (props.status == "retry") {
      props.onStatus("loading");
      callAutoComplete();
    }
  }, [props.status, props.content]);
  useEffect(() => {
    if (props.status == "success" && Result != null && Result != "") {
      typingTxt(0, Result, 0, 5);
    } else if (props.status == "success" && Result == null && Result == "") {
      props.onStatus("loading");
      callAutoComplete();
    }
    return () => {
      clearTimeout(intervalId);
    };
  }, []);
  return (
    <>
      {props.status != null && props.status == "success" && (
        <div ref={txtelement}></div>
      )}
      {props.status != null && props.status == "error" && (
        <div
          style={{
            backgroundColor: "tomato",
            color: "white",
            textAlign: "center",
            padding: "6px",
            borderRadius: "5px",
          }}
        >
          {Result == null || Result == ""
            ? "Ops! Failed to " + props.Caption + "!"
            : Result}
        </div>
      )}
      {(props.status == null || props.status == "loading") && (
        <>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
        </>
      )}
    </>
  );
}
export default AiTyping;
AiTyping.propTypes = {
  status: PropTypes.string,
  content: PropTypes.string,
  onStatus: PropTypes.func,
  onResult: PropTypes.func,
  ProjectId: PropTypes.number,
  Caption: PropTypes.string,
  type: PropTypes.string,
  result: PropTypes.string,
  Tone: PropTypes.string,
};
AiTyping.defaultProps = {
  status: null,
  content: "",
  onStatus: () => {},
  onResult: () => {},
  ProjectId: 0,
  Caption: "Suggested",
  type: "complete",
  result: null,
  Tone: null,
};
