import React, { useState } from "react";
import PropTypes from "prop-types";
import classess from "./BottomMenu.module.css";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import FeedBack from "../../../components/feedback/FeedBack.js";
import PopUpIconButton from "../../../components/PopUpIconButton/PopUpIconButton.js";
import HdIcon from "@material-ui/icons/Hd";
function BottomMenu(props) {
  const [showPopupBibliography, setShowPopupBibliography] = useState(false);
  return (
    <>
      <PopUpIconButton
        showPopup={showPopupBibliography}
        onSetShowPopup={setShowPopupBibliography}
        showbtnPopup={false}
        value={props.BibliographyValue}
        toolTip="Bibliography"
        title="References"
        // imgUrl={refImgUrl}
        customValue={
          <div style={{ textAlign: "center", width: "100%" }}>
            No reference found!
          </div>
        }
        pBottom="45px"
        pRight="0px"
        bBottom="0px"
        bRight="40px"
        bWidth="25px"
      />
      <div
        className={
          classess.PanelBox +
          " " +
          (props.isShowSettingPannel
            ? classess.PanelBoxShowSettingPanel
            : classess.PanelBoxCloseSettingPanel)
        }
      >
        <div
          title="Citations"
          onClick={props.CitationsClick}
          className={classess.box + " " + classess.bold}
        >
          @
        </div>
        <div
          className={classess.box}
          title="Bibliography"
          onClick={() => {
            props.BibliographyClick();
            setShowPopupBibliography(true);
          }}
        >
          <MenuBookIcon />
        </div>

        {/* <div
          className={classess.box}
          title="Headers"
          onClick={() => {
            props.onHeadersClick();
          }}
        >
          <HdIcon />
        </div> */}
        <div title={"Words count"} className={classess.box_noSelect}>
          <span style={{ fontWeight: "normal" }}>Words:&nbsp; </span>{" "}
          {props.getEditorWordCount()}
        </div>
        <div
          onClick={() => {
            props.setShowPopupHelp(!props.showPopupHelp);
          }}
          className={classess.box_last}
        >
          <FeedBack
            setShowPopup={props.setShowPopupHelp}
            showPopup={props.showPopupHelp}
            bWidth="25px"
            bHeight="25px"
            position={"relative"}
            bgcolor={"transparent"}
            color="#7b6bc9"
            projectId={props.projectInfo?.id}
          />
        </div>
      </div>
    </>
  );
}

BottomMenu.propTypes = {
  BibliographyClick: PropTypes.func,
  CitationsClick: PropTypes.func,
  getEditorWordCount: PropTypes.func,
  projectInfo: PropTypes.object,
  setShowPopupHelp: PropTypes.func,
  showPopupHelp: PropTypes.bool,
  BibliographyValue: PropTypes.array,
};

BottomMenu.defaultProps = {
  BibliographyClick: () => {},
  CitationsClick: () => {},
  getEditorWordCount: () => {},
  projectInfo: null,
  setShowPopupHelp: () => {},
  showPopupHelp: false,
  BibliographyValue: [],
  isShowSettingPannel: false,
  onHeadersClick: () => {},
};

export default BottomMenu;
