import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@material-ui/core";
import "../../../components/LiboBerryCss/liboBerry.css";
import styles from "./styles.module.css";
import "./NewProjectDialog.css";
import label from "./CreateNewLabelDialog.module.css";
import { toast } from "react-toastify";
import { ChromePicker } from "react-color";

const colors = [
  "#ff0000",
  "#0000ff",
  "#008000",
  "#FFFF00",
  "#FFA500",
  "#800080",
  "#FFC0CB",
  "#00FFFF",
  "#FF00FF",
];

const AddTagDialog = ({
  open,
  onClose,
  onCreateTag,
  onrefreshtag,
  title,
  apiCallSetLabel,
}) => {
  const [tagName, setTagName] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [customColor, setCustomColor] = useState("#ffffff"); // Initial color for custom color picker
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowColorPicker(false);
      setSelectedColor("");
    }
  }, [open]);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setShowColorPicker(false); // Hide color picker after selecting a color
  };

  const handleCustomColorChange = (color) => {
    setSelectedColor(color.hex); // Set the selected color to the custom color
    setCustomColor(color.hex); // Update the custom color
  };

  const callApiSetLabel = (name, color) => {
    let data = {
      name: name,
      color: color,
    };
    apiCallSetLabel(data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          onCreateTag(res.data.id, tagName, selectedColor || customColor); // Use selectedColor if available, otherwise use customColor
          setTagName("");
          setSelectedColor("");
          setCustomColor("#ffffff"); // Reset custom color to white
          onClose();
          toast("create successfully");
          onrefreshtag();
        } else {
          toast.error("Failed");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleCreateTag = () => {
    if (tagName.trim() !== "" && selectedColor.trim() !== "") {
      callApiSetLabel(tagName, selectedColor);
    }
  };

  // Condition for disabling the button
  const isDisabled =
    tagName.trim() === "" ||
    selectedColor.trim() === "" ||
    customColor.trim() === "";

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="add-tag-dialog">
      <DialogTitle id="add-tag-dialog" className={"dialog-title-panel "}>
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="tag-name"
          label="Name"
          variant="outlined"
          value={tagName}
          onChange={(e) => setTagName(e.target.value)}
          className={label.textfieldContainer}
        />

        <div className={label.tagColorLabel}> color:</div>
        <div className={label.flexContainer}>
          {colors.map((color) => (
            <div
              key={color}
              className={`${label.colorOption} ${
                color === selectedColor ? label.selected : ""
              }`}
              style={{ backgroundColor: color }}
              onClick={() => handleColorSelect(color)}
            ></div>
          ))}
          {/* White square with black border */}
          <div
            className={`${label.colorSquare} ${
              selectedColor ? "" : label.custom
            } ${showColorPicker ? label.selected : ""}`}
            style={{ backgroundColor: selectedColor || "white" }}
            onClick={() => setShowColorPicker(!showColorPicker)}
          >
            <span className={label.plusSign}>+</span>
          </div>
          {/* Custom color picker */}
          {showColorPicker && (
            <div className={label.customColorPickerContainer}>
              <ChromePicker
                color={customColor}
                onChange={handleCustomColorChange}
              />
            </div>
          )}
          {/* <div
            style={{
              position: "absolute",
              top: "70%",
              left: "89%",
              transform: "translate(-50%, -50%)",
              fontSize: "larger",
            }}
          >
            +
          </div> */}
        </div>
        <div className={label.flexContainerAction}>
          {/* Close button */}
          <Button
            onClick={onClose}
            color="primary"
            className={`${styles.closeButton} liboBtnCancel`}
          >
            Close
          </Button>
          <Button
            onClick={handleCreateTag}
            color="primary"
            className={isDisabled ? "liboBtnDisable" : "liboBtnApprove"}
            disabled={isDisabled}
          >
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddTagDialog;
