import { getUserInfo, getProjects } from "../../../Requests/Requests";
import { useEffect, useState } from "react";

function useData() {
    const [data, setData] = useState(null);
    const [projects, setProjects] = useState(null);

    useEffect(() => {
        getProjects()
            .then(async (response) => {
                setProjects(response.data);
            })
            .catch((er) => {
                console.log(JSON.stringify(er));
            });
    }, []);
    useEffect(() => {
        getUserInfo()
            .then(async (response) => {
                const {
                    liboberry_subscription,
                    first_name,
                    last_name,
                    is_active,
                    email,
                    profile_image,
                    remaining_pubberry_credits = 0,
                    remaining_refberry_credits = 0,
                } = response.data;
                setData({
                    liboberry_subscription,
                    first_name,
                    last_name,
                    is_active,
                    email,
                    profile_image,
                    remaining_pubberry_credits,
                    remaining_refberry_credits,
                });
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
            });
    }, []);

    return { data, projects };
}
export default useData;
