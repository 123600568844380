import classes from './MSKEditor.module.css';
import classesSpin from '../../../../components/Loader/Loader.module.css';
import ContentEditable from 'react-contenteditable';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Toolbar from './Components/Toolbar';
import './MSKEditor.css';
import { CloseOutlined, CloseRounded, Remove } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
}

function setCurrentCursorPosition(chars) {
  if (chars >= 0) {
    var selection = window.getSelection();
    console.log(document.getElementById('editorContent'));
    var range = createRange(
      document.getElementById('editorContent').parentNode,
      { count: chars }
    );
    console.log(range);

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

function saveCaretPosition(context) {
  var selection = window.getSelection();
  var range = selection.getRangeAt(0);
  range.setStart(context, 0);
  var len = range.toString().length;

  return function restore() {
    var pos = getTextNodeAtPosition(context, len);
    selection.removeAllRanges();
    var range = new Range();
    range.setStart(pos.node, pos.position);
    selection.addRange(range);
  };
}
function getTextNodeAtPosition(root, index) {
  const NODE_TYPE = NodeFilter.SHOW_TEXT;
  var treeWalker = document.createTreeWalker(
    root,
    NODE_TYPE,
    function next(elem) {
      if (index > elem.textContent.length) {
        index -= elem.textContent.length;
        return NodeFilter.FILTER_REJECT;
      }
      return NodeFilter.FILTER_ACCEPT;
    }
  );
  var c = treeWalker.nextNode();
  return {
    node: c ? c : root,
    position: index,
  };
}

function getCaretPosition() {
  var element = document.getElementById('editorContent');
  var caretOffset = 0;
  if (typeof window.getSelection != 'undefined') {
    var range = window.getSelection().getRangeAt(0);
    var preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
  } else if (
    typeof document.selection != 'undefined' &&
    document.selection.type != 'Control'
  ) {
    var textRange = document.selection.createRange();
    var preCaretTextRange = document.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

function MSKEditor(props) {
  const editorRef = useRef(null);
  const { defaultValue, onChange, sugs,ToolbarDisplay,displayBtnRefrence,RefrenceHandeler,spinValidateRefrence,name } = props;
  const [sel, setSel] = useState(window.getSelection());
  const [selChanged, setSelChanged] = useState(0);
  const [html, setHTML] = useState(props.defaultValue)
  const [isValidateRefrenceSpin, setisValidateRefrenceSpin] = useState(false);
  const [newValidateRefrenceText, setnewValidateRefrenceText] = useState('');
  const [refrencevalidatorTextChange, setrefrencevalidatorTextChange] = useState((require('md5')(props.defaultValue)));
  
  useEffect(() => { }, [selChanged]);

  const handleChangeSelection = (e) => {
    setSelChanged(Math.random());
    setSel(window.getSelection());
  };
  const handelerRefrenceClick = () => {
    setrefrencevalidatorTextChange(newValidateRefrenceText);
    setisValidateRefrenceSpin(false);
    RefrenceHandeler(html);
  };
const handleValidateRefrenceSpin = (data)=>{
        
        
            const newId = (require('md5')(data));
            setnewValidateRefrenceText(newId);
            if(newId != refrencevalidatorTextChange)
            {
                setisValidateRefrenceSpin(true);
            }
            else
            {
                setisValidateRefrenceSpin(false);
            }
        
        
       
    }
  return (
    <div
      className={classes.MSKEditor}
      style={{ width: props.width, overflowY: 'auto' }}
    >
      <IconButton onClick={handelerRefrenceClick} 
      style={{position: 'absolute',bottom: '0px',right: '0px',display:displayBtnRefrence}}>
        <img  className={(isValidateRefrenceSpin?classesSpin.spinItemAlways:'')+' '+classesSpin.spinItem}   id="handleValidateReferences" src='./burryIcon.png' style={{width:'40px'}}/>
      </IconButton>
      <Toolbar
        
        sel={sel}
        selChanged={selChanged}
        style={{ position: 'sticky', top: 0,display:ToolbarDisplay }}
        // onCopyHTML={() => console.log(html)}
        htmlToCopy={html}
      />
      <article
      
        contentEditable={true}
        onKeyDown={(e) => {
          handleChangeSelection();
          // if (e.key === 'Enter') {
          //     document.execCommand('formatBlock', false, 'p');
          // }
        }}
        onClick={(e) => {
          if (e.target.innerHTML.length === 0) {
            document.execCommand('formatBlock', false, 'DIV');
          }
        }}
        key={'editor'}
        id={'editor'}
        style={{ minHeight: 150, outline: 'none',width:'calc(100% - 20px)' }}
        ref={editorRef}
        // className={classes.MSKEditor}
        onInput={(e) => {
          // console.log(e.target.innerHTML)
          onChange(e.target.innerHTML);
          setHTML(e.target.innerHTML);
         
          if(spinValidateRefrence)
          {
            handleValidateRefrenceSpin(e.target.innerHTML);
          }
        }}
        onPaste={function (event) {
          // getting the copied text from clipboard in HTML format.
          let paste = (event.clipboardData || window.clipboardData).getData('text/html');

          // ability to paste from other apps like notepad.
          // without this conditional return, every paste event passes throgh this code and
          // it will cause a bug which users cant paste from not-work apps.
          // but using this conditional return, just pastes events which include table elements,
          // will pass through this process.
          if (!paste.includes('<table')) return;

          // removing the selection area from the document.
          const selection = window.getSelection();
          if (!selection.rangeCount) return false;
          selection.deleteFromDocument();

          // preparing a div element to wrap the copied html
          const toPaste = document.createElement('div');
          // setting the copied value as a child of toPaste
          toPaste.innerHTML = paste;

          // removing all of the attributes of the toPaste children (such as style, class, id, etc.)
          toPaste.querySelectorAll('*').forEach(el => {
            [...el.attributes].map(attr => el.removeAttributeNode(attr))
          })

          // inserting the prepared element to the document
          selection.getRangeAt(0).insertNode(toPaste);
          // preventing default paste functionality
          event.preventDefault();
          // Dispatching the 'input' event to let the content be saved. (check out the onInput listener on this element)
          document.getElementById('editor').dispatchEvent(new Event('input', { bubbles: true }));
        }}
        className={classes.MSKEditorContent+' '+props.name}
        onKeyUp={handleChangeSelection}
        onMouseLeave={handleChangeSelection}
        onMouseDown={handleChangeSelection}
        onMouseUp={handleChangeSelection}
        dangerouslySetInnerHTML={{ __html: props.defaultValue }}
      >
        {/*<span class="sug">Hello</span> Text*/}
      </article>
      {/*<ContentEditable*/}
      {/*    key={"MSKEditorContent"}*/}
      {/*    onBlur={() => {*/}
      {/*        console.log('Blur')*/}
      {/*        // setSel(window.getSelection())*/}
      {/*    }}*/}
      {/*    onKeyDown={handleChangeSelection}*/}
      {/*    onKeyUp={handleChangeSelection}*/}
      {/*    onMouseLeave={handleChangeSelection}*/}
      {/*    onMouseDown={handleChangeSelection}*/}
      {/*    onMouseUp={handleChangeSelection}*/}
      {/*    id={"editorContent"}*/}
      {/*    className={classes.MSKEditorContent}*/}
      {/*    innerRef={editorRef}*/}
      {/*    html={defaultValue} // innerHTML of the editable div*/}
      {/*    disabled={false} // use true to disable editing*/}
      {/*    onChange={handleChange} // handle innerHTML change*/}
      {/*    tagName='article' // Use a custom HTML tag (uses a div by default)*/}
      {/*/>*/}
    </div >
  );
}

export default MSKEditor;

MSKEditor.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  RefrenceHandeler:PropTypes.func,
  spinValidateRefrence:PropTypes.bool,
  width: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  name:PropTypes.string,
};

MSKEditor.defaultProps = {
  defaultValue: '',
  ToolbarDisplay:'',
  onChange: () => { },
  width: '100%',
  spinValidateRefrence:false,
  displayBtnRefrence:'none',
  RefrenceHandeler:() => { 
    alert('check validate');
  },
  name:''
};
