import React, { useState, useEffect } from 'react';
import classes from './CustomSelect.module.css';
import PropTypes from 'prop-types';


export default function CustomSelect(props) {
  const [selectedValue, setselectedValue] = useState(props.selectedValue);
  const [selectedText, setselectedText] = useState(props.selectedText.toUpperCase());
  const itemClickHandeler = (text, value) => {
    setselectedValue(value);
    setselectedText(text.toUpperCase());
    document.getElementById('refrenceTypeSelect').removeAttribute('open');
    if (props.hasGetSelectedValue) {
      props.getSelectedValue(value);
    }
  }

  useEffect(() => {
    itemClickHandeler(props.selectedText, props.selectedValue);
    setselectedText(props.selectedText.toUpperCase());
  }, [props.selectedValue]);

  return (
    <details id={'refrenceTypeSelect'} className={classes.custom_select}>
      <summary >
        {selectedText}
      </summary>
      <ul className={classes.list}>
        <li className={selectedValue == 'Vancouver' ? classes.selected : ''} onClick={() => { itemClickHandeler('Vancouver', 'Vancouver') }} >{'Vancouver'}</li>
        <li className={selectedValue == 'APA' ? classes.selected : ''} onClick={() => { itemClickHandeler('APA', 'APA') }} >{'APA'}</li>
        <li className={selectedValue == 'IEEE' ? classes.selected : ''} onClick={() => { itemClickHandeler('IEEE', 'IEEE') }} >{'IEEE'}</li>
        <li className={selectedValue == 'Chicago' ? classes.selected : ''} onClick={() => { itemClickHandeler('Chicago', 'Chicago') }} >{'Chicago'}</li>
        <li className={selectedValue == 'MLA' ? classes.selected : ''} onClick={() => { itemClickHandeler('MLA', 'MLA') }} >{'MLA'}</li>
        <li disabled={true}  >{'BibTex'}</li>
      </ul>
    </details>
  );
}

CustomSelect.propTypes = {
  selectedValue: PropTypes.string,
  selectedText: PropTypes.string,
  getSelectedValue: PropTypes.func,
  hasGetSelectedValue: PropTypes.bool

}
CustomSelect.defaultProps = {
  selectedValue: '',
  selectedText: '-',
  getSelectedValue: () => { },
  hasGetSelectedValue: false
}

