import { withRouter } from "react-router-dom";
import HolderCard from "./components/HolderCard";
import ServiceCard from "./components/service/ServiceCard";
import ProjectCard from "./components/project/ProjectCard";
import ProfileCard from "./components/profile/ProfileCard";
import classes from "./ProfilePage.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  GenerateApiKeys,
  GetApiKeys,
  getUserInfo,
  getProjects,
} from "../../Requests/Requests";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

function ProfilePage(props) {
  const [apiKey, setApiKey] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects()
      .then(async (response) => {
        setProjects(response.data);
      })
      .catch((er) => {
        console.log(er, er.response);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    callGetUserInfoApi();
    GetApiKeys()
      .then(async (response) => {
        try {
          if (response.data && response.data[0].key) {
            setApiKey(response.data[0].key);
            setExpirationDate(response.data[0].expires_at);
          }
        } catch {}
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const callGetUserInfoApi = () => {
    getUserInfo()
      .then(async (response) => {
        const {
          liboberry_subscription,
          first_name,
          last_name,
          is_active,
          email,
          organization,
          profile_image,
          remaining_pubberry_credits = 0,
          remaining_refberry_credits = 0,
        } = response.data;
        setUserInfo({
          liboberry_subscription,
          first_name,
          last_name,
          is_active,
          email,
          organization,
          profile_image,
          remaining_pubberry_credits,
          remaining_refberry_credits,
        });
      })
      .catch((error) => {
        console.log(error, error.response);
      });
  };

  const ReGenerateApiKey = () => {
    GenerateApiKeys({ regenerate: true })
      .then(async (response) => {
        console.log("GenerateApiKey", response);
        setApiKey(response.data.key);
        setExpirationDate(response.data.expires_at);
        toast.success("API Key Generated Successfully!");
      })
      .catch((er) => {
        console.log(er);
      });
  };
  const copyToClipBoard = (value) => {
    navigator.clipboard.writeText(value);
    toast.success("API KEY Copied Successfully!");
  };
  if (!userInfo) {
    return (
      <div className={classes.progress}>
        <CircularProgress />;
      </div>
    );
  }
  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.ProfilePage}>
      <HolderCard>
        <ProfileCard
          data={userInfo}
          ApiKey={apiKey}
          expirationDate={expirationDate}
          GenerateApiKeys={ReGenerateApiKey}
          copyToClipBoard={copyToClipBoard}
        />
        <ServiceCard data={userInfo} />
        <ProjectCard data={userInfo} projects={projects} />
      </HolderCard>
    </div>
  );
}

export default withRouter(ProfilePage);
