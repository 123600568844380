export default {
    darkRoot: {
        '& .MuiOutlinedInput-root': {
            margin: 7,
            color: 'var(--black)',
            '& fieldset': {
                borderColor: 'var(--black)',
                borderRadius: 15,
            },
            '&:hover fieldset': {
                borderColor: 'var(--black)',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--black)',
            },
        },
    },
    darkTextField: {
        '& label': {
            margin: 7,
            color: 'var(--black) !important',
        },
        '&:hover label': {
            color: 'var(--black)',
        },
    },
    lightRoot: {
        '& .MuiOutlinedInput-root': {
            color: 'white',
            margin: 7,
            '& fieldset': {
                borderColor: 'white',
                borderRadius: 15,
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
    lightTextField: {
        '& label': {
            margin: 7,
            color: 'white !important',
        },
        '&:hover label': {
            color: 'white',
        },
    },
};