import "./App.css";
import Introduction from "./containers/Introduction/Introduction";
import Title from "./components/Title/Title";
import Footer from "./containers/Footer/Footer";
import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
} from "@material-ui/core";
import CustomTextField from "./components/CustomTextField/CustomTextField";
import SingleChoice from "./components/SingleChoice/SingleChoice";
import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import NextButton from "./components/NextButton/NextButton";
import classes from "./App.module.css";
import Tag from "./components/Tag/Tag";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import FileInput from "./components/FileInput/FileInput";
import CustomButton from "./components/CustomButton/CustomButton";
import defaultAuthor from "./data/defaultAuthor";
import defaultForm from "./data/defaultForm";
import RefBerry from "../RefBerry/RefBerry";
import FullScreenEditor from "./components/Editor/FullScreenEditor";
import { getOffset, scrollIntoView } from "./Functions/scrollIntoView";
import EditableLabel from "./components/EditableLabel/EditableLabel";
import {
  restoreDataFromCookie,
  saveDataInBrowser,
} from "./Config/saveinprogress";
import { validateForm } from "./Functions/validateForm";
import CustomDatePicker from "./components/CustomDatePicker/CustomDatePicker";
import { toast } from "react-toastify";
import PulseEffect from "./components/PulseEffect/PulseEffect";
import Dialog from "@material-ui/core/Dialog";
import { getDataToSubmit } from "./Functions/getDataToSubmit";
import CustomCheckbox from "./components/CustomCheckbox/CustomCheckbox";
import axios from "axios";
import { startTour, endTour } from "./Config/msktour";
import "./Config/msktour.css";
import { authCheck } from "../../Requests/Requests";
import { useHistory } from "react-router";

let restoring = true;

function App() {
  const [errorPulsePosition, setErrorPulsePosition] = useState({
    x: 100,
    y: 100,
  });
  const [error, setError] = useState(false);
  const [openClearConfirm, setOpenClearConfirm] = useState(false);
  const [openAffiliateModal, setOpenAffiliateModal] = useState(false);

  const [form, setForm] = useState(defaultForm);
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [currentAffiliate, setCurrentAffiliate] = useState("");
  const [author, setAuthor] = useState(defaultAuthor);
  const [sectionTitle, setSectionTitle] = useState("");
  const [templatePDFFile, setTemplatePDFFile] = useState(null);
  const [referenceFile, setReferenceFile] = useState(null);
  const [editorData, setEditorData] = useState("");
  const [editorOpen, setEditorOpen] = useState(false);
  const [refberryEditerOpen, setRefberryEditerOpen] = useState(false);
  const [generateXMLLoading, setGenerateXMLLoading] = useState(false);
  const [editorDataChanged, setEditorDataChanged] = useState(0);
  const [viewHTMLoading, setViewHTMLoading] = useState(0);
  const [editorCloseHandler, setEditorCloseHandler] = useState({
    func: () => {},
  });

  const [btnsLoading, setBtnsLoading] = useState(false);

  const handleFormChange = (e) => {
    setInForm(e.target.name, e.target.value);
  };

  const handleAuthorChange = (e) => {
    setAuthor((author) => ({
      ...author,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTemplatePDFUpload = (file) => {
    setTemplatePDFFile(file);
  };

  const handleScrollToNext = (refId) => {
    document
      .getElementById(`ref${refId}`)
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const setInForm = (key, value) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const handleDeleteKeyword = (keyword) => {
    setInForm(
      "keywords",
      form.keywords?.filter((k) => k !== keyword)
    );
  };
  const handleDeleteAffiliate = (index) => {
    console.log(index);
    setAuthor((author) => {
      let newAffiliate = author.authorAffiliate.filter(
        (aff, idx) => idx !== index
      );
      return {
        ...author,
        authorAffiliate: newAffiliate,
      };
    });
  };

  const handleChangeEditorData = (data) => {
    setEditorData(data);
  };

  const handleCloseAbstractEditor = (data) => {
    setInForm("abstract", data);
    setEditorOpen(false);
    setEditorData("");
  };

  const handleStartAbstractEditing = () => {
    setEditorCloseHandler({
      func: handleCloseAbstractEditor,
    });
    setEditorOpen(true);
    setEditorData(form.abstract);
    setEditorDataChanged(Math.random());
  };

  const handleKeywordKeyDown = (e) => {
    if (e.key !== "Enter" && e.keyCode !== 188) return;
    if (e.target.value?.trim()?.length > 0) {
      const keyword = e.target.value;
      setCurrentKeyword("");
      setForm((form) => {
        const keywords = new Set([...(form.keywords || []), keyword]);
        return {
          ...form,
          keywords: [...keywords],
        };
      });
    }
  };

  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return;
  };
  let dragOn = null;
  const handleSectionsDragStart = (e) => {
    e.target.style.opacity = "0.4";
    e.effectAllowed = "move";
    e.dataTransfer.setData("text", e.target.id);
    dragOn = "sections";
  };
  const handleAuthorDragStart = (e) => {
    e.target.style.opacity = "0.4";
    e.effectAllowed = "move";
    e.dataTransfer.setData("text", e.target.id);
    dragOn = "authors";
  };
  const handleDragEnd = (e) => {
    e.target.style.opacity = "1";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    var parentNode = e.target;
    var tr = e.target;
    while (parentNode != null) {
      console.log(parentNode.tagName);
      if (parentNode.tagName == "TBODY") {
        break;
      }
      if (parentNode.tagName == "TR") {
        tr = parentNode;
      }
      parentNode = parentNode.parentNode;
    }
    if (parentNode == null || tr == null) {
      return false;
    }

    let indexDragEl = e.dataTransfer.getData("text").replace(dragOn, "");
    let indexDropEl = tr.id.replace(dragOn, "");
    if (parentNode.id == "TbodySection" && dragOn == "sections") {
      array_move(form.sections, indexDragEl, indexDropEl);
      setInForm("sections", form.sections);
    } else if (parentNode.id == "TbodyAuthor" && dragOn == "authors") {
      array_move(form.authors, indexDragEl, indexDropEl);
      setInForm("authors", form.authors);
    }
    return false;
  };

  const handleAffiliateKeyDown = (e) => {
    if (e.key !== "Enter" && e.keyCode !== 188) return;
    if (e.target.value?.trim()?.length > 0) {
      const entry = e.target.value;
      setCurrentAffiliate("");
      setAuthor((a) => {
        const affiliates = new Set([...(a.authorAffiliate || []), entry]);
        return {
          ...author,
          authorAffiliate: [...affiliates],
        };
      });
    }
    console.log(author);
  };

  const handleAddNewSection = (title) => {
    setSectionTitle("");
    setForm((form) => {
      let sections = form.sections;
      return {
        ...form,
        sections: [...sections, { title: title, body: "" }],
      };
    });
  };

  const handleAuthorsAdd = () => {
    let surname = author.authorSurname;
    let givenName = author.authorGivenName;
    let affiliate = author.authorAffiliate;
    let email = author.authorEmail;
    let isCorresponding = author.authorIsCorresponding;

    if (!surname || !givenName || !affiliate) return; // TODO: Empty alert
    if (
      surname.trim() === "" ||
      givenName.trim() === "" ||
      affiliate.length === 0
    )
      return; // TODO: Empty alert

    if (
      (form.authors || []).find(
        (author) =>
          author.surname === surname &&
          author.givenName === givenName &&
          JSON.stringify(author.affiliate?.sort()) ===
            JSON.stringify(affiliate?.sort)
      )
    ) {
      return; // TODO: alert exists
    }

    setForm((form) => ({
      ...form,
      authors: [
        ...(form.authors || []),
        { surname, givenName, affiliate, email, isCorresponding },
      ],
    }));
  };

  const handleAuthorDelete = (author) => {
    let newAuthors = form.authors.filter(
      (a) =>
        !(
          a.surname === author.surname &&
          a.givenName === author.givenName &&
          a.affiliate === author.affiliate
        )
    );
    setInForm("authors", newAuthors);
  };

  const handleSectionTitleEdit = (e, index) => {
    console.log(index);
    setForm((form) => {
      const sections = form.sections;
      const newSections = sections.map((section, idx) => {
        if (index !== idx)
          return {
            ...section,
            editing: false,
          };
        return {
          ...section,
          title: e.target.value,
          editing: true,
        };
      });
      return {
        ...form,
        sections: newSections,
      };
    });
  };

  const handleSectionEditorClose = (data, index) => {
    setForm((form) => {
      const sections = form.sections;
      const newSections = sections.map((section, idx) => {
        if (index === idx)
          return {
            ...section,
            body: data,
          };
        return section;
      });
      return {
        ...form,
        sections: newSections,
      };
    });
    setEditorOpen(false);
    setEditorData("");
  };

  const handleSectionBodyEdit = (index) => {
    setEditorCloseHandler({
      func: (data) => handleSectionEditorClose(data, index),
    });
    setEditorOpen(true);
    console.log(form.sections, index);
    setEditorDataChanged(Math.random());
    setEditorData(form.sections[index]?.body || "");
  };

  const setSectionTitleEditing = (index, bool) => {
    setForm((form) => {
      const sections = form.sections;
      const newSections = sections.map((section, idx) => {
        if (index !== idx)
          return {
            ...section,
            editing: false,
          };
        return {
          ...section,
          editing: bool,
        };
      });
      return {
        ...form,
        sections: newSections,
      };
    });
  };

  const handleDeleteSection = (index) => {
    setForm((form) => ({
      ...form,
      sections: form.sections.filter((section, idx) => index !== idx),
    }));
  };
  const submitTypes = { VIEW_HTML: "VIEW_HTML", GENERATE_XML: "GENERATE_XML" };
  const handleSubmitForm = (submitType) => {
    const requestConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const formData = {
      ...form,
      referenceFile: referenceFile,
      templatePDFFile: templatePDFFile,
    };
    const validation = validateForm(formData);
    if (validation.result === "error") {
      console.log(validation);
      const errorTarget = document.getElementById(`${validation.tagId}`);
      const targetPosition = getOffset(errorTarget);
      setErrorPulsePosition({
        x: targetPosition.left + 3,
        y: targetPosition.top + 3,
      });
      toast.error(validation.errorText);
      setError(true);
      setTimeout(() => setError(false), 10000);
      scrollIntoView(errorTarget);
      return;
    }
    const dataToSubmit = getDataToSubmit(formData);
    //        if (submitType === submitTypes.GENERATE_XML) {  // Not works
    // some text to commit ...
    setBtnsLoading(true);
    if (submitType === "/") {
      setGenerateXMLLoading(true);
      axios
        .post(process.env.REACT_APP_BASE_URL + "/pubberry/", dataToSubmit, {
          responseType: "blob",
        })
        .then((response) => {
          console.log("--------------");
          setGenerateXMLLoading(false);
          // console.log(response);
          console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          // link.download="1.zip"
          link.download = `${dataToSubmit.get(
            "abbreviation"
          )}-${dataToSubmit.get("volume")}-${dataToSubmit.get(
            "first_page"
          )}.zip`;

          link.click();

          setBtnsLoading(false);
        })
        .catch((error) => {
          setGenerateXMLLoading(false);
          setBtnsLoading(false);
        });
    } else {
      setViewHTMLoading(true);
      axios
        .post(
          process.env.REACT_APP_BASE_URL + "/pubberry/view",
          dataToSubmit,
          requestConfig
        )
        .then((response) => {
          setViewHTMLoading(false);
          let myWindow = window.open("", "response", "resizable=yes");
          myWindow.document.write(response.data);
          myWindow.document.clear();
          setBtnsLoading(false);
        })
        .catch((error) => {
          setViewHTMLoading(false);
          console.log(error);
          setBtnsLoading(false);
        });
    }
  };

  useEffect(() => {
    // let el = document.getElementsByName('referenceType')[0];
    // scrollIntoView(el)
  }, []);

  useEffect(() => {
    restoring = true;
    restoreDataFromCookie((form) => {
      if (Object.keys(form).length !== 0) setForm(form);
      setTimeout(() => {
        restoring = false;
      }, 1000);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (restoring) return;
      saveDataInBrowser(form);
    }, 2000);
  }, [form]);

  let history = useHistory();
  useEffect(() => {
    authCheck()
      .then(() => {})
      .catch(() => {
        history.replace("/");
      });
  }, []);

  const handleFormClear = async () => {
    setForm(defaultForm);
    handleCloseClearConfirm();
  };

  const handleCloseClearConfirm = () => {
    setOpenClearConfirm(false);
  };

  const handleOpenClearConfirm = () => {
    setOpenClearConfirm(true);
  };

  const handleCloseAffiliateModal = () => {
    setOpenAffiliateModal(false);
  };
  const handleOpenAffiliateModal = () => {
    setOpenAffiliateModal(true);
  };

  useEffect(() => {
    startTour(setRefberryEditerOpen);
    return () => {
      endTour();
    };
  }, []);

  return (
    <div className="Pub">
      <PulseEffect
        x={errorPulsePosition.x}
        y={errorPulsePosition.y}
        visible={error}
      />
      <Introduction
        onContinue={() =>
          document.getElementById("ref1")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      />
      <div id="ref1" style={{ position: "relative", top: -100 }} />
      <Fade bottom>
        <Title text={"Journal details"} />
      </Fade>
      <Container style={{ overflow: "hidden" }}>
        <Grid container alignItems={"flex-start"}>
          <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Fade left>
                <CustomTextField
                  value={form.title}
                  name={"title"}
                  id={"title"}
                  onChange={handleFormChange}
                  label="Title"
                  variant="outlined"
                />
              </Fade>
            </Grid>
            <Grid item container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade left>
                  <CustomTextField
                    value={form.publisherName}
                    name={"publisherName"}
                    id={"publisherName"}
                    onChange={handleFormChange}
                    label="Publisher name"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade left>
                  <CustomTextField
                    value={form.publisherLocation}
                    name={"publisherLocation"}
                    id={"publisherLocation"}
                    onChange={handleFormChange}
                    label="Publisher location"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade left>
                  <CustomTextField
                    value={form.publisherId}
                    name={"publisherId"}
                    id={"publisherId"}
                    onChange={handleFormChange}
                    label="Publisher id"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade left>
                  <CustomTextField
                    value={form.abbreviation}
                    name={"abbreviation"}
                    id={"abbreviation"}
                    onChange={handleFormChange}
                    label="Abbreviation"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade left>
                  <CustomTextField
                    value={form.ppub}
                    name={"ppub"}
                    id={"ppub"}
                    onChange={handleFormChange}
                    label="ISSN ppub"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade left>
                  <CustomTextField
                    value={form.eppub}
                    name={"eppub"}
                    id={"eppub"}
                    onChange={handleFormChange}
                    label="ISSN eppub"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
            {/*<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>*/}
            {/*    <Fade right>*/}
            {/*        <CustomTextField*/}
            {/*            value={form.epublish}*/}
            {/*            name={'epublish'}*/}
            {/*            id={'epublish'}*/}
            {/*            onChange={handleFormChange}*/}
            {/*            label="EPublish"*/}
            {/*            variant="outlined"*/}
            {/*        />*/}
            {/*    </Fade>*/}
            {/*</Grid>*/}

            <Grid item container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade right>
                  <CustomTextField
                    value={form.volume}
                    name={"volume"}
                    id={"volume"}
                    onChange={handleFormChange}
                    label="Volume"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade right>
                  <CustomTextField
                    value={form.issue}
                    name={"issue"}
                    id={"issue"}
                    onChange={handleFormChange}
                    label="Issue"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade right>
                  <CustomTextField
                    value={form.copyrightsStatement}
                    name={"copyrightsStatement"}
                    id={"copyrightsStatement"}
                    onChange={handleFormChange}
                    label="Copyrights statement"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Fade right>
                  <CustomDatePicker
                    name={"copyrightsDate"}
                    id={"copyrightsDate"}
                    label={"Copyrights date"}
                    value={form.copyrightsDate}
                    onSelect={(date) => setInForm("copyrightsDate", date)}
                  />
                </Fade>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        <Fade bottom>
          <NextButton refId="2" dark onClick={handleScrollToNext} />
        </Fade>
        <br />
        <br />
        <br />
      </Container>
      <div id="ref2" style={{ position: "relative", top: -100 }} />
      <div className={classes.darkSection} style={{ overflow: "hidden" }}>
        <Container>
          <br />
          <Fade bottom>
            <Title dark={false} text={"Article details"} />
          </Fade>
          <Grid container>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto" }}
              >
                <Fade bottom>
                  <CustomTextField
                    dark={false}
                    value={form.articleTitle}
                    name={"articleTitle"}
                    id={"articleTitle"}
                    onChange={handleFormChange}
                    label="Title"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto" }}
              >
                <Fade bottom>
                  <CustomTextField
                    dark={false}
                    value={form.articleSubTitle}
                    name={"articleSubTitle"}
                    id={"articleSubTitle"}
                    onChange={handleFormChange}
                    label="Subtitle"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto" }}
              >
                <Fade bottom>
                  <CustomTextField
                    dark={false}
                    value={form.articleType}
                    name={"articleType"}
                    id={"articleType"}
                    onChange={handleFormChange}
                    label="Article Type"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto" }}
              >
                <Fade bottom>
                  <CustomTextField
                    dark={false}
                    value={form.doi}
                    name={"doi"}
                    id={"doi"}
                    onChange={handleFormChange}
                    label="DOI"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto" }}
              >
                <Fade bottom>
                  <Fade right>
                    <CustomDatePicker
                      dark={false}
                      name={"submissionDate"}
                      id={"submissionDate"}
                      label={"Submission date"}
                      value={form.submissionDate}
                      onSelect={(date) => setInForm("submissionDate", date)}
                    />
                  </Fade>
                </Fade>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto" }}
              >
                <Fade bottom>
                  <Fade right>
                    <CustomDatePicker
                      dark={false}
                      name={"publishDate"}
                      id={"publishDate"}
                      label={"Publish date"}
                      value={form.publishDate}
                      onSelect={(date) => setInForm("publishDate", date)}
                    />
                  </Fade>
                </Fade>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ justifyContent: "center" }}
            >
              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                <Fade left>
                  <CustomTextField
                    dark={false}
                    value={form.firstPage}
                    name={"firstPage"}
                    id={"firstPage"}
                    onChange={handleFormChange}
                    label="First page"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                <Fade right>
                  <CustomTextField
                    dark={false}
                    value={form.lastPage}
                    name={"lastPage"}
                    id={"lastPage"}
                    onChange={handleFormChange}
                    label="Last page"
                    variant="outlined"
                  />
                </Fade>
              </Grid>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto" }}
              >
                <Fade bottom>
                  <CustomTextField
                    dark={false}
                    value={currentKeyword}
                    name={"keywords"}
                    id={"keywords"}
                    onChange={(e) => setCurrentKeyword(e.target.value)}
                    onKeyDown={handleKeywordKeyDown}
                    label="Keyword"
                    variant="outlined"
                    placeholder={"Keyword: Use enter for adding keyword"}
                  />
                </Fade>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: "auto", textAlign: "left" }}
              >
                <div className={classes.keywords}>
                  {form.keywords?.map((tag) => (
                    <Fade top key={tag}>
                      <Tag value={tag} onDelete={handleDeleteKeyword} />
                    </Fade>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <Fade bottom>
            <NextButton refId="3" onClick={handleScrollToNext} />
          </Fade>
          <br />
          <br />
        </Container>
      </div>
      <br />
      <br />

      <Container style={{ position: "relative", overflow: "hidden" }}>
        <Fade bottom>
          <div id="ref3" style={{ position: "relative", top: -100 }} />
          <Title text={"Abstract"} />
        </Fade>
        <Fade bottom>
          <div style={{ textAlign: "right", marginTop: -50 }}>
            <IconButton
              id={"editAbstract"}
              title="Edit abstract"
              style={{
                position: "relative",
                top: 50,
                right: 0,
                marginBottom: 0,
              }}
              onClick={handleStartAbstractEditing}
            >
              <EditIcon />
            </IconButton>
            <div
              id={"abstract"}
              className={classes.abstract}
              style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{
                __html:
                  form.abstract ||
                  "<p>Your article's abstract goes here...</p>",
              }}
            />
          </div>
        </Fade>
        <br />
        <br />
        <Fade bottom>
          <NextButton refId="4" dark onClick={handleScrollToNext} />
        </Fade>
        <br />
        <br />
      </Container>
      <div className={classes.darkSection}>
        <br />
        <Fade bottom>
          <div id="ref4" style={{ position: "relative", top: -100 }} />
          <Title dark={false} text={"Author details"} />
        </Fade>
        <Container>
          <Grid container>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Fade left>
                <CustomTextField
                  dark={false}
                  value={author.authorSurname}
                  name={"authorSurname"}
                  id={"authorSurname"}
                  onChange={handleAuthorChange}
                  label="First name"
                  variant="outlined"
                />
              </Fade>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Fade left>
                <CustomTextField
                  dark={false}
                  value={author.authorGivenName}
                  name={"authorGivenName"}
                  id={"authorGivenName"}
                  onChange={handleAuthorChange}
                  label="Last name"
                  variant="outlined"
                />
              </Fade>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Fade right>
                <CustomTextField
                  dark={false}
                  value={author.authorAffiliate.join("  _  ")}
                  name={"authorAffiliate"}
                  id={"authorAffiliate"}
                  onClick={handleOpenAffiliateModal}
                  label="Affiliate"
                  variant="outlined"
                />
              </Fade>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Fade right>
                <CustomTextField
                  dark={false}
                  value={author.authorEmail}
                  name={"authorEmail"}
                  id={"authorEmail"}
                  onChange={handleAuthorChange}
                  label="Email"
                  variant="outlined"
                />
              </Fade>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Fade right>
                <CustomCheckbox
                  label={<div id={"isCorresponding"}>Is Corresponding</div>}
                  checked={author.authorIsCorresponding}
                  onChange={(val) =>
                    setAuthor((author) => ({
                      ...author,
                      authorIsCorresponding: val,
                    }))
                  }
                />
              </Fade>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Fade right>
                <CustomButton
                  id={"addAuthorButton"}
                  style={{
                    margin: 0,
                    marginTop: 5,
                    width: "calc(100% - 20px)",
                  }}
                  onClick={handleAuthorsAdd}
                >
                  Add
                </CustomButton>
              </Fade>
            </Grid>
          </Grid>

          <br />
          <Fade bottom>
            <TableContainer style={{ backgroundColor: "transparent" }}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "white" }}>Surname</TableCell>
                    <TableCell align="left" style={{ color: "white" }}>
                      Given name
                    </TableCell>
                    <TableCell align="left" style={{ color: "white" }}>
                      Affiliate
                    </TableCell>
                    <TableCell align="left" style={{ color: "white" }}>
                      Email
                    </TableCell>
                    <TableCell align="left" style={{ color: "white" }}>
                      Corresponding
                    </TableCell>
                    <TableCell align="right">
                      <DeleteIcon style={{ color: "white" }} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id={"TbodyAuthor"} className={classes.whiteTable}>
                  {(!form.authors || form.authors.length === 0) && (
                    <TableRow key={JSON.stringify(author)}>
                      <TableCell colSpan={6} align="center">
                        The authors list is empty...
                      </TableCell>
                    </TableRow>
                  )}
                  {(form.authors || []).map((author, index) => (
                    <TableRow
                      key={author.surname}
                      id={"authors" + index}
                      className={"drag-box"}
                      draggable={true}
                      onDragStart={(e) => {
                        handleAuthorDragStart(e);
                      }}
                      onDragEnd={(e) => {
                        handleDragEnd(e);
                      }}
                      onDragOver={(e) => {
                        handleDragOver(e);
                      }}
                      onDrop={(e) => {
                        handleDrop(e);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Fade top>{author.surname}</Fade>
                      </TableCell>
                      <TableCell align="left">
                        <Fade top>{author.givenName}</Fade>
                      </TableCell>
                      <TableCell align="left">
                        <Fade top>{author.affiliate.join(" _ ")}</Fade>
                      </TableCell>
                      <TableCell align="left">
                        <Fade top>{author.email}</Fade>
                      </TableCell>
                      <TableCell align="left">
                        <Fade top>
                          {JSON.stringify(author.isCorresponding)}
                        </Fade>
                      </TableCell>
                      <TableCell align="right">
                        <Fade top>
                          <IconButton
                            style={{ margin: -10 }}
                            onClick={handleAuthorDelete.bind(this, author)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Fade>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Fade>
          <br />
          <br />
          <Fade bottom>
            <CustomTextField
              multiline
              dark={false}
              value={form.acknowledgment}
              InputProps={{
                style: {
                  minHeight: 200,
                  alignItems: "flex-start",
                  justifyContent: "center",
                  display: "flex",
                },
              }}
              name={"acknowledgment"}
              id={"acknowledgment"}
              onChange={handleFormChange}
              label="Acknowledgment"
              variant="outlined"
            />
          </Fade>
          <br />
          <br />
          <Fade bottom>
            <NextButton refId="5" onClick={handleScrollToNext} />
          </Fade>
          <br />
          <br />
        </Container>
      </div>

      <Container>
        <br />
        <Fade bottom>
          <div id="ref5" style={{ position: "relative", top: -100 }} />
          <Title text={"Sections"} />
        </Fade>

        <Grid container>
          <Grid item xl={6} lg={6} md={8} sm={8} xs={8}>
            <Fade left>
              <CustomTextField
                value={sectionTitle}
                name={"sectionTitle"}
                id={"sectionTitle"}
                onChange={(e) => setSectionTitle(e.target.value)}
                label="Section title"
                variant="outlined"
              />
            </Fade>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
            <Fade right>
              <CustomButton
                onClick={handleAddNewSection.bind(this, sectionTitle)}
                style={{ backgroundColor: "var(--light-red)", color: "white" }}
              >
                Add
              </CustomButton>
            </Fade>
          </Grid>
        </Grid>
        <Fade bottom>
          <TableContainer
            style={{ backgroundColor: "transparent" }}
            className={classes.fullBlackTable}
          >
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: "white" }}>Title</TableCell>
                  <TableCell style={{ color: "white" }}>
                    body (click to edit)
                  </TableCell>
                  <TableCell align="right" style={{ width: 50 }}>
                    <DeleteIcon style={{ color: "white" }} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                id="TbodySection"
                className={classes.blackTable + " section-container"}
              >
                {(!form.sections || form.sections.length === 0) && (
                  <TableRow key={author.authorSurname}>
                    <TableCell colSpan={4} align="center">
                      The sections list is empty...
                    </TableCell>
                  </TableRow>
                )}
                {(form.sections || []).map((section, index) => (
                  <TableRow
                    key={section.title + "" + index}
                    id={"sections" + "" + index}
                    draggable={true}
                    onDragStart={(e) => {
                      handleSectionsDragStart(e);
                    }}
                    onDragEnd={(e) => {
                      handleDragEnd(e);
                    }}
                    onDragOver={(e) => {
                      handleDragOver(e);
                    }}
                    onDrop={(e) => {
                      handleDrop(e);
                    }}
                    className={"drag-box"}
                  >
                    <TableCell scope="row" style={{ width: 200 }}>
                      <EditableLabel
                        onEditStart={() => setSectionTitleEditing(index, true)}
                        onEditEnd={() => setSectionTitleEditing(index, false)}
                        editing={section.editing}
                        value={section.title}
                        onChange={(e) => handleSectionTitleEdit(e, index)}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <div
                        onClick={() => handleSectionBodyEdit(index)}
                        dangerouslySetInnerHTML={{ __html: section.body }}
                        style={{
                          cursor: "pointer",
                          height: 60,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        className={classes.paper}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={handleDeleteSection.bind(this, index)}
                        style={{ margin: -10 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fade>

        <br />
        <br />
        <Fade bottom>
          <NextButton refId="6" dark onClick={handleScrollToNext} />
        </Fade>
        <br />
        <br />
      </Container>
      <RefBerry
        refTitle={"References"}
        refberryTour={false}
        useHtmlInForm={true}
        useRefTypeInForm={true}
        useInputRefInForm={true}
        setHtmlInForm={setInForm}
        useRefFileInForm={true}
        setRefFileInForm={setReferenceFile}
        useCustomRestoreData={true}
        restoreDataFromCookies={restoreDataFromCookie}
        useCustomSaveData={true}
        saveDataInBrowsers={saveDataInBrowser}
        EditerOpenhandler={refberryEditerOpen}
      />
      <Container style={{ overflow: "hidden" }}>
        <br />
        <Fade bottom>
          <div id="ref7" style={{ position: "relative", top: -100 }} />
          <Title text={"Template PDF"} />
        </Fade>
        <p style={{ marginTop: -20, color: "var(--black)" }}>
          Please upload your article's pdf template.
        </p>
        <br />
        <br />
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item xl={3} lg={3} md={3} sm={8} xs={12}>
            <Fade bottom>
              <FileInput
                id={"templatePDFFile"}
                accept={".pdf"}
                value={templatePDFFile}
                onFileSelect={handleTemplatePDFUpload}
                buttonText={"Select File"}
              />
            </Fade>
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider />
        <br />
        <br />

        <Grid container style={{ justifyContent: "center" }} spacing={2}>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Fade left>
              <CustomButton
                onClick={
                  btnsLoading ? () => {} : handleSubmitForm.bind(this, "/")
                }
                style={{
                  backgroundColor: "var(--light-red)",
                  opacity: btnsLoading ? 0.5 : 1,
                  cursor: btnsLoading ? "not-allowed" : "pointer",
                  margin: 0,
                  color: "white",
                }}
              >
                {btnsLoading && (
                  <CircularProgress
                    style={{ color: "#000", position: "absolute" }}
                  />
                )}
                Generate XML
              </CustomButton>
            </Fade>
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Fade right>
              <CustomButton
                onClick={
                  btnsLoading ? () => {} : handleSubmitForm.bind(this, "/view")
                }
                style={{
                  backgroundColor: "var(--light-red)",
                  opacity: btnsLoading ? 0.5 : 1,
                  cursor: btnsLoading ? "not-allowed" : "pointer",
                  margin: 0,
                  color: "white",
                }}
              >
                View HTML
                {btnsLoading && (
                  <CircularProgress
                    style={{ color: "#000", position: "absolute" }}
                  />
                )}
              </CustomButton>
            </Fade>
          </Grid>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Fade right>
              <CustomButton
                style={{
                  textTransform: "none",
                  backgroundColor: "#CCCCCC",
                  margin: 0,
                  color: "var(--black)",
                }}
                onClick={() => setOpenClearConfirm(true)}
              >
                Clear form
              </CustomButton>
            </Fade>
          </Grid>
        </Grid>
        <br />
        <br />
      </Container>
      <FullScreenEditor
        key={editorDataChanged}
        open={editorOpen}
        onClose={editorCloseHandler.func}
        data={editorData}
        onChange={handleChangeEditorData}
      />
      <Footer>
        <Grid container>
          <Grid
            container
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.gridItemContainer}
          >
            <ul>
              <li>
                <a href={"https://www.ncbi.nlm.nih.gov/pmc/tools/xmlchecker/"}>
                  PubMed Central (PMC)XML Validator
                </a>
              </li>
              <li>
                <a href={"/RefBerry"}>RedBerry</a>
              </li>
            </ul>
          </Grid>
          <Grid
            container
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.gridItemContainer}
          >
            <ul>
              <li>
                <a
                  href={
                    "https://www.ncbi.nlm.nih.gov/pmc/tools/article-previewer-intro/"
                  }
                >
                  PMC Article Previewer
                </a>
              </li>
              <li>
                <a href={"https://liboberry.com/contact-us/"}>Contact</a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Footer>
      <Dialog
        onClose={handleCloseClearConfirm}
        style={{ borderRadius: 0, overflow: "hidden" }}
        open={openClearConfirm}
      >
        <div
          style={{
            width: 400,
            padding: 20,
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <h2>Are you sure?</h2>
          <br />
          <Divider />
          <br />
          <p>Are you sure you want to clear the form?</p>
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton
              onClick={handleCloseClearConfirm}
              style={{
                margin: "0 5px 0 0",
                textTransform: "none",
                width: "auto",
                height: 40,
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleFormClear}
              style={{
                color: "white",
                backgroundColor: "var(--light-red)",
                margin: "0 0 0 5px",
                textTransform: "none",
                width: "auto",
                height: 40,
              }}
            >
              Yes, clear the form
            </CustomButton>
          </div>
        </div>
      </Dialog>

      <Dialog style={{ borderRadius: 15 }} open={openAffiliateModal}>
        <div style={{ margin: 10, width: 300 }}>
          <h2>Affiliates</h2>
          <Divider />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomTextField
              // dark={false}
              value={currentAffiliate}
              name={"affiliates"}
              id={"affiliates"}
              onChange={(e) => setCurrentAffiliate(e.target.value)}
              onKeyDown={handleAffiliateKeyDown}
              label="Affiliate (add by Enter button)"
              variant="outlined"
              style={{ margin: 0, width: "100%" }}
              // placeholder={'Keyword: Use enter for adding keyword'}
            />
          </div>
          <div className={classes.affiliatesWrapper}>
            {author.authorAffiliate?.map((aff, index) => (
              <Fade top key={aff}>
                <div className={classes.affiliateTag}>
                  <div className={classes.affiliateTagText}>{aff}</div>
                  <IconButton onClick={handleDeleteAffiliate.bind(this, index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Fade>
            ))}
          </div>
        </div>
        <CustomButton
          onClick={handleCloseAffiliateModal}
          style={{
            margin: 0,
            color: "white",
            backgroundColor: "green",
            borderRadius: 0,
          }}
        >
          Done
        </CustomButton>
      </Dialog>
      {/* <JoyRide /> */}
    </div>
  );
}

export default App;
