import { withRouter } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EditableArticalMetaData from "../../components/EditableArticalMetaData/EditableArticalMetaData";
import classesSpin from "../../components/Loader/Loader.module.css";
import classesAccordion from "../../components/accordion/accordion.module.css";
import ExtentionIcon from "../../components/ExtentionIcon/ExtentionIcon.js";
import { DocumentUploadFileAllowMimeType } from "../../Functions/FilterUploadFile";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DocStyles from "../DocumentsPage/DocumentsPage.module.css";
import LabelIcon from "@material-ui/icons/Label";
import AddTagDialog from "../DocumentsPage/components/CreateNewLabelDialog.js";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PublicIcon from "@material-ui/icons/Public";
import AiModelIcon from "../../components/Icons/AIModel/AiModelIcon.js";
import LabelItem from "../../components/Tags/LabelItem.js";
import {
  deleteProjectDocument,
  getProjectDocuments,
  uploadProjectDocument,
  uploadProjectDocumentWithURL,
  getDocument_tags,
  deleteDocument_tags,
  getProjectMLModels,
  AddDocument_tags,
  updateProjectDocument,
} from "../../Requests/Requests";
import { toast } from "react-toastify";
import {
  Button,
  Chip,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Checkbox,
} from "@material-ui/core";

import Mng from "./ManageRefrences.module.css";
import FloatDeleteButton from "../../components/FloatDeleteButton/FloatDeleteButton.js";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  chip: {
    margin: 5,
    backgroundColor: "#00800042",
    maxWidth: "72%",
  },
  errorChip: {
    margin: 5,
    backgroundColor: "#f50606bf",
    maxWidth: "72%",
  },
  referencesWrapper: {
    backgroundColor: "#EEE",
    padding: 5,
    borderRadius: 10,
  },
}));

function ManageReferencesPage(props) {
  const classes = useStyles();
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [projectArticles, setProjectArticles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [intervalRefreshProjectDocuments, setIntervalRefreshProjectDocuments] =
    useState([]);
  const [accordionStatusList, setAccordionStatusList] = useState([]);
  const [urlFile, setUrlFile] = useState(null);
  const [articleMenu, setArticleMenu] = React.useState(null);
  const open = Boolean(articleMenu);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectArticles, setSelectArticles] = useState([]);
  const [searchLabelInput, setSearchLabelInput] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddTagDialogOpen, setIsAddTagDialogOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [filterSelectLabel, setFilterSelectLabel] = useState([]);
  const [ml_modekStatus, setMl_modelStatus] = useState("success");
  const [tokenize, setTokenize] = useState("success");
  const [processStatus, setProcessStatus] = useState("success");
  const articlePanelBoxRef = useRef(null);
  const [minHeightArticleHeader, setMinHeightArticleHeader] = useState(null);
  const [ml_modelContent, setMl_modelContent] = useState(
    "Model trained successfully"
  );
  const [tokenizeContent, setTokenizeContent] = useState(
    "Tokenize successfully"
  );
  const [processContent, setProcessContent] = useState("Process successfully");
  const handleDeleteTag = (tagId, doc_id) => {
    const updatedProjects = projectArticles.map((project) => {
      if (project.id == doc_id) {
        const updatedTags = project.document_tags.filter(
          (tag) => tag.id != tagId
        );
        updateProjectDocumentTags(
          doc_id,
          updatedTags.map((s) => s.id)
        );
        return { ...project, document_tags: updatedTags };
      }
      return project;
    });
    let pr = updatedProjects;
    setProjectArticles(pr);
    timerForCallResizeWindow();
    // let index = pr.findIndex((project) => project.id == doc_id);
    // if (index >= 0) {
    //   refreshProjectDocuments(doc_id, { tags: pr[index].tags });
    // }
  };

  const timerForCallResizeWindow = (timer = 500) => {
    setTimeout(() => {
      resizeWindow();
    }, timer);
  };
  const resizeWindow = () => {
    if (articlePanelBoxRef && articlePanelBoxRef.current) {
      let headers = articlePanelBoxRef.current.getElementsByTagName("h3");

      let maxHeight = null;
      let h1 = null;
      let h2 = null;

      for (let h of headers) {
        h.style.minHeight = null;
        if (document.body.offsetWidth > 768) {
          if (h1 == null) {
            h1 = h;
          } else if (h2 == null) {
            h2 = h;
          }
          if (h1 != null && h2 != null) {
            let offsetHeight1 = Number(h1.offsetHeight);
            let offsetHeight2 = Number(h2.offsetHeight);
            let max = Math.max(offsetHeight1, offsetHeight2);
            h1.style.minHeight = max + "px";
            h2.style.minHeight = max + "px";

            h1 = null;
            h2 = null;
          }
        }
      }
      maxHeight = document.body.offsetWidth > 768 ? maxHeight : null;
    }
  };

  const handleFilterSelectLabel = (labelid) => {
    if (filterSelectLabel.includes(labelid)) {
      setFilterSelectLabel([...filterSelectLabel.filter((s) => s != labelid)]);
    } else {
      setFilterSelectLabel([...filterSelectLabel, labelid]);
    }
  };

  const handleDeleteTags = (id) => {
    const updatedTags = tags.filter((tag) => tag.id != id);

    setTags(updatedTags);

    deleteDocument_tags(props.match.params.id, id)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          if (response.data.message) {
            toast.success(response.data.message);
          }
        }
      })
      .catch((error) => {
        if (error.response.data?.error) {
          toast.error(error.response.data.error);
        }
        if (error.response.data?.detail) {
          toast.error(error.response.data.detail);
        } else {
          console.log(error, error.response);
        }
      });
    timerForCallResizeWindow();
  };

  useEffect(() => {
    window.onresize = resizeWindow;
    callApiGetLabel();
    return () => {
      window.onresize = null;
    };
  }, []);

  const handleRemoveTag = (tagId, removeAll = false) => {
    const updatedProjectArticles = projectArticles.map((article) => {
      if (
        (selectArticles.length > 0 && selectArticles.includes(article.id)) ||
        removeAll
      ) {
        if (article.document_tags && Array.isArray(article.document_tags)) {
          const existingTag = article.document_tags.find(
            (tag) => tag.id == tagId
          );

          if (existingTag) {
            let filterlabels = article.document_tags.filter(
              (s) => s.id != tagId
            );
            const updatedTags = [...filterlabels];
            updateProjectDocumentTags(
              article.id,
              updatedTags.map((s) => s.id)
            );
            return { ...article, document_tags: updatedTags };
          }
        }
      }
      return article;
    });
    setProjectArticles(updatedProjectArticles);
    timerForCallResizeWindow();
  };

  const handleRemoveTagClick = (tagId, removeAll = false) => {
    if (removeAll) {
      handleRemoveTag(tagId, removeAll);
    } else {
      handleRemoveTag(tagId);
    }
  };

  const handleAddTag = (doc_id, tagName, tagColor, tagId) => {
    const updatedProjectArticles = projectArticles.map((article) => {
      if (selectArticles.includes(article.id)) {
        if (article.document_tags && Array.isArray(article.document_tags)) {
          const existingTag = article.document_tags.find(
            (tag) => tag.id == tagId
          );
          if (!existingTag) {
            const updatedTags = [
              ...article.document_tags,
              { name: tagName, color: tagColor, id: tagId },
            ];
            updateProjectDocumentTags(
              article.id,
              updatedTags.map((s) => s.id)
            );
            return { ...article, document_tags: updatedTags };
          }
        } else {
          const updatedTags = [{ name: tagName, color: tagColor, id: tagId }];
          updateProjectDocumentTags(
            article.id,
            updatedTags.map((s) => s.id)
          );
          return { ...article, document_tags: updatedTags };
        }
      }
      return article;
    });

    setProjectArticles(updatedProjectArticles);
    timerForCallResizeWindow();
  };
  const updateProjectDocumentTags = (articleId, tagIds) => {
    let formdata = new FormData();
    for (let index = 0; index < tagIds.length; index++) {
      formdata.append("document_tags", tagIds[index]);
    }
    if (tagIds.length == 0) {
      formdata.append("document_tags", null);
    }
    updateProjectDocument(props.match.params.id, articleId, formdata)
      .then((res) => {
        if (res.status >= 200 && res.data.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.data?.error) {
          toast.error(err.response.data?.error);
        } else if (err.response.data?.detail) {
          toast.error(err.response.data?.detail);
        } else if (
          err.response.data?.document_tags &&
          err.response.data?.document_tags.length > 0
        ) {
          toast.error(err.response.data?.document_tags[0]);
          console.log(err, err.response);
        } else {
          console.log(err, err.response);
        }
      });
  };
  const handleAddTagClick = (tagName, tagColor, tagId) => {
    if (selectArticles.length > 0) {
      selectArticles.forEach((doc_id) => {
        handleAddTag(doc_id, tagName, tagColor, tagId);
      });
    }
  };

  const isSelectedTag = (labelId) => {
    let labelsStatus = [];
    let selectionRows = [...selectArticles];
    if (selectionRows.length > 0) {
      for (let article of projectArticles) {
        if (selectionRows.includes(article.id)) {
          if (article.document_tags && Array.isArray(article.document_tags)) {
            labelsStatus.push(
              article.document_tags.findIndex((tag) => tag.id == labelId) >= 0
            );
          }
        }
      }
    }

    let hasFalseLabel = labelsStatus.findIndex((s) => s == false) >= 0;
    let hasTrueLabel = labelsStatus.findIndex((s) => s == true) >= 0;

    if (hasTrueLabel && !hasFalseLabel) {
      return "true";
    } else if (hasTrueLabel && hasFalseLabel) {
      return "indeterminate";
    }
    return "false";
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOpenAddTagDialog = () => {
    setIsAddTagDialogOpen(true);
  };

  const callApiGetLabel = () => {
    getDocument_tags(props.match.params.id)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          if (res.data != null && res.data != "" && Array.isArray(res.data)) {
            setTags(res.data);
          } else {
            setTags([]);
          }
        }
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
        } else if (err.response?.data?.detail) {
          toast.error(err.response?.data?.detail);
        } else {
          console.log(err, err.response);
        }
      });
  };

  const handleCreateTag = (id, tagName, tagColor) => {
    handleAddTagClick(tagName, tagColor, id);
    setIsAddTagDialogOpen(false);
  };

  const handleCloseAddTagDialog = () => {
    setIsAddTagDialogOpen(false);
  };

  const handleOpenMenu = (event) => {
    setSearchLabelInput("");
    if (Boolean(anchorEl)) {
      handleCloseMenu();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleCloseMenu = () => {
    setSearchLabelInput("");
    setAnchorEl(null);
  };

  const addSelectArticleRows = (doc_id) => {
    if (!selectArticles.includes(doc_id)) {
      setSelectArticles([...selectArticles, doc_id]);
    }
  };

  const removeSelectArticleRows = (doc_id) => {
    setSelectArticles([...selectArticles.filter((id) => id != doc_id)]);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const filtered = projectArticles.filter((article) =>
      article.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredArticles(filtered);
  }, [searchQuery, projectArticles]);

  const handleClickArticleMenu = (event) => {
    setArticleMenu(event.currentTarget);
  };
  const handleCloseArticleMenu = () => {
    setArticleMenu(null);
    setUrlFile(null);
  };

  const handleUploadProjectURLArticles = () => {
    if (urlFile == null || urlFile?.trim() == "") {
      toast.error("Please Input Url");
      return;
    }
    setUploading(true);
    setMl_modelStatus("loading");
    setMl_modelContent("training models....");
    setTokenize("loading");
    setTokenizeContent("Tokenizing...");
    setProcessStatus("loading");
    setProcessContent("processing...");
    uploadProjectDocumentWithURL(props.match.params.id, urlFile)
      .then((res) => {
        refreshProjectDocuments();
        setUploading(false);
        setProcessStatus("success");
        setProcessContent("process successfully");
        if (res.status >= 200 && res.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        setUploading(false);
        setTokenize("error");
        setTokenizeContent("Faield Tokenized");
        setMl_modelStatus("error");
        setMl_modelContent("Failed trained Models");
        setProcessStatus("error");
        setProcessContent("Failed to load file");
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          console.log(err);
        }
      });
  };
  const handleUploadProjectArticles = (articles, resetSelectedArticles) => {
    resetSelectedArticles();
    for (const article of articles) {
      if (DocumentUploadFileAllowMimeType(article.type)) {
        setUploading(true);
        setMl_modelStatus("loading");
        setMl_modelContent("training models....");
        setTokenize("loading");
        setTokenizeContent("Tokenizing...");
        setProcessStatus("loading");
        setProcessContent("processing...");
        uploadProjectDocument(props.match.params.id, article)
          .then((res) => {
            refreshProjectDocuments();
            setUploading(false);
            setProcessStatus("success");
            setProcessContent("process successfully");
            if (res.status >= 200 && res.status <= 299) {
              if (res.data.message) {
                toast.success(res.data.message);
              }
            }
          })
          .catch((err) => {
            refreshProjectDocuments();
            setUploading(false);
            setTokenize("error");
            setTokenizeContent("Faield Tokenized");
            setMl_modelStatus("error");
            setMl_modelContent("Failed trained Models");
            setProcessStatus("error");
            setProcessContent("Failed to load file");
            if (err.response.data.error) {
              toast.error(err.response.data.error);
            } else {
              console.log(err);
            }
          });
      }
    }
  };

  const handleDeleteProjectArticle = (id) => {
    deleteProjectDocument(props.match.params.id, id)
      .then((res) => {
        refreshProjectDocuments();
        if (res.status >= 200 && res.status <= 299) {
          if (res.data.message) {
            toast.success(res.data.message);
          } else {
            toast.success("document deleted successfully.");
          }
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          toast.error("An error occurred while deleting the document!");
          console.log(err, err.response);
        }
      });
  };

  useEffect(() => {
    if (processStatus == "loading") {
      setMl_modelStatus("loading");
      setMl_modelContent("training models....");
      setTokenize("loading");
      setTokenizeContent("Tokenizing...");
      setProcessContent("processing...");
    } else if (processStatus == "error") {
      setTokenize("error");
      setTokenizeContent("Faield Tokenized");
      setMl_modelStatus("error");
      setMl_modelContent("Failed trained Models");
      setProcessContent("Failed to load file");
    }
  }, [processStatus]);
  useEffect(() => {
    refreshProjectDocuments(false);
    return () => {
      for (let item of intervalRefreshProjectDocuments) {
        clearTimeout(item);
      }
      return true;
    };
  }, []);
  const refreshDocumentsAndFile = () => {
    refreshProjectDocuments(false);
  };
  const handleOnClickAccordionElement = (e, doc_id) => {
    let element = e.target;
    if (element && element.tagName == "DIV") {
      element.classList.toggle(classesAccordion.active);
      var panel = element.parentElement.nextElementSibling;
      if (panel) {
        panel.style.maxHeight = panel.style.maxHeight ? null : "100%"; //(panel.scrollHeight > 700 ? panel.scrollHeight : 700) + "px";
        let accordionlist = accordionStatusList;
        accordionlist.find(function (entry) {
          if (entry.id == doc_id) {
            entry.maxHeight =
              entry.maxHeight == null
                ? "100%"
                : // panel.scrollHeight > 700
                  //   ? panel.scrollHeight
                  //   : 700
                  null;
          }
        });

        setAccordionStatusList(accordionlist);
      }
    }
  };

  const MlmodelStatus = () => {
    setMl_modelStatus("loading");
    setMl_modelContent("training models....");
    getProjectMLModels(props.match.params.id)
      .then((res) => {
        if (res.data) {
          if (
            res.data.some((s) => s.state == "training" || s.state == "waiting")
          ) {
            setMl_modelStatus("loading");
            setMl_modelContent("training models....");
            setTimeout(() => {
              MlmodelStatus();
            }, 20000);
          } else if (res.data.every((s) => s.state == "trained")) {
            setMl_modelStatus("success");
            setMl_modelContent("models trained successfully");
          } else {
            let result = "";
            if (
              res.data.filter(
                (s) => s.model_type == "paraphraser" && s.state == "trained"
              ).length > 0
            ) {
              result = "paraphraser model successfully";
            } else {
              result = "paraphraser model Failed";
            }
            if (
              res.data.filter(
                (s) => s.model_type == "recommender" && s.state == "trained"
              ).length > 0
            ) {
              result += ", recommender model successfully";
            } else {
              result += ". recommender model Failed";
            }
            setMl_modelStatus("error");
          }
        } else {
          setMl_modelStatus("error");
          setMl_modelContent("Failed trained Models");
        }
      })
      .catch((err) => {
        setProcessContent("error");
        console.error("Error :", err);
      });
  };

  const refreshProjectDocuments = (isOpenAccordion = true) => {
    setMl_modelStatus("loading");
    setMl_modelContent("training models....");
    setTokenize("loading");
    setTokenizeContent("Tokenizing...");
    getProjectDocuments(props.match.params.id)
      .then((res) => {
        setProjectArticles(res.data);
        console.log(res.data);
        let needToRefreshProjectDocuments = false;
        let tempAccList = accordionStatusList;

        for (let i = 0; i < res.data.length; i++) {
          if (
            tempAccList.length == 0 ||
            tempAccList.findIndex((a) => a.id == res.data[i].id) == -1
          ) {
            tempAccList.push({
              id: res.data[i].id,
              maxHeight: isOpenAccordion ? "1000px" : null,
            });
          }

          if (
            res.data[i].documentbibtexdata == null &&
            res.data[i].state != "parse_failed" &&
            res.data[i].state != "parsed"
          ) {
            //res.data[i].state=="waiting"||res.data[i].state=="parsing")
            needToRefreshProjectDocuments = true;
          }
        }

        setAccordionStatusList(tempAccList);

        if (needToRefreshProjectDocuments) {
          setMl_modelStatus("loading");
          setMl_modelContent("training models....");
          setTokenize("loading");
          setTokenizeContent("Tokenizing...");
          let intervalId = setTimeout(() => {
            refreshProjectDocuments();
          }, 20000);
          intervalRefreshProjectDocuments.push(intervalId);
          setIntervalRefreshProjectDocuments(intervalRefreshProjectDocuments);
        } else {
          const anyParseFailed = res.data.filter(
            (doc) => doc.state == "parse_failed"
          ).length;

          if (anyParseFailed == 0) {
            setMl_modelStatus("loading");
            setMl_modelContent("training models....");
            setTokenize("success");
            setTokenizeContent("Tokenized successfully");

            MlmodelStatus();
          } else {
            setTokenize("error");
            setTokenizeContent(
              "Failed Tokenized (" + anyParseFailed + " document Failed)"
            );
            setMl_modelStatus("error");
            setMl_modelContent("Failed trained Models");
          }
        }

        timerForCallResizeWindow();
      })
      .catch((err) => {
        setTokenize("error");
        setTokenizeContent("Faield Tokenized");
        setMl_modelStatus("error");
        setMl_modelContent("Failed trained Models");
        timerForCallResizeWindow();
        if (err.response?.data?.error) {
          toast.error(err.response.data.error);
        } else {
          toast.error("An error occurred while getting the document!");
          console.log(err, err.response);
        }
      });
  };

  const resetSelectedArticles = () => {
    setSelectedArticles([]);
  };
  const handleAddDocument_tags = (data) => {
    return AddDocument_tags(props.match.params.id, data);
  };
  const handleArticleSelect = (e) => {
    let list = [...e.target.files].filter((item) =>
      DocumentUploadFileAllowMimeType(item.type)
    );
    setSelectedArticles((selectedArticles) => [...selectedArticles, ...list]);
  };

  const handleDeleteSelectedArticle = (index) => {
    setSelectedArticles((article) =>
      article.filter((article, idx) => idx !== index)
    );
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.referencesWrapper}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <b>Add References (PDF, MS Word, PPT, Text, CSV, Bibtex)</b>
          <br />
          <br />
          {!uploading && (
            <>
              <Button
                id="select-aricles"
                name="select-aricles"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickArticleMenu}
                variant="contained"
                component="label"
                style={{ textTransform: "none", margin: 5 }}
              >
                Select Article(s) <ArrowDropDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                style={{ width: "100%" }}
                anchorEl={articleMenu}
                open={open}
                onClose={handleCloseArticleMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem style={{ width: "100%" }}>
                  <Button
                    variant="contained"
                    component="label"
                    style={{ textTransform: "none", margin: 5, width: "100% " }}
                  >
                    Browse
                    <input
                      type="file"
                      hidden
                      multiple
                      accept=".pdf, .csv, text/plain, .ppt, .pptx, .doc, .docx, .bib"
                      onChange={(e) => {
                        handleCloseArticleMenu();
                        handleArticleSelect(e);
                      }}
                    />
                  </Button>
                </MenuItem>
                <MenuItem style={{ width: "100%" }}>
                  <>
                    <TextField
                      name="urlFiles"
                      id="urlFiles"
                      label="http://"
                      size="small"
                      variant="outlined"
                      onChange={(e) => setUrlFile(e.target.value)}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleUploadProjectURLArticles();
                        handleCloseArticleMenu();
                      }}
                      style={{
                        margin: 5,
                        flex: 1,
                        backgroundColor: "green",
                        color: "white",
                      }}
                    >
                      Upload
                    </Button>
                  </>
                </MenuItem>
              </Menu>

              {selectedArticles.length !== 0 && (
                <Button
                  variant="contained"
                  onClick={() =>
                    handleUploadProjectArticles(
                      selectedArticles,
                      resetSelectedArticles
                    )
                  }
                  style={{
                    margin: 5,
                    flex: 1,
                    backgroundColor: "green",
                    color: "white",
                  }}
                >
                  Upload
                </Button>
              )}
            </>
          )}
          {uploading && (
            <>
              <img
                className={classesSpin.spinItemAlways}
                src="/loader.png"
                style={{ width: "60px" }}
              />
            </>
          )}
          <br />
          {selectedArticles.length !== 0 && (
            <>
              Selected files:
              <br />
            </>
          )}

          {selectedArticles.map((item, index) => {
            return (
              <Chip
                key={index}
                title={
                  item.name.length >= 100
                    ? "Upload failed! The file name exceed than 100 characters. Please rename the file."
                    : ""
                }
                icon={null}
                label={item.name}
                onDelete={() => handleDeleteSelectedArticle(index)}
                className={
                  item.name.length >= 100 ? classes.errorChip : classes.chip
                }
              />
            );
          })}
          {selectedArticles.length === 0 && "No Selected Articles..."}
        </div>
        <div style={{ width: "fit-content" }}>
          <AiModelIcon
            ErrorTitle="Failed"
            SuccessTitle="Complete successfully"
            step1Status={processStatus}
            step2Status={tokenize}
            step3Status={ml_modekStatus}
            step1Title={processContent}
            step2Title={tokenizeContent}
            step3Title={ml_modelContent}
          />
        </div>
      </div>

      <div className={Mng.ArticleMetaDataPanel}>
        <div className={Mng.SearchPanelWithTags}>
          <div className={Mng.SearchBoxPanel}>
            <b className={Mng.SearchTitle}>Articles Meta Data:</b>
            &nbsp;
            <TextField
              name="txtSearch"
              id="txtSearch"
              InputLabelProps={{ shrink: true }}
              size="small"
              variant="outlined"
              onChange={handleSearchQueryChange}
              type="text"
              value={searchQuery}
              placeholder="Search article titles..."
              className={Mng.SearchBox}
            />
          </div>
          <div className={Mng.TagsIconPanelBox}>
            <div className={Mng.showTagIconContainer}>
              <div
                onClick={handleOpenMenu}
                title="tag your document"
                className={Mng.labelIconButton}
              >
                <LabelIcon className={DocStyles.LabelIcon} />
                <ArrowDropDownIcon className={DocStyles.ArrowDropDownIcon} />
              </div>
              <Menu
                style={{
                  marginTop: "35px",
                  marginRight: "50px",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={handleOpenAddTagDialog}
                  className={DocStyles.MenuItem}
                  style={{ paddingLeft: "8px", paddingRight: "11px" }}
                >
                  <div className={DocStyles.MenuItemspan}>
                    Create new Tag &nbsp;
                    <AddIcon style={{ fontSize: "1.3em" }} />
                  </div>
                </MenuItem>
                <MenuItem
                  key={"searchTags"}
                  onClickCapture={stopImmediatePropagation}
                  onKeyDown={(e) => e.stopPropagation()}
                  disableGutters={true}
                  style={{ backgroundColor: "white" }}
                >
                  <div className={DocStyles.searchLabel}>
                    <TextField
                      className={classes.searchInput}
                      style={{ width: "100%" }}
                      variant="outlined"
                      size="small"
                      placeholder="Search Tags" // Use placeholder instead of label
                      value={searchLabelInput}
                      onChange={(e) => setSearchLabelInput(e.target.value)}
                    />
                  </div>
                </MenuItem>
                {tags
                  .filter((s) =>
                    s.name
                      .toLowerCase()
                      .includes(searchLabelInput.toLowerCase().trim())
                  )
                  .map((tag, index) => (
                    <MenuItem
                      key={index}
                      className={DocStyles.menuItem3}
                      // Call handleAddTagClick with the tag name and color
                    >
                      <div
                        onClick={() => {
                          if (selectArticles.length == 0) {
                            toast.info("No document selected to add tag to.");
                            return;
                          }

                          if (isSelectedTag(tag.id) != "true") {
                            handleAddTagClick(tag.name, tag.color, tag.id);
                          } else {
                            handleRemoveTagClick(tag.id);
                          }
                        }}
                        style={{
                          display: "flex",
                          padding: "5px 0px",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          size="small"
                          className={DocStyles.checkbox}
                          checked={isSelectedTag(tag.id) == "true"}
                          indeterminate={
                            isSelectedTag(tag.id) == "indeterminate"
                          }
                        />
                        <div
                          className={DocStyles.tagIcon}
                          style={{ backgroundColor: tag.color }}
                        ></div>
                        <div className={DocStyles.tag} title={tag.name}>
                          <div className="textOverflow">{tag.name}</div>
                        </div>
                      </div>
                      <div>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            setFilterSelectLabel(
                              filterSelectLabel.filter((s) => s != tag.id)
                            );
                            handleRemoveTagClick(tag.id, true);
                            handleDeleteTags(tag.id);
                          }}
                        >
                          <ClearIcon style={{ fontSize: "0.65em" }} />
                        </IconButton>
                      </div>
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          </div>
        </div>
        <div className={Mng.SearchLabelsBox}>
          {[...projectArticles].filter((s) => s.source == "discovery").length >
            0 && (
            <LabelItem
              key={"Discovery"}
              name={"Discovery"}
              isSelect={filterSelectLabel.includes(-4)}
              handleFilterSelectLabel={() => {
                handleFilterSelectLabel(-4);
              }}
              color="#574d8c"
              count={
                [...projectArticles].filter((s) => s.source == "discovery")
                  .length
              }
              icon={<PublicIcon style={{ width: "15px", height: "15px" }} />}
            />
          )}
          {[...projectArticles].filter((s) => s.source == "Bibtex").length >
            0 && (
            <LabelItem
              key={"Bibtex"}
              name={"Bibtex"}
              isSelect={filterSelectLabel.includes(-3)}
              handleFilterSelectLabel={() => {
                handleFilterSelectLabel(-3);
              }}
              color="#008000"
              count={
                [...projectArticles].filter((s) => s.source == "Bibtex").length
              }
              icon={<span style={{ fontWeight: "bold" }}>Bib</span>}
            />
          )}
          {[...projectArticles].filter((s) => s.source == "kb").length > 0 && (
            <LabelItem
              key={"Knowledge Base"}
              name={"Knowledge Base"}
              isSelect={filterSelectLabel.includes(-2)}
              handleFilterSelectLabel={() => {
                handleFilterSelectLabel(-2);
              }}
              color="#eb3449"
              count={
                [...projectArticles].filter((s) => s.source == "kb").length
              }
              icon={
                <CloudUploadIcon style={{ width: "16px", height: "16px" }} />
              }
            />
          )}
          {[...projectArticles].filter((s) => s.source == "url").length > 0 && (
            <LabelItem
              key={"URL"}
              name={"Url"}
              isSelect={filterSelectLabel.includes(-1)}
              handleFilterSelectLabel={() => {
                handleFilterSelectLabel(-1);
              }}
              color="#659795"
              count={
                [...projectArticles].filter((s) => s.source == "url").length
              }
              icon={<span style={{ fontWeight: "bold" }}>Http</span>}
            />
          )}
          {[...projectArticles].filter((s) => s.source == "Bibtex").length >
            0 && (
            <LabelItem
              key={"Custom"}
              name={"Custom"}
              isSelect={filterSelectLabel.includes(-5)}
              handleFilterSelectLabel={() => {
                handleFilterSelectLabel(-5);
              }}
              color="#73d673"
              count={
                [...projectArticles].filter((s) => s.source == "custom").length
              }
              icon={<span style={{ fontWeight: "bold" }}>Cu</span>}
            />
          )}

          {tags.map((tag, index) => (
            <LabelItem
              key={index}
              name={tag.name}
              isSelect={filterSelectLabel.includes(tag.id)}
              handleFilterSelectLabel={() => {
                handleFilterSelectLabel(tag.id);
              }}
              color={tag.color}
              count={
                [...projectArticles].filter(
                  (s) =>
                    s.document_tags != null &&
                    s.document_tags.length > 0 &&
                    s.document_tags.findIndex((t) => t.id == tag.id) >= 0
                ).length
              }
            />
          ))}
        </div>
        <div
          id="ArticlePanelBox"
          ref={articlePanelBoxRef}
          className={Mng.Editable_box}
        >
          {(searchQuery ? filteredArticles : projectArticles).length > 0 ? (
            (searchQuery ? filteredArticles : projectArticles)
              .filter((pr) => {
                return (
                  filterSelectLabel.length == 0 ||
                  (pr.document_tags &&
                    pr.document_tags.length > 0 &&
                    pr.document_tags.findIndex((t) =>
                      filterSelectLabel.includes(t.id)
                    ) >= 0) ||
                  (pr.source == "url" && filterSelectLabel.includes(-1)) ||
                  (pr.source == "kb" && filterSelectLabel.includes(-2)) ||
                  (pr.source == "Bibtex" && filterSelectLabel.includes(-3)) ||
                  (pr.source == "discovery" &&
                    filterSelectLabel.includes(-4)) ||
                  (pr.source == "custom" && filterSelectLabel.includes(-5))
                );
              })
              .map((article, index) =>
                article.documentbibtexdata != null ||
                article.state == "parse_failed" ||
                article.state == "parsed" ? (
                  <EditableArticalMetaData
                    customArticle={false}
                    onUpdateCustomArticle={(bibtexdata) => {}}
                    isDataChanged={(value) => {}}
                    headerMinHeight={minHeightArticleHeader}
                    handleProcessContent={setProcessStatus}
                    handleDeleteProjectArticle={handleDeleteProjectArticle}
                    handleDeleteTagMng={(tagId, doc_id) =>
                      handleDeleteTag(tagId, doc_id)
                    }
                    selectArticless={(doc_id) => {
                      addSelectArticleRows(doc_id);
                    }}
                    removeSelectArticles={(doc_id) => {
                      removeSelectArticleRows(doc_id);
                    }}
                    projectId={props.match.params.id}
                    key={article.id}
                    article={article}
                    status={article.state}
                    authors={article.documentbibtexdata?.authors_json}
                    issns={article.documentbibtexdata?.issns_json}
                    handleOnClickAccordion={handleOnClickAccordionElement}
                    refreshDocumentsAndFile={refreshDocumentsAndFile}
                    AccordionStatus={accordionStatusList.find(
                      (a) => a.id == article.id
                    )}
                  />
                ) : (
                  <div key={article.id} className={Mng.ArticlePanelBox_Defualt}>
                    <div className={Mng.ArticlePanelBoxHeaderBox}>
                      <FloatDeleteButton
                        onClick={() => {
                          handleDeleteProjectArticle(article.id);
                        }}
                      />
                      <h3
                        style={{ minHeight: minHeightArticleHeader }}
                        className={Mng.ArticlePanelBoxHeaderTitle}
                      >
                        <div
                          className={
                            classesAccordion.accordion +
                            " " +
                            (accordionStatusList.find((a) => a.id == article.id)
                              ?.maxHeight
                              ? classesAccordion.active
                              : "")
                          }
                          style={{ float: "left" }}
                          onClick={(e) =>
                            handleOnClickAccordionElement(e, article.id)
                          }
                        >
                          &nbsp;
                          <ExtentionIcon
                            FileName={article.file}
                            url={article.url}
                          />
                          &nbsp;{article.name}&nbsp;
                          {article.source == "discovery" && (
                            <LabelItem
                              name={"Discovery"}
                              color="#574d8c"
                              curserIsPointer={false}
                              hasCount={false}
                              icon={
                                <PublicIcon
                                  style={{ width: "15px", height: "15px" }}
                                />
                              }
                            />
                          )}
                          {article.source == "Bibtex" && (
                            <LabelItem
                              name={"Bibtex"}
                              color="#008000"
                              curserIsPointer={false}
                              hasCount={false}
                              icon={
                                <span style={{ fontWeight: "bold" }}>Bib</span>
                              }
                            />
                          )}
                          {article.source == "kb" && (
                            <LabelItem
                              name={"Knowledge Base"}
                              color="#eb3449"
                              curserIsPointer={false}
                              hasCount={false}
                              icon={
                                <CloudUploadIcon
                                  style={{ width: "16px", height: "16px" }}
                                />
                              }
                            />
                          )}
                          {article.source == "url" && (
                            <LabelItem
                              name={"Url"}
                              color="#659795"
                              icon={
                                <span style={{ fontWeight: "bold" }}>Http</span>
                              }
                              curserIsPointer={false}
                              hasCount={false}
                            />
                          )}
                          {article.source == "custom" && (
                            <LabelItem
                              name={"Custom"}
                              color="#73d673"
                              curserIsPointer={false}
                              hasCount={false}
                              icon={
                                <span style={{ fontWeight: "bold" }}>Cu</span>
                              }
                            />
                          )}
                        </div>
                      </h3>
                      <div
                        className={
                          classesSpin.divSpinCenter +
                          " " +
                          classesAccordion.panel
                        }
                        style={{
                          textAlign: "left",
                          maxHeight: accordionStatusList.find(
                            (a) => a.id == article.id
                          )?.maxHeight,
                        }}
                      >
                        <div
                          className={classesSpin.skeletonBox}
                          style={{ width: "20%", Height: "20px" }}
                        >
                          {" "}
                          &nbsp;
                        </div>
                        <div
                          className={classesSpin.skeletonBox}
                          style={{ width: "70%", Height: "20px" }}
                        >
                          {" "}
                          &nbsp;
                        </div>
                        <div
                          className={classesSpin.skeletonBox}
                          style={{ width: "30%", Height: "20px" }}
                        >
                          {" "}
                          &nbsp;
                        </div>
                        <div
                          className={classesSpin.skeletonBox}
                          style={{ width: "30%", Height: "20px" }}
                        >
                          {" "}
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
          ) : (
            <div style={{ textAlign: "center", margin: 10 }}>No documents</div>
          )}
        </div>
      </div>
      <AddTagDialog
        key={"Crate New Tag"}
        title="Create New Tag"
        open={isAddTagDialogOpen}
        onClose={handleCloseAddTagDialog}
        onCreateTag={handleCreateTag}
        onrefreshtag={callApiGetLabel}
        apiCallSetLabel={handleAddDocument_tags}
        tags={tags} // pass the tags state
        // onAddTag={handleAddTag} // pass the handleAddTag function
      />
    </div>
  );
}

export default withRouter(ManageReferencesPage);
