import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../Loader/skeleton.css";
import "../../../LiboBerryCss/liboBerry.css";
import { getParaphrase } from "../../../../Requests/Requests";

function Paraphrase(props) {
  const [paraphraseResult, setParaphraseResult] = useState();
  let intervalId = null;
  const typingParaphrase = (i, txt, speed, wordcount = 1) => {
    if (txt && i < txt?.split(" ")?.length) {
      intervalId = setTimeout(() => {
        try {
          let doc = document.getElementById("paraphraseDivValue");
          if (doc != null && doc.innerText != txt) {
            if (speed == 0) {
              doc.innerHTML = txt;
              clearTimeout(intervalId);
              intervalId = null;
            } else {
              let list = txt.split(" ");
              let newtxt = "";
              let countword = wordcount + i;
              countword = countword > list.length ? list.length : countword;
              for (; i < countword; i++) {
                if (i != 0) {
                  newtxt += list[i] == undefined ? "" : " " + list[i];
                } else {
                  newtxt += list[i] == undefined ? "" : list[i];
                }
              }
              doc.innerHTML += newtxt;
              if (i >= txt.split(" ").length) {
                typingParaphrase(0, txt, 0, wordcount);
              } else {
                typingParaphrase(i, txt, speed, wordcount);
              }
            }
          }
        } catch {}
      }, speed);
    } else {
      clearTimeout(intervalId);
      intervalId = null;
    }
  };
  const getParapherase = (regenerate = false) => {
    let param = {
      paragraph: props.content,
    };
    if (regenerate) {
      param.regenerate = true;
      param.regentext = paraphraseResult;
    }

    getParaphrase(props.ProjectId, param)
      .then((response) => {
        if (response.data.paraphrase) {
          props.onStatus("success");
          let txt = response.data.paraphrase?.trim();
          setParaphraseResult(txt);
          props.onResult(txt);
          typingParaphrase(0, txt, 100, 5);
        } else {
          let txt = response.data.error?.trim();
          props.onStatus("error");
          setParaphraseResult(txt);
          props.onResult(null);
        }
      })
      .catch((error) => {
        console.error("Failed to Paraphrase:", error);
        let txt = "Ops! Failed to Paraphrase!";
        props.onResult(null);
        props.onStatus("error");
        setParaphraseResult(txt);
      });
  };
  useEffect(() => {
    if (props.status == null) {
      props.onStatus("loading");
      getParapherase();
    }
    if (props.status == "regenerate") {
      props.onStatus("loading");
      getParapherase(true);
    }
  }, [props.status, props.content]);
  useEffect(() => {
    return () => {
      clearTimeout(intervalId);
    };
  });
  return (
    <>
      {props.status != null && props.status == "success" && (
        <div id="paraphraseDivValue"></div>
      )}
      {props.status != null && props.status == "error" && (
        <div
          style={{
            backgroundColor: "tomato",
            color: "white",
            textAlign: "center",
            padding: "6px",
            borderRadius: "5px",
          }}
        >
          {paraphraseResult}
        </div>
      )}
      {(props.status == null || props.status == "loading") && (
        <>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
          <div className={"skeletonBox"} style={{ width: "100%" }}>
            {" "}
            &nbsp;
          </div>
        </>
      )}
    </>
  );
}
export default Paraphrase;
Paraphrase.propTypes = {
  status: PropTypes.string,
  content: PropTypes.string,
  onStatus: PropTypes.func,
  onResult: PropTypes.func,
  ProjectId: PropTypes.number,
};
Paraphrase.defaultProps = {
  status: null,
  content: "",
  onStatus: () => {},
  onResult: () => {},
  ProjectId: 0,
};
