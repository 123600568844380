import classes from './PulseEffect.module.css';
import PropTypes from 'prop-types';

const PulseEffect = props => {
    const {x, y, visible} = props;
    if (visible)
        return (
            <div className={classes.wrapper} style={{top: y, left: x, '--color': 'rgb(255, 0, 68)'}}>
                <div className={classes.pulse} style={{ '--color': 'rgb(255, 0, 68)'}}>
                </div>
                <div className={classes.pulseHeart} style={{ '--color': 'rgb(255, 0, 68)'}}/>
            </div>
        )
    return ''
}

export default PulseEffect;

PulseEffect.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    visible: PropTypes.bool
}

PulseEffect.defaultProps = {
    x: 50,
    y: 50,
    visible: false
}