import {TextField, withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import classNames from './CustomTextField.module.css';
import CustomTextFieldStyles from "./CustomTextFieldStyles";

const styles = CustomTextFieldStyles;

const CustomTextField = props => {
    const styles = props.classes;
    const {dark, classes, ...otherProps} = props;

    return (
        <div className={dark ? styles.darkRoot : styles.lightRoot}>
            <TextField
                InputLabelProps={{ shrink: props.value?.length > 0 }} 
                className={
                    [
                        classNames.textField,
                        props.className,
                        (dark ? styles.darkTextField : styles.lightTextField),
                        ...(
                            !dark ? [styles.lightTextField] : []
                        )
                    ].join(' ')
                }
                {...otherProps}
            />
        </div>
    )
}

export default withStyles(styles)(CustomTextField);

CustomTextField.propTypes = {
    className: PropTypes.oneOf([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]),
    dark: PropTypes.bool
}

CustomTextField.defaultProps = {
    dark: true
}