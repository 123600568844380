const defaultForm = {
    referenceType: 'Vancouver',
    referenceInputType: 'Editor',
    publisherLocation: '',
    keywords: [],
    authors: [],
    sections: [],
    title: '',
    publisherName: '',
    publisherId: '',
    abbreviation: '',
    ppub: '',
    eppub: '',
    volume: '',
    issue: '',
    // epublish: '',
    firstPage: '',
    lastPage: '',
    copyrightsStatement: '',
    copyrightsDate: '',
    articleTitle: '',
    articleType: '',
    articleSubTitle: '',
    doi: '',
    abstract: '',
    acknowledgment: '',
    references: '',
    submissionDate: '',
    publishDate: ''

}

export default defaultForm;