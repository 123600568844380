import PropTypes from 'prop-types';
import classes from './EditableLabel.module.css'
import CustomTextField from "../CustomTextField/CustomTextField";

const EditableLabel = props => {
    const {value, onChange, editing, onEditStart, onEditEnd, ...otherProps} = props;

    return (
        <div {...otherProps} className={classes.wrapper}>
            {
                editing ? (
                    <CustomTextField
                        autoFocus
                        value={value}
                        name={'sectionTitle'}
                        onChange={onChange}
                        onBlur={onEditEnd}
                        variant="outlined"
                    />
                ) : (
                    <div
                        onClick={onEditStart}
                        className={classes.label}
                        title="Click to edit"
                    >
                        {value}
                    </div>
                )
            }
        </div>
    )
}

export default EditableLabel;

EditableLabel.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEditStart: PropTypes.func,
    onEditEnd: PropTypes.func,
}

EditableLabel.defaultProps = {
    value: null,
    onChange: () => {
    },
    onEditStart: () => {
    },
    onEditEnd: () => {
    }
}