import React, { useState, useEffect } from "react";

import { IconButton } from "@material-ui/core";
import classes from "./PopUpIconButton.module.css";
function PopUpIconButton(props) {
  const [showPopup, setShowPopup] = useState(props.showPopup);
  useEffect(() => {
    setShowPopup(props.showPopup);
  }, [props.showPopup]);
  return (
    <>
      {props.showbtnPopup && (
        <>
          <IconButton
            title={props.toolTip}
            onClick={() => {
              props.onClick();
              setShowPopup(true);
              props.onSetShowPopup(true);
            }}
            style={{
              position: "fixed",
              bottom: props.bBottom,
              right: props.bRight,
              left: props.bLeft,
              top: props.bTop,
              zIndex: "2",
            }}
          >
            {props.custombtn != null && <>{props.custombtn}</>}
            {props.custombtn == null && (
              <img src={props.imgUrl} style={{ width: props.bWidth }} />
            )}
          </IconButton>
        </>
      )}
      {showPopup && (
        <div
          className={classes.PopUpPanel}
          style={{
            bottom: props.pBottom,
            right: props.pRight,
            left: props.pLeft,
            top: props.pTop,
          }}
        >
          <div className={classes.PopUpHeaderPanel}>
            {props.title}&nbsp;
            <span
              className={classes.closePannel}
              onClick={() => {
                setShowPopup(false);
                props.onSetShowPopup(false);
              }}
            >
              &times;
            </span>
          </div>
          <div className={classes.PopUpContentPanel}>
            <div
              className={classes.boardslist + " " + classes.popUpScrollContent}
            >
              {props.value != null &&
              props.value != undefined &&
              props.value.length > 0
                ? props.value
                : props.customValue}
            </div>
            {props.footerValue}
          </div>
        </div>
      )}
    </>
  );
}
PopUpIconButton.defaultProps = {
  onClick: () => {},
  title: "",
  value: null,
  imgUrl: null,
  customValue: "No Data ...",
  pLeft: null,
  pRight: null,
  pBottom: null,
  pTop: null,
  bLeft: null,
  bRight: null,
  bTop: null,
  bBottom: null,
  bWidth: "40px",
  toolTip: "click me",
  showbtnPopup: true,
  showPopup: false,
  onSetShowPopup: () => {},
  footerValue: null,
  custombtn: null,
};
export default PopUpIconButton;
