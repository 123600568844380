import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Button,
  TextField,
  FormControl,
  Chip,
  makeStyles,
  Input,
} from "@material-ui/core";
import classesDiscoveryFilter from "./DiscoveryFilter.module.css";
import "../../../LiboBerryCss/liboBerry.css";
import { onlyUnique } from "../../../../Functions/liboFunction.js";
const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexWrap: "wrap",
    flexDirection: "row",
    border: "2px solid lightgray",
    padding: 4,
    borderRadius: "4px",
    "&> div.container": {
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "unset !important",
    },
    "& > div.container > span": {
      backgroundColor: "gray",
      padding: "1px 3px",
      borderRadius: "4px",
    },
  },
}));
function DiscoveryFilter(props) {
  const classes = useStyles();
  const [showFilter, SetShowFilter] = useState(props.isOpen);
  const [discoverFilter, setDiscoverFilter] = useState({
    content: props.content,
    title: props.title,
    year: props.year,
    autour: props.autour,
    doi: props.doi,
    keyWords: props.keyWords == null ? [] : props.keyWords,
  });

  const [curKeyWords, setCursetKeyWords] = useState("");

  const handleKeyUp = (e) => {
    if (e.keyCode == 13 && e.target.value.trim() != "") {
      setDiscoverFilter({
        ...discoverFilter,
        keyWords: [...discoverFilter.keyWords, e.target.value.trim()]
          .filter((s) => s.trim() != "")
          .filter(onlyUnique),
      });
      setCursetKeyWords("");
    }
  };

  const handleChange = (e) => {
    setCursetKeyWords(e.target.value);
  };

  const handleDelete = (item, index) => {
    let arr = [...discoverFilter.keyWords];
    arr.splice(index, 1);
    setDiscoverFilter({
      ...discoverFilter,
      keyWords: arr,
    });
  };
  const closePannelFilter = () => {
    props.onIsOpen(false);
    setTimeout(() => {
      SetShowFilter(false);
    }, 1000);
  };

  useEffect(() => {
    if (props.isOpen == true) {
      SetShowFilter(props.isOpen);
    } else {
      closePannelFilter();
    }
  }, [props.isOpen]);

  return (
    <>
      {showFilter == true && (
        <div
          id={"filterPanel"}
          key={"filterPanel"}
          onClick={(e) => {
            if (e.target.id == "filterPanel") {
              closePannelFilter();
            }
          }}
          className={classesDiscoveryFilter.baseFilterPannel}
        >
          <div
            className={
              props.isOpen
                ? classesDiscoveryFilter.filterAnimationOpen
                : classesDiscoveryFilter.filterAnimationClose
            }
          >
            <div className={classesDiscoveryFilter.headerFilterPanel}>
              Filter Discovery
              <IconButton
                className={classesDiscoveryFilter.closeFilterIcon}
                onClick={() => {
                  closePannelFilter();
                }}
              >
                &times;
              </IconButton>
            </div>
            <hr />
            <div
              className={
                "liboScroll" + " " + classesDiscoveryFilter.contentFilterPanel
              }
            >
              <div
                className={classesDiscoveryFilter.filterItemBox}
                style={{ paddingTop: "0px" }}
              >
                <TextField
                  id="txtFilterTitle"
                  label="Title"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  disabled={false}
                  defaultValue={discoverFilter.title}
                  onChange={(e) => {
                    setDiscoverFilter({
                      ...discoverFilter,
                      title: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              <div className={classesDiscoveryFilter.filterItemBox}>
                <TextField
                  id="txtFilterYear"
                  label="Year"
                  size="small"
                  variant="outlined"
                  style={{ width: "100%" }}
                  type={"number"}
                  disabled={false}
                  defaultValue={discoverFilter.year}
                  onChange={(e) => {
                    let year =
                      e.target.value == "" || e.target.value?.trim() == ""
                        ? new Date().getFullYear()
                        : Number(e.target.value.trim());
                    setDiscoverFilter({ ...discoverFilter, year: year });
                  }}
                />
              </div>
              <div className={classesDiscoveryFilter.filterItemBox}>
                <TextField
                  id="txtFilterAuthors "
                  label="Authors"
                  size="small"
                  variant="outlined"
                  style={{ width: "100%" }}
                  disabled={false}
                  defaultValue={discoverFilter.autour}
                  onChange={(e) => {
                    setDiscoverFilter({
                      ...discoverFilter,
                      autour: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              <div className={classesDiscoveryFilter.filterItemBox}>
                <TextField
                  id="txtFilterDOI"
                  label="DOI"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  disabled={false}
                  defaultValue={discoverFilter.doi}
                  onChange={(e) => {
                    setDiscoverFilter({
                      ...discoverFilter,
                      doi: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              <div className={classesDiscoveryFilter.filterItemBox}>
                <FormControl
                  classes={{ root: classes.formControlRoot }}
                  variant="outlined"
                >
                  <div className={"container"}>
                    {discoverFilter.keyWords.map((item, index) => (
                      <Chip
                        size="small"
                        onDelete={() => handleDelete(item, index)}
                        label={item}
                        style={{ backgroundColor: "#00800042" }}
                      />
                    ))}
                  </div>
                  <span
                    className={classesDiscoveryFilter.span_formControll_label}
                  >
                    {" "}
                    Keywords{" "}
                  </span>
                  <Input
                    style={{
                      width: "100%",
                      border: "0px none transparent !importent",
                    }}
                    className={classesDiscoveryFilter.removeInputborder}
                    value={curKeyWords}
                    onChange={handleChange}
                    onKeyDown={handleKeyUp}
                    placeholder={"Separate your keywords with enter key"}
                  />
                </FormControl>
              </div>
              <div className={classesDiscoveryFilter.filterItemBox}>
                <TextField
                  id="txtFilterContent"
                  label="Content"
                  variant="outlined"
                  style={{ width: "100%" }}
                  disabled={false}
                  defaultValue={discoverFilter.content}
                  multiline
                  size="small"
                  maxRows={6}
                  onChange={(e) => {
                    setDiscoverFilter({
                      ...discoverFilter,
                      content: e.target.value.trim(),
                    });
                  }}
                />
              </div>

              <div className={classesDiscoveryFilter.filterSearchBox}>
                <Button
                  onClick={() => {
                    let search = {
                      content: discoverFilter.content,
                      title: discoverFilter.title,
                      year: discoverFilter.year,
                      autour: discoverFilter.autour,
                      doi: discoverFilter.doi,
                      keyWords: discoverFilter.keyWords
                        .filter((s) => s.trim() != "")
                        .filter(onlyUnique),
                    };

                    if (curKeyWords.trim() != "") {
                      setDiscoverFilter({
                        ...discoverFilter,
                        keyWords: [
                          ...discoverFilter.keyWords,
                          curKeyWords.trim(),
                        ]
                          .filter((s) => s.trim() != "")
                          .filter(onlyUnique),
                      });
                      setCursetKeyWords("");

                      search.keyWords = [
                        ...discoverFilter.keyWords,
                        curKeyWords.trim(),
                      ]
                        .filter((s) => s.trim() != "")
                        .filter(onlyUnique);
                    }
                    props.onSearch(search);
                  }}
                  className={
                    "liboBtnColor " + classesDiscoveryFilter.btnFintFamily
                  }
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default DiscoveryFilter;

DiscoveryFilter.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
  year: PropTypes.number,
  autour: PropTypes.string,
  doi: PropTypes.string,
  onSearch: PropTypes.func,
  isOpen: PropTypes.bool,
  keyWords: PropTypes.string,
};

DiscoveryFilter.defaultProps = {
  content: "",
  title: "",
  year: new Date().getFullYear(),
  autour: "",
  doi: "",
  keyWords: [],
  onSearch: (result) => {},
  onIsOpen: (value) => {},
  isOpen: false,
};
