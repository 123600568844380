import React from "react";
import PropTypes from "prop-types";

export default function ShortcutIcon(props) {
  return (
    <>
      <svg
        fill={props.color}
        viewBox="0 0 24 24"
        width={props.width}
        style={{ ...props.style }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m6 2a4 4 0 0 1 4 4v2h4v-2a4 4 0 0 1 4-4 4 4 0 0 1 4 4 4 4 0 0 1 -4 4h-2v4h2a4 4 0 0 1 4 4 4 4 0 0 1 -4 4 4 4 0 0 1 -4-4v-2h-4v2a4 4 0 0 1 -4 4 4 4 0 0 1 -4-4 4 4 0 0 1 4-4h2v-4h-2a4 4 0 0 1 -4-4 4 4 0 0 1 4-4m10 16a2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0 -2-2h-2zm-2-8h-4v4h4zm-8 6a2 2 0 0 0 -2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2v-2zm2-10a2 2 0 0 0 -2-2 2 2 0 0 0 -2 2 2 2 0 0 0 2 2h2zm10 2a2 2 0 0 0 2-2 2 2 0 0 0 -2-2 2 2 0 0 0 -2 2v2z"
          fill={props.color}
        />
      </svg>
    </>
  );
}
ShortcutIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
};
ShortcutIcon.defaultProps = {
  width: "16px",
  color: "#000000",
};
