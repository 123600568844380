import React, { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import "../LiboBerryCss/liboBerry.css";
import classes from "../EditableArticalMetaData/EditableForm/JournalArticle.module.css";
import { toast } from "react-toastify";
function AuthorItem(props) {
  const [isEditAble, setIsEditAble] = useState(false);
  const [firstName, setFirstName] = useState(props.given);
  const [lastName, setLastName] = useState(props.family);
  const validationData = () => {
    let result = true;
    if (firstName == null || firstName.trim() == "") {
      toast.error("please Enter Author's First Name");
      result = false;
    }
    if (lastName == null || lastName.trim() == "") {
      toast.error("please Enter Author's Last Name");
      result = false;
    }
    return result;
  };
  return (
    <span>
      {props.given == null ||
      props.given?.trim() == "" ||
      props.family == null ||
      props.family?.trim() == "" ||
      isEditAble ? (
        <div className={classes.EditableAuthorsPanel}>
          <input
            title="Firs Name"
            size={Math.max(firstName?.length || 15, 15)}
            className={classes.textField}
            type="text"
            value={firstName}
            name="firstName"
            placeholder="First Name"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          &nbsp;
          <input
            title="Last Name"
            size={Math.max(lastName?.length || 15, 15)}
            className={classes.textField}
            type="text"
            value={lastName || ""}
            name="lastName"
            placeholder="Last Name"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <div className={classes.btnAuthorsPanel}>
            &nbsp;
            <span className={classes.btnAuthorsPanel} title="Discard">
              <CancelIcon
                onClick={() => {
                  setFirstName(props.given);
                  setLastName(props.family);
                  if (
                    props.given == null ||
                    props.given?.trim() == "" ||
                    props.family == null ||
                    props.family?.trim() == ""
                  ) {
                    props.onDelete(props.index);
                    setIsEditAble(false);
                  } else {
                    setIsEditAble(false);
                  }
                }}
                className={
                  "tomato_color" + " " + "cursor_pointer" + " " + "iconSize20"
                }
              />
            </span>
            &nbsp;
            <span className={classes.btnAuthorsPanel} title="Save">
              <SaveIcon
                onClick={() => {
                  if (validationData()) {
                    props.onChange(
                      firstName.trim(),
                      lastName.trim(),
                      props.index
                    );
                    setIsEditAble(false);
                  }
                }}
                className={
                  "green_color" + " " + "cursor_pointer" + " " + "iconSize20"
                }
              />
            </span>
            &nbsp;
            <span className={classes.btnAuthorsPanel} title="Remove">
              <DeleteForeverIcon
                onClick={() => {
                  props.onDelete(props.index);
                  setIsEditAble(false);
                }}
                className={
                  "libo_color" + " " + "cursor_pointer" + " " + "iconSize20"
                }
              />
            </span>
          </div>
          {props.Spreator}
        </div>
      ) : (
        <>
          {props.given} {props.family}{" "}
          <CreateIcon
            title="Edit"
            onClick={() => {
              setFirstName(props.given);
              setLastName(props.family);
              setIsEditAble(true);
            }}
            className={
              "green_color" + " " + "cursor_pointer" + " " + "iconSize16"
            }
          />
          {props.Spreator}
        </>
      )}
    </span>
  );
}

export default AuthorItem;

AuthorItem.defaultProps = {
  given: "",
  family: "",
  index: 0,
  onChange: (firstname, lastname, index) => {},
  onDelete: (index) => {},
  Spreator: "",
};
