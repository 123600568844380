import {Container, Grid} from '@material-ui/core';
import classes from './Footer.module.css';
import {Fade} from 'react-reveal';


const Footer = props => {
    return (
        <div className={classes.wrapper}>
            
            <Container>
                <Fade bottom>
                    <Grid container>
                        {props.children}
                    </Grid>
                </Fade>
            </Container>
        </div>
    )
}

export default Footer;