import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classess from "./headers.module.css";
import "../../../components/LiboBerryCss/liboBerry.css";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

function Headers(props) {
  const [headersValue, setHeadersValue] = useState([]);
  const panelRef = useRef(null);

  useEffect(() => {
    if (props.html) {
      let temp = document.createElement("div");
      temp.innerHTML = props.html;
      let tHeaders = temp.querySelectorAll("div[heading]");
      if (tHeaders == null || tHeaders.length == 0) {
        setHeadersValue([]);
        return;
      }
      let result = [];
      tHeaders.forEach((item) => {
        let attribute = item.getAttribute("heading");
        if (attribute != "div" || attribute != "") {
          result.push({
            title: attribute,
            type: attribute,
            text: item.innerText,
            id: item.id, // Store the id to scroll to
          });
        }
      });
      setHeadersValue(result);
    } else {
      setHeadersValue([]);
    }
  }, [props.html]);

  const handleHeaderClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      // Apply highlight effect
      element.classList.add(classess.highlight);
      setTimeout(() => {
        element.classList.remove(classess.highlight);
      }, 1000); // Remove highlight after 2 seconds
    }
  };

  const handleClickOutside = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      props.setOpen(false); // Close the panel if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        ref={panelRef} // Attach the ref to the panel
        className={props.open ? classess.panel_open : classess.panel_close}
      >
        <div>
          {/* <div className={classess.headers}>
            <IconButton
              onClick={() => {
                props.setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            Headers
          </div> */}
          <div className={classess.panel_content + " liboScroll"}>
            {headersValue.length > 0 &&
              headersValue.map((item) => (
                <div
                  key={item.id}
                  title={item.text}
                  type={item.type}
                  className={classess.item}
                  onClick={() => {
                    props.setOpen(false);
                    handleHeaderClick(item.id);
                  }}
                >
                  {/* <div className={classess.item_title}>{item.title}</div> */}

                  <div
                    className={classess.item_text}
                    style={{
                      color:
                        item.text != null && item.text.trim()
                          ? null
                          : "#a1a1a3",
                    }}
                  >
                    {item.text != null && item.text.trim() != ""
                      ? item.text.trim()
                      : "Undefined " + item.title.split("sub").join("sub ")}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

Headers.propTypes = {
  html: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

Headers.defaultProps = {
  html: "",
  open: false,
  setOpen: () => {},
};

export default Headers;
