import classes from './Introduction.module.css';
import {Button, Container, Grid, withStyles, useMediaQuery} from "@material-ui/core";
import {Fade} from 'react-reveal';
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import PropTypes from 'prop-types';
import websiteConfig from "../../Config/website.config";
import CustomButton from "../../components/CustomButton/CustomButton";

const styles = {
    continueBtn: {
        background: 'white',
        textTransform: 'none',
        borderRadius: 15,
        padding: '10px 20px',
        fontSize: 18,
        marginTop: 20,
        color: 'var(--light-red)',
        '&:hover': {
            background: '#FFFFFFAA',
        },
        width: '100%'
    }
}

const Introduction = props => {
    const styles = props.classes;
    const md = useMediaQuery('(max-width: 960px)');
    return (
        <div className={classes.wrapper}>
            <Container>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.intro}>
                        <Fade left>
                            <div className={classes.introAnimationWrapper}>
                                <h1 className={classes.title}>{websiteConfig.title}</h1>
                                <p className={classes.description}>{websiteConfig.description}</p>
                                <Grid container style={{justifyContent: md ? 'center' : 'flex-start'}}>
                                    <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                                        <CustomButton
                                            style={{margin: '20px 0 0 0'}}
                                            onClick={props.onContinue}
                                        >
                                            Start
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Fade right>
                            <img className={classes.img} alt="" src="./intro-img.png"/>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
            <img alt="" src={'./intro-effect.svg'} className={classes.introEffect}/>
        </div>
    )
}

export default withStyles(styles)(Introduction);

Introduction.propTypes = {
    onContinue: PropTypes.func
}

Introduction.defaultProps = {
    onContinue: () => {
    }
}