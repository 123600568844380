const cleanContentType = (content_type) => content_type?.toLowerCase().trim();

export const isCsv = (content_type) =>
  cleanContentType(content_type) == "text/csv";
export const isMsWord = (content_type) => {
  const cleanedContentType = cleanContentType(content_type);
  return (
    cleanedContentType == "application/msword" ||
    cleanedContentType ==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  );
};
export const isPowerPoint = (content_type) => {
  const cleanedContentType = cleanContentType(content_type);
  return (
    cleanedContentType == "application/vnd.ms-powerpoint" ||
    cleanedContentType ==
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  );
};
export const isExcel = (content_type) => {
  const cleanedContentType = cleanContentType(content_type);
  return (
    cleanedContentType == "application/vnd.ms-excel" ||
    cleanedContentType ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
};
export const isText = (content_type) =>
  cleanContentType(content_type) == "text/plain";
export const isBib = (content_type) => {
  return (
    content_type == "" ||
    content_type == "text/x-bibtex" ||
    content_type == "application/x-bibtex" ||
    cleanContentType(content_type) == "text/x-bibtex" ||
    cleanContentType(content_type) == "application/x-bibtex"
  );
};
export const isPdf = (content_type) =>
  cleanContentType(content_type) == "application/pdf";
export const ImageDocumentUploadFileAllowMimeType = (content_type) =>
  cleanContentType(content_type).startsWith("image/");
export const DocumentUploadFileAllowMimeType = (content_type) => {
  return (
    isCsv(content_type) ||
    isMsWord(content_type) ||
    isPowerPoint(content_type) ||
    isExcel(content_type) ||
    isText(content_type) ||
    isBib(content_type) ||
    isPdf(content_type)
  );
};
export const DocumentUploadFileAllowMimeTypeWithOutBibTex = (content_type) => {
  return (
    isCsv(content_type) ||
    isMsWord(content_type) ||
    isPowerPoint(content_type) ||
    isExcel(content_type) ||
    isText(content_type) ||
    isPdf(content_type)
  );
};
