import React from "react";
import PropTypes from "prop-types";

export default function ClearIcon(props) {
  return (
    <>
      <svg
        width={props.width}
        style={{ ...props.style }}
        fill={props.color}
        version="1.1"
        viewBox="0 0 1200 1200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m660 175-260 260 360 360 260-260c35-35 35-95 0-130l-230-230c-35-35-95-35-130 0z" />
        <path
          transform="scale(50)"
          d="m11.3 20 9.2-9.2c0.7-0.7 0.7-1.9 0-2.6l-4.6-4.6c-0.7-0.7-1.9-0.7-2.6 0l-9.7 9.7c-0.7 0.7-0.7 1.9 0 2.6l4.1 4.1h14.3"
          fill="none"
          stroke={props.color}
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2"
        />
      </svg>
    </>
  );
}
ClearIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
};
ClearIcon.defaultProps = {
  width: "15px",
  color: "#eb3449",
};
