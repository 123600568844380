import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Drawer as MUIDrawer,
    Button,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {drawerItemsList} from './drawerData';
import {withRouter} from 'react-router';
import styles from './Drawer.module.css';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    listItemText: {},
}));

const Drawer = (props) => {
    const {open, onClose} = props;
    const classes = useStyles();

    return (
        <MUIDrawer
            anchor={'left'}
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <div className={styles.logoWrapper}>
                <img className={styles.logo} src={'/logo.svg'} alt="Logo"/>
            </div>
            <Divider/>
            <List
                component="nav"
                aria-label="main mailbox folders"
                className={classes.list}
            >
                {drawerItemsList.map((item) => {
                    const Icon = item.icon;
                    return (
                        <ListItem
                            key={item.link + '::' + item.title}
                            button
                            onClick={() => {
                                onClose();
                                const redirectFunction = item.dontRedirect ? props.history.push : props.history.replace
                                redirectFunction(item.link);
                            }}
                        >
                            <ListItemIcon>
                                <Icon/>
                            </ListItemIcon>
                            <ListItemText
                                className={classes.listItemText}
                                primary={item.title}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </MUIDrawer>
    );
};

export default withRouter(Drawer);

Drawer.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

Drawer.defaultProps = {
    open: false,
    onClose: () => {
    },
};
