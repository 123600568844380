import React, { useState, useEffect } from "react";
import "./CreateNewProjectButton.css"; // Import the CSS file
import BookIcon from "@material-ui/icons/Book";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";

const CreateNewProjectButton = ({ handleClickOpen, isPopupOpen }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isPopupOpen) {
      setLoading(false);
    }
  }, [isPopupOpen]);

  const handleClick = () => {
    setLoading(true);
    handleClickOpen();
    setTimeout(() => {
      setLoading(false);
    }, 2000); // simulate async action
  };

  return (
    <Button className="cnpButton" onClick={handleClick} disabled={loading}>
      {loading ? (
        <CircularProgress size={24} className="cnpProgress" />
      ) : (
        <>
          <span className="cnpIconContainer">
            <BookIcon className="cnpIcon" /> Create
          </span>
          <span className="cnpHoverText">
            {" "}
            Create New Project <AddIcon className="cnpIconSmall" />
          </span>
        </>
      )}
    </Button>
  );
};

export default CreateNewProjectButton;
