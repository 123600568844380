import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Editor from './components/Editor/Editor';
import getToken from "./config/getToken";
import axios from "axios";
import urls from "./config/urls";
import { Route, BrowserRouter } from "react-router-dom";
import AuthPage from "./Pages/Auth/AuthPage";
import AppBar from "./components/AppBar/AppBar";
import Drawer from "./components/Drawer/Drawer";
import { useEffect, useState } from "react";
import DocumentsPage from "./Pages/DocumentsPage/DocumentsPage";
import DocumentPage from "./Pages/DocumentPage/DocumentPage";
import LiboEditor from "./components/MSKEditor/LiboEditor";
import PubBerry from "./Pages/PubBerry";
import ManageReferencesPage from "./Pages/ManageReferencesPage/ManageReferencesPage";
import SubscriptionPage from "./Pages/SubscriptionPage/SubscriptionPage";
import PubBerrySubscriptionPage from "./Pages/PubBerrySubscriptionPage/PubBerrySubscriptionPage";
import UserPanelRoutes from "./Routes/UserPanelRoutes";
// Documentation: http://127.0.0.1:8000/api/schema/swagger-ui/

const sendATestRequest = () => {
  axios
    .post(urls.testURL)
    .then((res) => alert(JSON.stringify(res.data)))
    .catch(console.log);
  const token = getToken();

  // fetch('http://localhost:8000/ai/test/', {
  //   method: 'POST',
  //   credentials: 'include',  // MSK JOOON => Dont forget to use "credentials: 'include'" in all requests
  //   headers: {
  //     'X-CSRFToken': token,  // MSK JOOON => Also you need to add "'X-CSRFToken': token" for POST requests
  //   }
  // })
  //   .then((response) => {
  //     return response.json();
  //   })
  //   .then((data) => {
  //     alert(JSON.stringify(data));
  //   });
};

function App() {
  // const [content, setContent] = useState('');
  //
  // useEffect(() => {
  //     console.log(content + '..')
  // }, [content])
  return (
    <div className="App">
      {/*Hello world*/}
      {/*<MSKEditor*/}
      {/*    key={"LiboEditor"}*/}
      {/*    onChange={html => setContent(html)}*/}
      {/*    defaultValue={'<div>sad </div>'}*/}
      {/*    suggestions={[*/}
      {/*        {*/}
      {/*            id: 'a941223d904f006c4d998598272d43d94',*/}
      {/*            content: '<div><span class="sug">hi world</span></div>'*/}
      {/*        },*/}
      {/*        {*/}
      {/*            id: 'a42acbf854b947f19db964dcd741f0c38',*/}
      {/*            content: '<div>ali <span class="sug">hi world</span> ali</div>'*/}
      {/*        }*/}
      {/*    ]}*/}
      {/*/>*/}
      <BrowserRouter>
        <UserPanelRoutes />

        {/*<Route*/}
        {/*    exact*/}
        {/*    path="/panel"*/}
        {/*    render={(props) => {*/}
        {/*        return <AuthPage {...props} />;*/}
        {/*    }}*/}
        {/*/>*/}
      </BrowserRouter>
      {/*<h1>hello</h1>*/}
      {/*<button onClick={sendATestRequest}>SEND A TEST REQUEST !</button>*/}
      {/*<Editor/>*/}
      <ToastContainer />
    </div>
  );
}

export default App;
