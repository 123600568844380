const checkStringField = (form, key) => {
    const field = form[key];
    return !(!field || !!!field.trim());
}
const checkArrayField = (form, key) => {
    const field = form[key];
    return !(!field || field === [] || field.length === 0);
}

const getStringIsNotValidError = field => {
    return `${field} is not valid!`
}

const getArrayCanNotBEEmptyError = field => {
    return `${field} can not be empty!`
}

const checkISSNppubANDISSNeppub = (ppub, eppub) => {
    if (!ppub || !!!ppub.trim()) {
        let newEppub = eppub.trim();
        if (newEppub.length !== 9) return false;
        const parts = newEppub.split('-');
        if (parts[0].length !== 4) return false;
        if (parts[0].isNaN) return false;
        if (parts[1].isNaN) return false;
    } else {
        let newPpub = ppub.trim();
        if (newPpub.length !== 9) return false;
        const parts = newPpub.split('-');
        if (parts[0].length !== 4) return false;
        if (parts[0].isNaN) return false;
        if (parts[1].isNaN) return false;
    }
    return true;
}

const checkSectionsBodies = (sections) => {
    for (const section of sections) {
        if (!!!section.body || section.body.trim().length === 0) {
            return false;
        }
    }
}
const checkSectionsTitles = (sections) => {
    for (const section of sections) {
        if (!!!section.title || section.title.trim().length === 0) {
            return false;
        }
    }
}

export const validateForm = (form) => {
    // referenceInputType: 'Editor',
    const fields = [
        {
            tagId: 'title',
            check: checkStringField(form, 'title'),
            errMsg: getStringIsNotValidError('Journal title')
        }, {
            tagId: 'publisherName',
            check: checkStringField(form, 'publisherName'),
            errMsg: getStringIsNotValidError('Publisher name')
        }, {
            tagId: 'publisherLocation',
            check: checkStringField(form, 'publisherLocation'),
            errMsg: getStringIsNotValidError('Publisher location')
        }, {
            tagId: 'publisherId',
            check: checkStringField(form, 'publisherId'),
            errMsg: getStringIsNotValidError('Publisher id')
        }, {
            tagId: 'abbreviation',
            check: checkStringField(form, 'abbreviation'),
            errMsg: getStringIsNotValidError('Abbreviation')
        }, {
            tagId: 'ppub',
            check: checkISSNppubANDISSNeppub(form.ppub, form.eppub),
            errMsg: getStringIsNotValidError('ISSN ppub')
        }, {
            tagId: 'eppub',
            check: checkISSNppubANDISSNeppub(form.ppub, form.eppub),
            errMsg: getStringIsNotValidError('ISSN eppub')
        }, {
            tagId: 'volume',
            check: checkStringField(form, 'volume'),
            errMsg: getStringIsNotValidError('Volume')
        }, {
            tagId: 'issue',
            check: checkStringField(form, 'issue'),
            errMsg: getStringIsNotValidError('Issue')
        }, {
            tagId: 'copyrightsStatement',
            check: checkStringField(form, 'copyrightsStatement'),
            errMsg: getStringIsNotValidError('Copyrights statement')
        }, {
            tagId: 'copyrightsDate',
            check: checkStringField(form, 'copyrightsDate'),
            errMsg: getStringIsNotValidError('Copyrights date')
        },{
            tagId: 'articleTitle',
            check: checkStringField(form, 'articleTitle'),
            errMsg: getStringIsNotValidError('Article title')
        }, {
            tagId: 'articleType',
            check: checkStringField(form, 'articleType'),
            errMsg: getStringIsNotValidError('Article Type')
        }, {
            tagId: 'doi',
            check: checkStringField(form, 'doi'),
            errMsg: getStringIsNotValidError('DOI')
        }, {
            tagId: 'firstPage',
            check: checkStringField(form, 'firstPage'),
            errMsg: getStringIsNotValidError('First page')
        }, {
            tagId: 'lastPage',
            check: checkStringField(form, 'lastPage'),
            errMsg: getStringIsNotValidError('Last page')
        }, {
            tagId: 'keywords',
            check: checkArrayField(form, 'keywords'),
            errMsg: getArrayCanNotBEEmptyError('Keywords')
        }, {
            tagId: 'abstract',
            check: checkStringField(form, 'abstract'),
            errMsg: getStringIsNotValidError('Abstract')
        }, {
            tagId: 'authors',
            check: checkArrayField(form, 'authors'),
            errMsg: getArrayCanNotBEEmptyError('Authors')
        },
        // {  It's not required so I commented it out!
        //     tagId: 'acknowledgment',
        //     check: checkStringField(form, 'acknowledgment'),
        //     errMsg: getStringIsNotValidError('Acknowledgment')
        // },
        { // check empty sections
            tagId: 'sectionTitle',
            check: checkArrayField(form, 'sections'),
            errMsg: getArrayCanNotBEEmptyError('Sections')
        }, { // check sections empty body
            tagId: 'sectionTitle',
            check: checkSectionsBodies(form.sections),
            errMsg: 'Sections can not have empty body.'
        }, { // check sections empty title
            tagId: 'sectionTitle',
            check: checkSectionsTitles(form.sections),
            errMsg: 'Sections can not have empty title.'
        }, {
            tagId: 'templatePdfFile',
            check: checkArrayField(form, 'references') || !!form.referenceFile,
            errMsg: 'Please upload references file or edit the references in the editor.'
        }, {
            tagId: 'templatePDFFile',
            check: !!form.templatePDFFile,
            errMsg: 'Please upload template pdf file.'
        }
    ];

    for (const field of fields) {
        if (field.check === false) {
            return {
                result: 'error',
                tagId: field.tagId,
                errorText: field.errMsg,
            };
        }
    }
    return {result: 'success'};
}