import {IconButton} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';

const CustomCheckbox = props => {
    const {checked, onChange, style, label, labelStyle} = props;
    const Icon = checked ? CheckBoxIcon : CheckBoxOutlineBlankIcon;
    return (
        <div style={{display: 'flex', alignItems: 'center', height: 55, margin: 5}}>
            <IconButton
                style={{color: 'white', ...style}}
                onClick={() => onChange(!checked)}
            >
                <Icon/>
            </IconButton>
            <p
                onClick={() => onChange(!checked)}
                style={{
                    userSelect: 'none',
                    color: "white",
                    cursor: 'pointer',
                    ...labelStyle
                }}
            >
                {label}
            </p>
        </div>
    )
}

export default CustomCheckbox;

CustomCheckbox.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    style: PropTypes.object,
    labelStyle: PropTypes.object,
    label: PropTypes.string,
}

CustomCheckbox.defaultProps = {
    onChange: () => {
    },
    checked: false,
    style: {},
    labelStyle: {},
    label: 'sad'
}