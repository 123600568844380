import {
    FastfoodRounded,
    HomeRounded,
    InsertDriveFileRounded,
    SettingsRounded,
    AccountCircleRounded,
    Description,
    Payment
} from '@material-ui/icons';

export const drawerItemsList = [
    { title: 'Home', link: '/panel/home', icon: HomeRounded },
    { title: 'Projects', link: '/panel/projects', icon: InsertDriveFileRounded, },
    { title: 'Settings', link: '/panel/settings', icon: SettingsRounded },
    { title: 'PubBerry', link: '/PubBerry', icon: Description, dontRedirect: true },
    { title: 'RefBerry', link: '/RefBerry', icon: Description , dontRedirect: true },
    { title: 'Subscription', link: '/panel/subscription', icon: Payment },
    { title: 'Profile', link: '/panel/profile', icon: AccountCircleRounded },
];
