import { useState, useEffect } from "react";
import classes from "./EditableArticalMetaData.module.css";
import { Button } from "@material-ui/core";
import EditableForm from "./EditableForm/EditableForm";
import { updateProjectDocument } from "../../Requests/Requests";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { DocumentTypes, SaveValidation } from "./EditableFunction.js";

const EditableArticalMetaData = ({ article, projectId, ...props }) => {
  const { id } = useParams();

  let documentType = article.documentbibtexdata
    ? article.documentbibtexdata.type
    : DocumentTypes.journalArticle;
  // const [docType, setDocType] = useState(documentType);

  const [changed, setChanged] = useState(false);
  const [data, setData] = useState({
    ...article.documentbibtexdata,
    type: documentType,
  }); // :: Dear Developer! PLZ READ the comment below!!!
  // one way data flow ==> this is just for saving the edited content;
  // I mean changing the data with setData, will not affect the displaying data
  // Because it's not passed to the component!
  // :: Although It's not a good idea, but there is not enough time to implement a better solution
  // .:: MVP :( ::.
  useEffect(() => {
    props.isDataChanged(changed);
  }, [changed]);
  useEffect(() => {
    props.onUpdateCustomArticle(data);
  }, [data]);

  useEffect(() => {
    if (props.customArticle) {
      setChanged(props.dataChangedValue);
    }
  }, []);
  const handleDocTypeChange = (e) => {
    // setChanged(docType => e.target.value !== docType);
    // setDocType(e.target.value);

    setData((data) => ({
      ...data,
      type: e.target.value,
    }));
    setChanged(true);
  };

  const handleSave = () => {
    let errorlist = SaveValidation(data);
    if (errorlist.length > 0) {
      for (let error of errorlist) {
        toast.error(error);
      }
    } else {
      if (
        data.type == DocumentTypes.webPage &&
        data.authors_json &&
        data.authors_json.length > 0
      ) {
        data.authors_json = data.authors_json.filter(
          (s) =>
            !(
              s.given == null ||
              s.family == null ||
              s.given.trim() == "" ||
              s.family.trim() == ""
            )
        );
        setData(data);
      }
      updateProjectDocument(id, article.id, { documentbibtexdata: data })
        .then((res) => {
          if (res.status >= 200 && res.status <= 299) {
            if (res.data && res.data.message) {
              toast.success(res.data.message);
            } else {
              toast.success("document updated successfully.");
            }
          }
          // console.log(res.data);
        })
        .catch((err) => {
          if (err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            console.log(err, err.response);
          }
        });
      setChanged(false);
    }
  };

  const saveNewDataInState = (data) => {
    setData(data);
    setChanged(true);
  };

  // useEffect(() => {
  //     // if (documentType !== prevDocType)
  //     setChanged(true)
  // }, [data.type]);

  const SaveButton = () => {
    return (
      <Button
        style={{
          margin: "5px 5px 0px 0px",
          position: "absolute",
          top: "-26px",
          left: "-4px",
        }}
        onClick={handleSave}
        className={classes.saveButton}
      >
        Save
      </Button>
    );
  };

  return (
    <div
      style={{ width: props.customArticle ? "calc(100% - 10px)" : null }}
      className={classes.wrapper}
    >
      <EditableForm
        customArticle={props.customArticle}
        onUpdateCustomArticle={props.onUpdateCustomArticle}
        headerMinHeight={props.headerMinHeight}
        handleDeleteProjectArticle={props.handleDeleteProjectArticle}
        handleDeleteTagMng={props.handleDeleteTagMng}
        selectArticless={props.selectArticless}
        removeSelectArticles={props.removeSelectArticles}
        projectId={projectId}
        isNull={article.documentbibtexdata === null}
        onDataChange={saveNewDataInState}
        status={props.status}
        authors={data.authors_json}
        issns={data.issns}
        renderSaveButton={() => (changed ? <SaveButton /> : null)}
        handleOnClickAccordion={props.handleOnClickAccordion}
        refreshDocumentsAndFile={props.refreshDocumentsAndFile}
        AccordionStatus={props.AccordionStatus}
        handleProcessContent={props.handleProcessContent}
        formObj={{
          ...article,
          documentbibtexdata: {
            // ...article.documentbibtexdata,
            // type: docType,
            ...data,
          },
        }}
        renderTypeChooser={() => (
          <>
            <label htmlFor="article-types">
              <b>Document type:</b> &nbsp;
            </label>
            <select
              name="articleType"
              id="article-types"
              onChange={handleDocTypeChange}
              value={data.type}
              className={classes.combobox}
            >
              {Object.keys(DocumentTypes).map((docType) => (
                <option key={docType} value={DocumentTypes[docType]}>
                  {DocumentTypes[docType]}
                </option>
              ))}
            </select>
          </>
        )}
      />
    </div>
  );
};

export default EditableArticalMetaData;

EditableArticalMetaData.defaultProps = {
  customArticle: false,
  onUpdateCustomArticle: (bibtexdata) => {},
  isDataChanged: (value) => {},
  dataChangedValue: false,
};
