import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {
  createProject,
  deleteProject,
  getProjects,
  getUserInfo,
} from '../../Requests/Requests';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  addButton: {
    width: 100,
    height: 100,
    borderRadius: 20,
    border: '2px solid #DDDDDD',
  },
  addButtonIcon: {
    color: '#555',
    width: 30,
    height: 'auto',
  },
  page: {
    padding: 20,
  },
  sectionTitle: {
    fontSize: 20,
    color: '#444',
    // marginBottom: 20
  },

}));

function PubBerrySubscriptionPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <h2 className={classes.sectionTitle}>Subscription</h2>
      PubBerry Sub
      {/* <Divider style={{ margin: '20px 0' }} /> */}
      {/* <Button className={classes.addButton}>
        <AddCircleOutlineRoundedIcon className={classes.addButtonIcon} />
      </Button> */}
      <br />
    </div>
  );
}

export default withRouter(PubBerrySubscriptionPage);

PubBerrySubscriptionPage.propTypes = {};
