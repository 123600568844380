import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import DashboardNav from './components/DashboardNav';

import axios from 'axios';
import { getUserInfo } from "../../Requests/Requests";

const useStyles = makeStyles((theme) => ({
  page: {
    padding: 25,
  },
  sectionTitle: {
    fontSize: 20,
    color: '#444',
    paddingLeft: '20px'
  },

}));

function DashboardPage(props) {
  const classes = useStyles();
  const [Greetingtitle, setGreetingtitle] = useState('');


  useEffect(() => {

    getUserInfo()
      .then(async (response) => {
        let userName = response.data.first_name + " " + response.data.last_name;
        userName = userName.trim() == "" ? response.data.email.split('@')[0] : userName.trim();
        setGreetingtitle('Hello ' + userName);
      })
      .catch((error) => {
        setGreetingtitle('Hello User');
      });

  }, []);

  return (
    <div className={classes.page}>

      <h2 className={classes.sectionTitle} >
        {Greetingtitle}.
      </h2>
      <DashboardNav />
    </div>
  );
}

export default withRouter(DashboardPage);

