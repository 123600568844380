export const feeddbackResults = [
  { key: 1, title: "Citation", color: null },
  { key: 2, title: "Plagiarism", color: null },
  { key: 3, title: "Knowledge Managment", color: null },
  { key: 4, title: "Upload Image", color: null },
  { key: 5, title: "Authors", color: null },
  { key: 6, title: "Export file", color: null },
  { key: 7, title: "LiboBerry AI Assistant", color: null },
  { key: 8, title: "Subscription", color: null },
  { key: 1000, title: "Others", color: null },
];
