import React from "react";
import { demoArticles } from "../../../Pages/ManageReferencesPage/demoArticles";
import { BookChapter } from "./BookChapter";
import { JournalArticle } from "./JournalArticle";
import { ProceedingsArticle } from "./ProceedingsArticle";
import { WebPage } from "./WebPage";

const DocumentTypes = {
  journalArticle: "journal-article",
  proceedingsArticle: "proceedings-article",
  bookChapter: "book-chapter",
  webPage: "web-page",
};

const UnSupportedForm = ({ renderTypeChooser }) => {
  return (
    <>
      {renderTypeChooser()}
      <div
        style={{
          border: "2px dashed red",
          color: "red",
          borderRadius: 5,
          padding: 5,
        }}
      >
        UnSupported Form Type!
      </div>
    </>
  );
};

const defaultJournalArticleBibtexData = {
  // "type": "journal-article",
  title: "",
  issue: "",
  doi: "",
  page: "",
  volume: "",
  authors_json: [],
  issns_json: [],
  year: "",
  accessed: null,
  published_at: null,
  publisher: "",
  url: "",
};

export default function EditableForm({
  formObj,
  projectId,
  renderTypeChooser,
  onDataChange,
  authors,
  status,
  issns,
  isNull,
  handleOnClickAccordion,
  refreshDocumentsAndFile,
  AccordionStatus,
  ...props
}) {
  let passFormObj = formObj;

  if (isNull) {
    passFormObj = {
      ...formObj,
      documentbibtexdata: {
        ...defaultJournalArticleBibtexData,
        type: formObj.documentbibtexdata.type, // default type is 'journal-article'
      },
    };
  }
  // if (passFormObj.documentbibtexdata.author)
  // if (!formObj.documentbibtexdata || !formObj.documentbibtexdata.type) return null;
  let SpecialForm;
  switch (formObj.documentbibtexdata.type) {
    case DocumentTypes.journalArticle:
      SpecialForm = JournalArticle;
      break;
    case DocumentTypes.proceedingsArticle:
      SpecialForm = ProceedingsArticle;
      break;
    case DocumentTypes.bookChapter:
      SpecialForm = BookChapter;
      break;
    case DocumentTypes.webPage:
      SpecialForm = WebPage;
      break;
    default:
      SpecialForm = UnSupportedForm;
  }

  return (
    <SpecialForm
      customArticle={props.customArticle}
      onUpdateCustomArticle={props.onUpdateCustomArticle}
      headerMinHeight={props.headerMinHeight}
      handleDeleteTagMng={props.handleDeleteTagMng}
      selectArticless={props.selectArticless}
      removeSelectArticles={props.removeSelectArticles}
      hasDocumentBibTexData={
        !isNull || status == "parse_failed" || status == "parsed"
      }
      projectId={projectId}
      authors_json={authors}
      issns_json={issns}
      status={status}
      refreshDocumentsAndFile={refreshDocumentsAndFile}
      handleOnClickAccordion={handleOnClickAccordion}
      AccordionStatus={AccordionStatus}
      {...{ formObj: passFormObj, renderTypeChooser }}
      onDataChange={onDataChange}
      handleDeleteProjectArticle={props.handleDeleteProjectArticle}
      handleProcessContent={props.handleProcessContent}
      {...props}
    />
  );
}

EditableForm.defaultProps = {
  formObj: { documentbibtexdata: {} },
  authors: {},
  issns: {},
  renderTypeChooser: () => {},
  onDataChange: () => {},
  handleOnClickAccordion: (e, doc_id) => {},
  AccordionStatus: null,
  projectId: 0,
  refreshDocumentsAndFile: () => {},
  selectArticles: (doc_id) => {},
  removeSelectArticles: (doc_id) => {},
  handleDeleteProjectArticle: (id) => {},
  headerMinHeight: null,
  customArticle: false,
  onUpdateCustomArticle: (bibtexdata) => {},
};
