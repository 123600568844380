import React, { useEffect, useState } from 'react';
import { getProjectMLModels, getMLModel, updateMLModel, deleteMLModel } from '../../Requests/Requests';
import MLCard from './components/mlmodel/MLCard';
import useData from './hooks/useData';
import { toast } from "react-toastify";

const SettingsPage = ({ projectId }) => {
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { data: user, projects } = useData();
    const [projectTitleMap, setProjectTitleMap] = useState({});
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [message, setMessage] = useState(null);



    useEffect(() => {
        if (projects && projects.length > 0) {
            // Generate project title map
            const map = projects.reduce((acc, project) => {
                acc[project.id] = project.title;
                return acc;
            }, {});
            setProjectTitleMap(map);

            // Fetch ML models for each project
            Promise.all(projects.map(project => getProjectMLModels(project.id)))
                .then(responses => {
                    const allModels = responses.flatMap(response => response.data);
                    setModels(allModels);
                })
                .catch(error => {
                    console.error('Error fetching ML models:', error);
                    // setError(error.message || 'Error fetching ML models');
                    toast.error(error.message || 'Error fetching ML models');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            // If there are no projects, don't try to load models
            setLoading(false);
            // setMessage('No project found');
        }
    }, [projects]);


    const handleUpdate = (projectId, modelId, updatedModelData) => {
        console.log(updatedModelData);
        // Call the updateMLModel function with the projectId, modelId, and the updated model data
        updateMLModel(projectId, modelId, updatedModelData)
            .then(response => {
                // If the update is successful, update the state of models
                setModels(models.map(model => {
                    // Check if this is the model that was updated
                    if (model.id === modelId) {
                        // If so, create a new object that combines the old model data
                        // with the updated model data from the server response
                        toast.success("Model updated successfully");
                        return { ...model, ...response.data };

                    } else {
                        // If this is not the updated model, return the original model
                        return model;
                    }
                }));
            })
            .catch(error => {
                // If there's an error during the update, log it to the console and set the error state
                console.error('Error updating ML model:', error);
                toast.error('Error updating ML model');
            });
    };

    const handleDelete = (projectId, modelId) => {
        deleteMLModel(projectId, modelId)
            .then(() => {
                // Remove the model from the state
                setModels(models.filter(model => model.id !== modelId));
                toast.success("Model deleted successfully");
            })
            .catch(error => {
                console.error('Error deleting ML model:', error);
                toast.error('Error deleting ML model');
            });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="settings-page">
            {/* <div className="nav-menu">
                <h2>Navigation Menu</h2>
                <ul>
                    <li>Account</li>
                    <li>Projects</li>
                    <li>Models</li>
                    <li>Billing</li>
                </ul>
            </div> */}

            <div className="content">
                <h2>Machine Learning Models</h2>
                {models.map((model) => {
                    const modelProjectTitle = projectTitleMap && projectTitleMap[model.project] ? projectTitleMap[model.project] : 'Untitled Document';

                    return (
                        <MLCard
                            model={model}
                            handleUpdate={handleUpdate}
                            handleDelete={() => handleDelete(model.project, model.id)}
                            projectId={model.project}
                            projectName={modelProjectTitle}
                            user={user}
                            message={message}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SettingsPage;
