import React from "react";
import PropTypes from "prop-types";
import classesFloatSuggestion from "../floatSuggestion.module.css";
import classesTab from "./TabItem.module.css";

function TabItem(props) {
  return (
    <div
      onClick={props.onClick}
      className={
        classesFloatSuggestion.float_suggestion_tab +
        " " +
        (props.type == "Left"
          ? classesTab.tabItem_Left
          : props.type == "Right"
          ? classesTab.tabItem_Right
          : props.type == "MidWithOutLeftBorder"
          ? classesTab.tabItem_MidWithOutLeftBorder
          : classesTab.tabItem_Mid) +
        " " +
        (props.active ? classesFloatSuggestion.active_float_suggestion_tab : "")
      }
    >
      {props.title}
    </div>
  );
}

export default TabItem;
TabItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  type: PropTypes.string,
};
TabItem.defaultProps = {
  title: "",
  active: false,
  onClick: () => {},
  type: "Mid",
};
