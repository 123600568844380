import PropTypes from 'prop-types';
import classes from './Title.module.css';

const Title = props => {
    const {dark, style, containerStyle, text} = props;
    return (
        <div className={classes.wrapper} style={containerStyle}>
            <h2 className={[classes.text, ...(dark ? [classes.dark] : [])].join(' ')} style={style}>{text}</h2>
        </div>
    )
}

export default Title;

Title.propTypes = {
    dark: PropTypes.bool,
    containerStyle: PropTypes.object,
    style: PropTypes.object,
    text: PropTypes.string
}

Title.defaultProps = {
    dark: true,
    containerStyle: {},
    style: {},
    text: ''
}