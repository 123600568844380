export const getDataToSubmit = (form) => {
    const data = new FormData();
    data.append('title', form.title);
    data.append('publishername', form.publisherName);
    data.append('location', form.publisherLocation);
    data.append('publisher_id', form.publisherId);
    data.append('issn_ppub', form.ppub);
    data.append('issn_epub', form.eppub);
    data.append('doi', form.doi);
    data.append('articleType', form.articleType);
    data.append('article_title', form.articleTitle);
    data.append('article_subtitle', form.articleSubTitle);
    data.append('abstract', form.abstract);
    data.append('keywords', JSON.stringify(form.keywords));
    data.append('authors', JSON.stringify(form.authors.map(author => ({
        "surname": author.surname,
        "givenname": author.givenName,
        "email": author.email,
        "corresponder": author.isCorresponding,
        "affiliates": author.affiliate
    }))));
    data.append('first_page', form.firstPage);
    data.append('last_page', form.lastPage);
    data.append('copyrights_statement', form.copyrightsStatement);
    data.append('copyrights_date', form.copyrightsDate);
    data.append('acknowledgment', form.acknowledgment);
    data.append('ref_style', form.referenceType);
    data.append('sections', JSON.stringify(form.sections));
    data.append('references', form.references);
    data.append('references_file', form.referenceFile);
    data.append('template_pdf', form.templatePDFFile);
    data.append('abbreviation', form.abbreviation);
    data.append('volume', form.volume);
    data.append('issue', form.issue);
    // data.append('e_publish', form.epublish);
    data.append('submission_date', form.submissionDate);
    data.append('publish_date', form.publishDate);
    return data;
}
