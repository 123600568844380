import PropTypes from 'prop-types';
import classes from './Tag.module.css'
import {IconButton} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

const Tag = props => {
  const {value, onDelete, ...otherProps} = props;

  return (
    <div {...otherProps} className={classes.Tag}>
      {value}
      <IconButton style={{color: 'var(--light-red)', marginRight: -15}} onClick={() => props.onDelete(value)}>
        <CancelIcon />
      </IconButton>
    </div>
  )
}

export default Tag;

Tag.propTypes = {
  value: PropTypes.string,
  onDelete: PropTypes.func,
}

Tag.defaultProps = {
  value: null,
  onDelete: () => {},
}