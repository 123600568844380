import { useState } from "react";
import { Route } from "react-router";
import "../App.css";
import AppBar from "../components/AppBar/AppBar";
import RefBerryPage from "../Pages/RefBerry/RefBerryPage";
import Drawer from "../components/Drawer/Drawer";
import AuthPage from "../Pages/Auth/AuthPage";
import DocumentPage from "../Pages/DocumentPage/DocumentPage";
import DocumentsPage from "../Pages/DocumentsPage/DocumentsPage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
import SettingsPage from "../Pages/SettingsPage/SettingsPage";
import ManageReferencesPage from "../Pages/ManageReferencesPage/ManageReferencesPage";
import PubBerry from "../Pages/PubBerry";
import PubBerrySubscriptionPage from "../Pages/PubBerrySubscriptionPage/PubBerrySubscriptionPage";
import SubscriptionPage from "../Pages/SubscriptionPage/SubscriptionPage";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import ResetPasswordPage from "../Pages/Auth/ResetPasswordPage";
import ConfirmShare from "../Pages/Auth/ConfirmShare";

const routesWithBackButton = [
  "/panel/projects/:id",
  "/panel/projects/:id/manageReferences",
  "/panel/subscription/liboberry",
  "/panel/subscription/pubberry",
  "/panel/subscription/refberry",
];

const routesWithDrawer = [
  "/panel",
  "/panel/projects",
  "/panel/settings",
  "/panel/profile",
  "/panel/home",
  "/RefBerry",
  "/PubBerry",
  "/panel/subscription",
];

const routesWithDrawerTitle = [
  { path: "/panel", title: "Home", checkWithStartWith: false },
  { path: "/panel/home", title: "Home", checkWithStartWith: false },
  { path: "/panel/projects", title: "Projects", checkWithStartWith: true },
  { path: "/panel/settings", title: "Settings", checkWithStartWith: false },
  { path: "/panel/profile", title: "Profile", checkWithStartWith: false },
  { path: "/RefBerry", title: "RefBerry", checkWithStartWith: false },
  { path: "/PubBerry", title: "PubBerry", checkWithStartWith: false },
  {
    path: "/panel/subscription",
    title: "Subscription",
    checkWithStartWith: true,
  },
];

const routes = [
  // by default => exact: true
  { path: "/", render: (props) => <AuthPage {...props} /> },
  {
    path: "/auth/reset_password/:id",
    render: (props) => <ResetPasswordPage {...props} />,
  },
  {
    path: "/editor/project/:id/share/:token",
    render: (props) => <ConfirmShare {...props} />,
  },
  { path: "/panel", render: (props) => <DashboardPage {...props} /> },
  { path: "/panel/home", render: (props) => <DashboardPage {...props} /> },
  { path: "/panel/projects", render: (props) => <DocumentsPage {...props} /> },
  { path: "/panel/settings", render: (props) => <SettingsPage {...props} /> },
  {
    path: "/panel/projects/:id",
    render: (props) => <DocumentPage {...props} />,
  },
  {
    path: "/panel/projects/:id/manageReferences",
    render: (props) => <ManageReferencesPage {...props} />,
  },
  { path: "/PubBerry", render: (props) => <PubBerry {...props} /> },
  {
    path: "/RefBerry",
    render: (props) => <RefBerryPage {...props} />,
    title: "RefBerry",
  },
  {
    path: "/panel/subscription",
    render: (props) => <SubscriptionPage {...props} />,
  },
  {
    path: "/panel/subscription/pubberry",
    render: (props) => <PubBerrySubscriptionPage {...props} />,
  },
  { path: "/panel/profile", render: (props) => <ProfilePage {...props} /> },
];

export default function UserPanelRoutes(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Route
        exact
        path={routesWithDrawer}
        render={(props) => {
          return (
            <>
              <AppBar
                title={routesWithDrawerTitle}
                pathname={props.location.pathname}
                {...props}
                onHamburgurClick={() => setOpen(true)}
                toolbar={{ hamburger: true }}
              />
              <Drawer {...props} open={open} onClose={() => setOpen(false)} />
            </>
          );
        }}
      />
      <Route
        exact
        path={routesWithBackButton}
        render={(props) => {
          return (
            <AppBar
              title={routesWithDrawerTitle}
              pathname={props.location.pathname}
              {...props}
              onHamburgurClick={() => setOpen(true)}
              toolbar={{ back: true }}
            />
          );
        }}
      />
      <div className={"panel"}>
        {routes.map((route) => (
          <Route key={route.path} exact {...route} />
        ))}
      </div>
    </>
  );
}
