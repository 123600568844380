import { useState, useEffect } from "react";
import AuthorItem from "./AuthorItem.js";
import AddIcon from "@material-ui/icons/Add";
import classes from "./EditableAuthorList.module.css";
import "../LiboBerryCss/liboBerry.css";

function EditableAuthorList({ authors, setAuthors, setChanging, ...props }) {
  const [tempAuthors, setTempAuthors] = useState([]);

  useEffect(() => {
    let temp = [];
    if (Array.isArray(authors)) {
      for (let item of authors) {
        temp.push({
          given: item.given,
          family: item.family,
          id: Math.random(1000000),
        });
      }
    }
    setTempAuthors(temp);
  }, []);

  const handleDeleteAuthor = (index) => {
    let newAuthers = authors.filter((author, idx) => index != idx);
    let tempnewAuthers = tempAuthors.filter((author, idx) => index != idx);
    setAuthors([...newAuthers]);
    setTempAuthors([...tempnewAuthers]);

    setChanging(true);
  };

  const handleEditAuthor = (index, newAuthor) => {
    authors[index] = newAuthor;
    tempAuthors[index].given = newAuthor.given;
    tempAuthors[index].family = newAuthor.family;
    setAuthors([...authors]);
    setTempAuthors([...tempAuthors]);
    setChanging(true);
  };

  const handleAdd = () => {
    setAuthors((authors) => [
      ...authors,
      {
        given: "",
        family: "",
      },
    ]);
    setTempAuthors((authors) => [
      ...tempAuthors,
      {
        given: "",
        family: "",
        id: Math.random(1000000),
      },
    ]);
    setChanging(true);
  };
  return (
    <>
      <b className={classes.AuthorsTitle}>
        Authors:&nbsp;
        <div
          title="Add Author"
          onClick={() => {
            handleAdd();
          }}
          className={classes.AddAuthorBox}
        >
          <AddIcon className={"iconSize16"} style={{ color: "#ffffff" }} />
        </div>
      </b>{" "}
      <table>
        <tbody>
          <tr>
            <td>
              {tempAuthors?.length > 0 &&
                tempAuthors?.map((item, index) => (
                  <>
                    <AuthorItem
                      key={"Key" + item.id + "AuthorItem"}
                      index={index}
                      given={item.given}
                      family={item.family}
                      Spreator={authors.length != index + 1 ? " ," : ""}
                      onDelete={(index) => {
                        handleDeleteAuthor(index);
                      }}
                      onChange={(first_name, last_name, index) => {
                        handleEditAuthor(index, {
                          given: first_name,
                          family: last_name,
                        });
                      }}
                    />
                  </>
                ))}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default EditableAuthorList;

EditableAuthorList.defaultProps = {
  authors: [],
  setAuthors: () => {},
  setChanging: () => {},
};
