import classes from './NextButton.module.css';
import {IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const NextButton = props => {
    const {dark, onClick, refId, ...otherProps} = props;
    return (
        <IconButton
            onClick={() => onClick(refId)}
            style={{
                color: dark ? 'white' : 'var(--light-red)',
                backgroundColor: dark ? 'var(--light-red)' : 'white'
            }}
            {...otherProps}
        >
            <ExpandMoreIcon />
        </IconButton>
    )
}

export default NextButton;

NextButton.propTypes = {
    onClick: PropTypes.func,
    dark: PropTypes.bool,
    refId: PropTypes.string
}

NextButton.defaultProps = {
    onClick: () => {}
}