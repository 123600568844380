import React, { useEffect, useState } from "react";
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes, { string } from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import "./FullScreenEditor.css";
// docs url: https://jpuri.github.io/react-draft-wysiwyg/#/docs
// Added in index.js --> import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import MSKEditor from "../../../PubBerry/components/MSKEditor/MSKEditor";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "var(--light-red)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenEditor = (props) => {
  const {
    data,
    onChange,
    onClose,
    open,
    RefrenceHandeler,
    displayBtnRefrence,
    ToolbarDisplay,
    validRefrenceCloseHandeler,
    spinValidateRefrence,
    name
  } = props;
  const [html, setHtml] = useState("");

  const classes = useStyles();

  useEffect(() => {
    setHtml(data);
  }, []);

  const handleClose = () => {
    // onClose(data);
    // console.log(data)
    onClose(html);

    validRefrenceCloseHandeler(null);
    document.body.style.overflow = "auto";
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            RefBerry
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ height: "100%", overflow: "hidden" }}>
        <MSKEditor
          width={props.width}
          displayBtnRefrence={displayBtnRefrence}
          RefrenceHandeler={RefrenceHandeler}
          defaultValue={data}
          spinValidateRefrence={spinValidateRefrence}
          name={props.name}
          ToolbarDisplay={ToolbarDisplay}
          onChange={(html) => {
            setHtml(html);
            //onChange(html);Do NOT uncomment otherwise user will lose cursor in every type !!!
          }}
        />
      </div>
    </Dialog>
  );
};

export default FullScreenEditor;

FullScreenEditor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  spinValidateRefrence: PropTypes.bool,
  name: PropTypes.string
};

FullScreenEditor.defaultProps = {
  data: "",
  onChange: (data) => { },
  onClose: () => { },
  width: "100%",
  open: false,
  spinValidateRefrence: false,
  RefrenceHandeler: () => { },
  validRefrenceCloseHandeler: () => { },
  displayBtnRefrence: "none",
  ToolbarDisplay: "",
  name: ''
};
