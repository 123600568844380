/**
 * credit_package_id,
 * payment_nethod_nonce
 */
export const pubBerryPlans = [
    { id: 0, title: '50 ITEMS', price: 50 },
    { id: 1, title: '100 ITEMS', price: 90 },
    { id: 2, title: '150 ITEMS', price: 130 },
    { id: 3, title: '200 ITEMS', price: 160 },
]

export const refBerryPlans = [
    { id: 0, title: '50 ITEMS', price: 50 },
    { id: 1, title: '100 ITEMS', price: 90 },
    { id: 2, title: '150 ITEMS', price: 130 },
    { id: 3, title: '200 ITEMS', price: 160 },
]

/**
 * liboBerrySubscribe:: {
 * plan_id, payment_nethod_nonce
 * }
 */

export const liboBerryPlans = [
    {
        id: 0,
        price: 200,
        title: 'MEMBERSHIP FOR 1 MONTH / 100 MB',
        storage_limit: 100, // MB
        duration_in_days: 30 // days
    },
    {
        id: 1,
        price: 350,
        title: 'MEMBERSHIP FOR 3 MONTHS / 200 MB',
        storage_limit: 200, // MB
        duration_in_days: 90 // days
    },
    {
        id: 2,
        price: 500,
        title: 'MEMBERSHIP FOR 6 MONTHS / 512 MB',
        storage_limit: 512, // MB
        duration_in_days: 180 // days
    },
    {
        id: 3,
        price: 700,
        title: 'MEMBERSHIP FOR 1 YEAR / 1024 MB',
        storage_limit: 1024, // MB
        duration_in_days: 365 // days
    }
]

export const demoUser = {
    // ...
    liboberry_subscription: {
        start_date: new Date().getTime(), // timestamp,
        plan: {
            id: 1,
            price: 25,
            storage_limit: 100, // MB
            duration_in_days: 65 // days
        }
    },
    remaining_pubberry_credits: 5, // number,
    remaining_refberry_credits: 5, // number,
    paypal_client_token: '<TOKEN>'
}

// before payment => paypal_client_token

// payment responses::


/**

success: 200: transaction_id
fail: 406: error_message, error_code, error_text




 */
