import React, { useEffect, useState } from "react";

import { Paper } from "@material-ui/core";
import classes from "./index.module.css";
import { withRouter } from "react-router";
import ErrorIcon from "@material-ui/icons/Error";
import classesSpin from "../../components/Loader/Loader.module.css";
import { authCheck, getShareConfirm } from "../../Requests/Requests";
import { toast } from "react-toastify";

const ConfirmShare = (props) => {
  const [token, setToken] = useState(props.match.params.token);
  const [Projectid, setProjectid] = useState(props.match.params.id);
  const [statusResult, setStatusResult] = useState("loading");
  const [messageResult, setMessageResult] = useState("");
  useEffect(() => {
    getShareConfirm(Projectid, token)
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          setStatusResult("success");
          setTimeout(() => {
            checkAutontication();
          }, 5000);
          if (res.data.message) {
            setMessageResult(res.data.message);
            toast.success(res.data.message);
          }
        } else {
          setStatusResult("error");
          setTimeout(() => {
            checkAutontication();
          }, 5000);
          if (res.data.error) {
            setMessageResult(res.data.error);
            toast.error(res.data.error);
          }
        }
      })
      .catch((err) => {
        setStatusResult("error");
        setTimeout(() => {
          checkAutontication();
        }, 5000);
        if (err.response.data.error) {
          setMessageResult(err.response.data.error);
          toast.error(err.response.data.error);
        }
      });
  }, []);

  const checkAutontication = () => {
    authCheck()
      .then(() => props.history.replace("/panel/projects"))
      .catch(() => {
        props.history.replace("/");
      });
  };

  return (
    <div className={classes.mostOuterDiv}>
      <div className={classes.divAroundForm}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Paper className={classes.paper} variant="outlined" elevation={24}>
            <div className={classes.logo}>
              <img
                className={classes.img}
                src={process.env.PUBLIC_URL + "/assets/logo-new.png"}
                alt="brand icon"
              />
            </div>

            <div className={classes.PanelTokenResultBox}>
              {statusResult == "loading" ? (
                <>
                  <h3 className={classes.TokenResultTitle}>
                    Processing Token...
                  </h3>

                  <img
                    className={classesSpin.spinItemAlways}
                    src="/burryIcon.png"
                    style={{ width: "51px" }}
                  />
                </>
              ) : statusResult == "success" ? (
                <>
                  <h3
                    title={messageResult}
                    style={{ color: "#53cc86" }}
                    className={classes.TokenResultTitle}
                  >
                    Share successfully!
                  </h3>
                  <svg
                    title={messageResult}
                    style={{
                      width: "51px",
                      height: "38px",
                      marginTop: "0px",
                    }}
                    className={classes.animatedCheck}
                    viewBox="0 0 24 24"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="12"
                      stroke="black"
                      fill="#53cc86"
                      stroke-width="0"
                    />
                    <path
                      style={{ strokeWidth: "2px", strokeDasharray: "20" }}
                      d="M5.1 12.7L9 17.6 20.3 6.3"
                      fill="none"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <h3
                    title={messageResult}
                    style={{ color: "tomato" }}
                    className={classes.TokenResultTitle}
                  >
                    Share failed!
                  </h3>
                  <ErrorIcon
                    title={messageResult}
                    style={{
                      width: "50px",
                      height: "39px",
                      color: "tomato",
                    }}
                  />
                </>
              )}
            </div>
          </Paper>
        </form>
      </div>
    </div>
  );
};

export default withRouter(ConfirmShare);
