import React from "react";
import PropTypes from "prop-types";

export default function EnterIcon(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 14a1 1 0 0 1 1-1h12a3 3 0 0 0 3-3V6a1 1 0 1 1 2 0v4a5 5 0 0 1-5 5H4a1 1 0 0 1-1-1z"
          fill={props.color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.293 14.707a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 1.414L5.414 14l3.293 3.293a1 1 0 1 1-1.414 1.414l-4-4z"
          fill={props.color}
        />
      </svg>
    </>
  );
}
EnterIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
EnterIcon.defaultProps = {
  width: "15px",
  height: "15px",
  color: "rgba(55, 53, 47, 0.45)",
};
