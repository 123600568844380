import store from "store";

let highlight;
let tourIndex = 0;

const tourPath = [
  {
    element: "#templatePdfFile",
    description: `Click on it and the editor will open and write the references in it`,
  },
  {
    element: "#handleValidateReferences",
    description: `It checks the references entered in the editor and displays whether the reference is right or wrong`,
  },
];
let setEditorOpen = () => {};
export const exitTour = () => {
  setEditorOpen(false);
  store.set("refTourIsShown", true);
  removeTour();
};
export const endTour = () => {
  window.onresize = () => {};
  setEditorOpen(false);
  removeTour();
};
export const startTour = (editorfunc) => {
  setEditorOpen = editorfunc;
  if (store.get("refTourIsShown") === true) return;
  tourIndex = 0;
  window.addEventListener("resize", () => {
    if (store.get("refTourIsShown") === true) return;
    renderTour();
  });
  renderTour();
};

export const next = () => {
  tourIndex++;
  if (tourIndex >= tourPath.length) {
    tourIndex = 0;
    exitTour();
    return;
  }
  setEditorOpen(true);
  renderTour();
};

export const prev = () => {
  tourIndex--;
  if (tourIndex === -1) {
    tourIndex = 0;
    exitTour();
    return;
  }

  setEditorOpen(false);
  renderTour();
};

function getElementPosition(el) {
  let left = 0,
    top = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    left += el.offsetLeft - el.scrollLeft;
    top += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top, left };
}

const scrollElementIntoView = (el, diff = -100) => {
  const top = getElementPosition(el).top + diff;
  window.scrollTo({
    top: top,
    behavior: "smooth",
  });
};

const renderTour = (diff = -100) => {
  removeTour();
  const el = document.querySelector(tourPath[tourIndex].element);
  document.body.style.overflowY = "hidden";
  scrollElementIntoView(el);
  setTimeout(() => {
    if (!el) return;
    let width = el.offsetWidth;
    let height = el.offsetHeight;
    const offset = getElementPosition(el);
    const details = {
      top: offset.top - 10,
      left: offset.left - 10,
      width: width + 20,
      height: height + 20,
    };
    highlight && highlight.remove && highlight.remove(); // delete highlight if exists (to prevent junk element production. because in the next line I'm gonna (re)assign value to the highlight variable.)
    highlight = document.createElement("div");
    highlight.className = "msk-tour-highlight";
    highlight.style.width = details.width + "px";
    highlight.style.height = details.height + "px";

    highlight.style.top = details.top + "px";
    highlight.style.left = details.left + "px";
    highlight.style.zIndex = 10000;

    document.body.appendChild(highlight);
    setTimeout(() => {
      highlight.style.boxShadow = "0 0 0 100000px #000000AA";
      let box = document.createElement("div");
      box.className = "msk-tour-box";
      let triangleStyle = "";
      if (details.left + details.width / 2 > window.innerWidth - (150 + 10)) {
        const shift =
          details.width / 2 + details.left + 150 + 10 - window.innerWidth;
        box.style.transform = `translateX(-${shift}px)`;
        triangleStyle = `transform: translateX(${shift}px)`;
      } else if (details.left + details.width / 2 <= 150 + 10) {
        const shift = Math.abs(details.left + details.width / 2 - (150 + 10));
        box.style.transform = `translateX(${shift}px)`;
        triangleStyle = `transform: translateX(-${shift}px)`;
      }
      box.style.top =
        el.id == "handleValidateReferences" ? "calc(100% + -280px)" : "";
      triangleStyle =
        el.id == "handleValidateReferences"
          ? "transform:rotateX(180deg) translateX(126px);top: 197px"
          : triangleStyle;
      box.innerText = tourPath[tourIndex].description;
      highlight.appendChild(box);

      const canPrev = tourIndex !== 0;
      const canNext = tourIndex !== tourPath.length - 1;
      const prevButtonBorderRadius = canNext ? "10px 0 0 10px" : "10px";
      const nextButtonBorderRadius = canPrev ? "0 10px 10px 0" : "10px";

      box.innerHTML = `
            <div class="triangle" style="left: calc(50% - 23px); ${triangleStyle}"></div>
            <div class="msk-tour-header">
                <div class="msk-tour-close-button" id="msk-tour-close-button" title="Skip tour">&times;</div>
            </div>
            <div class="msk-tour-body">${tourPath[tourIndex].description}</div>
            <div class="msk-tour-dots">
                ${Array(tourPath.length)
                  .fill(0)
                  .map(
                    (dot, index) =>
                      `<div class="msk-tour-dot${
                        index === tourIndex ? " active" : ""
                      }"></div>`
                  )
                  .join("")}            
            </div>
            <div class="msk-tour-buttons-container">
                <button type="button" class="msk-tour-button" id="msk-tour-skip-btn">Skip</button>
                <div style="flex: 1"></div>
                ${
                  canPrev
                    ? `<button type="button" class="msk-tour-button" style="border-radius: ${prevButtonBorderRadius}" id="msk-tour-prev-btn">Prev</button>`
                    : ""
                }
                ${
                  canNext
                    ? `<button type="button" class="msk-tour-button" style="border-radius: ${nextButtonBorderRadius}" id="msk-tour-next-btn">Next</button>`
                    : ""
                }
            </div>
            `;
      const skipButton = box.querySelector("#msk-tour-skip-btn");
      const closeButton = box.querySelector("#msk-tour-close-button");
      const nextButton = box.querySelector("#msk-tour-next-btn");
      const prevButton = box.querySelector("#msk-tour-prev-btn");
      skipButton?.addEventListener("click", exitTour);
      closeButton?.addEventListener("click", exitTour);
      nextButton?.addEventListener("click", next);
      prevButton?.addEventListener("click", prev);
    }, 100);
  }, 1000);
};

const removeTour = () => {
  document.body.style.overflowY = "auto";
  if (!highlight) return;
  highlight.style.boxShadow = "0 0 0 100000px #00000000";
  setTimeout(() => {
    highlight.remove();
    // document.body.removeChild(highlight);
    // document.body.removeChild(box);
  }, 500);
};
