import React from "react";
import propTypes from "prop-types";
import AuthorsList from "./AuthorsList";
import CreateAuthors from "./createAuthors";

function Authors(props) {
  return (
    <div>
      <AuthorsList
        AuthorsList={props.AuthorsList}
        projectid={props.projectid}
        setProjectInfo={props.setProjectInfo}
      />

      <CreateAuthors
        AuthorsList={props.AuthorsList}
        projectid={props.projectid}
        setProjectInfo={props.setProjectInfo}
      />
    </div>
  );
}
export default Authors;
Authors.propTypes = {
  AuthorsList: propTypes.array,
  projectid: propTypes.number,
  setProjectInfo: propTypes.func,
};
Authors.defaultProps = {
  AuthorsList: [],
  projectid: 0,
  setProjectInfo: (authors) => {},
};
