import React, { useEffect, useState } from 'react';
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import './FullScreenEditor.css';
// docs url: https://jpuri.github.io/react-draft-wysiwyg/#/docs
// Added in index.js --> import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MSKEditor from '../MSKEditor/MSKEditor';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: 'var(--light-red)',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

// const getFileBase64 = (file, callback) => {
//     var reader = new FileReader();
//     reader.readAsDataURL(file);
//     // Since FileReader is asynchronous,
//     // we need to pass data back.
//     reader.onload = () => callback(reader.result);
//     // TODO: catch an error
//     reader.onerror = error => {
//     };
// };

// const imageUploadCallback = file => new Promise(
//     (resolve, reject) => getFileBase64(
//         file,
//         data => resolve({data: {link: data}})
//     )
// );

// function entityMapper(entity) {
//     if (entity.type === "DIV") {
//         return `<div>${entity.data.innerHTML}</div>`;
//     }
//     if (entity.type === "TABLE") {
//         return `<table>${entity.data.innerHTML}</table>`;
//     }
//     if (entity.type === "TBODY") {
//         return `<tbody>${entity.data.innerHTML}</tbody>`;
//     }
//     if (entity.type === "TR") {
//         return `<tr>${entity.data.innerHTML}</tr>`;
//     }
//     if (entity.type === "TH") {
//         return `<th>${entity.data.innerHTML}</th>`;
//     }
//     if (entity.type === "TD") {
//         return `<td>${entity.data.innerHTML}</td>`;
//     }
//     if (entity.type === "STYLE") {
//         return `<style>${entity.data.innerHTML}</style>`;
//     }
//     return "";
// }

// function customChunkRenderer(nodeName, node) {
//     const allowedNodes = [
//         "div",
//         "table",
//         "tbody",
//         "tr",
//         "th",
//         "td",
//         "thead",
//         "style"
//     ];
//
//     if (allowedNodes.includes(nodeName)) {
//         return {
//             type: nodeName.toString().toUpperCase(),
//             mutability: "MUTABLE",
//             data: {
//                 // Pass whatever you want here (like id, or classList, etc.)
//                 innerText: node.innerText,
//                 innerHTML: node.innerHTML
//             }
//         };
//     }
//     return null;
// }

// function entityMapperToComponent(entity) {
//     if (entity.type === "DIV") {
//         return () => (
//             <div dangerouslySetInnerHTML={{__html: entity.data.innerHTML}}/>
//         );
//     }
//     if (entity.type === "TABLE") {
//         return () => (
//             <table dangerouslySetInnerHTML={{__html: entity.data.innerHTML}}/>
//         );
//     }
//     if (entity.type === "TBODY") {
//         return (
//             <tbody dangerouslySetInnerHTML={{__html: entity.data.innerHTML}}/>
//         );
//     }
//     if (entity.type === "TR") {
//         return () => (
//             <tr dangerouslySetInnerHTML={{__html: entity.data.innerHTML}}/>
//         );
//     }
//     if (entity.type === "TH") {
//         return () => (
//             <th dangerouslySetInnerHTML={{__html: entity.data.innerHTML}}/>
//         );
//     }
//     if (entity.type === "TD") {
//         return () => (
//             <td dangerouslySetInnerHTML={{__html: entity.data.innerHTML}}/>
//         );
//     }
//     if (entity.type === "STYLE") {
//         return () => <style>{entity.data.innerHTML}</style>;
//     }
//
//     return "";
// }

// function customBlockRenderFunc(block, config) {
//     console.log("block", block);
//     console.log("config", config);
//     if (block.getType() === "atomic") {
//         const contentState = config.getEditorState().getCurrentContent();
//         const entity = contentState.getEntity(block.getEntityAt(0));
//         return {
//             component: entityMapperToComponent(entity),
//             editable: false,
//             props: {
//                 children: () => entity.innerHTML
//             }
//         };
//     }
//     return undefined;
// }

const FullScreenEditor = (props) => {
    const { data, onChange, onClose, open } = props;
    const [html, setHtml] = useState('');

    const classes = useStyles();

    useEffect(() => {
        setHtml(data);
    }, []);

    const handleClose = () => {
        // onClose(data);
        // console.log(data)
        onClose(html);
    };
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // useEffect(() => {
    //     if (data) {
    //         addHtmlToEditor(data);
    //         console.log(data)
    //     }
    // }, [])
    // const [editorHTML, setEditorHTML] = useState("");
    // const onEditorStateChange = (editor) => {
    //     const editorHTML = draftToHtml(
    //         convertToRaw(editor.getCurrentContent()),
    //         null,
    //         false,
    //         entityMapper
    //     );
    //     setEditorState(editor);
    //     setEditorHTML(editorHTML);
    //     onChange(editorHTML);
    // };
    // const [showEditorCode, setShowEditorCode] = useState(false);
    // const onEditEditorHTML = (e) => {
    //     const editorHTML = e.target.value;
    //     setEditorHTML(editorHTML);
    // };
    // const toggleEditorCode = () => {
    //     if (!showEditorCode) {
    //         onEditorStateChange(editorState);
    //     }
    //     setShowEditorCode((prev) => !prev);
    // };
    // const ShowEditorCode = () => (
    //     <div className="rdw-option-wrapper" onClick={toggleEditorCode}>
    //         {showEditorCode ? "Hide Code" : "Show Code"}
    //     </div>
    // );
    // const addHtmlToEditor = (html) => {
    //     const contentBlock = htmlToDraft(html, customChunkRenderer);
    //     let editor;
    //     if (contentBlock) {
    //         const contentState = ContentState.createFromBlockArray(
    //             contentBlock.contentBlocks
    //         );
    //         editor = EditorState.createWithContent(contentState);
    //     } else {
    //         editor = EditorState.createEmpty();
    //     }
    //     onEditorStateChange(editor);
    // };
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleClose}
                        aria-label='close'
                    >
                        <CloseIcon />
                    </IconButton>
                    {/*<CustomTextField*/}
                    {/*    dark={false}*/}
                    {/*    value={'Some Text'}*/}
                    {/*    name={'keywords'}*/}
                    {/*    onChange={e => {*/}

                    {/*    }}*/}
                    {/*    label="Section title"*/}
                    {/*    variant="outlined"*/}
                    {/*    placeholder={'Keyword: Use enter for adding keyword'}*/}
                    {/*/>*/}
                    <Typography variant='h6' className={classes.title}>
                        Editor
                    </Typography>
                    <Button autoFocus color='inherit' onClick={handleClose}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>
            <div style={{ height: '100%', overflow: 'hidden' }}>
                <MSKEditor
                    defaultValue={data}
                    onChange={(html) => {
                        setHtml(html);
                        // onChange(html); Do NOT uncomment otherwise user will lose cursor in every type !!!
                    }}
                />
                <>
                    {/*<Editor*/}
                    {/*    toolbar={{*/}
                    {/*        // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker',*/}
                    {/*        //         'link', 'embedded', 'emoji', 'image', 'remove', 'history'],*/}
                    {/*        // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker',*/}
                    {/*        //     'link', 'embedded', /*'emoji', 'image', 'remove', 'history'],*/}
                    {/*        // inline: {*/}
                    {/*        //     inDropdown: false,*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        //     options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],*/}
                    {/*        //     bold: {className: undefined},*/}
                    {/*        //     italic: {className: undefined},*/}
                    {/*        //     underline: {className: undefined},*/}
                    {/*        //     strikethrough: {className: undefined},*/}
                    {/*        //     monospace: {className: undefined},*/}
                    {/*        //     superscript: {className: undefined},*/}
                    {/*        //     subscript: {className: undefined},*/}
                    {/*        // },*/}
                    {/*        // blockType: {*/}
                    {/*        //     inDropdown: true,*/}
                    {/*        //     options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        // },*/}
                    {/*        // fontSize: {*/}
                    {/*        //*/}
                    {/*        //     options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        // },*/}
                    {/*        // fontFamily: {*/}
                    {/*        //     options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        // },*/}
                    {/*        // list: {*/}
                    {/*        //     inDropdown: false,*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        //     options: ['unordered', 'ordered', 'indent', 'outdent'],*/}
                    {/*        //     unordered: {className: undefined},*/}
                    {/*        //     ordered: {className: undefined},*/}
                    {/*        //     indent: {className: undefined},*/}
                    {/*        //     outdent: {className: undefined},*/}
                    {/*        // },*/}
                    {/*        // textAlign: {*/}
                    {/*        //     inDropdown: false,*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        //     options: ['left', 'center', 'right', 'justify'],*/}
                    {/*        //     left: {className: undefined},*/}
                    {/*        //     center: {className: undefined},*/}
                    {/*        //     right: {className: undefined},*/}
                    {/*        //     justify: {className: undefined},*/}
                    {/*        // },*/}
                    {/*        // colorPicker: {*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     popupClassName: undefined,*/}
                    {/*        //     colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',*/}
                    {/*        //         'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',*/}
                    {/*        //         'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',*/}
                    {/*        //         'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',*/}
                    {/*        //         'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',*/}
                    {/*        //         'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],*/}
                    {/*        // },*/}
                    {/*        // link: {*/}
                    {/*        //     inDropdown: false,*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     popupClassName: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        //     showOpenOptionOnHover: true,*/}
                    {/*        //     defaultTargetOption: '_self',*/}
                    {/*        //     options: ['link', 'unlink'],*/}
                    {/*        //     link: {className: undefined},*/}
                    {/*        //     unlink: {className: undefined},*/}
                    {/*        //     linkCallback: undefined*/}
                    {/*        // },*/}
                    {/*        // emoji: {*/}
                    {/*        //*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     popupClassName: undefined,*/}
                    {/*        //     emojis: [*/}
                    {/*        //         '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',*/}
                    {/*        //         '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',*/}
                    {/*        //         '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',*/}
                    {/*        //         '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',*/}
                    {/*        //         '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',*/}
                    {/*        //         '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',*/}
                    {/*        //         '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',*/}
                    {/*        //         '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',*/}
                    {/*        //         '✅', '❎', '💯',*/}
                    {/*        //     ],*/}
                    {/*        // },*/}
                    {/*        // embedded: {*/}
                    {/*        //*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     popupClassName: undefined,*/}
                    {/*        //     embedCallback: undefined,*/}
                    {/*        //     defaultSize: {*/}
                    {/*        //         height: 'auto',*/}
                    {/*        //         width: 'auto',*/}
                    {/*        //     },*/}
                    {/*        // },*/}
                    {/*        image: {*/}
                    {/*            uploadCallback: imageUploadCallback,*/}
                    {/*            previewImage: true,*/}
                    {/*        },*/}
                    {/*        // remove: {className: undefined, component: undefined},*/}
                    {/*        // history: {*/}
                    {/*        //     inDropdown: false,*/}
                    {/*        //     className: undefined,*/}
                    {/*        //     component: undefined,*/}
                    {/*        //     dropdownClassName: undefined,*/}
                    {/*        //     options: ['undo', 'redo'],*/}
                    {/*        //     undo: {className: undefined},*/}
                    {/*        //     redo: {className: undefined},*/}
                    {/*        // },*/}
                    {/*    }}*/}
                    {/*    editorState={editorState}*/}
                    {/*    toolbarClassName="toolbarClassName"*/}
                    {/*    wrapperClassName="wrapperClassName"*/}
                    {/*    editorClassName="editorClassName"*/}
                    {/*    onEditorStateChange={onEditorStateChange}*/}
                    {/*    // customBlockRenderFunc={customBlockRenderFunc}*/}
                    {/*    // toolbarCustomButtons={[<ShowEditorCode />]}*/}
                    {/*    // customBlockRenderFunc={customBlockRenderFunc} DO not uncomment otherwise images can nor load*/}
                    {/*    // toolbarCustomButtons={[<ShowEditorCode />]}*/}
                    {/*/>*/}
                    {/*      {showEditorCode && (*/}
                    {/*          <div style={{width: "100%"}}>*/}
                    {/*<textarea*/}
                    {/*    rows={10}*/}
                    {/*    style={{*/}
                    {/*        width: "100%",*/}
                    {/*        padding: "0"*/}
                    {/*    }}*/}
                    {/*    value={editorHTML}*/}
                    {/*    onChange={onEditEditorHTML}*/}
                    {/*/>*/}
                    {/*              <div>*/}
                    {/*                  <button type="button" onClick={() => addHtmlToEditor(editorHTML)}>*/}
                    {/*                      Submit*/}
                    {/*                  </button>*/}
                    {/*              </div>*/}
                    {/*          </div>*/}
                    {/*      )}*/}
                    {/*<CKEditor*/}
                    {/*    editor={Editor}*/}
                    {/*    config={ckeditorConfig}*/}
                    {/*    data={data || ''}*/}
                    {/*    onChange={(event, editor) => {*/}
                    {/*        onChange(editor.getData());*/}
                    {/*    }}*/}
                    {/*/>*/}
                </>
            </div>
        </Dialog>
    );
};

export default FullScreenEditor;

FullScreenEditor.propTypes = {
    data: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

FullScreenEditor.defaultProps = {
    data: '',
    onChange: () => {},
    onClose: () => {},
    open: false,
};
