import "../PubBerry/App.css";
import Title from "../PubBerry/components/Title/Title";
import PropTypes from "prop-types";
import fullscreenImage from "./images/fullscreen.png";
import WarningIcon from "@material-ui/icons/Warning";

import smallscreenImage from "./images/smallscreen.png";
import { Container, Grid } from "@material-ui/core";
import SingleChoice from "../PubBerry/components/SingleChoice/SingleChoice";
import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import classes from "../PubBerry/App.module.css";
import FileInput from "../PubBerry/components/FileInput/FileInput";
import CustomButton from "../PubBerry/components/CustomButton/CustomButton";
import defaultForm from "../PubBerry/data/defaultForm";
import FullScreenEditor from "./Components/Editor/FullScreenEditor";
import CustomSelect, {
  CustomOption,
} from "./Components/CustomSelect/CustomSelect";
import {
  restoreDataFromCookie,
  saveDataInBrowser,
} from "./Config/saveinprogress";
import PulseEffect from "../PubBerry/components/PulseEffect/PulseEffect";
import { getDataToSubmit } from "./Functions/getDataToSubmit";
import axios from "axios";
import { startTour, endTour } from "./Config/msktour";
import "../PubBerry/Config/msktour.css";
import { authCheck } from "../../Requests/Requests";
import { useHistory } from "react-router";

let restoring = true;

function RefBerry(props) {
  const [errorPulsePosition, setErrorPulsePosition] = useState({
    x: 100,
    y: 100,
  });
  const [error, setError] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [templatePDFFile, setTemplatePDFFile] = useState(null);
  const [referenceFile, setReferenceFile] = useState(null);
  const [editorData, setEditorData] = useState("");
  const [editorOpen, setEditorOpen] = useState(props.EditerOpenhandler);
  const [editorDataChanged, setEditorDataChanged] = useState(0);
  const [refCheck, setRefCheck] = useState(null);
  const [screenStyle, setScreenStyle] = useState("normal");
  const [innerwidth, setInnerWidth] = useState(window.innerWidth);
  const reg = /[ ]*class=\"[A-Za-z]*[0-9]*\"[ ]*/g;

  const saveDate = (from) => {
    if (props.useCustomSaveData) {
      props.saveDataInBrowsers(from);
    } else {
      saveDataInBrowser(form);
    }
  };
  const setRerfrencetype = (value) => {
    setInForm("referenceType", value == null ? value : value.trim());
    if (props.useRefTypeInForm) {
      props.setHtmlInForm(
        "referenceType",
        value == null ? value : value.trim()
      );
    }
  };
  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [innerwidth]);

  const [editorCloseHandler, setEditorCloseHandler] = useState({
    func: () => {},
  });
  const [refBerryRemindCredit, setrefBerryRemindCredit] = useState(0);
  const handleReferenceFileUpload = (file) => {
    setReferenceFile(file);
    if (props.useRefFileInForm) {
      props.setRefFileInForm(File);
    }
  };

  const setInForm = (key, value) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };
  const handleChangeEditorData = (data) => {
    data = data.replaceAll(reg, "").replaceAll("divstyle", "div style");
    setEditorData(data);
  };
  const handleCloseReferenceEditor = (data) => {
    data = data.replace(reg, "").replaceAll("divstyle", "div style");
    setInForm("references", data);
    if (props.useHtmlInForm) {
      props.setHtmlInForm("references", data);
    }
    setEditorOpen(false);
    setEditorData("");
  };
  const handleStartReferencesEditing = () => {
    setEditorCloseHandler({
      func: handleCloseReferenceEditor,
    });
    setEditorOpen(true);

    form.references = form.references
      .replace(reg, "")
      .replaceAll("divstyle", "div style");
    setEditorData(form.references);

    setEditorDataChanged(Math.random());
  };

  const closeRefBerryValidation = () => {
    setScreenStyle("normal");
    setRefCheck(null);
    document.body.style.overflow = "auto";
  };
  const submitTypes = { VIEW_HTML: "VIEW_HTML", GENERATE_XML: "GENERATE_XML" };
  const handleValidateReferences = (data) => {
    data = data.replaceAll(reg, "").replaceAll("divstyle", "div style");
    setInForm("references", data);
    if (props.useHtmlInForm) {
      props.setHtmlInForm("references", data);
    }
    form.references = data;
    const formData = {
      ...form,
      referenceFile: referenceFile,
      templatePDFFile: templatePDFFile,
    };
    const dataToSubmit = getDataToSubmit(formData);

    axios
      .get(process.env.REACT_APP_BASE_URL + "/pubberry/get_credit_info")
      .then(async (response) => {
        let refberry_credits = response.data.refberry_credits;

        if (refberry_credits > 0) {
          setrefBerryRemindCredit(refberry_credits);
          axios
            .post(
              process.env.REACT_APP_BASE_URL + "/pubberry/validate_refs_json",
              dataToSubmit,
              { responseType: "blob" }
            )
            .then(async (response) => {
              setrefBerryRemindCredit(refberry_credits - 1);

              var text = await response.data.text();
              console.log(text);
              clearIdFromDivHtml();
              selectRefrenceValidationItem(0);
              text = getJsonAndCOnvertToHtml(text);

              setRefCheck(text);
              document.body.style.overflow = "hidden";
            })
            .catch((error) => {
              clearIdFromDivHtml();
              var text = (
                <div class="ref-no-credit">
                  Ooops! it seems system is unable to respond!{" "}
                </div>
              );
              setRefCheck(text);
              document.body.style.overflow = "hidden";
            });
        } else {
          clearIdFromDivHtml();
          setrefBerryRemindCredit(refberry_credits - 1);
          var text = (
            <div class="ref-no-credit">
              Ooops! it seems you don\'t have any credit left!{" "}
            </div>
          );
          setRefCheck(text);
          document.body.style.overflow = "hidden";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const selectRefrenceValidationItem = (index) => {
    document.querySelectorAll(".refrenceValidationItem").forEach((item) => {
      item.style.backgroundColor = "";
    });
    let obj = document.getElementById("refrenceValidationItemid" + index);
    if (obj != null && index > 0) {
      if (obj.classList.contains("ref-err")) {
        obj.style.backgroundColor = "#ed083b8a";
      } else if (obj.classList.contains("ref-duplicate")) {
        obj.style.backgroundColor = "#aca9aa";
      } else if (obj.classList.contains("ref-success")) {
        obj.style.backgroundColor = "#53cc868a";
      }

      let node = document.getElementsByClassName("RefrenseValidatorEditor");

      if (node != null) {
        node = node[0];
        var found = node.getElementsByClassName(
          "refrenceValidationTextItemid" + index
        );
        if (found != null && found.length > 0) {
          found = found[0];
          node.parentElement.scrollTop = found.offsetTop - node.offsetTop - 20;
          selectedText(found);
        }
      }
    }
  };

  const selectedText = (node) => {
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn("Could not select text in node: Unsupported browser.");
    }
  };
  const clearIdFromDivHtml = () => {
    let node = document.getElementsByClassName("RefrenseValidatorEditor");

    if (node != null) {
      node = node[0];
      var aTags = node.getElementsByTagName("div");
      for (var i = 0; i < aTags.length; i++) {
        aTags[i].removeAttribute("class");
      }
    }
  };
  const getJsonAndCOnvertToHtml = (jsonText) => {
    let htmlRefValidateResult = [];
    let duplicateText = [];
    let duplicateCount = 0,
      successCount = 0,
      wrongCount = 0;
    const jsonResult = JSON.parse(
      "[" + jsonText.replaceAll('"}{"', '"},{"') + "]"
    );
    for (let i = 1; i <= jsonResult.length; i++) {
      console.log(duplicateText, jsonResult[i - 1].title);
      duplicateText.push(
        jsonResult[i - 1].title
          .replaceAll(" ", "")
          .replaceAll("ö", "o")
          .replaceAll("ü", "u")
          .replaceAll("ä", "a")
          .replaceAll("Ö", "O")
          .replaceAll("Ü", "U")
          .replaceAll("Ä", "A")
          .replaceAll("ß", "ss")
          .toLowerCase()
      );
      let count = countDuplicatedValueInArray(
        duplicateText,
        jsonResult[i - 1].title
          .replaceAll(" ", "")
          .replaceAll("ö", "o")
          .replaceAll("ü", "u")
          .replaceAll("ä", "a")
          .replaceAll("Ö", "O")
          .replaceAll("Ü", "U")
          .replaceAll("Ä", "A")
          .replaceAll("ß", "ss")
          .toLowerCase()
      );

      htmlRefValidateResult.push(
        <div
          id={"refrenceValidationItemid" + i}
          key={"refrenceValidationItemid" + i}
          onClick={() => {
            selectRefrenceValidationItem(i);
          }}
          className={
            count <= 1
              ? jsonResult[i - 1].result == "wrong"
                ? "ref-err refrenceValidationItem"
                : "ref-success refrenceValidationItem"
              : "ref-duplicate refrenceValidationItem"
          }
        >
          {addDuplicateAlarm(count)}
          {jsonResult[i - 1].body}
        </div>
      );

      duplicateCount += count > 1 ? 1 : 0;
      successCount +=
        count <= 1 ? (jsonResult[i - 1].result == "wrong" ? 0 : 1) : 0;
      wrongCount +=
        count <= 1 ? (jsonResult[i - 1].result == "wrong" ? 1 : 0) : 0;

      let div = findNodefromEditor(jsonResult[i - 1].body, count);
      if (div != null) {
        div.className = "refrenceValidationTextItemid" + i;
      }
    }
    if (htmlRefValidateResult.length > 0) {
      htmlRefValidateResult.unshift(
        <div
          className={"refrence-summery"}
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-around",
            justifyContent: "space-between",
            padding: "8px 14px 0px 14px",
            fontWeight: "700",
          }}
        >
          <div>All : {successCount + wrongCount + duplicateCount} </div>
          <div>Success : {successCount} </div>
          <div>Wrong : {wrongCount} </div>
          <div>Duplicate : {duplicateCount} </div>
        </div>
      );
    }
    return htmlRefValidateResult;
  };
  const addDuplicateAlarm = (countitem) => {
    if (countitem > 1) {
      return (
        <>
          <WarningIcon className={"warning"} />{" "}
          <div style={{ marginTop: "5px", marginLeft: "28px" }}> Duplicate</div>
          <br style={{ clear: "both" }} />
        </>
      );
    }
    return <></>;
  };
  const countDuplicatedValueInArray = (duplicateText, searchText) => {
    let count = 0;
    for (var i = 0; i < duplicateText.length; ++i) {
      if (
        searchText != null &&
        duplicateText[i] != null &&
        duplicateText[i] == searchText
      )
        count++;
    }
    return count;
  };
  const findNodefromEditor = (searchText, count) => {
    let node = document.getElementsByClassName("RefrenseValidatorEditor");
    if (node != null) {
      node = node[0];
      var aTags = node.getElementsByTagName("div");
      var found = null;

      for (var i = 0; i < aTags.length; i++) {
        if (aTags[i].textContent.trim() == searchText.trim()) {
          count = count - 1;
          if (count <= 0) {
            found = aTags[i];
            break;
          }
        }
      }
      return found;
    }
    return null;
  };
  useEffect(() => {
    restoring = true;
    if (props.useCustomRestoreData) {
      props.restoreDataFromCookies((form) => {
        if (Object.keys(form).length !== 0) setForm(form);
        setTimeout(() => {
          restoring = false;
        }, 1000);
      });
    } else {
      restoreDataFromCookie((form) => {
        if (Object.keys(form).length !== 0) setForm(form);
        setTimeout(() => {
          restoring = false;
        }, 1000);
      });
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (restoring) return;
      saveDate(form);
    }, 2000);
  }, [form]);
  let history = useHistory();
  useEffect(() => {
    authCheck()
      .then(() => {})
      .catch(() => {
        history.replace("/");
      });
  }, []);
  useEffect(() => {
    if (props.refberryTour != false) {
      startTour(setEditorOpen);
    }
    return () => {
      endTour();
    };
  }, []);
  useEffect(() => {
    setEditorOpen(props.EditerOpenhandler);
  }, [props.EditerOpenhandler]);
  return (
    <div className="Pub">
      <PulseEffect
        x={errorPulsePosition.x}
        y={errorPulsePosition.y}
        visible={error}
      />
      <div className={classes.darkSection + " " + "ref-Page"}>
        <Container>
          <br />
          <Fade bottom>
            <div
              id="refValidate1"
              style={{ position: "relative", top: -100 }}
            />
            <Title dark={false} text={props.refTitle} />
          </Fade>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={4}>
              <Fade left>
                <div className={classes.titleContainer}>Type:</div>
              </Fade>
            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              md={3}
              sm={5}
              xs={8}
              style={{ zIndex: "1" }}
            >
              <Fade right>
                <CustomSelect
                  selectedValue={form.referenceType}
                  selectedText={form.referenceType}
                  hasGetSelectedValue={true}
                  getSelectedValue={setRerfrencetype}
                />
              </Fade>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={4}>
              <Fade left>
                <div className={classes.titleContainer}>Input type:</div>
              </Fade>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={5} xs={8}>
              <Fade right>
                <SingleChoice
                  id="refrenceyInputType"
                  name={"referenceInputType"}
                  active={form.referenceInputType}
                  options={[
                    { value: "Editor", displayValue: "Editor" },
                    { value: "Select File", displayValue: "Select File" },
                  ]}
                  onOptionSelect={(val) => {
                    setInForm("referenceInputType", val);
                    if (props.useInputRefInForm) {
                      props.setHtmlInForm("referenceInputType", val);
                    }
                  }}
                />
              </Fade>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid item xl={4} lg={4} md={5} sm={7} xs={12}>
              <Fade bottom>
                {form.referenceInputType === "Editor" ? (
                  <CustomButton
                    id={"templatePdfFile"}
                    onClick={handleStartReferencesEditing}
                    style={{ margin: "5px 0 0 0" }}
                  >
                    Edit
                  </CustomButton>
                ) : (
                  <FileInput
                    id={"templatePdfFile"}
                    containerStyle={{ marginTop: 5 }}
                    theme={{
                      button: {
                        color: "var(--light-red)",
                        backgroundColor: "white",
                      },
                      borderColor: "white",
                      textColor: "white",
                    }}
                    accept={".pdf"}
                    value={referenceFile}
                    onFileSelect={handleReferenceFileUpload}
                    buttonText={"Select File"}
                  />
                )}
              </Fade>
            </Grid>
          </Grid>
          <br />
          <br />
        </Container>
      </div>
      <FullScreenEditor
        name="RefrenseValidatorEditor"
        width={
          screenStyle == "maximize" && innerwidth >= 800 && refCheck
            ? "calc(100% - 462px)"
            : "100%"
        }
        key={editorDataChanged}
        open={editorOpen}
        validRefrenceCloseHandeler={closeRefBerryValidation}
        displayBtnRefrence="block"
        spinValidateRefrence={true}
        RefrenceHandeler={handleValidateReferences}
        onClose={editorCloseHandler.func}
        data={editorData}
        onChange={handleChangeEditorData}
      />
      {refCheck && (
        <div
          style={{
            zIndex: 10000,
            position: "fixed",
            height: "calc(100% )",
            left: 0,
            top: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#00000055",
            overflowY: "auto",
          }}
        >
          <div
            className="boardslist"
            style={{
              zIndex: 10000,
              width: "85vw",
              maxWidth: "450px",
              maxHeight:
                screenStyle == "maximize"
                  ? "calc(100% - 122px)"
                  : screenStyle == "minimize"
                  ? "50px"
                  : "250px",
              minHeight: screenStyle == "maximize" ? "calc(100% - 122px)" : "",
              backgroundColor: "white",
              borderRadius: 10,
              overflowX: "hidden",
              paddingTop: 10,
              textAlign: "right",
              position: "fixed",
              bottom: screenStyle == "maximize" ? "58px" : "18px",
              right: screenStyle == "maximize" ? "20px" : "60px",
              boxShadow: "0px 2px 14px 2px #888888",
              fontSize: "12px",
            }}
          >
            <div className={"ref-Header"}>
              <span>
                <span style={{ color: "#ed083b", paddingRight: "5px" }}>
                  &#9677;
                </span>
                Reference Validator
              </span>
              <span
                style={{
                  marginRight: "40px",
                  marginLeft: "16px",
                }}
              >
                <b>Remaining Credit :</b>&nbsp; {refBerryRemindCredit}
              </span>
              <span
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "10px",
                  fontSize: "20px",
                  top: "0px",
                }}
                onClick={() => {
                  closeRefBerryValidation();
                }}
              >
                &times;
              </span>
              <span
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "30px",
                  fontSize: "20px",
                  top: "0px",
                }}
                onClick={() => {
                  screenStyle != "maximize"
                    ? setScreenStyle("maximize")
                    : setScreenStyle("normal");
                }}
              >
                <img
                  src={
                    screenStyle != "maximize"
                      ? fullscreenImage
                      : smallscreenImage
                  }
                ></img>
              </span>
              <span
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "50px",
                  fontSize: "20px",
                  top: "0px",
                }}
                onClick={() => {
                  screenStyle != "minimize"
                    ? setScreenStyle("minimize")
                    : setScreenStyle("normal");
                }}
              >
                ...
              </span>
            </div>
            <div>{refCheck}</div>
            <div
              style={{
                margin: "12px",
                display: refBerryRemindCredit == 0 ? "block" : "none",
              }}
            >
              <a className={"ref-btn"} href="/panel/subscription">
                Buy Credit
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default RefBerry;
RefBerry.propTypes = {
  refberryTour: PropTypes.bool,
  useCustomRestoreData: PropTypes.bool,
  useCustomSaveData: PropTypes.bool,
  restoreDataFromCookies: PropTypes.func,
  saveDataInBrowsers: PropTypes.func,
  EditerOpenhandler: PropTypes.bool,
  useHtmlInForm: PropTypes.bool,
  useRefTypeInForm: PropTypes.bool,
  useInputRefInForm: PropTypes.bool,
  setHtmlInForm: PropTypes.func,
  useRefFileInForm: PropTypes.bool,
  setRefFileInForm: PropTypes.func,
  refTitle: PropTypes.string,
};
RefBerry.defaultProps = {
  refTitle: "RefBerry",
  refberryTour: true,
  useCustomRestoreData: false,
  useCustomSaveData: false,
  restoreDataFromCookies: () => {},
  saveDataInBrowsers: () => {},
  useInputRefInForm: false,
  EditerOpenhandler: false,
  useRefTypeInForm: false,
  useHtmlInForm: false,
  setHtmlInForm: () => {},
  useRefFileInForm: false,
  setRefFileInForm: () => {},
};
