import "core-js/actual";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./config/axios.config";
import configAxios from "./config/axios.config";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff7961",
      main: "#EB3449",
      dark: "#ba000d",
      contrastText: "#FFF",
      // light: '#757ce8',
      // main: '#3f50b5',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  shape: {
    borderRadius: 5,
  },
});

// axios configuration: sets CSRF Token to the request header.
// And also sets the baseURL and credentials support.
configAxios();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
